import { Reducer } from 'redux';
import { home as homeActions } from '../actions';
import { IHomeState } from '../models';
export const initialState: IHomeState = {
  data: { brandsList: [], knowyourtireData: [], testimonialsListData: [] },
  banner: []
};

const home =  (
  state: IHomeState = initialState,
  action: homeActions.Action,
): IHomeState => {
  switch (action.type) {
    case homeActions.ActionTypes.SET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case homeActions.ActionTypes.SET_BANNER: {
      return {
        ...state,
        banner: action.payload,
      };
    }
    default:
      return state;
  }
};
export default home;
