import React, { createRef, ReactNode } from 'react';
import './Header.css'
import { images } from '../../../constants/Images';
import { Link } from 'react-router-dom';
import i18n from '../../../services/i18n';
import { ICoreCities, ICoreLangType } from '../../../models/ICoreState';
import { withTranslation } from 'react-i18next';
import { ILangState } from '../../../models';
import { Filter } from '../../../utils';

interface IProps {
   onClickLocation: () => void;
   city: ICoreCities;
   onClickProfile: () => void;
   isLoggedin: boolean
   onNotificationToggel: () => void;
   notificationCount: number;
   openCart: () => void;
   changeLang: (lang: ICoreLangType) => void;
   t: (param: string) => ReactNode;
   lang: ILangState
   isEdit: boolean
   // onLogout: () => void;
}

interface IState {
   isSticky: boolean
   toggelBar: boolean
}

class Header extends React.PureComponent<IProps, IState> {
   scroll = createRef<HTMLDivElement>()
   state: IState = {
      isSticky : false,
      toggelBar: false
   }
   handleScroll = () => {
      let offsetTop  = window.pageYOffset;
    this.setState({ isSticky: offsetTop > 0})
    };

   componentDidMount () {
      window.addEventListener('scroll', this.handleScroll);
   }

   componentWillUnmount () {
      window.removeEventListener('scroll', () => this.handleScroll);
   }

   toggelBar = () => {
      const { toggelBar } = this.state;
      this.setState({ toggelBar: !toggelBar  })
   }

    render() {
       const { isSticky, toggelBar } = this.state;
       const { onClickLocation, city, onClickProfile, onNotificationToggel, notificationCount, openCart, changeLang, t, lang, isEdit, isLoggedin } = this.props;
       console.log('i18n.language', i18n.language);
       
      return (
<header className={`navbar d-block navbar-floating navbar-sticky navbar-expand-lg navbar-${isSticky ? 'light' : 'dark'} ${isSticky && 'navbar-stuck' }`} ref={this.scroll} style={{ backgroundColor: '#fcc223' }}>
   <div className="container-fluid">
      <a href="javascript:void(0);" className="logo-caption order-lg-1" onClick={onClickLocation}>
         <span>{t(`label.current_city`)}</span>
         
         {Filter.translate(city.city, city.city_arabic)} 
         {
            isEdit && (
                  <img src={images.edit}/>
            )
         }
         
      </a>
         <Link  className="navbar-brand d-none d-sm-block mr-3" to="/">
         <img className="navbar-floating-logo" src={images.logo} alt="ESTBNH" />
         <img className="navbar-stuck-logo" src={images.logo} alt="ESTBNH" />
         </Link>
         <Link className="navbar-brand d-sm-none mr-2 order-lg-1" to="/">
            <img width="74" src={images.logo} alt="ESTBNH" />
         </Link>

         <div className="navbar-toolbar d-flex align-items-center order-lg-3">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" onClick={this.toggelBar}>
            <span className="navbar-toggler-icon"></span>
         </button>
         <a className="navbar-tool d-none d-lg-flex" href="javascript:void(0);" onClick={onNotificationToggel}>
            <div className="navbar-tool-icon-box">
               {
                  (notificationCount !== 0)  && (
                     <span className="notification-badge">{notificationCount}</span>
                  )
               }
               
               <img src={images.notification} alt="notification" />
            </div>
         </a>
         
         <a  className="navbar-tool d-none d-lg-flex" href="javascript:void(0);" onClick={openCart}>
            <div className="navbar-tool-icon-box">
               <img src={images.cart} alt="cart" />
            </div>
         </a>
         <div className="user_icon">
           <a className="navbar-tool d-none d-lg-flex ml-2 user_iconmain" href="javascript:void(0);" >
              <div className="navbar-tool-icon-box">
                 <img src={images.user} alt="user" />
              </div>
           </a>
           <ul className="sub_drop_menu clearfix">
                <li>
            <a className="" href="javascript:void(0);" onClick={onClickProfile}>{isLoggedin ? i18n.t(`common.logout`) : i18n.t(`wd.login.button.login`) }</a>
                </li>
            </ul>
          </div>
      </div>
      <div className={`collapse navbar-collapse mr-auto order-lg-2 ${toggelBar &&  'show'}`} >
         <div className="navbar-toolbar d-lg-none d-flex justify-content-center align-items-center order-lg-3">
            <a className="navbar-tool d-lg-flex" href="javascript:void(0)" onClick={onNotificationToggel}>
               <div className="navbar-tool-icon-box">
                  {
                     (notificationCount !== 0) && (
                        <span className="notification-badge">{notificationCount}</span>
                     )
                  }
                  <img src={images.notification} alt="notification" />
               </div>
            </a>
            <a  className="navbar-tool d-lg-flex" href='javascript:void(0)' onClick={openCart}>
            <div className="navbar-tool-icon-box">
                  <img src={images.cart} alt="cart" />
               </div>
            </a>
            <div className="user_icon">
           <a className="navbar-tool d-lg-flex ml-2 user_iconmain" href="javascript:void(0);" >
              <div className="navbar-tool-icon-box">
                 <img src={images.user} alt="user" />
              </div>
           </a>
           <ul className="sub_drop_menu clearfix">
                <li>
            <a className="" href="javascript:void(0);" onClick={onClickProfile}>{isLoggedin ? i18n.t(`common.logout`) : i18n.t(`wd.login.button.login`) }</a>
                </li>
            </ul>
          </div>
            <ul className="navbar-nav ml-lg-4 pr-lg-2 mr-lg-2">
              <li className="nav-item"><a className="nav-link pl-0" href="javascript:void(0);" data-toggle="modal" data-target="#top_modal"><img src={lang.language === 'en' ? images.englishFlag : images.arFlag} />{lang.language} <img src={images.arrowDrop}className="drop-arrow" alt="" /></a> 
              <ul>
                <li className="nav-item"><a className="nav-link pl-0" href="javascript:void(0);" data-toggle="modal" data-target="#top_modal" onClick={() => changeLang('en')}><img src={images.englishFlag} />En </a>          
                </li>
                <li className="nav-item"><a className="nav-link pl-0" href="javascript:void(0);" data-toggle="modal" data-target="#top_modal" onClick={() => changeLang('ar')}><img src={images.arFlag}/>AR </a>          
                </li>
              </ul>         
             </li>
          </ul>
       </div>
       <ul className="navbar-nav d-none d-lg-flex ml-lg-4 pr-lg-2 mr-lg-2">
         <li className="nav-item"><a className="nav-link pl-0" href="javascript:void:(0);" data-toggle="modal" data-target="#top_modal"><img src={lang.language === 'en' ? images.englishFlag : images.arFlag} />{lang.language} <img src={images.arrowDrop}className="drop-arrow" alt="" /></a> 
          <ul>
            <li className="nav-item"><a className="nav-link pl-0" href="javascript:void(0);" data-toggle="modal" data-target="#top_modal" onClick={() => changeLang('en')}><img src={images.englishFlag} />EN</a>          
            </li>
            <li className="nav-item"><a className="nav-link pl-0" href="javascript:void(0);" data-toggle="modal" data-target="#top_modal" onClick={() => changeLang('ar')}><img src={images.arFlag} />AR </a>          
            </li>
          </ul>         
         </li>         
    </ul>
 </div>
</div>

</header>
      )
    }
}
export default withTranslation()<any>(Header)