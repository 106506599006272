import { IShopCache } from "../models/IShopState";



export enum ActionTypes {
    SHOP_GET = 'shop/SHOP_GET',
    SHOP_SET = 'shop/SHOP_SET',
}


export interface IActionShopGet {
  type: ActionTypes.SHOP_GET
}

export interface IActionShopSet {
  type: ActionTypes.SHOP_SET;
  payload: IShopCache[]
}


export function shopGet (): IActionShopGet {
  return {
    type: ActionTypes.SHOP_GET
  };
}

export function shopSet (data: IShopCache[]): IActionShopSet {
  return {
    type: ActionTypes.SHOP_SET,
    payload: data
  };
}

  

export type Action =
IActionShopGet
  | IActionShopSet
