import { Reducer } from 'redux';
import { cart as cartActions } from '../actions';
import { ICartState } from '../models';
import { ICartData, ItamaraPreData, ITamaraCheckoutData, ITamaraConfirmationData, ITabbyCheckoutData, ITabbyPaymentData, IPaymentConfirmationData } from '../models/ICartState';
export const initialState: ICartState = {
  cart: [],
  cartSuccess: {},
  tamaraPreData:{} as ItamaraPreData,
  tamaraCheckout:{} as ITamaraCheckoutData,
  tamaraConfirmation:{} as ITamaraConfirmationData,
  tabbyCheckout:{} as ITabbyCheckoutData,
  tabbyPayment:{} as ITabbyPaymentData,
  paymentConfirmation:{} as IPaymentConfirmationData
};

const cart =  (
  state: ICartState = initialState,
  action: cartActions.Action,
): ICartState => {
  switch (action.type) {
    case cartActions.ActionTypes.SET_CART: {
      return {
        ...state,
        cart: action.payload,
      }
    }
    case cartActions.ActionTypes.CART_SET_ORDER_ORDER_DETAIL: {
      const cartData = state.cart
      const index = cartData.findIndex((row: ICartData) => row.id === action.payload.id)
      const getIndexCart = state.cart[index]
      // @ts-ignore-start
      cartData[index] = {...state.cart[index], tireData: action.payload.tireData}
      
      return {
        ...state,
        cart: [...cartData],
      }
    }
    case cartActions.ActionTypes.ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        cartSuccess: action.payload,
      }
    }
    case cartActions.ActionTypes.SET_TAMARA_PRE_ORDER: {
      return {
        ...state,
        tamaraPreData: action.payload,
      }
    }
    case cartActions.ActionTypes.SET_TAMARA_CHECKOUT: {
      return {
        ...state,
        tamaraCheckout: action.payload,
      }
    }
    case cartActions.ActionTypes.SET_TAMARA_CONFIRMATION: {
      return {
        ...state,
        tamaraConfirmation: action.payload,
      }
    }
    case cartActions.ActionTypes.SET_PAYMENT_CONFIRMATION: {
      return {
        ...state,
        paymentConfirmation: action.payload,
      }
    }
    case cartActions.ActionTypes.SET_TABBY_CHECKOUT: {
      return {
        ...state,
        tabbyCheckout: action.payload,
      }
    }
    case cartActions.ActionTypes.SET_TABBY_PAYMENT: {
      return {
        ...state,
        tabbyPayment: action.payload,
      }
    }
    default:
      return state;
  }
};
export default cart;
