import React, { ReactNode } from 'react';
import { images } from '../../../../constants/Images';

import { Header } from '../../Components';
import IRootState, { IUserState, ILangState } from '../../../../models';
import { connect } from 'react-redux';
import { PATH } from '../../../../navigation/NavigationPath';
import { ICoreLangType } from '../../../../models/ICoreState';
import i18n, { switchLang, initI18n } from '../../../../services/i18n';
import { lang as languageActions, auth as authActions } from '../../../../actions'
import { api } from '../../../../services';
import { withTranslation } from 'react-i18next';

interface IProps {
  children: ReactNode
  user: IUserState
  lang: ILangState
  setLang: (param: ICoreLangType) => void;
  logout: () => void;
}

interface IState {
}



class Container extends React.PureComponent<IProps, IState> {



    componentDidMount () {
      const { user } = this.props;
      // if (user.type !== 'client') {
      //   require('../../../../styles/admin.css')
      // }
   
    }

    changeLang = (lang: ICoreLangType) => {
      console.log('lang', lang);
      
      switchLang(lang, this.onUpdateLang)
    }

    onUpdateLang = async () => {
      const { setLang } = this.props;
      // @ts-ignore-start
      setLang(i18n.language)
      // const { website_messages }  = await api.core.getLanguage({ lang: i18n.language })
      // @ts-ignore-start
      // initI18n(i18n.language, { [i18n.language]: { translation: website_messages } })
   }

   onLogout = () => {
    const { logout } = this.props;
    logout();
    setTimeout(() => {
      window.location.href = '/'
    }, 500)
    
    // require('../../../../styles/App.css')
   }

    render() {
       const { children, user, lang } = this.props;
       console.log('children', children);
       const sideMenu: Record<string, any> = {
        Normal: [
          { label: i18n.t('Manage_Order'), id: 'order', path: '/', icon: images.manageOrder }, 
          { label: i18n.t('wd.menu.manage_inventory'), id: 'inventory', path: PATH.INVENTORY, icon: images.manageOrder }, 
          // { label: 'Manage Tires', id: 'tires', path: PATH.MANGE_TIRES }
        ],
        Basic: [
          { label:i18n.t('Manage_Order'), id: 'order', path: '/',  icon: images.manageOrder }, 
          { label: i18n.t('wd.menu.manage_inventory'), id: 'inventory', path: PATH.INVENTORY, icon: images.inventoryIcon }
        ],
        Dealer: [
          { label: i18n.t('wd.menu.manage_shop'), id: 'shop', path: '/', icon: images.shop },
          { label: i18n.t('Manage_Order'), id: 'order', path: PATH.MANAGE_ORDER, icon: images.manageOrder }, 
          { label: i18n.t('wd.menu.manage_inventory'), id: 'inventory', path: PATH.INVENTORY, icon: images.inventoryIcon },
        ]
      }
      return(
       <>
       <Header
          userName={user.profile.username}
           // @ts-ignore-start
          pageType={children?.key}
          menu={user.type === 'warehouse' ? sideMenu[user.profile.warehouse_type] : sideMenu.Dealer}
          lang={lang}
          changeLang={this.changeLang}
          onLogout={this.onLogout}
       />
       {children}
       </>
      )
    }
}


const mapStateToProps = (state: IRootState) => ({
  user: state.user,
  lang: state.lang,
});

const mapDispatchToProps = {
  setLang: languageActions.setLang,
  logout: authActions.logout,
}



export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)<any>(Container))