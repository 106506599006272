import React from 'react';
import { images } from '../../../../constants/Images';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { PATH } from '../../../../navigation/NavigationPath';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

interface IProps {

}
const  settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
};

class StepsSection extends React.Component<IProps> {
    render() {
      return (
        <section className="simple-steps">
        <div className="background-effects">
          <span className="effect-1"></span>
          <span className="effect-3"></span>
          <span className="effect-6"></span>
       </div>
       <div className="container px-5">
          <div className="row">
             <div className="col-lg-12">
                {/* <div className="search-tabs">
                   <ul>
                        <li className="by-size"><Link to={PATH.SEARCH_BY_BRAND}><img  src={images.byVehicle} alt=""/><span>{i18n.t('home.label.by_vehicle')}</span></Link></li>
                        <li className="by-vehicle"><Link to={PATH.SEARCH_BY_SIZE}><img src={images.bySize} alt="" /><span>{i18n.t('home.label.by_size')}</span></Link></li>

                         
                    </ul>
                      </div> */}
                      {/* <div className="search-tires">
                         <h2>{i18n.t('home.search.title')}<img src={images.arrow} className="search-arrow" alt="" /></h2>
                      </div> */}
                   </div>
                </div>
    
                <div className="row">
                   <div className="col-lg-12 mb-5 text-center">
                      <div className="main-heading">
                         <h6>{i18n.t('home.search.how_we_works')}</h6>
                         <h2>{i18n.t('home.search.subtitle')}</h2>
                         <p>{i18n.t('home.search.description')}</p>
                      </div>
                   </div>
                </div>
    
                <div className="row steps-box">
                 <div className="col-12 col-md-3">
                       <div className="icon text-primary mb-3">
                     <img src={images.icon1} alt="" />
                     <span className="icon-number">01</span>
                  </div>
                  <h3>
                  {i18n.t('home.search.search.title')}
                  </h3>
    
                  <p>
                  {i18n.t('home.search.search.subtitle')}
                  </p>
    
               </div>
               <div className="col-12 col-md-3">
    
                <div className="icon text-primary mb-3">
                 <img src={images.icon2} alt="" />
                 <span className="icon-number">02</span>
              </div>
    
              <h3>
              {i18n.t('home.search.offers.title')}
              </h3>
    
              <p>
              {i18n.t('home.search.offers.subtitle')} 
            </p>
    
         </div>
         <div className="col-12 col-md-3">
    
          <div className="icon text-primary mb-3">
             <img src={images.icon3} alt="" />
             <span className="icon-number">03</span>
          </div>
    
          <h3>
          {i18n.t('home.search.location.title')}
         </h3>
    
         <p>
         {i18n.t('home.search.location.subtitle')} 
         </p>
    
      </div>
      <div className="col-12 col-md-3">
    
       <div className="icon text-primary mb-3">
          <img src={images.icon4} alt="" />
          <span className="icon-number">04</span>
       </div>
    
       <h3>
         {i18n.t('home.search.install.title')}
      </h3>
    
      <p>
         {i18n.t('home.search.install.subtitle')} 
      </p>
    
    </div>
    </div>
    
    <div className="tire-deco"><img src={images.tire2} alt="" /></div>
    </div>
    </section>
      )
    }
}

export default withTranslation()<any>(StepsSection)