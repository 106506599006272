import React from 'react';
import { images } from '../../../constants/Images';
import IRootState, { ICartState, IUserState } from '../../../models';
import { connect } from 'react-redux';
import { auth as authActions } from '../../../actions'
import { Container } from '../../../components/Base';
import config from '../../../constants/config';
import { IWareHouseLogin } from '../../../models/IAuthState';
import { Input } from '../../../components';
import { validation, i18n } from '../../../services';
import { withRouter } from 'react-router-dom';
import { PATH } from '../../../navigation/NavigationPath';
import { withTranslation } from 'react-i18next';

interface IProps {
  wareHouseLogin: (param: IWareHouseLogin) => void;
  loading: boolean;
  isLoggedin: boolean;
  history: any
}

interface IState {
  formData: {
    fields: {
      username: string;
      password: string;
      role: 'warehouse' | 'dealer'
    },
    required: string[]
  };
  error: any;
}

class LoginPage extends React.Component<IProps, IState> {
    
  state: IState = {
      formData: {
        fields: {
          username: '',
          password: '',
          role: 'warehouse'
        },
        required: ['username', 'password'],
      },
      error: {},
    }

    handleChange = (e: any, field: string) => {
      const { formData } = this.state;
      console.log('field', field);
      this.setState({ formData: { ...formData, fields: { ...formData.fields, [field]: e.target.value } } })
    }

    onSubmit = () => {
      const { formData } = this.state;
      const { wareHouseLogin } = this.props;
      const validate = validation.isValidate(formData)
      this.setState({ error: validate })
      if (!Object.keys(validate).length ) { 
      const { formData } = this.state;
        wareHouseLogin(formData.fields)
      }
    }

    static getDerivedStateFromProps(props: IProps, state: IState) {
      if (props.isLoggedin) {
        console.log('props.isLoggedin', props.isLoggedin);
        
          props.history.push('/')
      }
      return state
    }

    onToggelLogin = (role: 'warehouse' | 'dealer') => () => {
      const { formData } = this.state;
      this.setState({ formData: { ...formData, fields: { role, username: '', password: '' } } })
    }

    onForgotPass = () => {
      console.log('onForgotPass');
      
      this.props.history.push(PATH.FORGOT_PASSWORD)
    }

    render() {
      const { loading } = this.props;
      const { error, formData } = this.state;
      console.log('this.props', this.props);
      
      return (
        <section className="admin_banner-section bg-size-cover bg-position-center">
            <div className="container-fluid">
              <div className="login_form_modal">
                <div className="login_content">
                <div className="main-heading">
          <h2>{i18n.t(`wd.login.title`)}</h2>

        </div>
        <div className="login_pagecontent">
          <p>{i18n.t(`wd.login.caption`)}</p>
        </div>
        <div className="user_login">
          <ul>
            <li className={`${formData.fields.role === 'warehouse' ? 'active': ''}`}>
              <a href="javascript:void(0);" onClick={this.onToggelLogin('warehouse')}>
                {
                  formData.fields.role === 'warehouse' && (
                    <div className="select_option">
                      <img src={images.checkLogin} />
                    </div>
                  )
                }
               
                <img src={images.dealer} />
                  <span className="user_type">{i18n.t(`wd.login.button.warehouse`)}</span>
                  <span className="login_type">{i18n.t(`wd.login.button.login`)}</span>
              </a>
            </li>
            <li className={`${formData.fields.role === 'dealer' ? 'active': ''}`}>
            {
                  formData.fields.role === 'dealer' && (
                    <div className="select_option">
                      <img src={images.checkLogin} />
                    </div>
                  )
                }
               
              <a href="javascript:void(0);" onClick={this.onToggelLogin('dealer')}>
                <div className="user_loginlist">
                   <img src={images.whare_house} />
                </div>
                  <span className="user_type" >{i18n.t(`wd.login.button.dealer`)}</span>
                  <span className="login_type" >{i18n.t(`wd.login.button.login`)}</span>
              </a>
            </li>
          </ul>
        </div> 
        <div className="login_form">
  
            <Input
               type={'text'}
               name={'username'}
               onChange={(value: any) => this.handleChange(value, 'username')}
               placeholder={i18n.t(`wd.login.input.label.username`)}
               value={formData.fields.username}
              containerClass={'form-control'}
              label={i18n.t(`wd.login.input.label.username`)}
              error={error && error.username ? error.username.message : ''}
            />
            <Input
              type={'password'}
              name={'password'}
              onChange={(value: any) => this.handleChange(value, 'password')}
              placeholder={i18n.t(`wd.login.input.label.password`)}
              error={error && error.password ? error.password.message : ''}
              value={formData.fields.password}
              containerClass={'form-control'}
              label={i18n.t(`wd.login.input.label.password`)}
              link={ {name: i18n.t(`wd.login.link.forgot`), action: this.onForgotPass }}
            />
          <div className="login_btnwrp">
            <button 
              className="btn btn-default login_btn" 
              disabled={loading}
              onClick={this.onSubmit}
            >
              {i18n.t(`wd.login.button.login`)}
              {
                loading && (
                  <img src={images.loader} style={{ height: '30px', width: '30px' }}/>
                )
              }
              
            </button>
          </div>

        </div>
         <div className="banner-tire">
          <img src="img/banner-tire.png" alt="" />
         </div>
      </div>
    </div>
  </div>

        </section>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.auth.loading,
  isLoggedin: state.auth.isLoggedin
});

const mapDispatchToProps = {
  wareHouseLogin: authActions.wareHouseLogin
}



export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(LoginPage)) ) 