import { IUserProfile, IUserNotification } from "../models/IUserState";


export enum ActionTypes {
    SET_PROFILE = 'auth/SET_PROFILE',
    GET_NOTIFICATION = 'auth/GET_NOTIFICATION',
    SET_NOTIFICATION = 'auth/SET_NOTIFICATION',
    USER_RESET = 'auth/USER_RESET',
}

export interface IActionUserReset {
    type: ActionTypes.USER_RESET;
}

export interface IActionUserGetNotification {
    type: ActionTypes.GET_NOTIFICATION;
}

export interface IActionUserSetNotification {
    type: ActionTypes.SET_NOTIFICATION;
    payload: IUserNotification[];
}

export interface IActionUserSetProfile {
    type: ActionTypes.SET_PROFILE;
    payload: IUserProfile;
}


export function setProfile (data: IUserProfile): IActionUserSetProfile {
    return {
      payload: data,
      type: ActionTypes.SET_PROFILE,
    };
}

export function getNotification (): IActionUserGetNotification {
    console.log('getNotification');
    
    return {
      type: ActionTypes.GET_NOTIFICATION,
    };
}

export function setNotification (data: IUserNotification[]): IActionUserSetNotification {
    return {
        payload: data,
        type: ActionTypes.SET_NOTIFICATION,
    };
}

export function resetUser (): IActionUserReset {
    return {
        type: ActionTypes.USER_RESET,
    };
}
  

export type Action =
IActionUserSetProfile
| IActionUserGetNotification
| IActionUserSetNotification
| IActionUserReset
