import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import { ISearchBrand, ISearchModel, ISearchTireCache } from '../../../../models/ISearchState';
import config from '../../../../constants/config';
import { i18n } from '../../../../services';
import { withTranslation } from 'react-i18next';
import { Filter } from '../../../../utils';
import { PATH } from '../../../../navigation/NavigationPath';
import { Link, } from "react-router-dom";
import IRootState, { ICoreState, IAuthState, IUserState, ILangState } from '../../../../models';
import { Modal, Input } from '../../../../components';
import { validation } from '../../../../services';
import { IUserProfile } from '../../../../models/IUserState';

interface IProps {
  user: IUserProfile,
  onUserDectiveModal: () => void;
}

interface IState {
  showUserDectiveModal:boolean
}

class DeactiveUserCard extends React.PureComponent<IProps, IState> {
  state = {
    showUserDectiveModal: true,
  }
  
  onToggelLoginStatus = () => {
    this.setState({showUserDectiveModal:!this.state.showUserDectiveModal})
  }
  checkUserStatus(){
      if(this.props.user.status == 'Deactive'){
        this.onToggelLoginStatus()
      }
  }
    render() {
      const { onUserDectiveModal } = this.props
      return (
        <>
          {
            <Modal onToggel={() => {onUserDectiveModal()}} show={this.state.showUserDectiveModal}>                      
                <div className="modal-header title-modal">
                  <div style={{textAlign:'center'}} className="searchPopup">
                      <h1>Alert</h1>
                      <br />
                      <div className="selectHead">Sorry, Your account has been suspended</div>
                  </div>
                </div>
                <div className="modal-footer proceed" style={{display:"inline",textAlign:"center"}}>
                  <Link className="footer-btn" to={PATH.HOME}  style={{width:"45%",float:"left"}}>{i18n.t('OK')}</Link>
                  <Link className="footer-btn" to={PATH.CONTACT_US}  style={{width:"45%",float:"right"}}>{i18n.t(`footer.compony.contact`)}</Link>
                </div>
            </Modal>
          }
        </>
      )
    }
}

export default withTranslation()(DeactiveUserCard)
