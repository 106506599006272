import axios from 'axios'
import { api } from '..';
import { IUserLogin, IWareHouseLogin, IAuthForgotPassword, IAuthResetPassword } from '../../models/IAuthState';

async function login(params: IUserLogin) {
    try {
        return api.postApi('/user/addcustomer', params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function resendOTP(params: IUserLogin) {
    try {
        return api.postApi('/user/resendverificationotp', params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function wareHouseLogin(params: IWareHouseLogin) {
    try {
        return api.postApi('/warehouse/login', params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function adminLogin(params: IWareHouseLogin) {
    console.log('params', params);
    
    try {
        return api.postApi(`/${params.role}/login`, params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function forgotPassword(params: IAuthForgotPassword) {
    try {
        return api.postApi(`/common/forgotpassword`, params)
    } catch (e) {
        Promise.reject(e)
    }
}
async function resetPassword(params: IAuthResetPassword) {
    try {
        return api.postApi(`/common/resetpassword`, params)
    } catch (e) {
        Promise.reject(e)
    }
}


export {
    login,
    resendOTP,
    wareHouseLogin,
    adminLogin,
    forgotPassword,
    resetPassword
}
