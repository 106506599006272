import React from 'react';
import { images } from '../../../../constants/Images';
import { Link } from 'react-router-dom';
import { PATH } from '../../../../navigation/NavigationPath';
import { IOrder } from '../../../../models/IOrderState';
import moment from "moment"
import { Filter } from '../../../../utils';
import config from '../../../../constants/config';
import IShopState, { IShopCache } from '../../../../models/IShopState';
import { ModalRight } from '../../Components';
import { typeUser } from '../../../../models/IUserState';
import { IWDState } from '../../../../models';
import { i18n } from '../../../../services';
import { withTranslation } from 'react-i18next';
import { ISearchGetTireSize } from '../../../../models/ISearchState';

interface IProps {
   show: boolean
   onToggelModal: () => void
   userType: typeUser
   wareHouseType: "Basic" | "Normal"
   wd: IWDState
   onApply: (param: any) => void;
   onReset: () => void;
   tireSize: { f: string[], s: string[], t: string[] }
   getTireSize: (param: ISearchGetTireSize) => void;
   shop?: IShopState
}

interface IState {
   formData: {
      fields: {
         f_size: string,
         s_size: string,
         t_size: string,
         year: string,
         runflat: string,
         quantity: string,
         quantityFrom: string,
         quantityTo: string,
         madein: string,
         price: string,
         priceFrom: string,
         priceTo: string,
         brand_id: string,
      },
      required: []
   }
}

const priceArr = [0, 100, 200, 300, 400, 500, 750, 1000, 2000, 10000]
class FilterModal extends React.PureComponent<IProps, IState> {
   state: IState = {
      formData: {
         fields: {
            f_size: '',
            s_size: '',
            t_size: '',
            year: '',
            runflat: '0',
            quantity: '',
            quantityFrom: '0',
            quantityTo: '0',
            madein: '',
            price: '0',
            priceFrom: '0',
            priceTo: '0',
            brand_id: '',
            ...this.props.userType === 'warehouse' &&  this.props.wareHouseType === 'Normal' && {
               code: '',
               speedindex: '',
               loadindex: '',
               model_no: '',
            }
         },
         required: []
      }
   }
   handleChange = (e: any, field: string) => {
      const { formData } = this.state;
      const { getTireSize } = this.props; 

      const value = field === 'runflat' ?  `${Number(!Number(formData.fields.runflat))}` :e.target.value.replace(/^0+/, '')
      let obj = { [field]: value }

      if (['priceFrom', 'quantityFrom'].indexOf(field) >= 0) {
         obj = { 
            ...obj, 
            ...['priceFrom', 'quantityFrom'].indexOf(field) === 0 ? {
               priceTo: Number(value) > Number(formData.fields.priceTo ) ? value : formData.fields.priceTo
            } : {
               quantityTo: Number(value) > Number(formData.fields.quantityTo) ? value : formData.fields.quantityTo
            },
             
         }
      }
      if (['priceTo', 'quantityTo'].indexOf(field) >= 0) {
         obj = { 
            ...obj, 
            ...['priceTo', 'quantityTo'].indexOf(field) === 0 ? {
               priceFrom: Number(value) < Number(formData.fields.priceFrom ) ? value : formData.fields.priceFrom
            } : {
               quantityFrom: Number(value) < Number(formData.fields.quantityFrom) ? value : formData.fields.quantityFrom
            },
             
         }
      }
      console.log('obj', obj);
      
      this.setState({ formData: { ...formData, fields: { ...formData.fields, ...obj } } }, () => {
         if (['f_size', 's_size'].indexOf(field) >= 0) {
            const type: Record<string, any> = {
               f_size: 's',
               s_size: 't'
            }
            
            getTireSize({ type: type[field], fsize: this.state.formData.fields.f_size, ssize: this.state.formData.fields.s_size })

         }
         if (['priceFrom', 'priceTo', 'quantityFrom', 'quantityTo'].indexOf(field) >= 0) {
            console.log('field', this.state.formData.fields);
            
            this.setState({ formData: { ...this.state.formData, fields: { ...this.state.formData.fields, price: `${this.state.formData.fields.priceFrom}-${this.state.formData.fields.priceTo}`, quantity:`${this.state.formData.fields.quantityFrom}-${this.state.formData.fields.quantityTo}`  } } } )
         }
      })
    }

    renderOption = (data: any[], type: any) => {
      const { formData } = this.state;
      const { shop } = this.props;
      const sizeArr = ['f_size', 's_size', 't_size', 'priceFrom', 'priceTo', 'quantityFrom', 'quantityTo']
      const keyType: Record<string, string> =    {
         code: 'code',
         speedindex: 'weight',
         loadindex: 'weight',
         model_no: 'model_no',
      } 
      // return null
      let renderValue = ''
      return data.map(( row: any, index: number ) => {
   
         if (type === 'brand_id') {
            renderValue = Filter.translate(row.brand, row.brand_arabic)
         } else if (sizeArr.indexOf(type) >= 0) {
            renderValue = row
         } else if (type === 'vendor_id') {
            renderValue = Filter.translate(shop?.records.cache[row].shop_name, shop?.records.cache[row].shop_name_ar)
         } else {
            renderValue = row[keyType[type]]
         }
         return (
            // @ts-ignore-start
            <option value={row.id ? row.id : row} key={index} selected={row ? Number(formData.fields[type]) === Number(row) : formData.fields[type] === row.id }>
               {
                 renderValue
               }
            </option>
         )
      })
      
   }
    
   renderInput = () => {
      const { userType, wareHouseType, wd, tireSize, shop } = this.props;
      const { formData } = this.state;
      const inputArr: {field: string, label: string, type: string}[] = [
         {field: 'brand_id', label: i18n.t(`wd.inventory.input.label.brand`) , type: 'select'},
         ...userType === 'dealer' ? [
            {field: 'vendor_id', label: i18n.t(`wd.inventory.input.label.shop`) , type: 'select'},
         ]: [],
         {field: 'f_size', label: i18n.t(`wd.inventory.input.label.width`), type: 'select'},
         {field: 's_size', label: i18n.t(`wd.inventory.input.label.aspect`), type: 'select'}, 
         {field: 't_size', label: i18n.t(`wd.inventory.input.label.diameter`), type: 'select'},
         ...userType === 'warehouse' && wareHouseType === 'Normal' ?
         [{field: 'code', label: i18n.t(`wd.inventory.input.label.code`), type: 'select'},
         {field: 'speedindex', label: i18n.t(`wd.inventory.input.label.speed`), type: 'select'},
         {field: 'loadindex', label: i18n.t(`wd.inventory.input.label.load`), type: 'select'},
         {field: 'model_no', label: i18n.t(`wd.inventory.input.label.model`), type: 'select'}] : [],
         {field: 'runflat', label: i18n.t(`wd.inventory.input.label.runflat`) , type: 'checkbox'},
         {field: 'madein', label: i18n.t(`wd.inventory.input.label.madein`) , type: 'text'},
         {field: 'year', label: i18n.t(`wd.inventory.input.label.year`) , type: 'number'},
         // {field: 'quantity', label: i18n.t(`wd.inventory.input.label.quantity`) , type: 'number'},
         // {field: 'price', label: i18n.t(`wd.inventory.input.label.price`) , type: 'number'},
         {field: 'priceFrom', label: i18n.t(`wd.inventory.input.label.price_from`) , type: 'select'},
         {field: 'priceTo', label: i18n.t(`wd.inventory.input.label.price_to`) , type: 'select'},
         {field: 'quantityFrom', label: i18n.t(`wd.inventory.input.label.quantity_from`) , type: 'select'},
         {field: 'quantityTo', label: i18n.t(`wd.inventory.input.label.quantity_to`) , type: 'select'},
      ]

      
      const selectTypeData: Record<string, any[]> = {
         brand_id: wd.brand,
         code: wd.tireCode,
         speedindex: wd.speedRating,
         loadindex: wd.load,
         model_no: wd.model,
         f_size: tireSize.f,
         s_size: tireSize.s,
         t_size: tireSize.t,
         priceFrom: priceArr,
         priceTo: priceArr,
         quantityFrom: priceArr,
         quantityTo: priceArr,
         vendor_id: shop?.records.ids || []
      }
      // const selectInput = inputArr.filter(( row ) => row.type === 'select')
      
      return inputArr.map((ele: {field: string, label: string, type: string}, index: number) => {
         if (ele.type === 'select') {
     
            
            return (
               <div className={`fields  ${ele.field === 'brand_id' ? 'col-md-12' : 'col-md-3'} `}>
               <label>{ele.label}</label>
               <span className="select-wrapper">
                  <select className="custom-select" onChange={(e: any) => this.handleChange(e, ele.field)}>
            <option value={''}>{ele.label}</option>
                     { this.renderOption(selectTypeData[ele.field], ele.field) }
                  </select>
               </span>
               {/* <span className={'error-input'}>{validation.renderError(ele.field, errors)}</span> */}
            </div>
            )
         }


         if (ele.type === 'checkbox') {
            return (
               <div className="fields col-md-3"> 
                     <label className="check-label">{ele.label}
               <input 
                  type={ele.type} 
                  className="form-control mb-2 mr-sm-2" 
                  placeholder={ele.label} 
                  onChange={(e: any) => this.handleChange(e, ele.field)}
                  key={index}
                  // @ts-ignore-start 
                  value={formData.fields[ele.field]}
                  min={1}
                  checked={formData.fields.runflat === '1'}
               />
               <span className={'checkmark'}>
        
                  </span>
            
                  </label>   
            </div>
            )
         }

         return (
            <>
               <div className="fields col-md-6"> 
                  <label>{ele.label}</label>    
                  <input 
                     type={ele.type} 
                     className="form-control mb-2 mr-sm-2" 
                     placeholder={ele.label} 
                     onChange={(e: any) => this.handleChange(e, ele.field)}
                     key={index}
                     // @ts-ignore-start 
                     value={formData.fields[ele.field]}
                     min={1}
                  />
                  {/* <span className={'error-input'}>{validation.renderError(ele.field, errors)}</span> */}
               </div>
            </>
         )
      })
   }

   onReset = () => {
      const { onReset } = this.props;
      const { formData } = this.state;
      this.setState({
         formData: {
            fields: {
               f_size: '',
               s_size: '',
               t_size: '',
               year: '',
               runflat: '0',
               quantity: '',
               quantityFrom: '',
               quantityTo: '',
               madein: '',
               price: '',
               priceFrom: '',
               priceTo: '',
               brand_id: '',
            },
            required: []
         }
      })
      onReset()
   }

    render() {
       const { show, onToggelModal, onApply, onReset } = this.props;
       const { formData } = this.state;
       console.log('this.props', this.props);
       
       if (!show) {
          return null
       }
      return(
      <ModalRight 
         show={show}
         onToggel={onToggelModal}
         title={i18n.t('wd.inventory.modal.filter.title')}
         reset={true}
         onReset={this.onReset}
      >
      <div className="row">
         {this.renderInput()}
         <div className="col-md-12">
            <button type="button" className="button2" title="Submit" onClick={() => onApply(formData.fields)}>
            Apply
            </button>
         </div>
      </div>
      </ModalRight>
      )
    }
}

export default withTranslation()(FilterModal)