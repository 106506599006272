import { shop as shopActions } from '../actions';
import { IShopState } from '../models';
import { IShopCache } from '../models/IShopState';
export const initialState: IShopState = {
  loading: false,
  records: { ids: [], cache: {} }
};

const shop =  (
  state: IShopState = initialState,
  action: shopActions.Action,
): IShopState => {
  switch (action.type) {
    case shopActions.ActionTypes.SHOP_GET: {
      return {
        ...state,
        loading: true,
      };
    }
    case shopActions.ActionTypes.SHOP_SET: {
      const cache: {[key: string]: IShopCache} = { };
      const ids = [
        ...[],
        ...action.payload.map((item: IShopCache) => {
          cache[item.id] = item;
          return item.id;
        }),
      ];
      return {
        ...state,
        records: { ids, cache },
        loading: false,
      };
    }
    default:
      return state;
  }
};
export default shop;
