import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import { ISearchBrand, ISearchModel } from '../../../../models/ISearchState';
import config from '../../../../constants/config';
import { Filter } from '../../../../utils';

interface IProps {
  items: any
  onClick: (param: { id: number, type: 'make' | 'year' | 'model' | 'size', name: string }) => void;
  selectedYear: number
  selectedModel: number
  headerMenu?: { name: string, value: any, id: number }[]
  activeHeaderMenu?: number
}

interface IState {

}



export default class Years extends React.PureComponent<IProps, IState> {

    render() {
      const { items, onClick, selectedYear, selectedModel, headerMenu=[], activeHeaderMenu=0 } = this.props;
      const id = items.id ? items.id : items
      
      const selected = headerMenu.length && headerMenu.find(row => row.id === activeHeaderMenu)?.value === items
      console.log('selected', selected);
      return (
        <li>
          <a 
            href="javascript:void(0);"
            onClick={() => onClick({ id: items.id ? items.id : items, type: items.id ? 'model' : 'year', name: items.id ? items.model : ''  })}
            className={selectedYear === id || selectedModel === id || selected ? "active" : ''}
          >
            {items.id ? Filter.translate(items.model, items.model_arabic) : items}
          </a>
       </li>
      )
    }
}
