import React, { Component, PureComponent } from 'react';

import { connect } from 'react-redux';
import { core as coreActions } from '../../actions';
import { getNotification } from '../../selectors';
import { withAlert } from 'react-alert'
import IRootState from '../../models';

export class Notification extends PureComponent<any, any> {

    alert: any
    static defaultProps = {
        onClose: () => undefined,
        type: 'notice',
        templatetype: 'alert',
    };



    onClose = (message: any, type: any) => () => {
        this.props.onClose(message, type);
    }


    showAlert = () => {
        const { message, type, templatetype, onClose } = this.props;
        //console.log('akram', type);
        const types: Record<string, string> = {
            notice: 'success',
            error: 'error'
        }
        this.props.alert.remove(this.alert)
        // setTimeout(() => {
        this.alert = this.props.alert.show(message, {
            type: types[type],
            onClose: () => {
                onClose(message, type)
            }
        })
        // }, 1000)

    }

    render() {
        return (
            <div style={{ zIndex: 999 }}>
                {/* @ts-ignore-start  */}
                {this.showAlert()}
            </div>

        );
    }
}

const mapStateToProps = (state: IRootState) => {
    const notification = getNotification(state);
    return {
        // curruntScreen: state.core.curruntScreen,
        message: notification && notification.message,
        type: notification && notification.type,
        templatetype: notification && notification.templatetype,
    };
};
const mapDispatchToProps = {
    onClose: coreActions.removeNotification,
};

const ConnectedNotification = connect(
    mapStateToProps,
    mapDispatchToProps,
)<any>((props: any) => {
    if (props.message) {
        return (
            <Notification {...props} />
        );
    }
    return null;
});

export default withAlert()(ConnectedNotification);
