import { IInventoryGet, IInventorySet, IInventoryAct, IInventoryAdd, IInventoryEdit, IInventoryBulkUpload } from "../models/IInventoryState";
import { ISearchGetSize, ISearchGetTireSize, ISearchSetTireSize } from "../models/ISearchState";



export enum ActionTypes {
    INVENTORY_GET = 'inventory/INVENTORY_GET',
    INVENTORY_SET = 'inventory/INVENTORY_SET',
    INVENTORY_DELETE = 'inventory/INVENTORY_DELETE',
    INVENTORY_DELETE_SUCCESS = 'inventory/INVENTORY_DELETE_SUCCESS',
    INVENTORY_ADD = 'inventory/INVENTORY_ADD',
    INVENTORY_ADD_SUCCESS = 'inventory/INVENTORY_ADD_SUCCESS',
    INVENTORY_EDIT = 'inventory/INVENTORY_EDIT',
    INVENTORY_BULK_UPLOAD = 'inventory/INVENTORY_BULK_UPLOAD',
    INVENTORY_BULK_UPLOAD_SUCCESS = 'inventory/INVENTORY_BULK_UPLOAD_SUCCESS',
    INVENTORY_GET_TIRE_SIZE = 'inventory/INVENTORY_GET_TIRE_SIZE',
    INVENTORY_SET_TIRE_SIZE = 'inventory/INVENTORY_SET_TIRE_SIZE',
}

export interface IActionInventorySetSize {
  type: ActionTypes.INVENTORY_SET_TIRE_SIZE;
  payload: ISearchSetTireSize
}

export interface IActionInventoryGetSize {
  type: ActionTypes.INVENTORY_GET_TIRE_SIZE;
  payload: ISearchGetTireSize
}

export interface IActionInventoryBulkUploadSuccess {
  type: ActionTypes.INVENTORY_BULK_UPLOAD_SUCCESS;
}

export interface IActionInventoryBulkUpload {
  type: ActionTypes.INVENTORY_BULK_UPLOAD;
  payload: IInventoryBulkUpload
}

export interface IActionInventoryEdit {
  type: ActionTypes.INVENTORY_EDIT;
  payload: IInventoryEdit
}

export interface IActionInventoryAddSuccess {
  type: ActionTypes.INVENTORY_ADD_SUCCESS;
  payload: boolean;
}

export interface IActionInventoryAdd {
  type: ActionTypes.INVENTORY_ADD;
  payload: IInventoryAdd;
}

export interface IActionInventoryDeleteSuccess {
  type: ActionTypes.INVENTORY_DELETE_SUCCESS;
  payload: string;
}

export interface IActionInventoryDelete {
  type: ActionTypes.INVENTORY_DELETE;
  payload: IInventoryAct;
}

export interface IActionInventoryGet {
  type: ActionTypes.INVENTORY_GET;
  payload: IInventoryGet;
}

export interface IActionInventorySet {
  type: ActionTypes.INVENTORY_SET;
  payload: IInventorySet;
}


export function getInventory (data: IInventoryGet): IActionInventoryGet {
    return {
      payload: data,
      type: ActionTypes.INVENTORY_GET,
    };
}

export function setInventory (data: IInventorySet): IActionInventorySet {
    return {
      type: ActionTypes.INVENTORY_SET,
      payload: data
    };
}

export function deleteInventory (data: IInventoryAct): IActionInventoryDelete {
  return {
    type: ActionTypes.INVENTORY_DELETE,
    payload: data
  };
}

export function deleteInventorySuccess (data: string): IActionInventoryDeleteSuccess {
  return {
    type: ActionTypes.INVENTORY_DELETE_SUCCESS,
    payload: data
  };
}

export function addInventory (data: IInventoryAdd): IActionInventoryAdd {
  return {
    type: ActionTypes.INVENTORY_ADD,
    payload: data
  };
}

export function addInventorySuccess (data: boolean): IActionInventoryAddSuccess {
  return {
    type: ActionTypes.INVENTORY_ADD_SUCCESS,
    payload: data
  };
}

export function editInventory (data: IInventoryEdit): IActionInventoryEdit {
  return {
    type: ActionTypes.INVENTORY_EDIT,
    payload: data
  };
}

export function bulkUpload (data: IInventoryBulkUpload): IActionInventoryBulkUpload {
  return {
    type: ActionTypes.INVENTORY_BULK_UPLOAD,
    payload: data
  };
}

export function bulkUploadSuccess (): IActionInventoryBulkUploadSuccess {
  return {
    type: ActionTypes.INVENTORY_BULK_UPLOAD_SUCCESS,
  };
}

export function getTireSize (data: ISearchGetTireSize): IActionInventoryGetSize {
  return {
    type: ActionTypes.INVENTORY_GET_TIRE_SIZE,
    payload: data
  };
}

export function setTireSize (data: ISearchSetTireSize): IActionInventorySetSize {
  return {
    type: ActionTypes.INVENTORY_SET_TIRE_SIZE,
    payload: data
  };
}


  

export type Action =
IActionInventoryGet
  | IActionInventorySet
  | IActionInventoryDelete
  | IActionInventoryDeleteSuccess
  | IActionInventoryAdd
  | IActionInventoryAddSuccess
  | IActionInventoryEdit
  | IActionInventoryBulkUpload
  | IActionInventoryBulkUploadSuccess
  | IActionInventoryGetSize
  | IActionInventorySetSize
