import { Reducer } from 'redux';
import { user as userActions } from '../actions';
import { IAuthState, IUserState } from '../models';
import { typeUser } from '../models/IUserState';
export const initialState: IUserState = {
  // @ts-ignore-start
  profile: {},
  type: 'client',
  notification: [],
  loading: false,
};

const userType: Record<string, typeUser> = {
  "6": "warehouse",
  "3": "client",
  "5": "dealer",
}

const user = (
  state: IUserState = initialState,
  action: userActions.Action,
): IUserState => {
  switch (action.type) {
    case userActions.ActionTypes.SET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
        type: userType[action.payload.role]
      }
    }
    case userActions.ActionTypes.GET_NOTIFICATION: {
      return {
        ...state,
        loading: true,
      }
    }
    case userActions.ActionTypes.SET_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload,
        loading: false,
      }
    }
    case userActions.ActionTypes.USER_RESET: {
      return {
        ...state,
        // @ts-ignore-start
        profile: {},
        type: 'client',
      }
    }
    default:
      return state;
  }
};
export default user;
