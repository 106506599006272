import React, { createRef } from 'react';
import { images } from '../../constants/Images';
import { Link } from 'react-router-dom';

interface IProps {
   children: any
   onToggel: () => void
   show: boolean
}
interface IState {
   toggel: boolean
   
}

export default class Modal extends React.PureComponent<IProps, IState> {
   state = {
      toggel: false
   }

   onToggel = () => {
      const { toggel } = this.state;
      this.setState({ toggel: !toggel })
   }

    render() {
       const { children, onToggel, show } = this.props
       const { toggel } = this.state;
      return (
         <>
         <div className={`modal ${show ? 'show' : '' }`} id="Proceed" style={{ display: `${show ? 'block' : 'none' }` }}>
         <div className="modal-dialog">
           <div className="modal-content">
           <button type="button" className="close" data-dismiss="modal" onClick={onToggel}>&times;</button>
            {children}
           </div>
         </div>
       </div>
       {
          show && (
            <div className="modal-backdrop show"></div>
         )
       }
       </>
      )
    }
}