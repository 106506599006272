import React from 'react';
import './AlertBox.css'
import { Alert } from 'react-bootstrap';
interface IProps {
  message: string;
  type:   'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark';
  show: boolean;
}

interface IState {
  showAlert: boolean;
  show: false;
}

export default class AlertBox extends React.PureComponent<IProps> {
  static defaultProps = {
    message: '',
    type: 'primary',
  }
  state = {
    showAlert: true
  }

    render() {
      const { message, type, show} = this.props;
      const { showAlert } = this.state;
      
      if (!show) {
        return null
      }

      return (
        <Alert variant={type} onClose={() => this.setState({ showAlert: false })}>
          {message}
      </Alert>
      )
    }
}