import React, { createRef } from 'react';
import { withTranslation } from 'react-i18next';

interface IProps {
   children: any
   onToggel: () => void
   show: boolean
   title: string;
   extraClass: string
}
interface IState {
   toggel: boolean
}

class ModalRight extends React.PureComponent<IProps, IState> {
   static defaultProps = {
      extraClass: ''
   }
   state = {
      toggel: false
   }

   onToggel = () => {
      const { toggel } = this.state;
      this.setState({ toggel: !toggel })
   }

    render() {
       const { children, onToggel, show, title, extraClass } = this.props
       const { toggel } = this.state;
      return (
         <>
            <div className={`modal right fade tire-details ${show ? 'show' : '' } ${extraClass}`} id="right_modal_sm" tabIndex={-1} role="dialog" aria-labelledby="right_modal_sm" style={{ display: show ? 'block' : 'none' }}>
               <div className="modal-dialog" role="document">
                  <div className="modal-content">
                     
                     <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onToggel}>
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     {children}

                  </div>
               </div>
            </div>

       {
          show && (
            <div className="modal-backdrop show" onClick={onToggel}></div>
         )
       }
       </>
      )
    }
}

export default withTranslation()(ModalRight)