import IRootState from "../models";

export const getNotification = (state: IRootState) => {
  const { notifications } = state.core;
  if (notifications && notifications.length > 0) {
    return notifications[0];
  }
  return null;
};

export const getUser = (state: IRootState) => {
  return state.user;
};

export const isLoggedin = (state: IRootState) => {
  return state.auth.isLoggedin;
};

export const getCity = (state: IRootState) => state.core.selectedCity;