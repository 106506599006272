import { Reducer } from 'redux';
import { lang as languageActions } from '../actions';
import { ILangState } from '../models';
export const initialState: ILangState = {
  language: 'ar'
};

const lang =  (
  state: ILangState = initialState,
  action: languageActions.Action,
): ILangState => {
  switch (action.type) {
    case languageActions.ActionTypes.SET_LANG: {
      return {
        ...state,
        language: action.payload,
      }
    }
    default:
      return state;
  }
};
export default lang;
