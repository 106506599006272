import { api } from '..';
import { ICoreLangType } from '../../models/ICoreState';

async function getData(params = {}) {
    try {
        return api.postApi('/home/homecontent', params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getBanner(params: { city_id: number, lang: ICoreLangType }) {
    try {
        console.log('params', params);
        return api.postApi('/user/bannerapi', params)
    } catch (e) {
        Promise.reject(e)
    }
}

export {
    getData,
    getBanner,
}