import { wd as wdActions } from '../actions';
import { IWDState } from '../models';
export const initialState: IWDState = {
  loading: false,
  brand: [],
  load: [],
  speedRating: [],
  model: [],
  tireCode: [],
  installCenter: [],
};

const wd =  (
  state: IWDState = initialState,
  action: wdActions.Action,
): IWDState => {
  switch (action.type) {
    case wdActions.ActionTypes.WD_BRAND_SET: {
      console.log('WD_BRAND_SET', action);
      
      return {
        ...state,
        brand: action.payload,
      };
    }
    case wdActions.ActionTypes.WD_LOAD_SET: {
      return {
        ...state,
        load: action.payload,
      };
    }
    case wdActions.ActionTypes.WD_SPEED_RATING_SET: {
        return {
          ...state,
          speedRating: action.payload,
        };
    }
    case wdActions.ActionTypes.WD_TIRE_CODE_SET: {
      return {
        ...state,
        tireCode: action.payload,
      };
    }
    case wdActions.ActionTypes.WD_MODEL_SET: {
      return {
        ...state,
        model: action.payload,
      };
    }
    case wdActions.ActionTypes.WD_SET_INSTALLCENTER: {
      return {
        ...state,
        installCenter: action.payload,
      };
    }
    default:
      return state;
  }
};
export default wd;
