import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import { ISearchBrand } from '../../../../models/ISearchState';
import config from '../../../../constants/config';
import { Filter } from '../../../../utils';

interface IProps {
  items: ISearchBrand
  onClick: (param: ISearchBrand) => void;
  selectedBrand: number
}

interface IState {

}



export default class BrandCard extends React.PureComponent<IProps, IState> {

    render() {
      const { items, onClick, selectedBrand } = this.props
      return (
        <div className={`col ${selectedBrand === items.id && 'active'}`}>
          <a className="d-block bg-white py-3 py-sm-4 mb-grid-gutter" href="javascript:void(0)" onClick={() => onClick(items)}>
            <div className="img-box">
              <img className="mx-auto" src={`${config.imageBaseUrl}${items.image}`} alt="Brand"/>
            </div>
            <span className="audo">{Filter.translate(items.brand, items.brand_arabic)}</span> 
          </a>
        </div>
      )
    }
}