import React from 'react';
import { images } from '../../../constants/Images';
import IRootState, { ICartState, IUserState } from '../../../models';
import { connect } from 'react-redux';
import { auth as authActions } from '../../../actions'
import { Container } from '../../../components/Base';
import config from '../../../constants/config';
import { IWareHouseLogin, IAuthForgotPassword } from '../../../models/IAuthState';
import { Input } from '../../../components';
import { validation, i18n } from '../../../services';
import { withRouter } from 'react-router-dom';
import { PATH } from '../../../navigation/NavigationPath';
import { withTranslation } from 'react-i18next';

interface IProps {
  forgotPassword: (param: IAuthForgotPassword) => void;
  loading: boolean;
  isLoggedin: boolean;
  history: any
  otp: string;
  forgotPasswordSuccess: (param: string) => void;
}

interface IState {
  formData: {
    fields: {
      primary_mobile: string;
    },
    required: string[]
  };
  error: any;
  
}

class ForgotPasswordPage extends React.Component<IProps, IState> {
    
  state: IState = {
      formData: {
        fields: {
          primary_mobile: '',
        },
        required: ['primary_mobile'],
      },
      error: {},
    }

    componentDidMount = () => {
      this.props.forgotPasswordSuccess('')
    }

    handleChange = (e: any, field: string) => {
      const { formData } = this.state;
      console.log('field', field);
      this.setState({ formData: { ...formData, fields: { ...formData.fields, [field]: e.target.value.replace(/\D/g,'') } } })
    }

    onSubmit = () => {
      const { formData } = this.state;
      const { forgotPassword } = this.props;
      const validate = validation.isValidate(formData)
      this.setState({ error: validate })
      if (!Object.keys(validate).length ) { 
        const { formData } = this.state;
        const mobile = `${config.defaultPhoneCode}-${Number(formData.fields.primary_mobile)}`
        forgotPassword({...formData.fields, primary_mobile: mobile})
      }
    }

    static getDerivedStateFromProps(props: IProps, state: IState) {
      if (props.otp) {
          props.forgotPasswordSuccess('')
          props.history.push(PATH.RESET_PASSWORD)
      }
      return state
    }



    render() {
      const { loading } = this.props;
      const { error, formData } = this.state;
      console.log('this.props', this.props);
      
      return (
        <section className="admin_banner-section bg-size-cover bg-position-center">
            <div className="container-fluid">
              <div className="login_form_modal">
                <div className="login_content">
                <div className="main-heading">
                  <h2>{i18n.t(`forgot.title`)}</h2>

        </div>
        <div className="login_pagecontent">
          <p>{i18n.t(`forgot.caption`)}</p>
        </div>


                <div className="login_form">


                <div className="singup_content">
          <div className="select_numberwrap">
            <select>
              <option>{config.defaultPhoneCode}</option>
            </select>
            {/* <Input
              type={'tel'}
              name={'username'}
              onChange={(value: any) => this.handleChange(value, 'primary_mobile')}
              placeholder={'Enter mobile number'}
              value={formData.fields.primary_mobile}
              label={'Mobile'}
              error={error && error.primary_mobile ? error.primary_mobile.message : ''}
              inputClass={'inputMobile'}
            /> */}
            <input type="tel" name="phone" placeholder="5XXXXXXXX"  onChange={(value: any) => this.handleChange(value, 'primary_mobile')} className={'inputMobile'} value={!formData.fields.primary_mobile ? "" : formData.fields.primary_mobile}/>
      
            
          </div>
          {
              (error && error.primary_mobile) && (
              <span className={'error-input'}>{error.primary_mobile.message }</span>
              )
            }
        </div>

            {/* <Input
               type={'text'}
               name={'username'}
               onChange={(value: any) => this.handleChange(value, 'primary_mobile')}
               placeholder={'Enter mobile number'}
               value={formData.fields.primary_mobile}
              containerClass={'form-control'}
              label={'Mobile'}
              error={error && error.primary_mobile ? error.primary_mobile.message : ''}
            /> */}
    
          <div className="login_btnwrp">
            <button 
              className="btn btn-default login_btn" 
              disabled={loading}
              onClick={this.onSubmit}
            >
              {i18n.t(`common.button.submit`)}
              {
                loading && (
                  <img src={images.loader} style={{ height: '30px', width: '30px' }}/>
                )
              }
              
            </button>
          </div>

        </div>
         <div className="banner-tire">
          <img src="img/banner-tire.png" alt="" />
         </div>
      </div>
    </div>
  </div>

        </section>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.auth.loading,
  isLoggedin: state.auth.isLoggedin,
  otp: state.auth.otp,
});

const mapDispatchToProps = {
  forgotPassword: authActions.forgotPassword,
  forgotPasswordSuccess: authActions.forgotPasswordSuccess
}



export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(ForgotPasswordPage)) )