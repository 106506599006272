import { IOrderGet, IOrderSet, IOrderUpdateStatus } from "../models/IOrderState";


export enum ActionTypes {
    ORDER_GET = 'order/ORDER_GET',
    ORDER_SET = 'order/ORDER_SET',
    ORDER_UPDATE_STATUS = 'order/ORDER_UPDATE_STATUS',
}


export interface IActionOrderUpdateStatus {
  type: ActionTypes.ORDER_UPDATE_STATUS;
  payload: IOrderUpdateStatus;
}

export interface IActionOrderGet {
  type: ActionTypes.ORDER_GET;
  payload: IOrderGet;
}

export interface IActionOrderSet {
  type: ActionTypes.ORDER_SET;
  payload: IOrderSet;
}


export function getOrder (data: IOrderGet): IActionOrderGet {
    return {
      payload: data,
      type: ActionTypes.ORDER_GET,
    };
}

export function setOrder (data: IOrderSet): IActionOrderSet {
    return {
      type: ActionTypes.ORDER_SET,
      payload: data
    };
}

export function updateOrderStatus (data: IOrderUpdateStatus): IActionOrderUpdateStatus {
  return {
    type: ActionTypes.ORDER_UPDATE_STATUS,
    payload: data
  };
}

  

export type Action =
IActionOrderGet
  | IActionOrderSet
  | IActionOrderUpdateStatus
