import { put, takeEvery, call, delay, select } from 'redux-saga/effects'
import { inventory as inventoryActions, core as coreActions} from '../actions'
import { api, i18n } from '../services';
import { IActionInventoryGet, IActionInventoryDelete, IActionInventoryAdd, IActionInventoryEdit, IActionInventoryBulkUpload, IActionInventoryGetSize } from '../actions/inventoryActions';
import { getUser } from '../selectors';

export function* getInventorySaga(action: IActionInventoryGet) {
  try {
    console.log('action.payload', action.payload);
    
    const { data= {}, statusCode }  = yield call(api.inventory.getInventory, action.payload);
    if (statusCode === 200) {
      const { totalRecords, perPage, totalPages } = data
      yield put(inventoryActions.setInventory({ items: data.records, limit: perPage, totalPage: totalPages, totalRecords }))
    } else {
      yield put(inventoryActions.setInventory({ items: [], limit: "0", totalPage: "0", totalRecords:"0" }))
    }
  } catch(e) {
    
  }
}

export function* deleteInventorySaga(action: IActionInventoryDelete) {
  try {
    console.log('action.payload', action.payload);
    
    const data  = yield call(api.inventory.delteInventory, action.payload);
    console.log('data', data);
    
    yield put(inventoryActions.deleteInventorySuccess(action.payload.inventory_id))
  } catch(e) {
    
  }
}

export function* addInventorySaga(action: IActionInventoryAdd) {
  try {
    console.log('action.payload', action.payload);
    const user = yield select(getUser);
    const { statusCode, message }  = yield call(api.inventory.addInventory, action.payload);
    const defaultFilter = {
      f_size: '',
      s_size: '',
      t_size: '',
      year: '',
      runflat: '',
      quantity: '',
      madein: '',
      price: '',
      brand_id: ''
      }
    console.log('statusCode', statusCode);
    
    if (statusCode === 200) {
      yield put(inventoryActions.getInventory({ warehouse_user_id: user.profile.id,  page: 1, type: user.profile.warehouse_type, ...defaultFilter}))
      
      yield put(coreActions.addNotification(i18n.t(`wd.inventory.added_success`), 'notice'))
    } else {
      yield put(coreActions.addNotification('Please use unique value!', 'error'))
    }
    yield put(inventoryActions.addInventorySuccess(false))
  } catch(e) {
    
  }
}

export function* editInventorySaga(action: IActionInventoryEdit) {
  try {
    console.log('editInventorySaga', action.payload);
    const user = yield select(getUser);
    const { statusCode, message }  = yield call(api.inventory.editInventory, action.payload);
    yield put(inventoryActions.addInventorySuccess(false))
    // yield put(coreActions.addNotification(message, statusCode === 200 ? 'notice' : 'error'))
    yield put(inventoryActions.setInventory({ items: [], limit: '0', totalPage: '0', totalRecords: '0' }))
    yield put(inventoryActions.getInventory({ warehouse_user_id: user.profile.id,  page: 1, type: user.profile.warehouse_type})) 
    // if (statusCode === 200) {
    // }
  } catch(e) {
    
  }
}

export function* uploadInventorySaga(action: IActionInventoryBulkUpload) {
  try {
    console.log('editInventorySaga', action.payload);
    const { warehouse_user_id, type } = action.payload
    const { statusCode, message }  = yield call(api.inventory.bulkUpload, action.payload);
    yield put(inventoryActions.bulkUploadSuccess());
    if (statusCode === 200) {
      yield put(coreActions.addNotification('Data uploaded successfully', 'notice'))  
      yield put(inventoryActions.getInventory({ warehouse_user_id,  page: 1, type}))
    } else {
      yield put(coreActions.addNotification('Somthing went wrong, please try again later', 'error'))  
    }
  } catch(e) {
    
  }
}

export function* getTireSize(action: IActionInventoryGetSize) {
  try {
    const { type } = action.payload
    console.log('action.payload', action.payload);
    
    const { data = [] }  = yield call(api.search.getTireSize, action.payload) 
    yield put(inventoryActions.setTireSize({ type, items: data  }))
  } catch(e) {
    console.log('e', e);
  }

}



export default [
  takeEvery(inventoryActions.ActionTypes.INVENTORY_GET, getInventorySaga),
  takeEvery(inventoryActions.ActionTypes.INVENTORY_DELETE, deleteInventorySaga),
  takeEvery(inventoryActions.ActionTypes.INVENTORY_ADD, addInventorySaga),
  takeEvery(inventoryActions.ActionTypes.INVENTORY_EDIT, editInventorySaga),
  takeEvery(inventoryActions.ActionTypes.INVENTORY_BULK_UPLOAD, uploadInventorySaga),
  takeEvery(inventoryActions.ActionTypes.INVENTORY_GET_TIRE_SIZE, getTireSize),
];