import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import { ISearchBrand } from '../../../../models/ISearchState';
import config from '../../../../constants/config';
import { Filter } from '../../../../utils';
import { ICartData } from '../../../../models/ICartState';
import { Container,Loader } from '../../../../components/Base';
import moment from "moment"
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../../services'; 
import './Cart.css'

import ReactHtmlParser from 'react-html-parser';
import IRootState, { ICartState, IUserState, ISearchState, IAuthState} from '../../../../models';
import { NONAME } from 'dns';

interface IProps {
  items: ICartData
  onCancle: (order_id: string, type: 'EJ' | 'Retailer') => void;
  onGoMAp: () => void
  canNotCancel: () => void;
  callTo: (phone: string, title: string) => void;
  mapMessage:(labeltext: string, mapLink: string) => void;
  goToSpecs: (orderId: string) => void;
}

interface IState {
  loading:boolean
}


class Cart extends React.PureComponent<IProps, IState> {
  state: IState = {
    loading:false
  }


  status = ['Confirmed', 'Under Process', 'On The Way', 'Ready for Installation']
  

  showMsg(){
    alert(i18n.t(`msg_click_on_getdirection_popup`));
  }

    renderProgress = () => {
      
      this.setState({loading:false})
      const { items } = this.props
      const status = ['Confirmed', 'Under Process', 'On The Way', 'Ready for Installation']
      const statusTranlation = [i18n.t(`cart.element.progress.confirmed`), i18n.t(`cart.element.progress.under_process`), i18n.t(`cart.element.progress.on_the_way`), i18n.t(`cart.element.progress.ready_to_install`)]
      const statusIndex = status.map((value)=> value.toLocaleUpperCase()).indexOf(items.order_status.toLocaleUpperCase())
      if (statusIndex < 0 || items.record_type === 'Retailer') {
        return null
      }
      const activeIndex = 0
      return (
        <div className="md-stepper-horizontal" >
          {
                  status.map(( row, index ) => {
                    return (
                      <a href="javascript:void(0);" className={`md-step  ${statusIndex > index ? 'confiremd' : statusIndex === index ? 'active' : '' }`}>
                      <div className="md-step-circle">
                        {
                          statusIndex >= index && ( <img src={images.checkLogin} className="confirmed_check" /> )
                        }
                        
                      </div>
                      <div className="md-step-title">{statusTranlation[index]}</div>
                      <div className="md-step-bar-left"></div>
                      <div className="md-step-bar-right"></div>
                    </a>
                    )
                  })
          }
        </div>
      )

    }

    render() {
      const { items, onCancle, onGoMAp, canNotCancel, callTo, goToSpecs, mapMessage } = this.props

      // console.log('itemsitemsitems===>',items);

      if(this.state.loading){
        return <Loader />
      }

      const install_center_universal = items.install_center_universal ? items.install_center_universal : 0;
      return (
        
        <div className="cart_listingwrap" >
          {/* {
            this.state.loading ?
              (
                <div className="preloading">
                  <div className="wrap-preload">
                      <div className="cssload-loader"></div>
                  </div>
                </div>
            ) : ""
          } */}
        <div className="lising_cartmain">
        <div className="cart_top">
          <div className="booking_date_cart">
            <p><span>
            {items.record_type === 'EJ' ? i18n.t(`cart.element.order_no`) : i18n.t(`cart.element.booking_no`) } 
              </span> {items.code}</p>
            <p>
             <span>{i18n.t(`cart.element.date`)}
            </span> {moment(items.created_at).format('DD/MM/YYYY, h:mm a')}</p>
          </div>
          {
        items.order_status === 'Complete' && (
          <a href="javascript:void(0);" className="order_complete">
            {
             items.record_type === 'EJ' ? i18n.t(`cart.element.order_completed`) : i18n.t(`cart.element.booking_completed`)
            }
          </a>
        )
      }


      {
        items.order_status === 'Cancelled' && (
          <a href="javascript:void(0);" className="order_complete" style={{ color: 'red' }}>
            {
              items.record_type === 'EJ' ? i18n.t(`cart.element.order_cancelled`) : i18n.t(`cart.element.booking_cancelled`)
            }
          </a>
        )
      }

      {
       (items.order_status === 'Ready for Installation' && items.record_type === 'Retailer') && (
          <a href="javascript:void(0);" className="order_complete" style={{ color: '#8acd36' }}>{i18n.t(`cart.element.progress.ready_to_install`)}</a>
        )
      }


      
      { this.renderProgress() }

        </div>
        <div className="cart_inner">
            <div className="card-order u-inner-wrapper1">
                  <ul>
                     <li>
                        <div className="u-list-box cartmaindiv">
                           <div className="row">
                              <div className="u-brand-logo col-md-3">
                                 <span className="u-brand-col-left">
                                 <img src={`${config.imageBaseUrl}${items.branddetails ? items.branddetails.image : "/tire_default.jpeg"}`} />
                                 </span>
                                 <span className="u-brand-col-right">
                                 <span className="u-list-year">  {items.prod_year}</span>
                                 <span className="u-list-brand"><b>{Filter.translate(items.branddetails ? items.branddetails.brand : "", items.branddetails ? items.branddetails.brand_arabic : "")}</b> </span>
                               {
                                 items.inventory.madein && (
                                  <span className="u-list-made"><span>{i18n.t(`common.label.madein`)}</span> {items.inventory.madein}  </span> 
                                 )
                               }
                               {
                                 items.tyreInfo && (
                                  <a href={`javascript:void(0);`} className='selectSizeLink' onClick={() => goToSpecs(items.id)}>{i18n.t(`cart.element.link.tire_specs`)}</a>
                                 )
                               }
                              
                                 
                                 <span className="u-list-warrenty">{i18n.t(`common.label.delaer_warranty`)} <span className="bin">  {Filter.translate(items.branddetails ? items.branddetails.dealer_warranty : "", items.branddetails ? items.branddetails.dealer_warranty_arabic : "")}  </span></span> 
                                 </span>
                              </div>
                              <div className="rear-size col-md-5 order_complete paymentsize">
                                 
                                    <div className="order-main u-top-mid-col">
                                      
                                      <ul className="tire_qty">
                                        <li>
                                          <div className="order-title">
                                          {Number(items.price_rear) > 0  ?  i18n.t(`common.label.front_size`) : i18n.t(`cart.element.tire_size`)} {`${items.customerrequestdetail.f_size}/${items.customerrequestdetail.s_size}/${items.customerrequestdetail.t_size}`}
                                        </div>
                                        </li>
                                        <li> {Number(items.price_rear) > 0  ?  i18n.t(`common.label.front_qty`) : i18n.t(`cart.element.tire_qty`)} : <span>{items.quantity}</span>
                                        </li>
                                        <li>
                                          {i18n.t(`common.label.unit_price`)}
                                          <span>{items.single_front_price} {i18n.t(`sar`)}</span>
                                        </li>
                                      </ul>
                                    </div>
                                 
                                 {/* {(items.payment_type != 'Cash') && (
                                  <div className="clearfix">
                                    <div className="order-main u-top-mid-col paytypecls">
                                      <div className="order-title">
                                        <span>{i18n.t(`purchase_via`)} 
                                       
                                        </span>
                                        <img width={80} src={(items.payment_type ==='Tamara') ? images.tamaraImage : images.tabbyImage} />
                                      </div>
                                    </div>
                                 </div>
                                 )} */}
                                 
                                 {
                                   Number(items.price_rear) > 0 && (
                                      <div className="order-main u-top-mid-col">
                                        <ul className="tire_qty">
                                          <li><div className="order-title">{i18n.t(`common.label.rear_size`)} : {`${items.f_size_rear}/${items.s_size_rear}/${items.t_size_rear}`}</div></li>
                                          <li>{i18n.t(`common.label.rear_qty`)}<span>{items.quantity_rear}</span></li>
                                          <li>{i18n.t(`common.label.unit_price`)}<span>{items.single_rear_price} {i18n.t(`sar`)}</span></li>
                                        </ul>
                                      </div>
                                   )  
                                 }
    
                              </div>

                              <div className="bootom_cart cart_btmmobile">
              {
                
                items.installcenter && (
                   
                  <div className="left_wrap" style={{display: items.order_status === "Cancelled" || items.order_status === "Complete" ? 'none' : "block"}} >
                      
                  <div className="row">
                    <div className="col-lg-12">
                        {
                          items.type !== "Install at Home" ? (
                          <div className="listing-block">
                             <p className="deliver_time">
                              {ReactHtmlParser(i18n.t(`cart.label.fullfilled`, {
                              name: Filter.translate(items.warehouse.display_name, items.warehouse.display_name_ar), hours: items.warehouse.delivery_hours
                            }))}
                          </p>
                            <div className="inner-box">
                              <div className="image-box img_first">
                                <figure className="image"><img src={`${config.imageBaseUrl}${items.installcenter.image_name}`} alt="" /></figure>
                              </div>

                              <div className="content-box">
                                <div className="upper-box tireinfo_istingwrap">       
                                  <div className="listing-heading tireinfo_isting-heading">
                                      <h3><a href="javascript:void:(0);">{ Filter.translate(items.installcenter.name, items.installcenter.name_ar) }</a></h3>
                                    <p className="address">{ Filter.translate(items.installcenter.display_location, items.installcenter.display_location_ar) }</p>
                                  </div> 
                                  
                              </div>
                            </div>
                          </div>
                          <div className="working_timeing">
                              <p>{i18n.t(`common.label.working_hour`)}</p>
                              <span className="full_time">{ Filter.renderHours(items.installcenter.working_hours, items.installcenter.working_hours_ar)}</span>
                            </div> 
                              <ul className="contact_shop">
                              {/* <li> <a href={`tel:${items.installcenter.phone_number}`}>{i18n.t(`cart.element.button.call`)}</a></li> */}
                              <li> <a href={`javascript:void(0);`} onClick={() => callTo(items.warehouse.primary_mobile, i18n.t(`cart.element.button.call`))}>{i18n.t(`cart.element.button.call`)}</a></li>
{/*


                              <li><a href={`https://maps.google.com/?q=${items.installcenter.latitude},${items.installcenter.longitude}`} target="_blank">{i18n.t(`cart.element.button.direction`)}</a></li>
*/}
                              <li>


                              {  
                              (items.order_status === "Confirmed" || items.order_status === "Under process" || items.order_status === "On the way")  ? <a 

                              onClick={() => mapMessage(i18n.t(`msg_click_on_getdirection_popup`), `https://www.google.com/maps/place/${items.installcenter.latitude},${items.installcenter.longitude}`)
                            }  
                              href="javascript:void(0);" >{i18n.t(`cart.element.button.direction`)}</a>  : <a href={`https://www.google.com/maps/place/${items.installcenter.latitude},${items.installcenter.longitude}`} target="_blank">{i18n.t(`cart.element.button.direction`)}</a> 
                            }  


                                
                                
                                
                                
                                
                                </li>
  
                            </ul>            
                          </div>  
                        ) : (
                          <div className="listing-block">
                          <p className="deliver_time">
                            {ReactHtmlParser(i18n.t(`cart.label.fullfilled_home`, {
                            name: Filter.translate(items.warehouse.display_name, items.warehouse.display_name_ar), hours: items.warehouse.delivery_hours
                          }))}
                          </p> 
                          <div className="inner-box">
                          <div className="content-box">
                            <div className="upper-box tireinfo_istingwrap">       
                              <div className="listing-heading">
                                {items.delivery_address}
                              </div>
                            </div>
                          </div>
                        </div> 
                        </div>
                        )}     
                            
                    </div>
                  </div>            
              </div>
                )
              }

{
                items.vendor && (
                  <div className="left_wrap">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="listing-block">
                        <div className="inner-box">
                          <div className="image-box img_first">
                            <figure className="image"><img src={`${config.imageBaseUrl}${items.vendor.image}`} alt="" /></figure>
                          </div>

                          <div className="content-box">
                            <div className="upper-box tireinfo_istingwrap">       
                              <div className="listing-heading tireinfo_isting-heading">
                                  <h3><a href="javascript:void:(0);">{ Filter.translate(items.vendor.shop_name, items.vendor.shop_name_ar) }</a></h3>
                                <p className="address">{ Filter.translate(items.vendor.location, items.vendor.location_ar) }</p>
                              </div> 
                                        
                            </div>      
                              
                          </div>
                        </div>
                        <div className="working_timeing">
                          <p>{i18n.t(`common.label.working_hour`)}</p>
                          <span className="full_time">{ Filter.renderHours(items.vendor.working_hours, items.vendor.working_hours_ar) }</span>
                        </div> 
                        <ul className="contact_shop">
                            <li> <a href={`javascript:void(0);`} onClick={() => callTo(items.vendor.primary_mobile, i18n.t(`cart.element.button.call`))}>{i18n.t(`cart.element.button.call`)}</a></li>
                            <li><a href={`https://www.google.com/maps/?q=-${items.vendor.latitude},${items.vendor.longitude}`} target={'_blank'}>{i18n.t(`cart.element.button.direction`)}</a></li>
                          </ul>  
                        <p className="deliver_time">{i18n.t(`cart.label.booking`)}</p>     
                      </div>

                    </div>
                  </div>            
              </div>
                )
              }
         
         
                
            



            </div>

                              <div className="col-sm-4 col_3responsive payouterboxwrap">

                            {(items.order_status === 'Cancelled') ? "" : ( 
                              <div className="paytypecls mobileshow">
                                    <div className="order-title">
                                    {(items.payment_type != 'Cash') ? ( 
                                      <>
                                        <img width={80} src={(items.payment_type ==='Tamara') ? images.tamaraImage : images.tabbyImage} />
                                        <span>{(items.payment_type ==='Tamara') ? i18n.t(`purchase_via_tamara`) : i18n.t(`purchase_via_tabby`)} </span>
                                      </>
                                      ) : (
                                      <>
                                        <img width={80} src={images.cashPayImage} />
                                        <span>{i18n.t(`pay_at_shop`)} </span>
                                      </>
                                      )
                                    }
                                    </div>
                                  </div>
                                  )
                            }


                                 <div className="order-good1 ravi1">
                                    <div className="order-add"><span> {i18n.t(`common.label.subtotal`)}

                                       </span> <b>{Number(items.quantity) * Number(items.single_front_price) + Number(items.quantity_rear) * Number(items.single_rear_price)} {i18n.t(`sar`)} </b> 
                                    </div>
                                    <div className="text-left ml-2">
                                     {(items.type == "Install at Home" && install_center_universal) ? '' : (
                                      <div className="order-add anorder"><span>{i18n.t(`common.label.installation`)} </span> 
                                        {
                                          Number(items.installation_amount) > 0 ? (
                                          <b> {items.installation_amount} {i18n.t(`sar`)} </b> 
                                        ) : (
                                          <span className="free"> {i18n.t(`common.free`)} </span>
                                        )}
                                      </div>
                                    )} 
                                    
                                    <div className="order-add"><span>{i18n.t(`common.label.Delivery`)} : </span> 
                                       {
                                          Number(items.delivery_amount) > 0 ? (
                                          <b> {items.delivery_amount} {i18n.t(`sar`)} </b> 
                                        ) : (
                                          <span className="free">{i18n.t(`common.free`)} </span>
                                        )}
                                    </div>
                                    {
                                      Number(items.discount) > 0 && (

                                    <div className="order-add u-dis"><span>{i18n.t(`common.label.discount`)}
                                       </span> <b> {items.discount} {i18n.t(`sar`)} </b> 
                                    </div>
                                    )}
                                  </div>
                                     <div className="order-add anorder"><span>{i18n.t(`common.label.total`)} 
                                       </span> <b>{items.priceD} {i18n.t(`sar`)}  </b> 
                                    </div>
                                    
                                    <div className="order-good1-desktop ravi2">
                                        <div className="order-add"><span>{i18n.t(`common.label.vat`)} 
                                                  </span> <b>{items.vat} {i18n.t(`sar`)}</b> 
                                              </div>
                                              <div className="order-add">
                                                  <span className="u-list-tot"> {i18n.t(`common.label.grand`)} {items.grand_total} {i18n.t(`sar`)}  </span>  
                                              </div>
                                    </div>

                                    

                                    <div className="order-good1-mobile d-none shouldberemove">
                            <div className="order-add"><span>{i18n.t(`common.label.vat`)} 
                                      </span> <b>{items.vat} {i18n.t(`sar`)}</b> 
                                  </div>
                                  <div className="order-add">
                                      <span className="u-list-tot"> {i18n.t(`common.label.grand`)} {items.grand_total} {i18n.t(`sar`)}  </span>  
                                  </div>
                            </div>
                                 

                                 </div>
                              </div>
                          
                           



                              
                           </div>
                        </div>
                     </li>
                  </ul>
            </div>
            <div className="bootom_cart">
              {
                items.installcenter && items.type !== "Install at Home" &&(
                  <div className="left_wrap">
                  <div className="row" style={{display: items.order_status === "Cancelled" || items.order_status === "Complete" ? 'none' : "block"}}>
                    <div className="col-lg-12">
                      <div className="listing-block">
                        <div className="inner-box">
                          <div className="image-box img_first">
                            <figure className="image"><img src={`${config.imageBaseUrl}${items.installcenter.image_name}`} alt="" /></figure>
                          </div>

                          <div className="content-box">
                            <div className="upper-box tireinfo_istingwrap">       
                              <div className="listing-heading tireinfo_isting-heading">
                                  <h3><a href="javascript:void:(0);">{ Filter.translate(items.installcenter.name, items.installcenter.name_ar) }</a></h3>
                                <p className="address">{ Filter.translate(items.installcenter.display_location, items.installcenter.display_location_ar) }</p>
                              </div> 
                              <div className="working_timeing">
                                <p>{i18n.t(`common.label.working_hour`)}</p>
                                <span className="full_time">{ Filter.renderHours(items.installcenter.working_hours, items.installcenter.working_hours_ar)}</span>
                              </div>            
                            </div>      
                              <ul className="contact_shop">
                                {/* <li> <a href={`tel:${items.installcenter.phone_number}`}>{i18n.t(`cart.element.button.call`)}</a></li> */}
                                <li> <a href={`javascript:void(0);`} onClick={() => callTo(items.warehouse.primary_mobile, i18n.t(`cart.element.button.call`))}>{i18n.t(`cart.element.button.call`)}</a></li>

              <li>

                {  

(items.order_status === "Confirmed" || items.order_status === "Under process" || items.order_status === "On the way") ? <a 

                  onClick={() => mapMessage(i18n.t(`msg_click_on_getdirection_popup`), `https://www.google.com/maps/place/${items.installcenter.latitude},${items.installcenter.longitude}`)} 

                  /*onClick={this.showMsg} */ 
                  href="javascript:void(0);" >{i18n.t(`cart.element.button.direction`)}</a>  : <a href={`https://www.google.com/maps/place/${items.installcenter.latitude},${items.installcenter.longitude}`} target="_blank">{i18n.t(`cart.element.button.direction`)}</a> 
                }  

                


                                </li>
                              </ul> 
                          </div>
                        </div>

                        <p className="deliver_time" style={(items.order_status == "Cancelled" || items.order_status == "Complete") ? {display:"none"} : {display:"block"}}>{ReactHtmlParser(i18n.t(`cart.label.fullfilled`, {
                          name: Filter.translate(items.warehouse.display_name, items.warehouse.display_name_ar), hours: items.warehouse.delivery_hours
                        }))}
                        </p>     
                      </div>
                    </div>
                  </div>            
              </div>
                )
              }

{
                items.vendor && items.type !== "Install at Home" &&(
                  <div className="left_wrap" >
                  <div className="row" style={{display: items.order_status === "Cancelled" ? 'none' : "block"}}>
                    <div className="col-lg-12">
                      <div className="listing-block">
                        <div className="inner-box">
                          <div className="image-box img_first">
                            <figure className="image"><img src={`${config.imageBaseUrl}${items.vendor.image}`} alt="" /></figure>
                          </div>

                          <div className="content-box">
                            <div className="upper-box tireinfo_istingwrap">       
                              <div className="listing-heading tireinfo_isting-heading">
                                  <h3><a href="javascript:void:(0);">{ Filter.translate(items.vendor.shop_name, items.vendor.shop_name_ar) }</a></h3>
                                <p className="address">{ Filter.translate(items.vendor.location, items.vendor.location_ar) }</p>
                              </div> 
                              <div className="working_timeing">
                                <p>{i18n.t(`common.label.working_hour`)}</p>
                                <span className="full_time">{ Filter.renderHours(items.vendor.working_hours, items.vendor.working_hours_ar) }</span>
                              </div>            
                            </div>      
                            { (items.order_status === "Cancelled") ? "" :
                              ( <ul className="contact_shop">
                                <li> <a href={`javascript:void(0);`} onClick={() => callTo(items.vendor.primary_mobile, i18n.t(`cart.element.button.call`))}>{i18n.t(`cart.element.button.call`)}</a></li>
                                <li><a href={`https://www.google.com/maps/?q=-${items.vendor.latitude},${items.vendor.longitude}`} target={'_blank'}>{i18n.t(`cart.element.button.direction`)}</a></li>
                              </ul> 
                              ) }
                          </div>
                        </div>
                        <p className="deliver_time">{i18n.t(`cart.label.booking`)}</p>     
                      </div>
                    </div>
                  </div>            
              </div>
                )
              }
              {
                items.type == "Install at Home" &&(
                  <div className="left_wrap">
                  <div className="row" style={{display: items.order_status === "Cancelled" || items.order_status === "Complete" ? 'none' : "block"}}>
                    <div className="col-lg-12">
                      <div className="listing-block">
                        <p className="deliver_time" style={(items.order_status == "Cancelled" || items.order_status == "Complete") ? {display:"none"} : {display:"block"}}>
                          {ReactHtmlParser(i18n.t(`cart.label.fullfilled_home`, {
                            name: Filter.translate(items.warehouse.display_name, items.warehouse.display_name_ar), hours: items.warehouse.delivery_hours
                          }))}
                        </p>  
                        
                        <div className="inner-box">
                          <div className="content-box">
                            <div className="upper-box tireinfo_istingwrap">       
                              <div className="listing-heading">
                                {items.delivery_address}
                              </div>
                            </div>
                          </div>
                        </div>  
                        <ul className="contact_shop">
                          {
                            items.warehouse && items.order_status != "Cancelled" && (<li> <a href={`javascript:void(0);`} onClick={() => callTo(items.warehouse.primary_mobile, i18n.t(`cart.element.button.call`))}>{i18n.t(`cart.element.button.call`)}</a></li>)
                          }
                          {
                            items.vendor && (<li> <a href={`javascript:void(0);`} onClick={() => callTo(items.vendor.primary_mobile, i18n.t(`cart.element.button.call`))}>{i18n.t(`cart.element.button.call`)}</a></li>)
                          }
                        </ul>  
                      </div>
                    </div>
                  </div>            
              </div>
                )
              }
         
         
                <div className="right_wrap">

                                {(items.order_status === 'Cancelled') ? "" : ( 
                                  <div className="paytypecls">
                                    <div className="order-title">
                                    {(items.payment_type != 'Cash') ? (  
                                      <>
                                        <img width={80} src={(items.payment_type ==='Tamara') ? images.tamaraImage : images.tabbyImage} />
                                        <span>{(items.payment_type ==='Tamara') ? i18n.t(`purchase_via_tamara`) : i18n.t(`purchase_via_tabby`)} </span>
                                      </>
                                      ) : ( 
                                      <>
                                        <img width={80} src={images.cashPayImage} />
                                        <span>{i18n.t(`pay_at_shop`)} </span>
                                      </>
                                      )
                                    }
                                    </div>
                                  </div>
                                  )
                                }


                  {/* <div className="order-good1 ravi2">
                  <div className="order-add"><span>{i18n.t(`common.label.vat`)} 
                            </span> <b>{items.vat} {i18n.t(`sar`)}</b> 
                        </div>
                        <div className="order-add">
                            <span className="u-list-tot"> {i18n.t(`common.label.grand`)} {items.grand_total} {i18n.t(`sar`)}  </span>  
                        </div>
                  </div> */}
                              {
              items.order_status !== 'Cancelled' && ( items.order_status !== 'Complete' && (  
                <div className="pay_btn">
                
                        <a 
                          href="javascript:void(0);" 
                          className="confrm_book" 
                          onClick={
                            () => {
                              ((items.record_type === "EJ" && ['Confirmed', 'Under process'].indexOf(items.order_status) >= 0 ) 
                              || items.record_type === "Retailer" && ['Ready for Installation'].indexOf(items.order_status) >= 0 ) 
                              ? onCancle(items.id, items.record_type) : canNotCancel()                            
                            }
                          }
                          style={items.record_type === "EJ" && ['Confirmed', 'Under process'].indexOf(items.order_status) >= 0 ? { backgroundColor: '#ffc000', color: '#fff' } : items.record_type === "Retailer" && ['Ready for Installation'].indexOf(items.order_status) >= 0 ? { backgroundColor: '#ffc000', color: '#fff' }  : {}}
                        >
                            {i18n.t(`cart.element.button.cancel`)} 
                        </a>
                
                  <a href="javascript:void(0);" className="alert-primary_link" style={{ cursor: 'text' }}>
                  {/* {i18n.t(`cart.element.pay_at_center`)} */}
                  </a>
                </div>
              )
  )
}
              </div>
            



            </div>

            <div className="col-sm-2 col_3responsive listing_mobile d-none shouldberemove">
               <div className="order-good1 ravi3">
                  <div className="order-add"><span> {i18n.t(`common.label.subtotal`)}
                     </span> <b>{Number(items.quantity) * Number(items.single_front_price) + Number(items.quantity_rear) * Number(items.single_rear_price)} {i18n.t(`sar`)} </b> 
                  </div>
                  <div className="text-left ml-2">
                  <div className="order-add anorder"><span>{i18n.t(`common.label.installation`)}</span> 
                    {
                      Number(items.installation_amount) > 0 ? (
                      <b> {items.installation_amount} {i18n.t(`sar`)} </b> 
                    ) : (
                      <span className="free">{i18n.t(`common.free`)} </span>
                    )}
                  </div>

                  
                  <div className="order-add"><span>{i18n.t(`common.label.Delivery`)} : </span> 
                     {
                        (Number(items.delivery_amount) > 0) ? (
                        <b> {items.delivery_amount} {i18n.t(`sar`)} </b> 
                      ) : (
                        <span className="free">{i18n.t(`common.free`)} </span>
                      )}
                  </div>
                  {
                    Number(items.discount) > 0 && (

                  <div className="order-add u-dis"><span>{i18n.t(`common.label.discount`)}
                     </span> <b> {items.discount} {i18n.t(`sar`)} </b> 
                  </div>
                  )}
                </div>
                   <div className="order-add anorder"><span>{i18n.t(`common.label.total`)} 
                     </span> <b>{items.priceD} {i18n.t(`sar`)}  </b> 
                  </div>

                  <div className="order-good1-mobile">
                    <div className="order-add"><span>{i18n.t(`common.label.vat`)} 
                              </span> <b>{items.vat} {i18n.t(`sar`)}</b> 
                          </div>
                          <div className="order-add">
                              <span className="u-list-tot"> {i18n.t(`common.label.grand`)} {items.grand_total} {i18n.t(`sar`)}  </span>  
                          </div>
                    </div>
               </div>
            </div>
            <div className="right_wra cartb_right">
                  <div className="order-good1">
                  <div className="order-add"><span>{i18n.t(`common.label.vat`)} 
                            </span> <b>{items.vat} {i18n.t(`sar`)}</b> 
                        </div>
                        <div className="order-add">
                            <span className="u-list-tot"> {i18n.t(`common.label.grand`)} {items.grand_total} {i18n.t(`sar`)}  </span>  
                        </div>
                  </div>
                              {
              items.order_status !== 'Cancelled' && ( items.order_status !== 'Complete' && (
                <div className="pay_btn">
                
                        <a 
                          href="javascript:void(0);" 
                          className="confrm_book" 
                          onClick={() => ((items.record_type === "EJ" && ['Confirmed', 'Under process'].indexOf(items.order_status) >= 0 ) || items.record_type === "Retailer" && ['Ready for Installation'].indexOf(items.order_status) >= 0 ) ? onCancle(items.id, items.record_type) : canNotCancel()}
                          style={items.record_type === "EJ" && ['Confirmed', 'Under process'].indexOf(items.order_status) >= 0 ? { backgroundColor: '#ffc000', color: '#fff' } : items.record_type === "Retailer" && ['Ready for Installation'].indexOf(items.order_status) >= 0 ? { backgroundColor: '#ffc000', color: '#fff' }  : {}}
                        >
                            {i18n.t(`cart.element.button.cancel`)} 
                        </a>
                
                  {/* <a href="javascript:void(0);" className="alert-primary_link" style={{ cursor: 'text' }}>
                  {i18n.t(`cart.element.pay_at_center`)}
                  </a> */}
                </div>
              )
  )
}
              </div>


        </div>
      </div>
      </div>
      )
    }
}


export default withTranslation()(Cart)