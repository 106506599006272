import { api } from '..';
import { IOrderGet, IOrderUpdateStatus, IOrderGetAll } from '../../models/IOrderState';

async function getOrder(params: IOrderGet) {
    try {
        return api.postApi(`/${params.role}/orderlist`, params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function updateOrderStatus(params: IOrderUpdateStatus) {
    try {
        return api.postApi(`/warehouse/updateorder`, params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getAllOrder(params: IOrderGetAll): Promise<any> {
    const urlType = {
        Basic: '/exports/basicorders',
        Normal: '/exports/normalorders',
        Dealer: '/exports/dealerorders'
    }
    try {
        return api.postApi(urlType[params.type], params)
    } catch (e) {
        Promise.reject(e)
    }
}

export {
    getOrder,
    updateOrderStatus,
    getAllOrder
}
