import React from 'react';
import { images } from '../../../constants/Images';
import IRootState, { ICartState, IUserState, ISearchState, IAuthState} from '../../../models';
import { connect } from 'react-redux';
import { cart as cartActions } from '../../../actions'
import { Container,Loader } from '../../../components/Base';
import config from '../../../constants/config';
import { Cart } from '../Components';
import { Modal, AlertModal, ModalRight, MapAlertModal } from '../../../components';
import { validation, i18n } from '../../../services';
import { ICartCancelOrder, ICartOrderDetail, ICartData } from '../../../models/ICartState';
import { withRouter,useHistory } from 'react-router-dom';
import { PATH } from '../../../navigation/NavigationPath';
import { withTranslation } from 'react-i18next';
import { DetailModal } from '../../Search/Components';
import { ISearchTireCache } from '../../../models/ISearchState';

interface IProps {
  getCart: (param: { user_id: string }) => void;
  cart: ICartState;
  user: IUserState;
  isLoggedin: boolean;
  cancelOrder: (param: ICartCancelOrder) => void;
  history: any
  search: ISearchState;
  getOrderDetail: (param: ICartOrderDetail) => void;
  auth: IAuthState,
}
interface IState {
  toggelModal: boolean
  formData: {
    fields: {
      reason: string
      user_id: string
      order_id: string
      type: 'EJ' | 'Retailer'
    },
    required: string[],
  },
  error: any;
  isShowAlertModal: boolean;
  callTo: string;
  mapMessage : string;
  mapLink : string;
  callToTitle: string;
  isSpecsShow: boolean;
  orderId: string;
  tireData: ISearchTireCache,
  loader:boolean,  
}

class CartPage extends React.Component<IProps, IState> {
    state: IState = {
      toggelModal: false,
      formData: {
        fields: {
          reason: '',
          user_id: this.props.user.profile.id,
          order_id: '',
          type: 'EJ'
        },
        required: ['reason']
      },
      error: {},
      isShowAlertModal: false,
      callTo: '',
      mapMessage : '',
      mapLink : '',
      callToTitle: '',
      isSpecsShow: false,
      orderId: '',
      // @ts-ignore-start
      tireData: {},
      loader:false
    }
    componentDidMount () {
      const { getCart, user, isLoggedin } = this.props;
      if (isLoggedin) {        
        console.log(this.props);
        getCart({ user_id: user.profile.id })
      }
      this.setState({loader:true})
        setTimeout(() => {
          this.setState({loader: false});
        }, 2000)
    }
    componentDidUnmount () {
      this.setState({loader:false})
    }


    renderItem = () => {
      const {cart, history } = this.props
      const {formData } = this.state
      let cartLength = (cart.cart).length -1;
      return cart.cart.map((item, index) => {
        const onGoMAp = () => history.push(PATH.MAP, item)
        return <Cart 
          items={item} 
          key={index}
          onCancle={this.onToggel}
          onGoMAp={onGoMAp}
          canNotCancel={this.toggelAlertModal} 
          callTo={this.callTo}
          mapMessage={this.mapMessage}
          goToSpecs={this.onSpecsToggel}
        /> 
      });
   } 

  mapMessage = (labeltext: string, mapLink: string) => {
    this.setState({ mapMessage: labeltext, mapLink: mapLink });
  } 

  callTo = (phone: string, title: string) => {
    this.setState({ callTo: phone, callToTitle: title });
  }

   onToggel = (order_id: string = '', type: 'EJ' | 'Retailer'='EJ') => {
     const { toggelModal, formData } = this.state;
     this.setState({ 
       toggelModal: !toggelModal, 
       formData: { 
         ...formData, 
         fields: { 
           ...formData.fields, 
           ...type === 'EJ' ? { ej_order_id: order_id  } : { order_id }, 
           type 
          } 
        } 
      })
   }

   onCancel = () => {
    const { formData } = this.state;
    const { cancelOrder } = this.props;
    const validate = validation.isValidate(formData)
    this.setState({ error: validate })
    
    if (!Object.keys(validate).length ) {
      cancelOrder(formData.fields);
      this.setState({ toggelModal: false })
    }
    this.setState({loader:true})
    setTimeout(() => {
      this.setState({loader: false});
    }, 2000)
    // window.location.reload(true);
   }

   toggelAlertModal = () => {
     const { isShowAlertModal } = this.state;
     this.setState({ isShowAlertModal: !isShowAlertModal })
   }

   onSpecsToggel = (orderId: string = '') => {
     const { isSpecsShow } = this.state;
     const { getOrderDetail, user } = this.props;
     this.setState({ isSpecsShow: !isSpecsShow, orderId })
     if (orderId !== '') {
      getOrderDetail({ ej_order_id: orderId,  user_id: user.profile.id})
     }
     
   }

   static getDerivedStateFromProps(props: IProps, state: IState) {
    const cartData = props.cart.cart.find((row: ICartData) => row.id === state.orderId)
    return {...state, tireData: cartData?.tireData}
  }

    render() {
      const { search, auth, cart } = this.props;
      const { toggelModal, formData, error, isShowAlertModal, callTo, callToTitle, isSpecsShow, orderId, tireData , mapMessage, mapLink} = this.state;     

      return (
        <>
        <Container>
             <div>
             <section className="inner-page-banner center-text-banner bg-size-cover" style={{ background: ` #fcc223` }}>
              <div className="tire-mark"><img src={images.innerTireMark} alt=""/></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="list-heading text-center">
                      <h3>{i18n.t(`cart.title`)}</h3>
                    </div>
                  </div>         
                </div>
              </div>
            </section>
            <section className="cart_contenwrp whoweare-wrap">
              <div className="container">
                <div className="cart_heading">
                  <h2>{i18n.t(`cart.subtitle`)}</h2>
                </div>

                <div style={this.state.loader?{"display":"block"}:{"display":"none"}}>
                  <Loader />
                </div>
                <div style={this.state.loader?{"display":"none"}:{"display":"block"}}>
                  {this.renderItem()}
                </div>
                
              </div>
            </section>
            </div>
         </Container>
         <Modal 
          onToggel={this.onToggel}
          show={toggelModal}
        >   
          <div className="modal-header title-modal">
            <h4 className="modal-title cancelpopup">
            {i18n.t(`common.cancellation_reason`)}
            </h4>
          </div>
          <div className="modal-body quantity sizeQuantity">
            <textarea name="" style={{ width: '100%', height: '100px' }} onChange={(e: any) => this.setState({ formData: { ...formData, fields: {...formData.fields, reason: e.target.value } } })}/>
            
          </div>
          {
            validation.renderError('reason', error) && (
             <span  style={{ paddingLeft: '1.25rem', color: 'red' }}>{validation.renderError('reason', error)}</span>
              
            )
          }
          
          <div className="modal-footer proceed">
            <button type="button" className="footer-btn" onClick={this.onCancel}>{i18n.t(`common.button.submit`)}</button>
          </div>
          </Modal>
          <AlertModal
            show={isShowAlertModal}
            handleClose={this.toggelAlertModal}
            title={i18n.t(`cart.cancel_order.title`)}
            description={i18n.t(`cart.cancel_order_inactive`)}
            actionType={'remove'}
            handleAction={()=> {}}
            isConfirmation={false}
          />  
          <AlertModal
            show={callTo !== ''}
            handleClose={() => this.callTo('', '')}
            title={callToTitle}
            description={(i18n.language === 'en') ? `+${callTo}` : `${callTo}+`}
            actionType={'call'}
            handleAction={()=> {}}
          /> 
          
          <MapAlertModal
            show={mapMessage !== ''}
            handleClose={() => this.mapMessage('', '')}
            title={mapMessage}
            description={`${mapLink}`}
            actionType={'call'}
            handleAction={()=> {}}
          />
 

         <ModalRight onToggel={this.onSpecsToggel} show={isSpecsShow} title={i18n.t(`tire.detail.title`)}>
                  <DetailModal  items={tireData} />
          </ModalRight>
        </>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  cart: state.cart,
  user: state.user,
  isLoggedin: state.auth.isLoggedin,
  search: state.search
});

const mapDispatchToProps = {
  getCart: cartActions.getCart,
  cancelOrder: cartActions.cancelOrder,
  getOrderDetail: cartActions.getOrderDetail
}



export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(CartPage))) 