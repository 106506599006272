
const appEnvironment = 'staging2'; //live / staging /local / staging2 / staging3
// const appEnvironment2 = 'live2';
const apiUrl = { 'live': 'https://www.estbnh.com/webservices', 'live2': 'https://estbnh.com/webservices', 'staging': 'https://kafaratitest.girnarsoft.co.in/webservices', 'local': 'http://local.kafarati.com/webservices', 'staging2': 'https://estbnhtest.girnarsoft.co.in/webservices', 'staging3':'https://estbnh.girnarsoft.co/webservices' };
const apiImgUrl = { 'live': 'https://www.estbnh.com/backend/web/uploads', 'live2': 'https://estbnh.com/backend/web/uploads', 'staging': 'https://kafaratitest.girnarsoft.co.in/backend/web/uploads', 'local': 'http://local.kafarati.com/backend/web/uploads', 'staging2': 'https://estbnhtest.girnarsoft.co.in/backend/web/uploads', 'staging3':'https://estbnh.girnarsoft.co/backend/web/uploads'};

const isValidWwwUrl = () => {
  let url = window.location.href;
  return url.includes('www');
}

const baseUrlBuilder = () => {
  // const urlcheck = isValidWwwUrl();
  // console.log('urlcheck====>',urlcheck);
  // if(urlcheck){
  //   return `${apiUrl[appEnvironment]}`;
  // } else {
  //   return `${apiUrl[appEnvironment2]}`;
  // }
  return `${apiUrl[appEnvironment]}`;
}

const config = {
  apiUrl: baseUrlBuilder(),
  // imageBaseUrl: isValidWwwUrl() ? apiImgUrl[appEnvironment] : apiImgUrl[appEnvironment2],
 // imageBaseUrl: 'https://www.estbnh.com/backend/web/uploads',
  imageBaseUrl: apiImgUrl[appEnvironment],
  currency: 'SAR',
  googleApiKey: 'AIzaSyD-6jEMi205oSuzSoALHH0jWX-d2BX5Ato', //'AIzaSyBruCKVT6sQNGIwfVUh70tuQcqiDgCDESE',
  defaultPhoneCode: '966',
  appName: 'Estbnh',
}



export default config;
