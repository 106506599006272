export enum PATH {
    HOME =  '/',
    CONTACT_US = '/contact-us',
    SEARCH_BY_BRAND = '/search-by-brand',
    // SEARCH_BY_SIZE = '/search-by-size',
    SEARCH_BY_BRAND_YEAR = '/search-by-brand/:id',
    STATIC= '/:id',
    SEARCH_TIRES_LIST= '/search-tires',
    SEARCH_BY_SIZE= '/search-by-size',
    CART= '/cart',
    CHOOSE_LOCATION= '/choose-location',
    LOGIN= '/login',
    INVENTORY= '/inventory',
    MANAGE_ORDER= '/manage-order',
    MANGE_TIRES= '/manage-tires',
    MANGE_SHOP= '/manage-shop',
    FORGOT_PASSWORD= '/forgot-password',
    RESET_PASSWORD= '/reset-password',
    MAP= '/map',
    FAQ= '/faq',
    BLOG_DETAIL= '/blog-detail',
    TAMARA_SUCCESS= '/tamara-success',
    TAMARA_FAILURE= '/tamara-failure',
    TAMARA_CANCEL= '/tamara-cancel',
    TABBY_SUCCESS= '/tabby-success',
    TABBY_FAILURE= '/tabby-failure',
    TABBY_CANCEL= '/tabby-cancel',
}