import React from 'react';
import { Container } from '../../../components/Base';
import { withRouter } from 'react-router-dom';
import { AlertModal } from '../../../components';
import { PATH } from '../../../navigation/NavigationPath';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../services';


interface IProps {
  history: any
}

interface IState {
	isShowAlertModal: boolean,
  popupMsg:string,
  okMsg:string
}

class TamaraFailure extends React.Component<IProps, IState> {

  state: IState =  {
		isShowAlertModal: true,
    popupMsg:"",
    okMsg:""
	}

  componentDidMount() {
    let url_string = window.location.href;
    let url = new URL(url_string);
    // let referenceId = url.searchParams.get("referenceId");
    // let paymentStatus = url.searchParams.get("paymentStatus");
    // let orderId = url.searchParams.get("orderId");
    

      // console.log('rammji=====>', url_string);

      setTimeout(() => {
        this.setState({popupMsg:i18n.t(`failed_payment_tamara`), okMsg:i18n.t(`OK`)});
      }, 1000);
  }

  toggelAlertModal = () => {
    const { history} = this.props;
		const { isShowAlertModal } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal })
    history.push(PATH.CART)
	}

    render() {
      const { history} = this.props;
      const { isShowAlertModal } = this.state;
       
      return (
        <Container>
          <div style={{height:'300px'}}></div>
        <AlertModal
            show={isShowAlertModal}
            handleClose={this.toggelAlertModal}
            title={(`Tamara Payment Fail`)}
            description={this.state.popupMsg}
            actionType={'remove'}
            handleAction={()=> {}}
            isConfirmation={false}
            closeButtonName={this.state.okMsg}
            handleNavigation={() => history.push(PATH.CART)}
         />
         </Container>
      )
    }
}

export default withRouter<any, any>(withTranslation()(TamaraFailure)) ;