import React from 'react';
import { Container } from '../../../components/Base';
import { images } from '../../../constants/Images';
import { withRouter } from "react-router";
import IRootState, { ICoreState } from '../../../models';
import { connect } from 'react-redux';
import { core as coreActions } from '../../../actions'
import { Accordion, Card, Button } from 'react-bootstrap';
import { IFaq } from '../../../models/ICoreState';
import './FaqPage.css'
import { validation, i18n } from '../../../services';

interface IProps {
  match: any;
  getFaq: () => void;
  core: ICoreState;
  history: any
}


class FaqPage extends React.Component<IProps, {}> {
  
    componentDidMount () {
      const { getFaq } = this.props;
      getFaq();
    }

    renderItem = () => {
      const { core } = this.props;
      return core.faq.map(( row: IFaq, index: number ) => {
        return (
          <Card>
              <Accordion.Toggle as={Card.Header} eventKey={`${index}`} className={`title`}>
               {row.question}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`${index}`}>
              <Card.Body>{row.answer}</Card.Body>
            </Accordion.Collapse>
          </Card>
        )
      })
    }

    render() {
      
      return (
         <Container>
           <div>
           <section className="inner-page-banner center-text-banner bg-size-cover" style={{ background: ` #fcc223` }}>
            <div className="tire-mark"><img src={images.innerTireMark} alt=""/></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="list-heading text-center">
                    <h3>{i18n.t(`FAQ`)}</h3>
                    {/* <p>{HEADING[slug].subtitle}</p> */}
                  </div>
                </div>         
              </div>
            </div>
          </section>
            <Accordion defaultActiveKey="0" style={{ margin: '30px' }} className="accordionData">
              {this.renderItem()}
            </Accordion>
          </div>
         </Container>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  core: state.core,
});

const mapDispatchToProps = {
  getFaq: coreActions.getFaq
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(FaqPage))