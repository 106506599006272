import React, { FunctionComponent } from 'react';
import { Header, Footer, Container } from '../../../components/Base';
import { Banner, StepsSection, Blogs } from '../Components';
import Brands from '../Components/Brands';
import Testimonials from '../Components/Testimonials';
import { connect } from 'react-redux';
import IRootState, { IHomeState, ILangState } from '../../../models';
import { home as homeActions, core as coreActions, user as userActions } from '../../../actions'
import { ICoreCities, ICoreLangType } from '../../../models/ICoreState';
import { useHistory, withRouter } from 'react-router-dom';
import { IHomeBanner, IHomeknowyourtireData } from '../../../models/IHomeState';
import { PATH } from '../../../navigation/NavigationPath';

interface IProps {
   getData: ({}) => void;
   home: IHomeState
   getCities: () => void;
   getBanner: (lang: ICoreLangType) => void; 
   getNotification: () => void;
   isLoggedin: boolean;
   selectedCity: ICoreCities
   addNotification: (mag: string, type: any) => void
   lang: ILangState;
   history: any;
}

 

const HomePage: FunctionComponent<IProps> = (props) => {
      
      const history = useHistory();

      React.useEffect(() => {
         const { getData, getCities, getNotification } = props
         getData({})
         // getCities();
      },[])

      React.useEffect(() => {
         const { getBanner } = props
         console.log('props.lang.language', props.lang.language);
         
         // @ts-ignore-start
         getBanner({ lang: props.lang.language });
      }, [props.selectedCity, props.lang.language])

    

      React.useEffect(() => {
         if (props.isLoggedin) {
            const { getNotification } = props
            getNotification();
         }
      }, [props.isLoggedin])
      

      const onSearch = (param: IHomeBanner) => {
         if (param.offer_id) {
            history.push(PATH.SEARCH_BY_SIZE, { bannerData: param })
         }
      }

      const goToBlogDetail = (item: IHomeknowyourtireData) => {
         const { history } = props;
         history.push(PATH.BLOG_DETAIL, item)
      }
    
      const { home } = props
      return (
         <Container>
            <div key={'home'}>
               <Banner key={1} items={home.banner} onSearch={onSearch}/>
               <StepsSection key={2}/>
               <Brands 
                  key={3} 
                  items={home.data['brandsList']}
               />
               <Testimonials key={4}  items={home.data['testimonialsListData']}/>
               <Blogs 
                  key={5} 
                  items={home.data['knowyourtireData']}
                  goToBlogDetail={goToBlogDetail}
               /> 
               </div>            
         </Container>
      )
   
}


const mapStateToProps = (state: IRootState) => ({
   home: state.home,
   isLoggedin: state.auth.isLoggedin,
   selectedCity: state.core.selectedCity,
   lang: state.lang
 });
 
 const mapDispatchToProps = {
   getData: homeActions.getData,
   getCities: coreActions.getCities,
   getBanner: homeActions.getBanner,
   getNotification: userActions.getNotification,
   addNotification: coreActions.addNotification
 }
 
 
 
 export default withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(HomePage)) 