import { order as orderActions } from '../actions';
import { IOrderState } from '../models';
import { IOrder } from '../models/IOrderState';
export const initialState: IOrderState = {
  loading: false,
  records: { ids:[], cache:{}, limit: '', totalPage: '', totalRecords: ''  }
};

const order =  (
  state: IOrderState = initialState,
  action: orderActions.Action,
): IOrderState => {
  switch (action.type) {
    case orderActions.ActionTypes.ORDER_SET: {
      const { totalPage, limit, totalRecords } = action.payload
      const cache: {[key: string]: IOrder} = { ...state.records.cache };
      const ids = [
        ...[],
        ...action.payload.items.map((item: IOrder) => {
          cache[item.id] = item;
          return item.id;
        }),
      ];
      return {
        ...state,
        records: { ids, cache, totalPage: totalPage, limit, totalRecords   },
        loading: false,
      };
    }
    default:
      return state;
  }
};
export default order;
