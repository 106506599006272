import { IGetDataParam, IHomeData, IHomeBanner } from "../models/IHomeState";
import { ICoreLangType } from "../models/ICoreState";


export enum ActionTypes {
    GET_DATA = 'home/GET_DATA',
    SET_DATA = 'home/SET_DATA',
    GET_BANNER = 'home/GET_BANNER',
    SET_BANNER = 'home/SET_BANNER',
}



export interface IActionHomeGetBanner {
    type: ActionTypes.GET_BANNER;
    payload?: { lang: ICoreLangType }
}

export interface IActionHomeSetBanner {
    type: ActionTypes.SET_BANNER;
    payload: IHomeBanner[]
}

export interface IActionGetData {
    type: ActionTypes.GET_DATA;
    payload: IGetDataParam;
}

export interface IActionSetData {
    type: ActionTypes.SET_DATA;
    payload: any;
}


export function getData (data: IGetDataParam): IActionGetData {
    return {
      payload: data,
      type: ActionTypes.GET_DATA,
    };
}

export function setData (data: IHomeData): IActionSetData {
    return {
      payload: data,
      type: ActionTypes.SET_DATA,
    };
}

export function getBanner (data: { lang: ICoreLangType }): IActionHomeGetBanner {
    return {
      type: ActionTypes.GET_BANNER,
      payload: data
    };
}

export function setBanner (data: IHomeBanner[]): IActionHomeSetBanner {
    return {
      payload: data,
      type: ActionTypes.SET_BANNER,
    };
}
  

export type Action =
IActionGetData
| IActionSetData
| IActionHomeGetBanner
| IActionHomeSetBanner
