import React from 'react';
import './Input.css'

interface IProps {
   placeholder: string
   type: string
   value: any;
   onChange: (param: any) => void;
   onBlur?: () => void;
   error: string
   name: string;
   containerStyle: any
   maxLength?: number
   containerClass: string
   label: string;
   link?: { name: string; action: () => void }
   inputClass: string
}

export default class Input extends React.PureComponent<IProps> {
   static defaultProps = {
      placeholder: '',
      type: 'text',
      value: '',
      error: '',
      name: '',
      containerStyle: {},
      containerClass: '',
      label: '',
      inputClass: '',
      lang: 'en'
   }

   state = {
      value: this.props.value
   }

   handleChange(evt: any) {
      const value = (evt.target.validity.valid) ? evt.target.value : this.state.value;
      this.setState({ value });
   }

   render() {
      const { placeholder, type, value, onChange, error, name, containerStyle, maxLength, containerClass, label, link, inputClass } = this.props;
      const extraProps = type === 'number' ? {
         // pattern:"[0-9]*", 
         min: 1,
         onInput: this.handleChange.bind(this)
      } : {}
      //console.log('containerStyle', containerStyle);

      return (
         <>
            <div style={{ ...containerStyle, ...error && { border: 'solid 1px red' } }} className={containerClass}>
               {
                  label !== '' && (
                     <label>{label}</label>
                  )
               }
               <input
                  className={inputClass}
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  lang='en'
                  {...extraProps}
                  // style={error ? { border: 'solid 1px red' } : {}}
                  {
                  ...maxLength && { maxLength }
                  }

               />
               {
                  link && link.name && (
                     <a href="javascript:void(0);" className="forgot_passwod" onClick={link.action}>{link.name}</a>
                  )
               }

            </ div>
            <span className='error-input'>{error}</span>
         </>
      )
   }
}