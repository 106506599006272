import React from 'react';
import { images } from '../../../../../constants/Images';
import { Link } from 'react-router-dom';
import { i18n } from '../../../../../services';
import { withTranslation } from 'react-i18next';
import { IAboutRes } from '../../../../../models/ICoreState';
import { Filter } from '../../../../../utils';
import ReactHtmlParser from 'react-html-parser';

interface IProps {
   items: IAboutRes
}




class About extends React.Component<IProps> {

render () {
   const { items } = this.props;
   console.log('items', items);
   if (!items.whyChoose) {
      return null
   }
      return (
        <>
          <section className="about-section-1">
  <div className="container px-5">
    <div className="row justify-content-center">
      <div className="col-lg-12 mb-5 text-center">
       <div className="main-heading">
        <h2>{Filter.translate(items.whyChoose.page_title, items.whyChoose.title_arebic)}</h2>
        <p>{ReactHtmlParser(Filter.translate(items.whyChoose.content, items.whyChoose.content_arebic))}</p>
      </div>
    </div>
  </div>
  <div className="row px-lg-5">
    <div className="col-12 mb-4">
      <div className="row">
        <div className="col-12 col-md-8 mb-lg-5">
          <img src={images.aboutImg1}  alt="" className="border-radius-6" />
        </div>
        <div className="col-12 col-md-4 mb-lg-5">
          <img src={images.aboutImg2} alt="" className="border-radius-6" />
        </div>
        <div className="tire-deco"><img src={images.tire2}  alt="" /></div>
      </div>
    </div>
  </div>
  <div className="row align-items-center about-inner px-lg-5">
    {/* <div className="col-12 col-lg-4">
      <h3 className="">We're fortunate to work with fantastic clients from across the globe in over 11 countries on design and branding.</h3>
    </div> */}
    <div className="col-12 col-lg-4">
      <strong className="">{Filter.translate(items.ourApproach.page_title, items.ourApproach.title_arebic)}</strong>
      <p>{ReactHtmlParser(Filter.translate(items.ourApproach.content, items.ourApproach.content_arebic))}</p>
    </div>
    <div className="col-12 col-lg-4">
      <strong className="">{Filter.translate(items.ourMission.page_title, items.ourMission.title_arebic)}</strong>
      <p>{ReactHtmlParser(Filter.translate(items.ourMission.content, items.ourMission.content_arebic))}</p>
    </div>                    
  </div>
</div>
</section>

<section className="simple-steps about-steps">
    <div className="background-effects">
      <span className="effect-1"></span>
      <span className="effect-3"></span>
      <span className="effect-6"></span>
   </div>
   <div className="container px-5">      

   <div className="row">
                   <div className="col-lg-12 mb-5 text-center">
                      <div className="main-heading">
                         <h6>{i18n.t('home.search.how_we_works')}</h6>
                         <h2>{i18n.t('home.search.subtitle')}</h2>
                         <p>{i18n.t('home.search.description')}</p>
                      </div>
                   </div>
                </div>

    
                <div className="row steps-box">
                 <div className="col-12 col-md-3">
                       <div className="icon text-primary mb-3">
                     <img src={images.icon1} alt="" />
                     <span className="icon-number">01</span>
                  </div>
                  <h3>
                  {i18n.t('home.search.search.title')}
                  </h3>
    
                  <p>
                  {i18n.t('home.search.search.subtitle')}
                  </p>
    
               </div>
               <div className="col-12 col-md-3">
    
                <div className="icon text-primary mb-3">
                 <img src={images.icon2} alt="" />
                 <span className="icon-number">02</span>
              </div>
    
              <h3>
              {i18n.t('home.search.offers.title')}
              </h3>
    
              <p>
              {i18n.t('home.search.offers.subtitle')} 
            </p>
    
         </div>
         <div className="col-12 col-md-3">
    
          <div className="icon text-primary mb-3">
             <img src={images.icon3} alt="" />
             <span className="icon-number">03</span>
          </div>
    
          <h3>
          {i18n.t('home.search.location.title')}
         </h3>
    
         <p>
         {i18n.t('home.search.location.subtitle')} 
         </p>
    
      </div>
      <div className="col-12 col-md-3">
    
       <div className="icon text-primary mb-3">
          <img src={images.icon4} alt="" />
          <span className="icon-number">04</span>
       </div>
    
       <h3>
         {i18n.t('home.search.install.title')}
      </h3>
    
      <p>
         {i18n.t('home.search.install.subtitle')} 
      </p>
    
    </div>
    </div>

<div className="tire-deco"><img src="img/tire-2.png" alt="" /></div>
</div>
</section>
<hr className="hr-light mt-md-2 pb-3" />
{/* <div className="whoweare-wrap">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-6">
                        <div className="whowecontent">
                           <h2 className="titletire">
                              <span>What we do</span>
                             Pallentesque pretium justo et orci rhoncus, nec feugiat tortor.
                           </h2>
                           <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.<br/><br/>
                            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                           </p>
                           <Link to="/contact-us" className="btn btn-warning">Contact Us</Link>
                           {/* <a href="javascript:void(0);" className="btn btn-warning">Contact Us</a>          
                        </div>
                     </div>
                     <div className="col-lg-5 offset-lg-1">
                        <div className="wholistwrap">
                           <ul>
                              <li>
                                 <div className="whodatalst">
                                    <span className="checkico"><img src={images.check} alt="img" /> </span>
                                    <div className="rightdatawho">
                                       <h2>World Class Quality</h2>
                                       <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>
                                    </div>
                                 </div>
                              </li>
                              <li>
                                 <div className="whodatalst">
                                    <span className="checkico"><img src={images.check} alt="img" /> </span>
                                    <div className="rightdatawho">
                                       <h2>Authorized Dealer</h2>
                                       <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium unde omnis iste natus.</p>
                                    </div>
                                 </div>
                              </li>
                              <li>
                                 <div className="whodatalst">
                                    <span className="checkico"><img src={images.check} alt="img" /> </span>
                                    <div className="rightdatawho">
                                       <h2>Best-In-Class Service</h2>
                                       <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui sequi nesciunt.</p>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  
               </div>
            </div> */}
        </>
      )
    }
}

export default withTranslation()(About)