import React from 'react';
import { images } from '../../../constants/Images';
import IRootState, { ICartState, IUserState } from '../../../models';
import { connect } from 'react-redux';
import { auth as authActions } from '../../../actions'
import { Container } from '../../../components/Base';
import config from '../../../constants/config';
import { IWareHouseLogin, IAuthResetPassword } from '../../../models/IAuthState';
import { Input } from '../../../components';
import { validation, i18n } from '../../../services';
import { withRouter } from 'react-router-dom';
import { PATH } from '../../../navigation/NavigationPath';
import { withTranslation } from 'react-i18next';

interface IProps {
  resetPassword: (param: IAuthResetPassword) => void;
  loading: boolean;
  isLoggedin: boolean;
  history: any
  location: { state: { otp: string, user_id: string } }
  forgotUserId: string;
  otp: string;
  isPasswordReset: boolean
  resetPasswordSuccess: (param: boolean) => void;
}

interface IState {
  formData: {
    fields: {
     otp: string;
     password: string;
     user_id: string;
    },
    required: string[]
  };
  error: any;
  otp: string[]
}

class ResetPasswordPage extends React.Component<IProps, IState> {
  firstTextInput: any
  secondTextInput: any
  thirdTextInput: any
  fourthTextInput: any  
  state: IState = {
      formData: {
        fields: {
          otp: '',
          password: '',
          user_id: this.props.forgotUserId
        },
        required: ['password', 'otp'],
      },
      error: {},
      otp: ["","","",""],
    }

    handleChange = (e: any, field: string) => {
      const { formData } = this.state;
      console.log('field', field);
      this.setState({ formData: { ...formData, fields: { ...formData.fields, [field]: e.target.value.replace(/\s/g, '') } } })
    }

    onSubmit = () => {
      const { formData, error } = this.state;
      const { resetPassword, otp } = this.props;
      const validate = validation.isValidate(formData)
      this.setState({ error: validate })
      if (!Object.keys(validate).length ) { 
      const { formData } = this.state;
      if (otp !== formData.fields.otp) {
        this.setState({ error: { ...error, otp: { message: 'Invailid OTP' } } })
      }  
      resetPassword(formData.fields)
      }
    }

    updateOTP = (index: number, e: any) => {
      const { otp, formData } = this.state;
      const reg = /^\d+$/;
    
      
      const spliceOTP = [...otp]
      const inputArr = [
         this.secondTextInput, 
         this.thirdTextInput, 
         this.fourthTextInput,
         this.firstTextInput,
        ]
      spliceOTP.splice(index, 1, reg.test(e.target.value) ? e.target.value.substr(e.target.value.length-1) : "")
      console.log('spliceOTP', spliceOTP);
      
      if (index < 3) {
         if (spliceOTP[index])
           inputArr[index].focus();
      } else { 
        this.fourthTextInput.blur()
      }
      // @ts-ignore-start
     this.setState({ otp: spliceOTP, isVerify: true, formData: { ...formData, fields: { ...formData.fields, otp: otp.join("") } }  })
   }

   static getDerivedStateFromProps(props: IProps, state: IState) {
    if (props.isPasswordReset) {
        props.resetPasswordSuccess(false)
        props.history.push(PATH.LOGIN)
    }
    return state
  }

    render() {
      const { loading } = this.props;
      const { error, formData } = this.state;
      console.log('this.props', this.props);
      
      return (
        <section className="admin_banner-section bg-size-cover bg-position-center">
            <div className="container-fluid">
              <div className="login_form_modal">
                <div className="login_content">
                <div className="main-heading">
          <h2>{i18n.t(`reset_password.title`)}</h2>

        </div>
        <div className="login_pagecontent">
          <p>{i18n.t(`reset_password.input.label.otp`)}</p>
        </div>

        <div className="login_form">
        <div className="otp_wrap">
            <input className="partitioned" value={this.state.otp[0]} type="tel" maxLength={1}    onChange={(e: any) =>  this.updateOTP(0, e) } ref={(input) => { this.firstTextInput = input; }} blur-on-submit={false}/>
            <input className="partitioned" value={this.state.otp[1]} type="tel" maxLength={1}    onChange={(e: any) =>  this.updateOTP(1, e) } ref={(input) => { this.secondTextInput = input; }}  blur-on-submit={false}/>
            <input className="partitioned" value={this.state.otp[2]} type="tel" maxLength={1}    onChange={(e: any) =>  this.updateOTP(2, e) } ref={(input) => { this.thirdTextInput = input; }} blur-on-submit={false}/>
            <input className="partitioned" value={this.state.otp[3]} type="tel" maxLength={1}    onChange={(e: any) =>  this.updateOTP(3, e) } ref={(input) => { this.fourthTextInput = input; }}blur-on-submit={false}/>
          </div>
          {
            (error && error.otp) && (
              <span className={'error-input'}>{error.password.otp}</span>
            )
          }
            <Input
               type={'text'}
               name={'username'}
               onChange={(value: any) => this.handleChange(value, 'password')}
               placeholder={i18n.t(`reset_password.input.placeholder.password`)}
               value={formData.fields.password}
              containerClass={'form-control'}
              label={i18n.t(`wd.login.input.label.password`)}
              error={error && error.password ? error.password.message : ''}
            />
    
          <div className="login_btnwrp">
            <button 
              className="btn btn-default login_btn" 
              disabled={loading}
              onClick={this.onSubmit}
            >
              {i18n.t(`common.button.submit`)}
              {
                loading && (
                  <img src={images.loader} style={{ height: '30px', width: '30px' }}/>
                )
              }
              
            </button>
          </div>

        </div>
         <div className="banner-tire">
          <img src="img/banner-tire.png" alt="" />
         </div>
      </div>
    </div>
  </div>

        </section>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.auth.loading,
  isLoggedin: state.auth.isLoggedin,
  forgotUserId: state.auth.forgotUserId,
  otp: state.auth.forgotUserId,
  isPasswordReset: state.auth.isPasswordReset,
});

const mapDispatchToProps = {
  resetPassword: authActions.resetPassword,
  resetPasswordSuccess: authActions.resetPasswordSuccess
}



export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(ResetPasswordPage)) )