import React from 'react';
import { images } from '../../../../constants/Images';
import { Link } from 'react-router-dom';
import { PATH } from '../../../../navigation/NavigationPath';
import { IOrder } from '../../../../models/IOrderState';
import moment from "moment"
import { Filter } from '../../../../utils';
import config from '../../../../constants/config';
import { IShopCache } from '../../../../models/IShopState';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../../services';

interface IProps {
   items: IShopCache
}

interface IState {
}

class ShopCard extends React.PureComponent<IProps, IState> {




    render() {
       const { items } = this.props;
      return(
         <div className="shop-create create">
         {/* <a href="javascript:void:(0);" className="dote-show" >
         <span className="dote"><i></i><i></i><i></i></span></a>
         <div className="bd-cn-dropdown">
            <ul>
               <li> <a href="javascript:void(0);"> Edit </a></li>
               <li> <a href="javascript:void(0);"> Integrate </a></li>
            </ul>
         </div> */}
         <div className="shop-info">
            <img src={`${config.imageBaseUrl}${items.image}`} />
      <h3>{Filter.translate(items.shop_name, items.shop_name_ar)}</h3>
      <a href={`tel:${items.primary_mobile}`}>{items.primary_mobile}</a>
         </div>
         <div className="status row">
            <div className="status-left">
      <span>{i18n.t(`wd.shop.card.status`)}</span>
               <button  className={`deactive ${items.status === 'Active' ? 'active' : ''}`}>{items.status}</button>
            </div>
            <div className="status-right">
               <span>{i18n.t(`wd.shop.card.shop_address`)}</span>
               <p>{items.address}</p>
            </div>
         </div>
      </div>
      )
    }
}

export default withTranslation()(ShopCard)