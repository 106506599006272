import { IWDBrand, IWDLoad, IWDSpeedRating, IWDTireCode, IWDModel, IWdGetElecon, IWDInstallCenter } from "../models/IWDState";


export enum ActionTypes {
    WD_ELENCO_GET = 'wd/WD_ELENCO_GET',
    WD_BRAND_SET = 'wd/WD_BRAND_SET',
    WD_LOAD_SET = 'wd/WD_LOAD_SET',
    WD_SPEED_RATING_SET = 'wd/WD_SPEED_RATING_SET',
    WD_TIRE_CODE_SET = 'wd/WD_TIRE_CODE_SET',
    WD_MODEL_SET = 'wd/WD_MODEL_SET',
    WD_GET_INSTALLCENTER = 'wd/WD_GET_INSTALLCENTER',
    WD_SET_INSTALLCENTER = 'wd/WD_SET_INSTALLCENTER',
}

export interface IActionWDGetInstallCeter {
  type: ActionTypes.WD_GET_INSTALLCENTER;
}

export interface IActionWDSetInstallCeter {
  type: ActionTypes.WD_SET_INSTALLCENTER;
  payload: IWDInstallCenter[]
}


export interface IActionElencoGet {
  type: ActionTypes.WD_ELENCO_GET;
  payload: IWdGetElecon
}

export interface IActionWDbrandSet {
  type: ActionTypes.WD_BRAND_SET;
  payload: IWDBrand[];
}

export interface IActionWDLoadSet {
  type: ActionTypes.WD_LOAD_SET;
  payload: IWDLoad[];
}

export interface IActionWDSpeedRatingSet {
  type: ActionTypes.WD_SPEED_RATING_SET;
  payload: IWDSpeedRating[];
}


export interface IActionWDTireCodeSet {
  type: ActionTypes.WD_TIRE_CODE_SET;
  payload: IWDTireCode[];
}

export interface IActionWDModelSet {
  type: ActionTypes.WD_MODEL_SET;
  payload: IWDModel[];
}


export function getElenco (data: IWdGetElecon): IActionElencoGet {
    return {
      type: ActionTypes.WD_ELENCO_GET,
      payload: data
    };
}

export function setBrand (data: IWDBrand[]): IActionWDbrandSet {
    return {
      type: ActionTypes.WD_BRAND_SET,
      payload: data
    };
}

export function setLoad (data: IWDLoad[]): IActionWDLoadSet {
  return {
    type: ActionTypes.WD_LOAD_SET,
    payload: data
  };
}

export function setSpeedRating (data: IWDSpeedRating[]): IActionWDSpeedRatingSet {
  return {
    type: ActionTypes.WD_SPEED_RATING_SET,
    payload: data
  };
}

export function setTireCode (data: IWDTireCode[]): IActionWDTireCodeSet {
  return {
    type: ActionTypes.WD_TIRE_CODE_SET,
    payload: data
  };
}

export function setModel (data: IWDModel[]): IActionWDModelSet {
  return {
    type: ActionTypes.WD_MODEL_SET,
    payload: data
  };
}

export function getInstallCenter (): IActionWDGetInstallCeter {
  return {
    type: ActionTypes.WD_GET_INSTALLCENTER,
  };
}

export function setInstallCenter (data: IWDInstallCenter[]): IActionWDSetInstallCeter {
  return {
    type: ActionTypes.WD_SET_INSTALLCENTER,
    payload: data
  };
}

  

export type Action =
IActionElencoGet
  | IActionWDbrandSet
  | IActionWDLoadSet
  | IActionWDSpeedRatingSet
  | IActionWDTireCodeSet
  | IActionWDModelSet
  | IActionWDGetInstallCeter
  | IActionWDSetInstallCeter
