import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { IHomeknowyourtireData } from '../../../models/IHomeState';
import { images } from '../../../constants/Images';
import { Container } from '../../../components/Base';import { withRouter } from 'react-router-dom';
import { Filter } from '../../../utils';
import config from '../../../constants/config';
;

interface IProps {
   items: IHomeknowyourtireData
   location: { state: IHomeknowyourtireData }
}

class BlogDetailPage extends React.Component<IProps> {

   

    render() {
       const { location } = this.props;
       console.log('location', location);
       
      return (
         <Container>
           <div>
           <section className="inner-page-banner center-text-banner bg-size-cover" style={{ background: ` #fcc223` }}>
            <div className="tire-mark"><img src={images.innerTireMark} alt=""/></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="list-heading text-center">

                    <h3>{Filter.translate(location.state.page_title, location.state.title_arebic)}</h3>
                    {/* <p>{HEADING[slug].subtitle}</p> */}
                  </div>
                </div>         
              </div>
            </div>
          </section>
          <section className="privacy-policy">
         <div className="container px-5">
            <img src={`${config.imageBaseUrl}${location.state.path}`}/>
            <br/>
            <br/>
            <div className="row justify-content-center">
               <div className="col-lg-12 mb-lg-5">
               <div className="privacy-content">
                  {ReactHtmlParser(Filter.translate(location.state.content, location.state.content_arebic))}
               </div>
            </div>
         </div>

         
         </div>
         </section>
            </div>
         </Container>
      )
    }
}

export default  withTranslation()(withRouter<any, any>(BlogDetailPage)) 