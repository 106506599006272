import React from 'react';
import { images } from '../../../constants/Images';
import IRootState, { ICartState, IUserState, IOrderState, IShopState } from '../../../models';
import { connect } from 'react-redux';
import { order as orderActions, wd as wdActions, shop as shopActions } from '../../../actions'
import { Container, OrderCard } from '../Components';
import config from '../../../constants/config';
import { IOrderGet, IOrderUpdateStatus, TOrderStatus, IOrderExportData } from '../../../models/IOrderState';
import IWDState, { IWdGetElecon, TWDElecon, IWDInstallCenter } from '../../../models/IWDState';
import { Pagination } from '../../../components';
import { Filter } from '../../../utils';
import { Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { IShopCache } from '../../../models/IShopState';
import { api, i18n } from '../../../services';
import { CSVLink, CSVDownload } from "react-csv";
import  moment from "moment"
// @ts-ignore-start
import ReactExport from "react-export-excel";
import DateRangePicker from 'react-bootstrap-daterangepicker';
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface IProps {
   order: IOrderState
   user: IUserState
   getOrder: (param: IOrderGet) => void;
   getElenco: (param: IWdGetElecon) => void;
   wd: IWDState;
   getInstallCenter: () => void;
   updateOrderStatus: (param: IOrderUpdateStatus) => void;
   shop: IShopState
   shopGet: () => void;
}

interface IState {
   page: number;
   filter: {
      code: string;
      order_status: string;
      install_center_id?: string;
      vendor_id?: string
      startDate: any;
      endDate: any
   }
   typingTimeout: any
   exportData: IOrderExportData[]
}
const status  = ['Confirmed', 'Under Process', 'On The Way', 'Ready for Installation', 'Cancelled', 'Complete', 'No Show']
class OrderPage extends React.Component<IProps, IState> {
   status = this.props.user.type === 'dealer' ? ['Ready for Installation', 'Cancelled', 'Complete'] :
   ['Confirmed', 'Under Process', 'On The Way', 'Ready for Installation', 'Cancelled', 'Complete', 'No Show']
   status_arabic  = [i18n.t(`wd.order.element.status.confirmed`), i18n.t(`wd.order.element.status.under_process`), i18n.t(`wd.order.element.status.on_the_way`), i18n.t(`wd.order.element.status.ready_for_installation`), i18n.t(`wd.order.element.status.cancelled`), i18n.t(`wd.order.element.status.complete`), i18n.t(`wd.order.element.status.no_show`)] 
   state = {
      page: 1,
      filter: {
         code: '',
         order_status: '',
         startDate: '',
         endDate: '',
         ...this.props.user.type === 'dealer' ? { vendor_id : ''} : { install_center_id: '' }
      },
      typingTimeout: 0,
      exportData: [],
     
   }
   componentDidMount() {
      // moment.locale('ar');
      const { getOrder, user, getInstallCenter, shopGet, shop } = this.props;
      getOrder({ 
         [user.type === 'dealer' ? 'user_id': 'warehouse_user_id']: user.profile.id, 
         page: 1,
         // @ts-ignore-start
         role: user.type 
      });
      getInstallCenter();
      if (!shop.records.ids.length)
      shopGet();
      this.getAllOrder()
   }

   getAllOrder = async () => {
      const { user } = this.props;
      const { data = [] } = await api.order.getAllOrder({ 
         [user.type === 'dealer' ? 'user_id' : 'warehouse_user_id']: user.profile.id, 
         type: user.type === 'dealer' ? 'Dealer': user.profile.warehouse_type 
      })
      this.setState({ exportData: data })
   }

   renderOrder = () => {
      const { order, user } = this.props;
      return order.records.ids.map((id: string, index: number) => {
        return (
            <OrderCard
               items={order.records.cache[id]}
               key={index}
               role={user.type}
               wareHouseType={user.profile.warehouse_type}
               onUpdateStatus={this.changeStatus}
            />
        )
      })
   }

   changeStatus = (order_status: TOrderStatus, order_id: string) => {
      const { updateOrderStatus, user } = this.props;
      updateOrderStatus({ 
         order_id,
         order_status,
         warehouse_user_id: user.profile.id,
         reason: ''
      })
      
   }

   onPaginate = (page: number) => {
      const { getOrder, user } = this.props;
      getOrder({ 
         [user.type === 'dealer' ? 'user_id': 'warehouse_user_id']: user.profile.id, 
         page,
         // @ts-ignore-start
         role: user.type 
      });
      this.setState({ page })
   }

   renderInstallCenter = () => {
      const { wd } = this.props;
      return wd.installCenter.map(( raw: IWDInstallCenter, index: number ) => {
         return (
         <option  key={index} value={raw.id}>{ Filter.translate(raw.name, raw.name_ar) }</option>
         )
      })
   }

   renderVendor = () => {
      const { shop } = this.props;
      return shop.records.ids.map(( id: string, index: number ) => {
         const cache = shop.records.cache[id];
         return (
         <option  key={index} value={cache.id}>{ Filter.translate(cache.shop_name, cache.shop_name_ar) }</option>
         )
      })
   }

   renderOrderStatus = () => {
      
      return this.status.map((row: string, index: number ) => {
         return (
            <option key={index} value={row}>{i18n.language === 'en' ? row : this.status_arabic[index]}</option>
         )
      })
   }

   applyFilter = (filed: string, value: any) => {
      const { filter } = this.state
      const { getOrder, user } = this.props;
      console.log('filed', filed, value);
      
      this.setState({ 
         filter: { ...filter, [filed]: value  }, }, 
       () => {
         getOrder({ 
            [user.type === 'dealer' ? 'user_id': 'warehouse_user_id']: user.profile.id, 
            page: 1,
            // @ts-ignore-start
            role: user.type,
            ...this.state.filter
         });
      })
   }

   applyCodeFilter = (filed: string, value: any) => {
      const { filter, page, typingTimeout } = this.state
      const { getOrder, user } = this.props;
      if (typingTimeout) {
         clearTimeout(typingTimeout);
      }
  
      this.setState({ 
         filter: { ...filter, [filed]: value  },   
         typingTimeout: setTimeout( () => {
            getOrder({ 
               [user.type === 'dealer' ? 'user_id': 'warehouse_user_id']: user.profile.id, 
               page: 1,
               // @ts-ignore-start
               role: user.type,
               ...{...filter, [filed]: value} 
            });
       }, 1000) })
   }

   exportOrder = () => {
      const { user } = this.props;
      const { exportData } = this.state;
      const headers = user.type === 'warehouse' ? [
         { label: "order number", key: "order_number" },
         { label: "date time", key: "date_time" },
         { label: "customer number", key: "customer_number" },
         { label: "install center", key: "install_center" },
         { label: "brand name", key: "brand_name" },
         { label: "Made in", key: "madein" },
         { label: "Year", key: "year" },
         { label: "front size", key: "front_size" },
         ...user.profile.warehouse_type === 'Normal' ? [
            { label: "speed", key: "speed" },
         ]: [],
         
         { label: "quantity", key: "quantity" },
         { label: "unit price", key: "unit_price" },
         { label: "rear size", key: "rear_size" },
         ...user.profile.warehouse_type === 'Normal' ? [
            { label: "speed rear", key: "speed_rear" },
         ] : [],
         { label: "quantity rear", key: "quantity_rear" },
         { label: "unit price rear", key: "unit_price_rear" },
         { label: "sub total", key: "sub_total" },
         { label: "discount", key: "discount" },
         { label: "vat", key: "vat" },
         { label: "grand total", key: "grand_total" },
         { label: "order status", key: "order_status" },
      ] : [
         { label: "booking number", key: "Booking_number" },
         { label: "shop name", key: "shop_name" },
         { label: "Width", key: "Width" },
         { label: "Aspect", key: "Aspect" },
         { label: "Diameter", key: "Diameter" },
         { label: "Quantity", key: "Quantity" },
         { label: "Width Rear", key: "Width_R" },
         { label: "Aspect Rear", key: "Aspect_R" },
         { label: "Diameter Rear", key: "Diameter_R" },
         { label: "Quantity Rear", key: "Quantity_R" },
         { label: "Price", key: "Price" },
         { label: "Price Rear", key: "Price_R" },
         { label: "Discount", key: "Discount" },
         { label: "Vat", key: "Vat" },
         { label: "Total price", key: "Total_price" },
         { label: "Order status", key: "Order_status" },
      ];
      if (!exportData.length) {
         return null
      }
      return (
         <div className="pull-left u-filter-col">
                        <ExcelFile element={<button type={'button'} className="btn btn-dark exportid form-control" >{i18n.t(`wd.inventory.button.export`)}  <img className="icon-import" src={images.exportIcon} alt="export" /></button>}>
               <ExcelSheet data={exportData} name='Order'>
                  {
                      headers.map(( row, index ) => {
                         return (
                           <ExcelColumn label={row.label} value={row.key}/>
                         )
                        
                      }) 
                  }
               </ExcelSheet>
            </ExcelFile>
            {/* <CSVLink 
               filename={`${user.profile.warehouse_type}_order_${moment(new Date()).format('YYYY-MM-DD HH:MM A')}.csv`}
               data={exportData} 
               headers={headers} 
               className="btn btn-dark exportid form-control"
            >
               {i18n.t('wd.order.button.export')} <img className="icon-import" src={images.exportIcon} alt="export" />
            </CSVLink> */}
         </div>
      )

   }

   onCallbackDate = (start: any, end: any, label: any) => {
      console.log('start', moment(start).format('DD/MM/YYYY'), moment(end).format('DD/MM/YYYY'));
      console.log('label', label);
      const { filter } = this.state;
      const { user, getOrder } = this.props
      this.setState({ 
         filter: { ...filter, startDate: moment(start).format('DD-MM-YYYY'), endDate: moment(end).format('DD-MM-YYYY')  }, }, 
       () => {
         getOrder({ 
            [user.type === 'dealer' ? 'user_id': 'warehouse_user_id']: user.profile.id, 
            page: 1,
            // @ts-ignore-start
            role: user.type,
            ...this.state.filter
         });
      })
   }

    render() {
      const { order, user } = this.props;
      const { page, filter } = this.state;
      return (
         <Container>
       <div className="main-content" key="order">
         <div className="u-top-wrapper">
            <div className="top-container">
               <form>
                  <div className="top-filter-box row">
                     <div className="col-md-2 col-sm-12 inventry-top">
                        <h1>{i18n.t(`wd.order.title`)}</h1>
                        <span>
                           {i18n.t(`wd.pagination.record`, { record: `${ page === 1 ? 1 : Number(order.records.limit) * (Number(page) - 1)} - ${Number(order.records.limit)*Number(page)}`, total: order.records.totalRecords })}
                           {/* Showing {`${page === 1 ? 1 : Number(order.records.limit) * (Number(page) - 1)}`}-{Number(order.records.limit)*Number(page)} of {order.records.totalRecords} items   */}
                           </span>
                     </div>
                     <div className="col-md-10 col-sm-12  inventry-bottom">
                        <div className="u-filter-right pull-right">

                        <div className={`pull-left u-filter-col ${filter.startDate ? 'col-sm-4': 'col-sm-3' }`}>
                        <div className="u-select u-calendar-box">
                        <DateRangePicker
                              initialSettings={{ startDate: moment(new Date()).format('D/M/yyyy'), endDate: moment(new Date()).format('D/M/yyyy') }}
                              onCallback={this.onCallbackDate}      
                        >
                              <button type="button" className="btn btn-light exportid form-control" data-toggle="modal" data-target="#admin">
                                 {
                                    filter.startDate ? `${filter.startDate}-${filter.endDate}` : i18n.t(`wd.order.filter.date_range`)
                                 }
                               
                              <img className="u-calendar" src={images.calendarIcon} alt="calendar" />
                              </button> 
                              </DateRangePicker>
                              </div>
                           </div>


                           <div className="pull-left u-filter-col">
                              <div className="u-search-box">
                                 <input type="text" className="form-control u-search" placeholder={i18n.t(`wd.order.filter.search_by_code`)} onChange={(e: any) => this.applyCodeFilter('code', e.target.value)}/><span className="search"><img src={images.search} /></span>
                              </div>
                           </div>
                           <div className=" pull-left u-filter-col">
                       
                              {
                                 user.type === 'dealer' ? (
                                    <div className="u-select">
                                    <select 
                                       className="form-control" 
                                       id="sel1"
                                       onChange={(e: any) => this.applyFilter('vendor_id', e.target.value)}
                                    >
                                       <option value={''}>{i18n.t(`wd.order.filter.vendor`)}</option>
                                      {this.renderVendor()}
                                    </select>
                                 </div>
                                 ) : (
                                    <div className="u-select">
                                    <select 
                                       className="form-control" 
                                       id="sel1"
                                       onChange={(e: any) => this.applyFilter('install_center_id', e.target.value)}
                                    >
                                       <option value={''}>{i18n.t(`wd.order.filter.install_center`)}</option>
                                      {this.renderInstallCenter()}
                                    </select>
                                 </div>
                                 )
                              }
                             
                           </div>
                           <div className="pull-left u-filter-col">
                              <div className="u-select">
                                 <select className="form-control" id="sel1" onChange={(e: any) => this.applyFilter('order_status', e.target.value)}>
                                    <option value={''}>{i18n.t(`wd.order.filter.status`)}</option>
                                    {this.renderOrderStatus()}
                                 </select>
                              </div>
                           </div>
                           {this.exportOrder()}
                           {/* <div className="pull-left u-filter-col">
                              <div className="u-select u-calendar-box">
                                 <img className="u-calendar" src={images.calendarIcon} alt="calendar" />
                                     <DateRangePicker
                              initialSettings={{ startDate: '1/1/2014', endDate: '3/1/2014' }}
                           >
                              <button type={'button'} className="btn btn-dark exportid form-control" >{i18n.t(`wd.order.filter.date_range`)} <img className="icon-import" src={images.exportIcon} alt="export" /></button>
                           </DateRangePicker>
                              </div>
                           
                           </div> */}
                           {/*<div className="pull-left u-filter-col">
                              <button type="button" className="btn btn-dark exportid form-control" data-toggle="modal" data-target="#admin">
                              Export <img className="icon-import" src={images.exportIcon} alt="export" />
                              </button> 
                           </div>*/}
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
         <div className="u-list-wrapper">
            <div className="admin-container">
               <div className="card-order u-inner-wrapper1">
                  <ul>
                     {this.renderOrder()}
                  </ul>
               </div>
            </div>
         </div>
         {
            order.records.ids.length > 0 && (
               <Pagination 
                  pages={Number(order.records.totalPage)}
                  limit={Number(order.records.limit)}
                  total={Number(order.records.totalRecords)} 
                  active={page}
                  onClick={this.onPaginate}
               />
            )
         }
      </div>
         </Container>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  order: state.order,
  user: state.user,
  wd: state.wd,
  shop: state.shop,
});

const mapDispatchToProps = {
   getOrder: orderActions.getOrder,
   getElenco: wdActions.getElenco,
   getInstallCenter: wdActions.getInstallCenter,
   updateOrderStatus: orderActions.updateOrderStatus,
   shopGet: shopActions.shopGet
}



export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)<any>(OrderPage)) 