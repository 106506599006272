import Geocode from "react-geocode";
import { IGeoLocation } from "../models/ICoreState";

Geocode.setApiKey("AIzaSyD-6jEMi205oSuzSoALHH0jWX-d2BX5Ato");
Geocode.setLanguage("en");

export const getLocation = async () => {
    return new Promise<IGeoLocation>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((position) => {
            Geocode.fromLatLng(`${position.coords.latitude}`, `${position.coords.longitude}`).then((response) => {
                const city = response.results[0].address_components.find((item: any) => item.types.indexOf('locality') > 0)
                console.log(city);
                resolve({
                    latitude: position.coords.latitude,
                    longitude : position.coords.longitude,
                    city: city != undefined ? city.long_name : "",
                    address: response.results[0].formatted_address
                })
            }, error => {
                reject(true)
                console.error(error);
            });
        }, err => reject(true));
    })

}