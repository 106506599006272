import { search as seacrhActions } from '../actions';
import { ISearchState } from '../models';
import { ISearchTireCache } from '../models/ISearchState';

export const initialState: ISearchState = {
  brand: [],
  years: [],
  model: [],
  tires: { ids:[], cache: {} },
  loading: false,
  size: [],
  tireSize: { f: [], s: [], t: [] },
  details: { },
  installCenter: [],
  vehiclePage: {}
};

const search =  (
  state: ISearchState = initialState,
  action: seacrhActions.Action,
): ISearchState => {
  switch (action.type) {
    case seacrhActions.ActionTypes.SET_BRAND: {
      return {
        ...state,
        brand: action.payload,
      };
    }
    case seacrhActions.ActionTypes.SET_YEAR: {
      return {
        ...state,
        years: action.payload,
      };
    }
    case seacrhActions.ActionTypes.SET_MODEL: {
      return {
        ...state,
        model: action.payload,
      };
    }
    case seacrhActions.ActionTypes.GET_TIRES: {
      return {
        ...state,
        loading: true,
      };
    }
    case seacrhActions.ActionTypes.SET_TIRES: {
      const cache: {[key: string]: ISearchTireCache} = { ...state.tires.cache };
      const ids = [
        ...[],
        ...action.payload.map((item: ISearchTireCache) => {
          cache[item.inventory_id] = item;
          return item.inventory_id;
        }),
      ];
      return {
        ...state,
        tires: { ids, cache },
        loading: false,
      };
    }
    case seacrhActions.ActionTypes.SET_SIZE: {
      return {
        ...state,
        size: action.payload,
      };
    }
    case seacrhActions.ActionTypes.SET_TIRE_SIZE: {
      const { type, items } = action.payload;
      return {
        ...state,
        tireSize: { ...state.tireSize, [type]: items },
      };
    }
    case seacrhActions.ActionTypes.SET_INSTALL_CENTER: {
      return {
        ...state,
        installCenter : action.payload,
      };
    }
    case seacrhActions.ActionTypes.SET_ORDER_DETAIL: {
      return {
        ...state,
        details : action.payload,
      };
    }
    case seacrhActions.ActionTypes.SET_VEHICLE_PAGE_STATE: {
      return {
        ...state,
        vehiclePage : action.payload,
      };
    }
    
    default:
      return state;
  }
};
export default search;
