import { i18n } from "../services";
import ReactHtmlParser from 'react-html-parser';

export function translate(textEn: string = '', textAr: string = '') {
  return i18n.language === 'ar' ? textAr : textEn
}


export function renderHours(textEn: string = '', textAr: string = '') {
  const str =  i18n.language === 'ar' ? textAr : textEn
  return ReactHtmlParser(str.replace(/(\r\n|\n|\r)/gm, "<br>"))
}