import React from 'react';
import { images } from '../../../constants/Images';
import IRootState, { ICartState, IUserState } from '../../../models';
import { connect } from 'react-redux';
import { cart as cartActions } from '../../../actions'
import { Container } from '../Components';
import config from '../../../constants/config';

interface IProps {
  getCart: (param: { user_id: string }) => void;
  cart: ICartState;
  user: IUserState;
  isLoggedin: boolean;
}

interface IState {
  
}

class ManageTirePage extends React.Component<IProps> {



    render() {
      const { cart } = this.props;
      console.log('cart', cart);
      return (
         <Container>
      <div className="main-content report-page" key={'tires'}>
         <div className="u-main-wrapper">
            <div className="top-container">
               <form>
                  <div className="top-filter-box row">
                     <div className="col-md-2 col-sm-12 inventry-top">
                        <h1> Inventory </h1>
                        <span>Showing 1-10 of 33 items  </span>
                     </div>
                     <div className="col-md-10 col-sm-12  inventry-bottom">
                        <div className="u-filter-right pull-right">
                           <div className="pull-left u-filter-col">
                              <div className="u-search-box">
                                 <input type="text" className="form-control u-search" placeholder="Search" /><span className="search"><img src={images.search} /></span>
                              </div>
                           </div>
                           <div className=" pull-left u-filter-col">
                              <div className="u-select">
                                 <select className="form-control" id="sel1">
                                    <option>Sort by</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                 </select>
                              </div>
                           </div>
                           <div className="pull-left u-filter-col">
                              <div className="u-select">
                                 <select className="form-control" id="sel1">
                                    <option>Filter by</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                 </select>
                              </div>
                           </div>
                           <div className="pull-left u-filter-col">
                              <div className="u-select u-calendar-box">
                                 <img className="u-calendar" src={images.calendarIcon} alt="calendar" />
                                 <input className="form-control" type="text" name="date" placeholder="Any Date" />
                              </div>
                           </div>
                           <div className="pull-left u-filter-col-btn-box">
                              <div className="pull-left u-filter-col">
                                 <button type="button" className="btn btn-dark exportid form-control">
                                 Export <img className="icon-import" src={images.exportIcon} alt="export" />
                                 </button> 
                              </div>
                              <div className="pull-left u-filter-col">
                                 <button type="button" id="create-inventory" className="btn btn-dark exportid form-control" data-toggle="modal" data-target="#admin">
                                 + Create Inventory 
                                 </button> 
                              </div>
                              <div className="pull-left u-filter-col">
                                 <button type="button" className="btn btn-dark exportid bulk form-control">
                                 + Bulk Upload 
                                 </button> 
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
         <div className="u-main-wrapper">
            <div className="admin-container u-table-container">
               <div className="u-inner-wrapper ">
                  <div className="table-responsive ">
                     <table className="table ">
                        <thead className="table-head">
                           <tr>
                              <th>code</th>
                              <th>Warehouse </th>
                              <th>Brand</th>
                              <th>Model No.</th>
                              <th>width</th>
                              <th>Aspect</th>
                              <th>Diamete</th>
                              <th>year </th>
                              <th> speed </th>
                              <th>load </th>
                              <th> Runflat </th>
                              <th>Made in </th>
                              <th> qty</th>
                              <th>price</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td>01-285-MP1	</td>
                              <td>Normal Warehouse </td>
                              <td>Michelin</td>
                              <td>Pilot Sport 2</td>
                              <td>285</td>
                              <td>35</td>
                              <td>22</td>
                              <td>2020</td>
                              <td>Y</td>
                              <td>98</td>
                              <td>0</td>
                              <td>France</td>
                              <td>200</td>
                              <td>200</td>
                              <td> <a href="javascript:void:(0);"> <img src="img/add.png" /> </a>  <a href="javascript:void:(0);"><img src="img/add1.png" />  </a></td>
                           </tr>
                           <tr>
                              <td>01-285-MP1	</td>
                              <td>Normal Warehouse </td>
                              <td>Michelin</td>
                              <td>Pilot Sport 2</td>
                              <td>285</td>
                              <td>35</td>
                              <td>22</td>
                              <td>2020</td>
                              <td>Y</td>
                              <td>98</td>
                              <td>0</td>
                              <td>France</td>
                              <td>200</td>
                              <td>200</td>
                              <td> <a href="javascript:void:(0);"> <img src="img/add.png" /> </a>  <a href="javascript:void:(0);"><img src="img/add1.png" />  </a></td>
                           </tr>
                           <tr>
                              <td>01-285-MP1	</td>
                              <td>Normal Warehouse </td>
                              <td>Michelin</td>
                              <td>Pilot Sport 2</td>
                              <td>285</td>
                              <td>35</td>
                              <td>22</td>
                              <td>2020</td>
                              <td>Y</td>
                              <td>98</td>
                              <td>0</td>
                              <td>France</td>
                              <td>200</td>
                              <td>200</td>
                              <td> <a href="javascript:void:(0);"> <img src="img/add.png" /> </a>  <a href="javascript:void:(0);"><img src="img/add1.png" />  </a></td>
                           </tr>
                           <tr>
                              <td>01-285-MP1	</td>
                              <td>Normal Warehouse </td>
                              <td>Michelin</td>
                              <td>Pilot Sport 2</td>
                              <td>285</td>
                              <td>35</td>
                              <td>22</td>
                              <td>2020</td>
                              <td>Y</td>
                              <td>98</td>
                              <td>0</td>
                              <td>France</td>
                              <td>200</td>
                              <td>200</td>
                              <td> <a href="javascript:void:(0);"> <img src="img/add.png" /> </a>  <a href="javascript:void:(0);"><img src="img/add1.png" />  </a></td>
                           </tr>
                           <tr>
                              <td>01-285-MP1	</td>
                              <td>Normal Warehouse </td>
                              <td>Michelin</td>
                              <td>Pilot Sport 2</td>
                              <td>285</td>
                              <td>35</td>
                              <td>22</td>
                              <td>2020</td>
                              <td>Y</td>
                              <td>98</td>
                              <td>0</td>
                              <td>France</td>
                              <td>200</td>
                              <td>200</td>
                              <td> <a href="javascript:void:(0);"> <img src="img/add.png" /> </a>  <a href="javascript:void:(0);"><img src="img/add1.png" />  </a></td>
                           </tr>
                           <tr>
                              <td>01-285-MP1	</td>
                              <td>Normal Warehouse </td>
                              <td>Michelin</td>
                              <td>Pilot Sport 2</td>
                              <td>285</td>
                              <td>35</td>
                              <td>22</td>
                              <td>2020</td>
                              <td>Y</td>
                              <td>98</td>
                              <td>0</td>
                              <td>France</td>
                              <td>200</td>
                              <td>200</td>
                              <td> <a href="javascript:void:(0);"> <img src="img/add.png" /> </a>  <a href="javascript:void:(0);"><img src="img/add1.png" />  </a></td>
                           </tr>
                           <tr>
                              <td>01-285-MP1	</td>
                              <td>Normal Warehouse </td>
                              <td>Michelin</td>
                              <td>Pilot Sport 2</td>
                              <td>285</td>
                              <td>35</td>
                              <td>22</td>
                              <td>2020</td>
                              <td>Y</td>
                              <td>98</td>
                              <td>0</td>
                              <td>France</td>
                              <td>200</td>
                              <td>200</td>
                              <td> <a href="javascript:void:(0);"> <img src="img/add.png" /> </a>  <a href="javascript:void:(0);"><img src="img/add1.png" />  </a></td>
                           </tr>
                           <tr>
                              <td>01-285-MP1	</td>
                              <td>Normal Warehouse </td>
                              <td>Michelin</td>
                              <td>Pilot Sport 2</td>
                              <td>285</td>
                              <td>35</td>
                              <td>22</td>
                              <td>2020</td>
                              <td>Y</td>
                              <td>98</td>
                              <td>0</td>
                              <td>France</td>
                              <td>200</td>
                              <td>200</td>
                              <td> <a href="javascript:void:(0);"> <img src="img/add.png" /> </a>  <a href="javascript:void:(0);"><img src="img/add1.png" />  </a></td>
                           </tr>
                           <tr>
                              <td>01-285-MP1	</td>
                              <td>Normal Warehouse </td>
                              <td>Michelin</td>
                              <td>Pilot Sport 2</td>
                              <td>285</td>
                              <td>35</td>
                              <td>22</td>
                              <td>2020</td>
                              <td>Y</td>
                              <td>98</td>
                              <td>0</td>
                              <td>France</td>
                              <td>200</td>
                              <td>200</td>
                              <td> <a href="javascript:void:(0);"> <img src="img/add.png" /> </a>  <a href="javascript:void:(0);"><img src="img/add1.png" />  </a></td>
                           </tr>
                           <tr>
                              <td>01-285-MP1	</td>
                              <td>Normal Warehouse </td>
                              <td>Michelin</td>
                              <td>Pilot Sport 2</td>
                              <td>285</td>
                              <td>35</td>
                              <td>22</td>
                              <td>2020</td>
                              <td>Y</td>
                              <td>98</td>
                              <td>0</td>
                              <td>France</td>
                              <td>200</td>
                              <td>200</td>
                              <td> <a href="javascript:void:(0);"> <img src="img/add.png" /> </a>  <a href="javascript:void:(0);"><img src="img/add1.png" />  </a></td>
                           </tr>
                           <tr>
                              <td>01-285-MP1	</td>
                              <td>Normal Warehouse </td>
                              <td>Michelin</td>
                              <td>Pilot Sport 2</td>
                              <td>285</td>
                              <td>35</td>
                              <td>22</td>
                              <td>2020</td>
                              <td>Y</td>
                              <td>98</td>
                              <td>0</td>
                              <td>France</td>
                              <td>200</td>
                              <td>200</td>
                              <td> <a href="javascript:void:(0);"> <img src="img/add.png" /> </a>  <a href="javascript:void:(0);"><img src="img/add1.png" />  </a></td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
         <div className="col-md-12">
            <div className="admin-container">
               <div className="admin-pagination">
                  <ul className="pagination pagination-sm">
                     <li className="page-item b-none"><a className="page-link " href="javascript:void:(0);"></a></li>
                     <li className="page-item active"><a className="page-link" href="javascript:void:(0);">1</a></li>
                     <li className="page-item"><a className="page-link" href="javascript:void:(0);">2</a></li>
                     <li className="page-item"><a className="page-link" href="javascript:void:(0);">3</a></li>
                     <li className="page-item"><a className="page-link" href="javascript:void:(0);">4</a></li>
                     <li className="page-item"><a className="page-link" href="javascript:void:(0);">5</a></li>
                     <li className="page-item"><a className="page-link" href="javascript:void:(0);">6</a></li>
                     <li className="page-item"><a className="page-link" href="javascript:void:(0);">7</a></li>
                     <li className="page-item"><a className="page-link" href="javascript:void:(0);">8</a></li>
                     <li className="page-item"><a className="page-link" href="javascript:void:(0);">9</a></li>
                     <li className="page-item"><a className="page-link" href="javascript:void:(0);">10</a></li>
                     <li className="page-item b-none"><a className="page-link" href="javascript:void:(0);"></a></li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
      </Container>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  cart: state.cart,
  user: state.user,
  isLoggedin: state.auth.isLoggedin
});

const mapDispatchToProps = {
  getCart: cartActions.getCart
}



export default connect(mapStateToProps, mapDispatchToProps)<any>(ManageTirePage)