import React from 'react';
import { Container } from '../../../components/Base';
import { withRouter } from 'react-router-dom';
import { AlertModal } from '../../../components';
import { PATH } from '../../../navigation/NavigationPath';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../services';
import { connect } from 'react-redux';
import IRootState, { ISearchState, ICoreState,IUserState } from '../../../models';
import { search as searcActions, cart as cartActions, core as coreActions } from '../../../actions';
import ICartState, { ITamaraConfirmationParam, ICartAddParam, IPaymentConfirmationParam} from '../../../models/ICartState';


interface IProps {
  history: any;
  cart: ICartState;
  getPaymentConfirmation: (param: IPaymentConfirmationParam) => void;
}

interface IState {
	isShowAlertModal: boolean,
  popupMsg:string,
  okMsg:string
}

class TabbyCancel extends React.Component<IProps, IState> {

  state: IState =  {
		isShowAlertModal: true,
    popupMsg:"",
    okMsg:""
	}

  componentDidMount() {
    const { cart, getPaymentConfirmation } = this.props;
    console.log('tabbyCancel===>', cart);

    const paymentParam = {
      order_number: cart.tamaraPreData.order_number,
      reference_number: cart.tamaraPreData.order_reference_id,
      status:'Payment_Failed'
  }
// console.log('77777777777', paymentParam);
    localStorage.removeItem('addtocartparam');
    localStorage.removeItem('paymentSuccessResponse');
    getPaymentConfirmation(paymentParam);

    // setTimeout(() => {
    //   this.setState({popupMsg:i18n.t(`failed_payment_tamara`), okMsg:i18n.t(`OK`)});
    // }, 1000);
  }

  componentDidUpdate(prevProps: IProps) {
    const { cart } = this.props;
    if(prevProps.cart.paymentConfirmation !== cart.paymentConfirmation){ 
      if(cart.paymentConfirmation.status_code === 200){
        this.setState({popupMsg:i18n.t(`failed_payment_tabby`), okMsg:i18n.t(`OK`)});
      } else {
        alert(cart.paymentConfirmation.message);
      }
    }
  }

  toggelAlertModal = () => {
    const { history} = this.props;
		const { isShowAlertModal } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal })
    history.push(PATH.CART)
	}

    render() {
      const { history} = this.props;
      const { isShowAlertModal } = this.state;
      // console.log('rammm===>', i18n.t(`Tamara Payment Cancel`));
       
      return (
        <Container>
          <div style={{height:'300px'}}></div>
          <AlertModal
              show={isShowAlertModal}
              handleClose={this.toggelAlertModal}
              title={(`Tabby Payment Cancel`)}
              description={this.state.popupMsg}
              actionType={'remove'}
              handleAction={()=> {}}
              isConfirmation={false}
              closeButtonName={this.state.okMsg}
              handleNavigation={() => history.push(PATH.CART)}
          />
         </Container>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  search: state.search,
  core: state.core,
  cart: state.cart,
  isLoggedin: state.auth.isLoggedin,
  user: state.user.profile
});

const mapDispatchToProps = {
  getTamaraConfirmation: cartActions.getTamaraConfirmation,
  getPaymentConfirmation: cartActions.getPaymentConfirmation,
  addToCart: cartActions.addToCart,
  addToCartSuccess: cartActions.addToCartSuccess,
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(TabbyCancel)) )
