import React from 'react';
import { Container } from '../../../components/Base';
import { images } from '../../../constants/Images';
import { withRouter } from "react-router";
import IRootState, { ICoreState } from '../../../models';
import { connect } from 'react-redux';
import { core as coreActions } from '../../../actions'
import { Policy, About } from './Components';
import { Filter } from '../../../utils';
import { withTranslation } from 'react-i18next';

interface IProps {
  match: any;
  getPage: ({  }) => void;
  core: ICoreState;
  history: any
}

const SLUG: { [key in string]: string } = {
  'about-us': 'about_us',
  'privacy-policy': 'privacy_policy',
  'terms-and-conditions':'terms_condition'
}

const dataKey: { [key in string]: 'about' | 'policy' | 'terms' } = {
  'about-us': 'about',
  'privacy-policy': 'policy',
  'terms-and-conditions':'terms'
}

const HEADING: { [key in string]: { title: string, subtitle: string } } = {
  'about-us': { title: 'About Us',  subtitle: 'Who We Are'},
  'privacy-policy': { title: 'Privacy Policy',  subtitle: 'Privacy Policy of Estbnh Platform'},
  'terms-and-conditions':{ title: 'Terms and Conditions',  subtitle: 'Terms and Conditions of Estbnh Platform'},
}

class StaticPage extends React.Component<IProps, {}> {
  
  // static getDerivedStateFromProps(props: any, state: any) {
  //   console.log('props', props);
  //   const { match } = props
  //   props.getPage({
  //     access_key: 'r9ga6iv7ri1nc3ah4ra8u0h0an',
  //     lang: 'en',
  //     slug: SLUG[match.params.id]
  //   })
  // }

  componentWillMount = () => {
    const { match, core, history } = this.props
    const slug = match.params.id
    // this.props.getPage({
    //   slug: 'about_us'
    // })
    this.props.getPage({
      slug: 'privacy_policy'
    })
    this.props.getPage({
      slug: 'terms_condition'
    })
    this.props.getPage({
      slug: 'about_us'
    })
    if (!core.pages[dataKey[slug]]) {
      window.location.href = '/'
    }
  }

 

    render() {
      const { match, core } = this.props
      const slug = match.params.id
      const ComponentsType: { [ key in string ]: any } = {
        'about-us': <About items={core.pages['about']}/>,
        'privacy-policy': <Policy items={core.pages['policy']}/>,
        'terms-and-conditions': <Policy items={core.pages['terms']}/>,
      }
      console.log('core.pages', core.pages);
      
      
      return (
         <Container>
           <div>
           <section className="inner-page-banner center-text-banner bg-size-cover" style={{ background: ` #fcc223` }}>
            <div className="tire-mark"><img src={images.innerTireMark} alt=""/></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="list-heading text-center">

                    <h3>{slug === 'about-us' ? Filter.translate(core.pages.about.title, core.pages.about.title_ar)  : core.pages[dataKey[slug]].title}</h3>
                    {/* <p>{HEADING[slug].subtitle}</p> */}
                  </div>
                </div>         
              </div>
            </div>
          </section>
          {ComponentsType[slug]}
            </div>
         </Container>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  core: state.core,
});

const mapDispatchToProps = {
  getPage: coreActions.getPage
}



export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(StaticPage)))