import axios from 'axios'
import { api } from '..';
import { ISearchGetYear, ISearchGetModel, ISearchGetTires, ISearchGetSize, ISearchGetTireSize, ISearchGetRetailerOrderDetail, ISearchGetInstallCenter } from '../../models/ISearchState';

async function getBrand(params = {}) {
    try {
        return api.postApi('/user/getmake')
    } catch (e) {
        Promise.reject(e)
    }
}

async function getYears(params: ISearchGetYear) {
    try {
        return api.postApi('/user/getyear', params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getModel(params: ISearchGetModel) {
    try {
        return api.postApi('/user/getmodel', params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getTires(params: ISearchGetTires) {
    console.log('params', params);
    
    try {
        return api.postApi('/search/searchbysizebrandlist', params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getSize(params: ISearchGetSize) {
    try {
        return api.postApi('/search/searchbyvehicle', params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getTireSize(param: ISearchGetTireSize): Promise<any> {
    const apiType = {
        f: 'fsizeapi',
        s: 'ssizeapi',
        t: 'tsizeapi',
    }
    try {
        return api.postApi(`/tyre/${apiType[param.type]}`, param)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getOrderDetail(params: ISearchGetRetailerOrderDetail) {
    console.log('getOrderDetail', params);
    
    const apiType = {
        NORMAL: 'preejorderdetails',
        BASIC: 'preejbasicorderdetails',
        RETAILER: 'preorderdetails',
    }
    try {
        if(params.homeInstall){
            return api.postApi(`/v2/search/${apiType[params.type]}`, params)
        }else{
            return api.postApi(`/search/${apiType[params.type]}`, params)
        }
    } catch (e) {
        Promise.reject(e)
    }
}

async function getInstallCenter(params: ISearchGetInstallCenter) {
    try {
        return api.postApi('/search/ejtireinstallcenters', params)
    } catch (e) {
        Promise.reject(e)
    }
}



export {
    getBrand,
    getYears,
    getModel,
    getTires,
    getSize,
    getTireSize,
    getOrderDetail,
    getInstallCenter,
}