import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import { ISearchBrand, ISearchTireCache } from '../../../../models/ISearchState';
import config from '../../../../constants/config';
import './EjNormal.css'
import { EfficiencyBar } from '../../../../components';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../../services';
interface IProps {
  items: ISearchTireCache
}

interface IState {

}



class EjNormal extends React.PureComponent<IProps, IState> {

    render() {
      const { items } = this.props
     console.log('items', items);
     
      return (
        <ul>
          {
            items.price_rear ? (
              <>
                        <li>{i18n.t(`common.label.load_index`)}
      <span className="tire_details"><span>{items.loadindex_front}</span>{items.loadindex_weight_front}</span>
        </li>
        <li>{i18n.t(`common.label.load_index`)}
            <span className="tire_details"><span>{items.loadindex_rear}</span>{items.loadindex_weight_rear}</span>
        </li>
              </>
            ) : (
              <li>{i18n.t(`common.label.load_index`)}
              <span className="tire_details"><span>{items.loadindex_front}</span>{items.loadindex_weight_front}</span>
                </li>
            )
          }
    {
      items.price_rear ? (
        <>
                  <li>{i18n.t(`common.label.speed_rating`)}
            <span className="tire_details"><span>{items.speed_front}</span> {items.speed_weight_front}</span>
        </li>
        <li>{i18n.t(`common.label.speed_rating`)}
            <span className="tire_details"><span>{items.speed_rear}</span> {items.speed_weight_rear} </span>
        </li>
        </>
      ): (
        <li>{i18n.t(`common.label.speed_rating`)}
        <span className="tire_details"><span>{items.speed_front}</span> {items.speed_weight_front}</span>
    </li>
      )
    }

    {
      items.price_rear ? (
        <>
        <EfficiencyBar 
          image={images.fuelIcon}
          title={i18n.t(`tire.detail.label.fule_efficiency`)}
          value={Number(items.efficiency_front)}
        />
        <EfficiencyBar 
          image={images.fuelIcon}
          title={i18n.t(`tire.detail.label.fule_efficiency`)}
          value={Number(items.efficiency_rear)}
        />
        <EfficiencyBar 
          image={images.clouldIcon}
          title={i18n.t(`tire.detail.label.wet_traction`)}
          value={Number(items.wet_traction_front)}
        />
         <EfficiencyBar 
          image={images.clouldIcon}
          title={i18n.t(`tire.detail.label.wet_traction`)}
          value={Number(items.wet_traction_rear)}
        />
        </>
      ) : (
        <>
        <EfficiencyBar 
          image={images.fuelIcon}
          title={i18n.t(`tire.detail.label.fule_efficiency`)}
          value={Number(items.efficiency_front)}
        />
        <EfficiencyBar 
          image={images.clouldIcon}
          title={i18n.t(`tire.detail.label.wet_traction`)}
          value={Number(items.wet_traction_front)}
        />
        </>
      )
    }

      </ul>
      )
    }
}

export default withTranslation()(EjNormal)