import React from 'react';
import { images } from '../../../../constants/Images';
import { Link } from 'react-router-dom';
import { PATH } from '../../../../navigation/NavigationPath';
import { ILangState } from '../../../../models';
import { ICoreLangType } from '../../../../models/ICoreState';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../../services';
interface IProps {
   userName: string
   pageType: string
   menu: Record<string, any>
   lang: ILangState
   changeLang: (lang: ICoreLangType) => void;
   onLogout: () => void
}

interface IState {
   toggelMenu: boolean
}



class Header extends React.PureComponent<IProps, IState> {

      state = {
         toggelMenu: false,
      }

     renderMenu = () => {
        const { menu, pageType } = this.props
        return menu.map((row: any, index: number) => {
            return (
               <li className={pageType === row.id ? 'active' : ''} key={index}>
               <Link to={row.path}>
               <img src={row.icon} />
               <span>{row.label}</span>
               </Link>
            </li>
            )
        })
     }


     renderMobileMenu = () => {
      const { menu, pageType } = this.props
      return menu.map((row: any, index: number) => {
          return (
            <li> <Link to={row.path}> {row.label}</Link></li>
          )
      })
   }


    render() {
       const { userName, pageType, lang, changeLang, onLogout } = this.props;
       const { toggelMenu } = this.state;
       console.log('pageType', pageType);
       
      return(
      <div className="admin_header_main">
        <header className="admin_header">
        <div className="admin_main_header">
           <div className="header_container">
              <div className="main_admin_header">
                 <ul>
                    <li>
                       <a className="nav-link pl-0" href="javascript:void:(0);" data-toggle="modal" data-target="#top_modal"><img width="30" src={lang.language === 'en' ? images.englishFlag : images.arFlag} />{lang.language} <img src={images.arrowDrop} className="drop-arrow" alt="" /></a>
                       <ul className="lag_dorpdown">
                          <li className="nav-item"><a className="nav-link pl-0" href="javascript:void(0);" data-toggle="modal" data-target="#top_modal" onClick={() => changeLang('en')}><img src={images.englishFlag} />En </a>          
                          </li>
                          <li className="nav-item"><a className="nav-link pl-0" href="javascript:void(0);" data-toggle="modal" data-target="#top_modal" onClick={() => changeLang('ar')}><img src={images.arFlag}/>AR </a>          
                          </li>
                        </ul> 
                    </li>
                    <li className="user_icon">
                       <a className="navbar-tool d-none d-lg-flex ml-2" href="javascript:void(0);">
                          <span className="barline">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          </span>
                          <span className="user_name">{i18n.t(`common.welcome`)} {userName}</span>
                          <div className="navbar-tool-icon-box">
                             <img width="22" src={images.user} alt="user" />
                          </div>
                       </a>
                       <ul className="sub_drop_menu clearfix">
                           <li><a className="" href="javascript:void(0);" onClick={onLogout}>{i18n.t(`common.logout`)}</a></li>
                        </ul>
                    </li>
                 </ul>
              </div>
           </div>
        </div>
        <div className="admin_side_bar">
           <div className="logo">
              <div className="mobile-nav">
                 <img className="u-nav-icon" width="30" src={images.navIcon} onClick={() => this.setState({ toggelMenu: !toggelMenu })}/>
                 <div className={`u-mobile-menu-outer ${toggelMenu ? 'u-open-nav' : '' }`}>
                    <ul className="u-mobile-menu">
                       {this.renderMobileMenu()}
                       <li> <a href="javascript:void(0);" onClick={onLogout}>{i18n.t(`common.logout`)}</a></li>
                    </ul>
                 </div>
              </div>
              <img className="web-logo" src={images.logo} />
              <img  className="mob-logo" src={images.yellowLogo} />
           </div>
           <div className="admin_menu">
              <ul>
              {this.renderMenu()}
              </ul>
           </div>
        </div>
     </header>
     </div>
      )
    }
}

export default withTranslation()(Header)