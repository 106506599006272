import { inventory as inventoryActions } from '../actions';
import { IInventoryState } from '../models';
import { IInventoryCache } from '../models/IInventoryState';
export const initialState: IInventoryState = {
  loading: false,
  records: { ids:[], cache:{}, limit: '', totalPage: '', totalRecords: ''  },
  importLoading: false,
  tireSize: { f: [], s: [], t: [] },
};

const inventory =  (
  state: IInventoryState = initialState,
  action: inventoryActions.Action,
): IInventoryState => {
  switch (action.type) {
    case inventoryActions.ActionTypes.INVENTORY_SET: {
      const { totalPage, limit, totalRecords } = action.payload
      const cache: {[key: string]: IInventoryCache} = { };
      const ids = [
        ...[],
        ...action.payload.items.map((item: IInventoryCache) => {
          cache[item.id] = item;
          return item.id;
        }),
      ];
      return {
        ...state,
        records: { ids, cache, totalPage: totalPage, limit, totalRecords   },
        loading: false,
      };
    }
    case inventoryActions.ActionTypes.INVENTORY_DELETE_SUCCESS: {
      const cache = state.records.cache
      delete cache[action.payload];
      return {
        ...state,
        records: { 
          ...state.records, 
          ids:  state.records.ids.filter(id => id !== action.payload),
          cache: { ...cache },
          totalRecords: `${Number(state.records.totalRecords)-1}`
        }
      };
    }
    case inventoryActions.ActionTypes.INVENTORY_ADD: {
      return {
        ...state,
        loading: true,
      }
    }
    case inventoryActions.ActionTypes.INVENTORY_ADD_SUCCESS: {
      return {
        ...state,
        loading: action.payload,
      }
    }
    case inventoryActions.ActionTypes.INVENTORY_EDIT: {
      return {
        ...state,
        loading: true,
      }
    }
    case inventoryActions.ActionTypes.INVENTORY_BULK_UPLOAD: {
      return {
        ...state,
        importLoading: true,
      }
    }
    case inventoryActions.ActionTypes.INVENTORY_BULK_UPLOAD_SUCCESS: {
      return {
        ...state,
        importLoading: false,
      }
    }
    case inventoryActions.ActionTypes.INVENTORY_SET_TIRE_SIZE: {
      const { type, items } = action.payload;
      return {
        ...state,
        tireSize: { ...state.tireSize, [type]: items },
      };
    }
    default:
      return state;
  }
};
export default inventory;
