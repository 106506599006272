import React from 'react';
import './EfficiencyBar.css'
import { withTranslation } from 'react-i18next';
import { i18n } from '../../services';

interface IProps {
   title: string,
   value: number,
   image: string,
}

class EfficiencyBar extends React.PureComponent<IProps> {
  
    render() {
       const { title, value, image } = this.props
       
       const EFFICIENCY_LEVEL = [{color: '#C00000', label: i18n.t(`tire.detail.efficiency.very_poor`)}, {color: '#FF5A35', label: i18n.t(`tire.detail.efficiency.poor`)}, {color: '#FFDB00', label: i18n.t(`tire.detail.efficiency.average`)}, {color: '#94BC00', label: i18n.t(`tire.detail.efficiency.good`)}, {color: '#09B34D', label: i18n.t(`tire.detail.efficiency.very_good`)}, {color: '#548235', label: i18n.t(`tire.detail.efficiency.excellent`)}]
       let graph_color = "";
       let graph_label = "";
       if (!value) {
        graph_color = '#FFFFFF';
        graph_label = i18n.t(`tire.specs.na`);
        //  return null
       } else {
        graph_color = EFFICIENCY_LEVEL[value-1].color;
        graph_label = EFFICIENCY_LEVEL[value-1].label;
       }
      return (
         <li className="fuelbox">                   
         <div id="gauge3" className="gauge-container two">
         <svg
       viewBox="0 0 100 50"
     ><g
      fillOpacity="0"
       strokeWidth="2"
     ><path
       d="M5 50a45 45 0 1 1 90 0"
       stroke="#eee"
     /><path
       // className="progress"
       d="M5 50a45 45 0 1 1 90 0"
       stroke={graph_color}
       strokeDasharray="142"
       strokeDashoffset="142"
       style={{ strokeDashoffset: `calc(142 - (${16.66*value} * 142 / 100))`, transition: 'stroke-dashoffset 850ms ease-in-out' }}
     /></g></svg>

         </div>
           <div className="gauge_listdetail">
             <img src={image} />
             <p>{title}</p>
      <span className="tire_details"><span>{graph_label}</span></span>
           </div>
       </li>
      )
    }
}

export default withTranslation()(EfficiencyBar)