import React from 'react';
import './NotificationCard.css'
import { IUserNotification } from '../../models/IUserState';
import { images } from '../../constants/Images';
import { Filter } from '../../utils';
import { validation, i18n } from '../../services';
interface IProps {
  items: IUserNotification
}

interface IState {
  showAlert: boolean;
  show: false;
}

export default class NotificationCard extends React.PureComponent<IProps> {
  

    render() {
      const { items } = this.props;
      return (
        <li className={items.status !== "0" ? 'active' : ''}>
        <div className="listing_wrap">
           <img src={images.notificationBell} />
           <p className="notify_content">{items.title}
             {/*
                i18n.language === 'ar' ? (
                <span>{items.message_ar}</span>
              ) : (
              )*/}
                <span>{items.message}</span>
           </p>
           {/* <p className="notify_time"> 07 min ago </p> */}
        </div>
     </li>  
      )
    }
}