import { combineReducers } from 'redux';
import auth from './authReducer'
import core from './coreReducer'
import home from './homeReducer'
import search from './searchReducer'
import cart from './cartReducer'
import user from './userReducer'
import lang from './languageReducer'
import order from './orderReducer'
import inventory from './inventoryReducer'
import wd from './wdReducer'
import shop from './shopReducer'
import { connectRouter } from 'connected-react-router'

const rootReducer = (history: any) => combineReducers<any>({
  core,
  auth,
  home,
  search,
  cart,
  user,
  lang,
  order,
  inventory,
  wd,
  shop,
  router: connectRouter(history),
});
export default rootReducer;