import React from 'react';
import './HomeLoader.css'

interface IProps {

}

export default class HomeLoader extends React.Component<IProps> {
    render() {
      return (
         <div className="preloading">
            <div className="wrap-preload">
               <div className="cssload-loader"></div>
            </div>
         </div>
      )
    }
}