import React, { ReactNode, createRef } from 'react';
import { images } from '../../../../constants/Images';
import { Link } from 'react-router-dom';
import { PATH } from '../../../../navigation/NavigationPath';
import { IOrder } from '../../../../models/IOrderState';
import moment from "moment"
import { Filter } from '../../../../utils';
import config from '../../../../constants/config';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../../services';

interface IProps {
  show: boolean
  children: ReactNode
  onToggel: () => void;
  title: string
  reset: boolean
  onReset: () => void;
}

interface IState {
}


class ModalRight extends React.PureComponent<IProps, IState> {
   scroll = createRef<HTMLDivElement>()
   static defaultProps = {
      title: '',
      reset: false
   }


    render() {
       const { show, children, onToggel, title, reset, onReset } = this.props;
      return(
         <>
         <div className={`book fade ${show ? 'show': ''}`} id="admin" role="dialog" ref={this.scroll} >
         <button type="button" className="close" data-dismiss="book" onClick={onToggel}>{i18n.t(`common.button.close`)}  <span> &times; </span></button>
         <div className="bookservicemain" >
            <div className="contactdetail clearfix">
               <form>
                  <div className="selectsec clearfix">
                
                     <div className="ud-flex-space-between">
                        <h2>{title}</h2>
                        {
                           reset && (
                              <button type="reset" className="txt_star" onClick={onReset}>{i18n.t(`wd.form.reset`)}</button>
                           )
                        }
                        
                     </div>
                     {children}
                  </div>
                  
               </form>
            </div>
         </div>
      </div>
      {
          show && (
            <div className="modal-backdrop show" onClick={onToggel}></div>
         )
       }
      </>
      )
    }
}

export default withTranslation()(ModalRight)