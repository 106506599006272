import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import rootReducer from '../reducers';
import rootSaga from '../saga';
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['inventroy', 'order', 'wd', 'shop']
};


const sagaMiddleware = createSagaMiddleware({
  onError: () => {

  }
})

const middleware = [sagaMiddleware]

const pReducer = persistReducer(persistConfig, rootReducer(history));

const configureStore = (initialState) => {
  const store = createStore(
    pReducer,
    initialState,
    applyMiddleware(...middleware),
  )
  sagaMiddleware.run(rootSaga)
  const persister = persistStore(store, {}, () => store.getState());
  //console.log('persister', persister);
  
  return {
    persister,
    store
  }
}

export default configureStore;

