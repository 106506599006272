import { Reducer } from 'redux';
import { auth as authActions } from '../actions';
import { IAuthState } from '../models';
import { Action } from 'history';
export const initialState: IAuthState = {
  isLoggedin: false,
  loading: false,
  otp: '',
  sendingOTP: false,
  forgotUserId: '',
  isPasswordReset: false,
};

const auth = (
  state: IAuthState = initialState,
  action: authActions.Action,
): IAuthState => {
  switch (action.type) {
    case authActions.ActionTypes.LOGIN: {
      return {
        ...state,
        loading: true,
      }
    }
    case authActions.ActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedin: true,
        loading: false,
        sendingOTP: false,
      }
    }
    case authActions.ActionTypes.SET_OTP: {
      return {
        ...state,
        otp: action.payload,
        sendingOTP: false,
      }
    }
    case authActions.ActionTypes.LOGIN_FAIELD: {
      return {
        ...state,
        loading: false,
      }
    }
    case authActions.ActionTypes.RESEND_OTP: {
      return {
        ...state,
        sendingOTP: true,
      }
    }
    case authActions.ActionTypes.WEREHOUSE_LOGIN: {
      return {
        ...state,
        loading: true,
      }
    }
    case authActions.ActionTypes.LOGOUT: {
      return {
        ...state,
        isLoggedin: false,
      }
    }
    case authActions.ActionTypes.FORGOT_PASSWORD: {
      return {
        ...state,
        loading: true,
      }
    }
    case authActions.ActionTypes.RESET_PASSWORD: {
      return {
        ...state,
        loading: true,
      }
    }
    case authActions.ActionTypes.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        forgotUserId: action.payload,
        otp: ''
      }
    }
    case authActions.ActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        isPasswordReset: true
      }
    }
    default:
      return state;
  }
};
export default auth;
