import { api } from '..';
import { IWdGetElecon } from '../../models/IWDState';

async function getElecon(param: IWdGetElecon) {
    const apiType = {
        brand: `/common/brandlists`,
        load: `/common/loadlists`,
        speedRating: `/common/speedratinglists`,
        tireCode: `/common/tyrescodelist`,
        model: `/common/modelslist`
    }
    const { type } = param
    try {
        return api.postApi(apiType[type], param.brand_id && { brand_id: param.brand_id })
    } catch (e) {
        Promise.reject(e)
    }
}

export {
    getElecon,
}
