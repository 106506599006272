import { put, takeEvery, call, select } from 'redux-saga/effects'
import { shop as shopActions} from '../actions'
import { api } from '../services';
import { getUser } from '../selectors';
import { IActionShopGet } from '../actions/shopActions';

export function* getShopSaga(action: IActionShopGet) {
  try {
    const user = yield select(getUser);
    const { dealerVendors=[] }  = yield call(api.shop.getShop, user.profile.id);
    yield put(shopActions.shopSet(dealerVendors))
  } catch(e) {
    
  }

}



export default [
  takeEvery(shopActions.ActionTypes.SHOP_GET, getShopSaga),
];