import React from 'react';
import { images } from '../../../../constants/Images';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import config from '../../../../constants/config';
import i18n from '../../../../services/i18n';
import { withTranslation } from 'react-i18next';
import { IHomeknowyourtireData } from '../../../../models/IHomeState';


interface IProps {
   items: any[]
   goToBlogDetail: (item: IHomeknowyourtireData) => void;
}

class Brands extends React.Component<IProps> {

   renderItem = () => {
      const {items } = this.props
      if(items)
      return items.map((item, index) => {
         return (
            <div className="col-md-2 col-sm-4 col-6 px-2" key={index}><a className="d-block bg-white py-3 py-sm-4 mb-grid-gutter" href="javascript:void:(0);"><img className="d-block mx-auto" src={`${config.imageBaseUrl}${item.image}`} alt="Brand" /></a></div>
         )
      })

   }

    render() {
       console.log('Brands', this.props);
      return (
         <section className="brands-section">
         <div className="container px-5">
          <div className="row">
            <div className="col-lg-12 mb-5 text-center">
               <div className="main-heading">
                  <h6>{i18n.t('home.brands.title')}</h6>
                  <h2>{i18n.t('home.brands.subtitle')}</h2>
                  <p>{i18n.t('home.brands.description')}</p>
               </div>
            </div>
         </div>
      
         <div className="row pb-2 pb-sm-0 pb-md-3 brands">
        {this.renderItem()}
        
           {/* <div className="col-md-2 col-sm-4 col-6 px-2">
            <a className="d-block bg-white py-3 py-sm-4 mb-grid-gutter all-brands" href="javascript:void:(0);">See All<span>150+ Brands</span></a>
         </div> */}
      </div>
      </div>
      </section> 
      )
    }
}

export default withTranslation()<any>(Brands)