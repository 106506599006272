import { put, takeEvery, call, delay, select } from 'redux-saga/effects'
import { core as coreActions} from '../actions'
import { IActionLogin } from '../actions/authActions';
import { ICorePages } from '../models/ICoreState';
import { IActionGetPage, IActionContatcUs, IActionGetCities, IActionUpdateCity, IActionCoreSubscribeNewsLetter, IActionCoreGetFaq } from '../actions/coreActions';
import { api } from '../services';
import { getUser } from '../selectors';
import { core } from '../services/api';
const type: { [key in string]: string } = {
  about_us: 'about',
  privacy_policy: 'policy',
  terms_condition: 'terms'
}

export function* getPages(action: IActionGetPage) {
  console.log('getPages', action.payload);
  
  try {
    const { slug } = action.payload;
    const { data } = yield call(api.core.pages, action.payload) 
    yield put(coreActions.setPage({...data, type: type[slug] }))
    console.log('data', data);
    
  } catch(e) {
    console.log(e);
    
  }

}

export function* contactUs(action: IActionContatcUs) {
  console.log('getPages', action.payload);
  
  try {
    const { message } = yield call(api.core.contactUs, action.payload) 
    yield put(coreActions.addNotification(message, 'notice'))
  } catch(e) {
    console.log(e);
    
  }
}

export function* getCities(action: IActionGetCities) {
  try {
    const { data } = yield call(api.core.getCities) 
    yield put(coreActions.setCities(data))
  } catch(e) {
    console.log(e);
    
  }
}

export function* updateCity(action: IActionUpdateCity) {
  try {
    const { id } = action.payload;
    const user = yield select(getUser)
    if (user.id) {
      yield call(api.core.updateCity, { user_id: user.id, city: id }) 
    }
    yield put(coreActions.setUpdatedCity(action.payload))
  } catch(e) {
    console.log(e);
  }
}

export function* subscribeNewsletter(action: IActionCoreSubscribeNewsLetter) {
  try {
    const {statusCode, message} = yield call(api.core.subscribeNewsletter, action.payload) 
    yield put(coreActions.subscribeNewsLetterSuccess(statusCode !== 200 ? true : false))
    console.log('statusCode', statusCode);
    
    yield put(coreActions.addNotification(message, statusCode !== 200 ? 'error' : 'notice'))
  } catch(e) {
    console.log(e);
  }
}

export function* getFaqSaga(action: IActionCoreGetFaq) {
  try {
    const {statusCode, faqList=[]} = yield call(api.core.getFaq)
    yield put(coreActions.setFaq(faqList));
  } catch(e) {
    console.log(e);
  }
}



export default [
  takeEvery(coreActions.ActionTypes.GET_PAGE, getPages),
  takeEvery(coreActions.ActionTypes.CONTACT_US, contactUs),
  takeEvery(coreActions.ActionTypes.GET_CITIES, getCities),
  takeEvery(coreActions.ActionTypes.UPDATE_CITY, updateCity),
  takeEvery(coreActions.ActionTypes.SUBSCRIBE_NEWSLETTER, subscribeNewsletter),
  takeEvery(coreActions.ActionTypes.GET_FAQ, getFaqSaga),
];