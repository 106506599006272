import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import coreSaga from './coreSaga';
import homeSaga from './homeSaga';
import searchSaga from './searchSaga';
import cartSaga from './cartSaga';
import userSaga from './userSaga';
import orderSaga from './orderSaga';
import inventorySaga from './inventorySaga';
import wdSaga from './wdSaga';
import shopSaga from './shopSaga';

export default function* rootSaga () {
  yield all([
    ...authSaga,
    ...coreSaga,
    ...homeSaga,
    ...searchSaga,
    ...cartSaga,
    ...userSaga,
    ...orderSaga,
    ...inventorySaga,
    ...wdSaga,
    ...shopSaga,
  ]);
}
