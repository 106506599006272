import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from '../locales/en.json'
import ar from '../locales/ar.json'
import { ICoreLangType } from "../models/ICoreState";
// the translations
// (tip move them in a JSON file and import them)


const resources = {
  en: {
    translation: en
  },
  ar: {
    translation: ar
  }
};

export const initI18n = (lng: ICoreLangType = 'en', translation: { [key in string]: { translation: { [key in string]: string } } } = resources) => {
  // console.log('initI18n-translation', translation);
  // console.log('../locales/en.json', ar);
  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      lng,
      // resources,
      resources: translation,
      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
}


export function switchLang(lng: 'en' | 'ar', callback?: () => void) {
  i18n.changeLanguage(lng, callback)
}

export default i18n