import React from 'react';

import { images } from '../../../constants/Images';
import IRootState, { ISearchState } from '../../../models';
import { connect } from 'react-redux';
import { search as searcActions, search } from '../../../actions'
import { PATH } from '../../../navigation/NavigationPath';
import { withRouter } from "react-router";
import { ISearchGetYear, ISearchGetModel, ISearchModel, ISearchGetTires, ISearchGetRetailerOrderDetail, ISearchTireCache, ISearchSetRetailerOrderDetail, ISearchInstallCenter } from '../../../models/ISearchState';
import { Container, Loader } from '../../../components/Base';
import { ModalRight, Marker } from '../../../components';
import config from '../../../constants/config';
import GoogleMapReact from 'google-map-react';
import { ICartData } from '../../../models/ICartState';

interface IProps {
	location: { state: ICartData }
}

interface IState {
}


class LocationPage extends React.Component<IProps, IState> {

	map: any


	renderMarker = () => {
		const { location } = this.props
		return (
			<Marker
				lat={Number(location.state.installcenter.latitude)}
				lng={Number(location.state.installcenter.longitude)}
				image={images.markerActive}
			/>
		)
	}

	componentDidMount () {
		
	}
	componentDidUpdate () {
		
	}

    render() {
		const { location } = this.props;
		
      return (
        <Container>
			<div  style={{ height: '100vh'}}>
				<GoogleMapReact
					bootstrapURLKeys={{ key: config.googleApiKey }}
					defaultCenter={{lat: Number(location.state.installcenter.latitude), lng: Number(location.state.installcenter.longitude)}}
					defaultZoom={7}
					ref={(ref) => { this.map = ref; }}
					// onGoogleApiLoaded={this.handleApiLoaded}
				>
					{this.renderMarker()}
				</GoogleMapReact>
			</div>
		</Container>
      )
    }
}


const mapStateToProps = (state: IRootState) => ({
	
  });
  
  const mapDispatchToProps = {
	
  }
  
  
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(LocationPage))