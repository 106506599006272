import { IUserProfile, IUserNotification } from "../models/IUserState";
import { ICoreLangType } from "../models/ICoreState";


export enum ActionTypes {
    SET_LANG = 'language/SET_LANG',
}

export interface IActionLangSetLang {
    type: ActionTypes.SET_LANG;
    payload: ICoreLangType
}


export function setLang (data: ICoreLangType): IActionLangSetLang {
    return {
      payload: data,
      type: ActionTypes.SET_LANG,
    };
}


  

export type Action =
IActionLangSetLang