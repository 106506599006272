import React from 'react';

import { images } from '../../../constants/Images';
import IRootState, { ISearchState, ICoreState,IUserState } from '../../../models';
import { connect } from 'react-redux';
import { Header, BrandCard, Years, TireCard, DetailModal,DeactiveUserCard } from '../Components';
import { search as searcActions, cart as cartActions, core as coreActions } from '../../../actions'
import { PATH } from '../../../navigation/NavigationPath';
import { withRouter } from "react-router";
import { ISearchGetTires, ISearchGetRetailerOrderDetail, ISearchGetInstallCenter, recordType } from '../../../models/ISearchState';
import { Container, Loader } from '../../../components/Base';
import { ModalRight, AddCartSuccessModal, Modal, HomeLoader } from '../../../components';
import DetailPage from '../DetailPage';
import LocationPage from '../LocationPage';
import ChooseLocationPage from '../ChooseLocationPage';
import ICartState, { ICartAddParam, ICartSuccess, ITamaraPreOrderParam, ITamaraCheckoutParam, ITabbyCheckoutParam } from '../../../models/ICartState';
import { stat } from 'fs';
import { IUserProfile } from '../../../models/IUserState';
import { withTranslation } from 'react-i18next';
import { i18n,validation } from '../../../services';
import ReactHtmlParser from 'react-html-parser';
import { core } from '../../../services/api';
import { Filter } from '../../../utils';

interface IProps {
  search: ISearchState;
  getTires: (param: ISearchGetTires) => void;
  location: { state: ISearchGetTires | any}
  getOrderDetail: (param: ISearchGetRetailerOrderDetail) => void;
  history: any;
  getInstallCenter: (param: ISearchGetInstallCenter) => void;
  addToCart: (param: ICartAddParam) => void;
  tamaraPreOrder: (param: ITamaraPreOrderParam) => void;
  getTamaraCheckout: (param: ITamaraCheckoutParam) => void;
  getTabbyCheckout: (param:ITabbyCheckoutParam)=>void;
  cart: ICartState;
  addToCartSuccess: (param: any) => void;
  forceToLogin: (prams: boolean) => void;
  core: ICoreState
  isLoggedin: boolean;
  user: IUserProfile
  onHomeLocation: (id: number) => void;
  onChooseLocation: (id: number) => void;
  onToggelLoginStatus: () => void;
}

interface IState {
  isModalShow: boolean;
  selectedTireId: number;
  deliveryInstallCharge:number;
  installCenterUniversal:number;
  installationCharge:string;
  deliveryCharge:string;
  detailItems: ISearchGetRetailerOrderDetail | {};
  showInstaller: boolean;
  homeInstall: boolean;
  loginTo: { set: boolean, action: () => void }
  homeInstallModal: boolean;
  validation: any;
  showUserDectiveModal:boolean;
  isLoading : boolean;
  tamaraCheckoutUrl:string;
  tabbyCheckoutUrl:string;
  isTamaraPopOpen : boolean;
  paymentBy:string;
  paymentSuccess : boolean;
}

class TiresPage extends React.Component<IProps, IState> {
  _isMounted: boolean = true;
  state = {
    isModalShow: false,
    selectedTireId: this.props.location.state && this.props.location.state.selectedTireId ? this.
    props.location.state.selectedTireId : 0, 
    deliveryInstallCharge : 0,
    installCenterUniversal : 0,
    deliveryCharge: "0",
    installationCharge:"0",
    detailItems:  this.props.location.state && this.props.location.state.detailItems ? this.props.location.state.detailItems :  {},
    showInstaller: this.props.location.state && this.props.location.state.showInstaller ? this.props.location.state.showInstaller : false,
    homeInstall: this.props.location.state && this.props.location.state.homeInstall ? this.props.location.state.homeInstall : false,
    loginTo: { set: false, action: () => {} },
    homeInstallModal: false,
    validation: {},
    showUserDectiveModal:false,
    isLoading:false,
    tamaraCheckoutUrl: "",
    tabbyCheckoutUrl: "",
    isTamaraPopOpen:false,
    paymentBy:"",
    paymentSuccess:false

  }

  componentDidMount() {
    const { getTires, location, history } = this.props;
    console.log('tttttttttttttt', this.props);
    getTires(location.state);
    window.onpopstate = () => {
      const { detailItems, showInstaller } = this.state;
      if (Object.keys(detailItems).length) {
        this.setState({ detailItems: {} })
      } else if (showInstaller) {
        this.setState({ showInstaller: false })
      }
      this.props.onToggelLoginStatus()
      // window.history.go(0);
    };
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    if (!props.location.state) {
      // props.history.replace('/')
      // props.history.goBack()
      window.location.href = '/'
    }
    // if (props.isLoggedin) {
    //   return {...state, loginTo: { set: false, action: () => {} }}
    // }
    return state
  }

  checkLogin = (action: any) => {
    const { forceToLogin } = this.props;
    forceToLogin(true)
    this.setState({ loginTo:  {set: true, action} })
  }

  componentDidUpdate(prevProps: IProps) {
    const { isLoggedin, cart, getTamaraCheckout, getTabbyCheckout, addToCart } = this.props;
    // console.log('cartbyravi====>',cart);
    const { loginTo } = this.state;
    if (prevProps.isLoggedin !== isLoggedin &&  loginTo.set && isLoggedin) {
      loginTo.action()
      this.setState({loginTo: { set: false, action: () => {} }})
    }

    if(prevProps.cart.tamaraPreData !== cart.tamaraPreData){
      const per_order_data = cart.tamaraPreData;
      if(typeof per_order_data !== 'undefined' && Object.keys(per_order_data).length > 0){

        let order_number = cart.tamaraPreData.order_number;
        let order_reference_id = cart.tamaraPreData.order_reference_id;
        // let order_reference_id = cart.tamaraPreData2;
        let orderDataParam: string | null = localStorage.getItem('addtocartparam'); // Example of a nullable value
        // Non-null assertion
        let orderData_obj: {} = JSON.parse(orderDataParam!);
        // console.log('orderData_obj===>', orderData_obj);
        let grandTotal: number = (orderData_obj as { grand_total: number }).grand_total;
        let vat: number = (orderData_obj as { vat: number }).vat;
        let price_final : number = (orderData_obj as {price_final : number}).price_final;
        let first_name : string = (orderData_obj as {first_name : string}).first_name;
        let last_name : string = (orderData_obj as {last_name : string}).last_name;
        let email_address : string = (orderData_obj as {email_address : string}).email_address;
        let payment_type : string = (orderData_obj as {payment_type : string}).payment_type;
        let quantity : string = (orderData_obj as {quantity : string}).quantity;
        let delivery_charge : string = (orderData_obj as {delivery_amount : string}).delivery_amount;
        let tire_brand_model : string = (orderData_obj as {tire_brand_model : string}).tire_brand_model;


        let user_data:any = localStorage.getItem('persist:root');
        let user_data_arr = JSON.parse(user_data!);
        let user_data_obj = JSON.parse(user_data_arr.user);
        let user_mobileno = user_data_obj.profile.primary_mobile;
        user_mobileno = user_mobileno.replace(/-/g, "");
        user_mobileno = '+'+user_mobileno;

        // console.log('user_mobile_number===>', user_mobileno);
        // console.log('orederParam===>', orderData_obj);
        // console.log('orederParam===>', payment_type);

        if(payment_type === 'Tamara'){
          const orederParam = {
            order_reference_id:order_reference_id,
            order_number:order_number,
            total_amount:grandTotal,
            description:'Testing',
            discount_amount:'0',
            tax_amount:vat,
            first_name:first_name,
            last_name:last_name,
            phone_number: user_mobileno, //'+966533333326', //'+966544337866', //'+966533333334', //user_mobileno, 
            email:email_address,
            unit_price:price_final,
            shipping_amount:'0.00',
            currency:'SAR',
            platformtype:'web',
            tire_brand_model:tire_brand_model
          }

          // console.log('orederParam===>222', orederParam);
          getTamaraCheckout(orederParam);
        } else {
        const tabby_checkout_param = {
          order_number:order_number,
          order_reference_id:order_reference_id,
          grand_total:grandTotal,
          vat:vat,
          price_final:price_final,
          first_name:first_name,
          last_name:last_name,
          email_address:email_address, //"card.success@tabby.ai", //"otp.rejected@tabby.ai", //email_address,
          payment_type:payment_type,
          user_mobileno:user_mobileno, //"500000001", //user_mobileno,
          quantity:Number(quantity),
          delivery_charge:delivery_charge,
          current_date: this.getCurrentDateTimeFomat(),
          currency:"SAR",
          tabby_lang:"en",
          merchant_code:"Estbnhsau",
          tire_brand_model:tire_brand_model
        }
        // console.log('orederParam===>333', tabby_checkout_param);
        // const sessionobj = this.tabbySessionObj(tabby_checkout_param);
        // console.log('sessionobj===>',sessionobj);
        // @ts-ignore-start
        getTabbyCheckout(tabby_checkout_param);

        }
      }
    }

    if(prevProps.cart.tamaraCheckout !== cart.tamaraCheckout){
      let tamaraUrl = cart.tamaraCheckout.checkout_url;
      let tamaraStatus = cart.tamaraCheckout.status;

      if(tamaraUrl){

      this.setState({isLoading:false, tamaraCheckoutUrl:tamaraUrl, isTamaraPopOpen:true});
      // window.open(tamaraUrl, "_blank",  "width=800, height=600");
      // window.location.href = tamaraUrl;
      const per_order_data = cart.tamaraPreData;
      // console.log('rammmmm===========>',per_order_data);
      let order_number = "";
      let order_reference_id = "";
      if(typeof per_order_data !== 'undefined' && Object.keys(per_order_data).length > 0){
        order_number = per_order_data.order_number;
        order_reference_id = per_order_data.order_reference_id;
      } 
      let orderDataParam: string | null = localStorage.getItem('addtocartparam'); // Example of a nullable value
        // console.log('aaa====>', orderDataParam);
        let param2 = JSON.parse(orderDataParam!);
        const param = {
          ...param2,
          platform_type: "Web",
          order_number: order_number,
          reference_number: order_reference_id,
        }
        // localStorage.removeItem('addtocartparam');
         addToCart(param);
      } else {
        alert(tamaraStatus);
        this.setState({isLoading:false});
      }
    }

    if(prevProps.cart.tabbyCheckout !== cart.tabbyCheckout){
      let tabbyData = cart.tabbyCheckout;
      if(tabbyData.status_code === 200){
        this.setState({tabbyCheckoutUrl:tabbyData.data});
        // window.location.href = tabbyData.data;
        // window.open(tabbyData.data, "_blank",  "width=800, height=600");

        const per_order_data = cart.tamaraPreData;
      // console.log('rammmmm===========>',per_order_data);
      let order_number = "";
      let order_reference_id = "";
      if(typeof per_order_data !== 'undefined' && Object.keys(per_order_data).length > 0){
        order_number = per_order_data.order_number;
        order_reference_id = per_order_data.order_reference_id;
      } 
      let orderDataParam: string | null = localStorage.getItem('addtocartparam'); // Example of a nullable value
        // console.log('aaa====>', orderDataParam);
        let param2 = JSON.parse(orderDataParam!);
        const param = {
          ...param2,
          platform_type: "Web",
          order_number: order_number,
          reference_number: order_reference_id,
        }
        // localStorage.removeItem('addtocartparam');
         addToCart(param);

      } else {
        this.setState({isLoading:false});
        alert(tabbyData.message);
        return false;
      }



      // this.setState({isLoading:false, tamaraCheckoutUrl:tamaraUrl, isTamaraPopOpen:true});
      // window.open(tamaraUrl, "_blank",  "width=800, height=600");
      // window.location.href = tamaraUrl;
    }


    if(prevProps.cart.cartSuccess !== cart.cartSuccess){
        if(this.state.paymentBy === 'Cash'){
            this.setState({paymentSuccess:true});
        } else {
          localStorage.setItem('paymentSuccessResponse', JSON.stringify(cart.cartSuccess));
          if(this.state.paymentBy === 'Tamara'){
            window.location.href = this.state.tamaraCheckoutUrl;
          } else if(this.state.paymentBy === 'Tabby'){
            window.location.href = this.state.tabbyCheckoutUrl;
          }
        }
    }


  }

  tabbySessionObj = (param:any) => {
		const currentDate = this.getCurrentDateTimeFomat();
		const payment_obj = {
			amount:param.grand_total,
			currency:"SAR",
			description:"",
			buyer:{email:"card.success@tabby.ai", phone:param.user_mobileno, name:param.first_name +" "+param.last_name, dob:"2019-08-24"},
			shipping_address:{address:"Sample Address #2", city:"Dubai", zip:"11111"},
			order:{reference_id:param.order_reference_id,items:[{title:"", description:"ESTBNH Tabby Payment WEB", quantity:Number(param.quantity), unit_price:param.price_final, discount_amount:0, reference_id:param.order_reference_id, image_url:"", product_url:"", ordered:0, captured:0, shipped:0, refunded:0, gender:null, category:null, color:null, product_material:null, size_type:null, size:null, brand:null}], shipping_amount:param.delivery_charge, tax_amount:param.vat},
			buyer_history:{registered_since:currentDate, loyalty_level:0, wishlist_count:null, is_social_networks_connected:null, is_phone_number_verified:null, is_email_verified:null},
			order_history:[], 
			meta:{order_id:"#1234",customer: "#customer-id"}, 
			attachment:null
		}

		const sessionobj = {
			payment:payment_obj,
			lang:"en",
			merchant_code:"Estbnhsau",
			merchant_urls:{
				"success": "https://your-store/success",
				"cancel": "https://your-store/cancel",
				"failure": "https://your-store/failure"
			}
		}
    return sessionobj;
	}

  getCurrentDateTimeFomat= () => {
		const currentDate = new Date();
		const formattedDate = currentDate.toISOString(); // Outputs: '2023-11-02T10:30:45.123Z'

		// If you specifically want the format '2019-08-24T14:15:22Z'
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, '0');
		const day = String(currentDate.getDate()).padStart(2, '0');
		const hours = String(currentDate.getHours()).padStart(2, '0');
		const minutes = String(currentDate.getMinutes()).padStart(2, '0');
		const seconds = String(currentDate.getSeconds()).padStart(2, '0');

		const formattedDateCustom = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
		// console.log(formattedDateCustom); // Outputs: '2023-11-02T10:30:45Z'
		return formattedDateCustom;
	}

  renderItem = () => {
    const { search, isLoggedin, forceToLogin } = this.props;
    return search.tires.ids.map((id, index) => {
      const onDetailOpen = () => !isLoggedin ? this.checkLogin(() => this.onModalToggel(id)) : this.onModalToggel(id)
      const onReview = () => !isLoggedin ? this.checkLogin(() => this.onReview(id)) : this.onReview(id)
      const onChooseLocation = () => !isLoggedin ? this.checkLogin(() => this.onChooseLocation(id)) : this.onChooseLocation(id)
      const onHomeInstall = () => !isLoggedin ? this.checkLogin(() => this.onHomeInstall(id)) : this.onHomeInstall(id)
      return (
        <TireCard 
          items={search.tires.cache[id]} 
          onClick={this.onPress}
          onDetailOpen={onDetailOpen}
          onReview={onReview}
          onChooseLocation={onChooseLocation}
          onHomeInstall={onHomeInstall}
          key={index}
        />
      )
    }) 
  }

  onChooseLocation = (id: number) => {
    // if(this.props.user.status == "Deactive"){
    //   this.setState({ showUserDectiveModal: true })
    // }else{
      const { history, location, search, getInstallCenter, user } = this.props;
      const { showInstaller } = this.state;
      const items = search.tires.cache[id]
      this.setState({ showInstaller: true,  selectedTireId: id,homeInstall:false})
      getInstallCenter({ warehouse_user_id: items.warehouse_user_id, user_id: Number(user.id) })
      history.push(PATH.SEARCH_TIRES_LIST, {...location.state, showInstaller: true, selectedTireId: id,homeInstall:false})
    // }
  }
  onHomeLocation = (id: number) => {
    // if(this.props.user.status == "Deactive"){
    //   this.setState({ showUserDectiveModal: true })
    // }else{
      const { history, location, search,user,core } = this.props;
      // console.log(this.state);
      const items = search.tires.cache[id]
      this.setState({ showInstaller: false,  selectedTireId: id,homeInstall:true})
      // getInstallCenter({ warehouse_user_id: items.warehouse_user_id, user_id: Number(user.id) })
      history.push(PATH.SEARCH_TIRES_LIST, {...location.state, showInstaller: false, selectedTireId: id,homeInstall:true})
    // }
  }

  onReview = (id: number, installerId: string = '0') => {
    const { location, search, getOrderDetail, history, user } = this.props;
    const items = search.tires.cache[id]
    // console.log('items', items);
    const record_type: { [key: string]: recordType } = {
      "EJ Basic": "BASIC",
      "EJ Normal": "NORMAL",
      "Retailer": "RETAILER",
    }
    const param = { 
      ...location.state,
      brand_id: items.brand_id,
      user_id: user.id,
      inventory_id: items.inventory_id,
      inventory_id_rear: items.inventory_rear_id,
      coupon_check: items.offer_id ? 1 : 0,
      install_center_id: installerId,
      warehouse_user_id: items.warehouse_user_id,
      
      type: record_type[items.record_type],
      f_rear_size: location.state.f_size_rear,
      s_rear_size: location.state.s_size_rear,
      t_rear_size: location.state.t_size_rear,
      coupon_discount: items.offer_discount,
      homeInstall:this.state.homeInstall,
      ...items.vendor_id && {
        vendor_id: items.vendor_id,
      }
      
    } 
    this.setState({ detailItems: param})
    getOrderDetail(param)
    // window.history.pushState(this.state, '', window.location.href);
    history.push(PATH.SEARCH_TIRES_LIST, {...param, detailItems: param})
  } 

  onPress = () => {

  }
  
  onModalToggel = (id: number = 0) => {
    // if(this.props.user.status == "Deactive"){
    //   this.setState({ showUserDectiveModal: true })
    // }else{
      const { isModalShow } = this.state;
      this.setState({ isModalShow: !isModalShow, selectedTireId: id })
    // }
  }
  onHomeInstall = (id: number = 0) => {
    const { homeInstallModal,selectedTireId } = this.state;
    if(this.props.search.tires.cache[id] != undefined && Number(this.props.search.tires.cache[id].install_center_count) == 0){
      this.onHomeLocation(id)
    }else{
      const delivery_install_charge = this.props.search.tires.cache[id] != undefined ? this.props.search.tires.cache[id].delivery_install_charge : 0;
      const delivery_charge = this.props.search.tires.cache[id] != undefined ? this.props.search.tires.cache[id].delivery_charge : '0';
      const installation_charge = this.props.search.tires.cache[id] != undefined ? this.props.search.tires.cache[id].installation_charge : "0";
      const install_center_universal = this.props.search.tires.cache[id] != undefined ? this.props.search.tires.cache[id].install_center_universal : 0;
      this.setState({ homeInstallModal: !homeInstallModal, deliveryInstallCharge:delivery_install_charge,deliveryCharge:delivery_charge, installationCharge:installation_charge,selectedTireId: id, installCenterUniversal:install_center_universal})
    }
  }

  onDetailBack = () => {
    this.setState({ detailItems: {} })
    this.props.history.goBack()
  }

  onGoBack = () => {
    // console.log(this.state);
    const { detailItems, showInstaller, homeInstall } = this.state;
    if (Object.keys(detailItems).length) {
      this.setState({ detailItems: {} })
    } else if (showInstaller) {
      this.setState({ showInstaller: false })
    } else if (homeInstall) {
      this.setState({ homeInstall: false })
    }
    this.setState({ detailItems: {} })
  }

  onBooking = (param: ICartAddParam) => {
    localStorage.setItem('addtocartparam', JSON.stringify(param));
    if(param.payment_type ==='Tamara' || param.payment_type ==='Tabby'){
      this.setState({isLoading:true, paymentBy:param.payment_type});
      const {tamaraPreOrder} = this.props;
      tamaraPreOrder({user_id:param.user_id});
    } else if(param.payment_type ==='Tabby'){
      alert('Tabby Working');
    } else {
      this.setState({isLoading:true, paymentBy:'Cash'});
      const { addToCart } = this.props;
      addToCart(param)
    }
  }

  closeTamaraPopup = () => {
    this.setState({isTamaraPopOpen:false});
  }

  onCloseCartSuccess = () => {
    const { addToCartSuccess, history } = this.props;
    addToCartSuccess({})
    history.push(PATH.CART)
  }
  onUserDectiveModal = ()=>{
    this.setState({showUserDectiveModal:!this.state.showUserDectiveModal})
  }

    render() {
      const { search, location, cart, user, core } = this.props;
      const { state } = location;
      const { isModalShow, selectedTireId, detailItems, showInstaller,homeInstall, isLoading, tamaraCheckoutUrl, isTamaraPopOpen, installCenterUniversal } = this.state;
      
      
      if (Object.keys(detailItems).length) {
        
        return (
        <>
        <DetailPage 
          items={detailItems} 
          tireData={search.tires.cache[detailItems.inventory_id]} 
          // @ts-ignore-start
          detail={search.details} 
          // @ts-ignore-end
          onBack={this.onGoBack}
          onBooking={this.onBooking}
          user={user}
          isLoading={isLoading}
          tamaraCheckoutUrl={tamaraCheckoutUrl}
          isTamaraPopOpen={isTamaraPopOpen}
          closeTamaraPopup = {this.closeTamaraPopup}
        /> 
        {
          (cart.cartSuccess != undefined && this.state.paymentSuccess) ?
          <AddCartSuccessModal
          // @ts-ignore-end
            items={cart.cartSuccess}
            onToggel={this.onCloseCartSuccess}
            show={!!Object.keys(cart.cartSuccess).length}
          />
          :
          ""
        }
          
        </>
        )
      }

      if (showInstaller) {
        return (
          <LocationPage 
            tireDetail={search.tires.cache[selectedTireId]}
            installCenter={search.installCenter}
            onReview={(id) => this.onReview(selectedTireId,  id)}
            onGoBack={this.onGoBack}
          />
        )
      }
      if (homeInstall) {
        return (
          <ChooseLocationPage 
            tireDetail={search.tires.cache[selectedTireId]}
            installCenter={search.installCenter}
            onReview={(id) => this.onReview(selectedTireId,  id)}
            onGoBack={this.onGoBack}
            selectedCity={core.selectedCity.city}
            selectedCityArabic={core.selectedCity.city_arabic}
            core={core}
          />
        )
      }

      if(search.loading){
       return <HomeLoader />
      }
      
      return (
        <>
         <Container>
           <div key={'search-tire'}>
              <section className="inner-page-banner bg-size-cover" style={{
             background: '#fcc223'
           }} >
              <div className="tire-mark"><img src="img/inner-tire-mark.png" alt=""/></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="list-heading">
                      <ul>
                        {
                          state.rear_size_tyre ? (
                            <>
                            <li><h1><span>{i18n.t(`tire.label.front_size`)}:</span>{`${state.f_size}/${state.s_size}R-${state.t_size}`}</h1></li>
                            {state.f_size_rear ? (
                              
                            <li><h1><span>{i18n.t(`tire.label.rear_size`)}:</span>{`${state.f_size_rear}/${state.s_size_rear}R-${state.t_size_rear}`}</h1></li>  
                            ) : ""}
                            
                            </>
                          ) : (
                            <li><h1><span>{i18n.t(`tire.label.tire_size`)}:</span>{`${state.f_size}/${state.s_size}R-${state.t_size}`}</h1></li>
                          )
                        }
                        
                      </ul>
                    </div>
                  </div>         
                </div>
              </div>
            </section>
            <section className="listing-section">
            <div className="container px-lg-5">
              {
                  search.tires.ids.length > 0 && (
                  <div className="row">
                    <div className="col-lg-12">
                          <h5 className="listing-description">{ReactHtmlParser(i18n.t(`tire.description`, { length: search.tires.ids.length, city: Filter.translate(core.selectedCity.city, core.selectedCity.city_arabic) }))}</h5>
                    </div>
                  </div>
              )
                } 
              {
                  (search.loading) ? (
                    <Loader/>
                  ) : this.renderItem()
              }        

               {
                  search.tires.ids.length == 0 && (
                    <div className="row">
                        <div className="col-lg-12">
                          <div className="main_emptylisting">
                            <img src={images.emptyTire} />
                          </div>
                          <div className="listing_nodatacontent">
                              <div className="main-heading">
                                <h2>{i18n.t('tire.empty.title')}</h2>
                  <p>{ReactHtmlParser(i18n.t('tire.empty.description'))}</p>
                              </div>
                              
                          </div>
                        </div>
                      </div>
                  )
                } 

                </div>
              </section>
            </div>
            
            </Container>
                <ModalRight onToggel={this.onModalToggel} show={isModalShow} title={i18n.t(`tire.detail.title`)}>
                  {search.tires.cache[selectedTireId] && search.tires.cache[selectedTireId].home_delivery == 'Yes'?
                  <DetailModal  items={search.tires.cache[selectedTireId]} onHomeInstall={this.onHomeInstall}/>
                  : <DetailModal  items={search.tires.cache[selectedTireId]} onChooseLocation={this.onChooseLocation}/>
                  }
                  
                  
                  
                </ModalRight>

                <Modal onToggel={() => { 
                  this.onHomeInstall(selectedTireId)
                }} show={this.state.homeInstallModal}>                      
                      <div className="modal-header title-modal">
                        <div style={{textAlign:'center'}} className="searchPopup">
                          <div className="selectHead">{i18n.t(`select_install_option`)}</div>
                          <div className="popupBox" style={{backgroundColor:'#fcc223', color:"#fff", padding:"10px 0 10px 0"}}>
                            <a href="javascript:void(0)" onClick={() => this.onHomeLocation(this.state.selectedTireId)} style={{color:"#fff"}}>
                            <div className="install_at_home">{installCenterUniversal ? i18n.t(`multicity_msg_install_at_home`) : i18n.t(`msg_install_at_home`)}</div>
                            <div className="serchpopup-midd">{installCenterUniversal ? i18n.t(`multicity_msg_have_you_tires_del`) : i18n.t(`msg_have_you_tires_del`)}</div>
                            <br />
                            {
                              this.state.deliveryInstallCharge != 0 ?
                              <div style={{fontStyle:"italic",fontWeight:"bold"}}>{installCenterUniversal ? i18n.t(`Delivery`) : i18n.t(`delivery_installation`)}&nbsp;(+{this.state.deliveryInstallCharge} {i18n.t(`sar`)} <span style={{fontSize:"60%"}}>{i18n.t(`common.label.inc_vat`)} </span>)
                               </div>
                              : 
                              <div style={{fontStyle:"italic",fontWeight:"bold"}}>{installCenterUniversal ? i18n.t(`Delivery`) : i18n.t(`delivery_installation`)}&nbsp;({i18n.t(`common.free`)})
                               </div>
                            }
                            </a>
                          </div>
                          <div className="popupBox" style={{backgroundColor:'#fcc223', color:"#fff", padding:"10px 0 10px 0", marginTop:"15px"}}>
                            <a href="javascript:void(0)" onClick={() => this.onChooseLocation(this.state.selectedTireId)} style={{color:"#fff"}}>
                            <div className="install_at_home">{installCenterUniversal ? i18n.t(`multicity_msg_chooseinstall_popup`) : i18n.t(`msg_chooseinstall_popup`)}</div>
                            <div className="serchpopup-midd">{i18n.t(`common.label.choose_center`)}</div>
                            <br />
                            {
                              Number(this.state.deliveryCharge) > 0 ?
                              <div style={{fontStyle:"italic",fontWeight:"bold"}}>{installCenterUniversal ? i18n.t(`delivery_installation`) : i18n.t(`common.label.Delivery`)}&nbsp;(+{this.state.deliveryCharge} {i18n.t(`sar`)} <span style={{fontSize:"60%"}}>{i18n.t(`common.label.inc_vat`)} </span>)
                              </div>
                              :
                              <div style={{fontStyle:"italic",fontWeight:"bold"}}>{i18n.t(`delivery_installation`)}&nbsp;({i18n.t(`common.free`)})
                               </div>
                            }
                            </a>
                          </div>
                        </div>
                      </div>
                    </Modal>
                {
                  this.state.showUserDectiveModal ? 
                  <DeactiveUserCard user={this.props.user} onUserDectiveModal={this.onUserDectiveModal} />
                  :""
                }
                  
                  
            </>
      )
    }
}


  const mapStateToProps = (state: IRootState) => ({
    search: state.search,
    core: state.core,
    cart: state.cart,
    isLoggedin: state.auth.isLoggedin,
    user: state.user.profile
  });
 
  const mapDispatchToProps = {
    getTires: searcActions.getTires,
    getOrderDetail: searcActions.getOrderDetail,
    getInstallCenter: searcActions.getInstallCenter,
    addToCart: cartActions.addToCart,
    tamaraPreOrder: cartActions.tamaraPreOrder,
    getTamaraCheckout: cartActions.getTamaraCheckout,
    addToCartSuccess: cartActions.addToCartSuccess,
    forceToLogin: coreActions.forceToLogin,
    getTabbyCheckout: cartActions.getTabbyCheckout,
  }
 
 
 
 export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(TiresPage)) )