import React from 'react';
import { Container } from '../../../components/Base';
import IRootState, { ISearchState, ICoreState } from '../../../models';
import { connect } from 'react-redux';
import Geocode from "react-geocode";
import { geoCode } from '../../../services';
import { ICoreContact } from '../../../models/ICoreState';
import { withTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';
import config from '../../../constants/config';
import { ISearchGetTires } from '../../../models/ISearchState';
import { ISearchTireCache, ISearchInstallCenter } from '../../../models/ISearchState';
import { InstallCenterHeader, InstallCenterCard } from '../../Search/Components';
import { images } from '../../../constants/Images';
import { i18n } from '../../../services';

interface IProps {
  tireDetail: ISearchTireCache
  installCenter: ISearchInstallCenter[];
  onReview: (id: string) => void
  onGoBack: () => void;
  selectedCity: string,
  selectedCityArabic: string,
  core?: ICoreState;
}

interface IState {
  isValidCaptcha: boolean
  selectedTireId: number;
  selectedInstallCenterID: string
  inventory_id: string,  
}
class ChooseLocationPage extends React.Component<IProps> {
  state = {
    lat: (this.props.selectedCity == 'Riyadh' || this.props.selectedCity == "الرياض") ? 24.774265 : 21.543333,
    lng: (this.props.selectedCity == 'Riyadh' || this.props.selectedCity == "الرياض") ? 46.738586 : 39.172779,
    address: '',
    city_name: '',
    showMap: false,
    showMarker: false,
    selectedCity: this.props.selectedCity,
    selectedCityArabic: this.props.selectedCityArabic,
    // selectedTireId: this.props.location.state && this.props.location.state.selectedTireId ? this.props.location.state.selectedTireId : 0, 
    inventory_id: this.props.tireDetail && this.props.tireDetail.inventory_id ? this.props.tireDetail.inventory_id : '',
  }
  map: any

  componentDidMount() {
    // const { core } = this.props;
    // if(core){
    //   let latitude = core.selectedCity.latitude;
    //   let longitude = core.selectedCity.longitude;
    // console.log('rammmm===>', core.selectedCity);
    // this.setState({lat:latitude, lng:longitude});
    // }
    
    this.getCity().then(() => {
      this.setState({ showMap: true })
    })
 }

  componentWillMount() {
    //console.log(this.props.core);
    //alert(this.props.selectedCity);

    const { core } = this.props;
    if(core){
      let latitude = parseFloat(core.selectedCity.latitude);
      let longitude = parseFloat(core.selectedCity.longitude);
    this.setState({lat:latitude, lng:longitude});
    
    }

    
  }

  currentLocation = async () => {
    // this.setState({lat:('24.774265'),lng:("46.738586")})
    // const currentLocation = await geoCode.getLocation().then((e) => {
    //   this.setState({lat:Number(e.latitude),lng:Number(e.longitude),address:`${e.address}`,city_name:`${e.city}`})
    //   if(`${e.city}` == this.state.selectedCity){
    //     this.setState({showMarker:true})
    //   }
    // }, error => {
    //   console.error(error);
    //   });

  }
  handleApiLoaded = ({ map, maps }: { map: any, maps: any }) => {
    console.log("map", map);
  }
  getCity = async () => {
    // this.getLocation()
    Geocode.setApiKey("AIzaSyD-6jEMi205oSuzSoALHH0jWX-d2BX5Ato");
    Geocode.setLanguage("en");
    Geocode.fromLatLng(`${this.state.lat}`, `${this.state.lng}`).then((response) => {
      if (response.results[0] != undefined) {
        // console.log(response.results[0]);
        const city = response.results[0].address_components.find((item: any) => item.types.indexOf("locality") >= 0);
        // const address = response.results.find((item: any) => item.types.indexOf('administrative_area_level_2') >= 0);
        // console.log(response.results[0].address_components);
        console.log(city);
        if (city != undefined) {
          this.setState({ city_name: city.long_name, address: response.results[0].formatted_address })
          //alert(city.long_name);
          if (city.long_name == this.state.selectedCity || city.long_name == this.state.selectedCityArabic) {
            this.setState({ showMarker: true })
          } else {
            this.setState({ showMarker: false })
            console.error('Please set pin in ' + this.state.selectedCity);
            console.error(1);
          }
        } else {
          this.setState({ address: response.results[0].formatted_address })
          this.setState({ showMarker: false })
          console.error('Please set pin in ' + this.state.selectedCity);
          console.error(2);
        }
      }
    }, error => {
      this.setState({ showMarker: false })
      //alert('Unable to get location from google.');
      console.error(error);
    });
  }

  setLocation = () => {
    const { core} = this.props;
    if(core){
    let latitude = parseFloat(core.selectedCity.latitude);
    let longitude = parseFloat(core.selectedCity.longitude);
    let radius = core.selectedCity.radius;
    let new_lat = this.state.lat;
    let new_lng = this.state.lng;
    console.log('radius====>', radius);
    console.log('oldlatlong===>', latitude, longitude);
    console.log('newlatlong===>', new_lat, new_lng);
    let distence_km = this.getDistanceFromLatLonInKm(latitude, longitude, new_lat, new_lng);
    console.log('distence===>', distence_km);
    if (radius < distence_km) {
      let msg = i18n.t(`setlocation.error.msg`);
      let selectedCity = i18n.language == 'en' ? this.state.selectedCity : this.state.selectedCityArabic;
      let msg2 = msg.replace("<CITY>", selectedCity);
      alert(msg2);
      return false;
    }
    }
    // if (this.state.showMarker == false) {
    //   alert('Please set pin in ' + this.state.selectedCity);
    //   return false;
    // }
    // console.log('lallll=>',`${this.state.lat}`);
    localStorage.setItem('user_choose_lat', `${this.state.lat}`);
    localStorage.setItem('user_choose_lng', `${this.state.lng}`);
    localStorage.setItem('user_choose_city_name', `${this.state.city_name}`);
    localStorage.setItem('user_choose_address', `${this.state.address}`);
    this.props.onReview("" + this.state.inventory_id);
  }
  
  getDistanceFromLatLonInKm = (lat1 : number,lon1:number,lat2:number,lon2:number) => {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2); 
      
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
  }

  deg2rad = (deg:any) => {
    return deg * (Math.PI/180)
  }

  // getLocation = () => {
  // 	var lat = localStorage.getItem('user_choose_lat') != ''? localStorage.getItem('user_choose_lat') : this.state.lat;
  // 	var lng = localStorage.getItem('user_choose_lng') != ''? localStorage.getItem('user_choose_lng') : this.state.lng;
  // 	var city_name = localStorage.getItem('user_choose_city_name') != ''? localStorage.getItem('user_choose_city_name') : this.state.city_name;
  // 	var address = localStorage.getItem('user_choose_address') != ''? localStorage.getItem('user_choose_address') : this.state.address;
  // 	this.setState({lat:Number(lat),lng:Number(lng),address:`${address}`,city_name:`${city_name}`})
  // }

  render() {
    const { tireDetail, onGoBack } = this.props;
    // console.log('tireDetail', this.state);
    const install_center_universal = tireDetail.install_center_universal;
    return (
      <>
        <InstallCenterHeader items={tireDetail} onGoBack={onGoBack} />
        <div className="location_page_wrap">
          <section className="loction_mapwrap" style={{ width: "100%", marginTop: "1%" }}>
            <div className="row">
              <div className="col-lg-12">
                <div className="mapLocation">{install_center_universal ? i18n.t(`multicity_msg_Setlocation`) : i18n.t(`msg_Setlocation`)}</div>
              </div>
              <div className="col-lg-12">

                <div className="" style={{ height: 'calc(96vh - 180px)' }}>
                  {this.state.showMap == true ?
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: config.googleApiKey }}
                      defaultCenter={{ lat: this.state.lat, lng: this.state.lng }}
                      defaultZoom={5}
                      draggable={true}
                      onDrag={(map) => {
                        console.log("map", map)
                        this.setState({ lat: map.center.lat(), lng: map.center.lng() })
                        // let marker = new maps.Marker({
                        //       position: { lat: map.center.lat, lng: map.center.lng },
                        //       map,
                        //       draggable: false,
                        //     });
                      }}
                      // onGoogleApiLoaded={this.handleApiLoaded}
                      onGoogleApiLoaded={({ map, maps }) => {
                        console.log("map", map);
                        const state_value = this;
                        let marker = new maps.Marker({
                          position: { lat: this.state.lat, lng: this.state.lng },
                          map,
                          draggable: false,
                          icon: {
                            url: images.orangepin,
                            scaledSize: new maps.Size(50, 50),
                          }
                        });
                        maps.event.addListener(map, 'drag', function () {
                          marker.setPosition({ lat: map.center.lat(), lng: map.center.lng() });

                        });
                        maps.event.addListener(map, 'dragend', function () {
                          state_value.setState({ lat: map.center.lat(), lng: map.center.lng() })
                          state_value.getCity()
                        });
                      }}
                    >
                    </GoogleMapReact>
                    : ""
                  }
                </div>
              </div>
              <div className="col-lg-12 mapLocationSubmit" style={{ marginTop: "-5%", backgroundColor: "#fff", display: "grid" }}>
                <p style={{ lineHeight: "25px" }}>{this.state.address}</p>
                <div className="btn btn-primary" onClick={this.setLocation}>{i18n.t(`common.button.submit`)}</div>
              </div>
            </div>
          </section>
        </div>

      </>
    )
  }
}
const mapStateToProps = (state: IRootState) => ({
  core: state.core,
});
export default withTranslation()(ChooseLocationPage);