import { ISearchBrand, ISearchGetYear, ISearchGetModel, ISearchModel, ISearchGetTires, ISearchTireCache, ISearchGetSize, ISearchSize, ISearchGetTireSize, ISearchSetTireSize, ISearchGetRetailerOrderDetail, ISearchSetRetailerOrderDetail, ISearchGetInstallCenter, ISearchInstallCenter } from "../models/ISearchState";
import { IBrandState } from "../pages/Search/BrandPage/BrandPage";



export enum ActionTypes {
    GET_BRAND = 'search/GET_BRAND',
    SET_BRAND = 'search/SET_BRAND',
    GET_YEAR = 'search/GET_YEAR',
    SET_YEAR = 'search/SET_YEAR',
    GET_MODEL = 'search/GET_MODEL',
    SET_MODEL = 'search/SET_MODEL',
    GET_TIRES = 'search/GET_TIRES',
    SET_TIRES = 'search/SET_TIRES',
    GET_SIZE = 'search/GET_SIZE',
    SET_SIZE = 'search/SET_SIZE',
    GET_TIRE_SIZE = 'search/GET_TIRE_SIZE',
    SET_TIRE_SIZE = 'search/SET_TIRE_SIZE',
    GET_RETAILER_ORDER_DETAIL = 'search/GET_RETAILER_ORDER_DETAIL',
    SET_RETAILER_ORDER_DETAIL = 'search/SET_RETAILER_ORDER_DETAIL',
    GET_INSTALL_CENTER = 'search/GET_INSTALL_CENTER',
    SET_INSTALL_CENTER = 'search/SET_INSTALL_CENTER',
    GET_ORDER_DETAIL = 'search/GET_ORDER_DETAIL',
    SET_ORDER_DETAIL = 'search/SET_ORDER_DETAIL',
    SET_VEHICLE_PAGE_STATE = 'search/SET_VEHICLE_PAGE_STATE',
}

export interface IActionSearchSetVehicleState {
    type: ActionTypes.SET_VEHICLE_PAGE_STATE;
    payload: IBrandState
}

export interface IActionGetInstallCenter {
    type: ActionTypes.GET_INSTALL_CENTER;
    payload: ISearchGetInstallCenter
}

export interface IActionSetInstallCenter {
    type: ActionTypes.SET_INSTALL_CENTER;
    payload: ISearchInstallCenter[]
}

export interface IActionGetOrderDetail {
    type: ActionTypes.GET_ORDER_DETAIL;
    payload: ISearchGetRetailerOrderDetail
}

export interface IActionSetOrderDetail {
    type: ActionTypes.SET_ORDER_DETAIL;
    payload: ISearchSetRetailerOrderDetail
}

export interface IActionGetRetailerDetail {
    type: ActionTypes.GET_RETAILER_ORDER_DETAIL;
    payload: ISearchGetRetailerOrderDetail
}

export interface IActionSetRetailerDetail {
    type: ActionTypes.SET_RETAILER_ORDER_DETAIL;
    payload: ISearchSetRetailerOrderDetail
}

export interface IActionGetTireSize {
    type: ActionTypes.GET_TIRE_SIZE;
    payload: ISearchGetTireSize
}

export interface IActionSetTireSize {
    type: ActionTypes.SET_TIRE_SIZE;
    payload: ISearchSetTireSize
}

export interface IActionGetSize {
    type: ActionTypes.GET_SIZE;
    payload: ISearchGetSize;
}

export interface IActionSetSize {
    type: ActionTypes.SET_SIZE;
    payload: ISearchSize[];
}

export interface IActionGetTires {
    type: ActionTypes.GET_TIRES;
    payload: ISearchGetTires;
}

export interface IActionSetTires {
    type: ActionTypes.SET_TIRES;
    payload: ISearchTireCache[]
}

export interface IActionGetModel {
    type: ActionTypes.GET_MODEL;
    payload: ISearchGetModel;
}

export interface IActionSetModel {
    type: ActionTypes.SET_MODEL;
    payload: ISearchModel[];
}

export interface IActionSetYear {
    type: ActionTypes.SET_YEAR;
    payload: number[];
}

export interface IActionGetYear {
    type: ActionTypes.GET_YEAR;
    payload: ISearchGetYear;
}

export interface IActionGetBrand {
    type: ActionTypes.GET_BRAND;
}

export interface IActionSetBrand {
    type: ActionTypes.SET_BRAND;
    payload: ISearchBrand[];
}

/**
 * @param  {ISearchGetYear} payload
 * @returns IActionGetYear
 */
export function getYear (payload: ISearchGetYear): IActionGetYear {
    return {
        payload,
        type: ActionTypes.GET_YEAR,
    };
}
/**
 * @param  {number[]} payload
 * @returns IActionSetYear
 */
export function setYear (payload: number[]): IActionSetYear {
    return {
        payload,
        type: ActionTypes.SET_YEAR,
    };
}

/**
 * @returns IActionGetBrand
 */
export function getBrand (): IActionGetBrand {
    return {
      type: ActionTypes.GET_BRAND,
    };
}
/**
 * @param  {ISearchBrand[]} data
 * @returns IActionSetBrand
 */
export function setBrand (data: ISearchBrand[]): IActionSetBrand {
    return {
      payload: data,
      type: ActionTypes.SET_BRAND,
    };
}

/**
 * @param  {ISearchGetModel} data
 * @returns IActionGetModel
 */
export function getModel (data: ISearchGetModel): IActionGetModel {
    return {
      payload: data,
      type: ActionTypes.GET_MODEL,
    };
}

/**
 * @param  {ISearchModel[]} data
 * @returns IActionSetModel
 */
export function setModel (data: ISearchModel[]): IActionSetModel {
    return {
      payload: data,
      type: ActionTypes.SET_MODEL,
    };
}

/**
 * @param  {ISearchTireCache[]} data
 * @returns IActionSetTires
 */
export function setTires (data: ISearchTireCache[]): IActionSetTires {
    return {
      payload: data,
      type: ActionTypes.SET_TIRES,
    };
}
  
export function getTires (data: ISearchGetTires): IActionGetTires {
    return {
      payload: data,
      type: ActionTypes.GET_TIRES,
    };
}

/**
 * @param  {ISearchGetSize} data
 * @returns IActionGetSize
 */
export function getSize (data: ISearchGetSize): IActionGetSize {
    return {
      payload: data,
      type: ActionTypes.GET_SIZE,
    };
}

/**
 * @param  {ISearchSize[]} data
 * @returns IActionSetSize
 */
export function setSize (data: ISearchSize[]): IActionSetSize {
    return {
      payload: data,
      type: ActionTypes.SET_SIZE,
    };
}

export function getTireSize (payload: ISearchGetTireSize): IActionGetTireSize {
    return {
        payload,
        type: ActionTypes.GET_TIRE_SIZE,
    };
}

export function setTireSize (payload: ISearchSetTireSize): IActionSetTireSize {
    return {
        payload,
        type: ActionTypes.SET_TIRE_SIZE,
    };
}
  
/**
 * @param  {ISearchGetRetailerOrderDetail} payload
 * @returns IActionGetRetailerDetail
 */
export function getRetailerOrderDetail (payload: ISearchGetRetailerOrderDetail): IActionGetRetailerDetail {
    return {
        payload,
        type: ActionTypes.GET_RETAILER_ORDER_DETAIL,
    };
}

/**
 * @param  {ISearchGetRetailerOrderDetail} payload
 * @returns IActionGetOrderDetail
 */
export function getOrderDetail (payload: ISearchGetRetailerOrderDetail): IActionGetOrderDetail {
    return {
        payload,
        type: ActionTypes.GET_ORDER_DETAIL,
    };
}

/**
 * @param  {ISearchSetRetailerOrderDetail} payload
 * @returns IActionSetOrderDetail
 */
export function setOrderDetail (payload: ISearchSetRetailerOrderDetail): IActionSetOrderDetail {
    return {
        payload,
        type: ActionTypes.SET_ORDER_DETAIL,
    };
}

/**
 * @param  {ISearchSetRetailerOrderDetail} payload
 * @returns IActionSetRetailerDetail
 */
export function setRetailerOrderDetail (payload: ISearchSetRetailerOrderDetail): IActionSetRetailerDetail {
    return {
        payload,
        type: ActionTypes.SET_RETAILER_ORDER_DETAIL,
    };
}

/**
 * @param  {IGetInstallCenter} payload
 * @returns IActionGetInstallCenter
 */
export function getInstallCenter (payload: ISearchGetInstallCenter): IActionGetInstallCenter {
    return {
        payload,
        type: ActionTypes.GET_INSTALL_CENTER,
    };
}

/**
 * @param  {ISearchInstallCenter[]} payload
 * @returns IActionSetInstallCenter
 */
export function setInstallCenter (payload: ISearchInstallCenter[]): IActionSetInstallCenter {
    return {
        payload,
        type: ActionTypes.SET_INSTALL_CENTER,
    };
}

export function setVehicleState (payload: IBrandState): IActionSearchSetVehicleState {
    return {
        payload,
        type: ActionTypes.SET_VEHICLE_PAGE_STATE,
    };
}


export type Action =
IActionGetBrand
| IActionSetBrand
| IActionGetYear
| IActionSetYear
| IActionGetModel
| IActionSetModel
| IActionGetTires
| IActionSetTires
| IActionGetSize
| IActionSetSize
| IActionGetTireSize
| IActionSetTireSize
| IActionGetRetailerDetail
| IActionSetRetailerDetail
| IActionGetInstallCenter
| IActionSetInstallCenter
| IActionGetOrderDetail
| IActionSetOrderDetail
| IActionSearchSetVehicleState
