import React from 'react';

import { images } from '../../../constants/Images';
import IRootState, { ISearchState } from '../../../models';
import { connect } from 'react-redux';
import { Header, BrandCard, Years } from '../Components';
import { search as searcActions } from '../../../actions'
import { ISearchGetYear } from '../../../models/ISearchState';
import { withRouter } from "react-router";

interface IProps {
  getYear: (param: ISearchGetYear) => void;
  search: ISearchState
  location: { state: { id: number } };
  history: any;
}

interface IState {
  selectedYear: number
}

class YearPage extends React.Component<IProps, IState> {

    state: IState =  {
      selectedYear: 0
    }

    componentDidMount = () => {
      const { getYear, location } = this.props;
      getYear({ brand_id: location.state.id });
    }

    renderItem = () => {
      // const { search } = this.props;
      // const { selectedYear } = this.state
      // return search.years.map((row, index) => {
      //   return <Years  items={row} key={index} onClick={this.onSelect} />
      // })
    }

    onSelect = (id: number) => {
      this.setState({ selectedYear: id })
    }

    render() {
      return null
    
    }
}


  const mapStateToProps = (state: IRootState) => ({
    search: state.search,
  });
 
  const mapDispatchToProps = {
    getYear: searcActions.getYear
  }
 
 
 
 export default withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(YearPage))