import { IUserLogin, IWareHouseLogin, IAuthForgotPassword, IAuthResetPassword } from "../models/IAuthState";


export enum ActionTypes {
  LOGIN = 'auth/LOGIN',
  LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS',
  SET_OTP = 'auth/SET_OTP',
  LOGIN_FAIELD = 'auth/LOGIN_FAIELD',
  RESEND_OTP = 'auth/RESEND_OTP',
  WEREHOUSE_LOGIN = 'auth/WEREHOUSE_LOGIN',
  LOGOUT = 'auth/LOGOUT',
  FORGOT_PASSWORD = 'auth/FORGOT_PASSWORD',
  RESET_PASSWORD = 'auth/RESET_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = 'auth/FORGOT_PASSWORD_SUCCESS',
  RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS',
}


export interface IActionAuthForgotPasswordSuccess {
  type: ActionTypes.FORGOT_PASSWORD_SUCCESS;
  payload: string;
}

export interface IActionAuthResetPasswordSuccess {
  type: ActionTypes.RESET_PASSWORD_SUCCESS;
  payload: boolean;
}

export interface IActionAuthForgotPassword {
  type: ActionTypes.FORGOT_PASSWORD;
  payload: IAuthForgotPassword;
}

export interface IActionAuthResetPassword {
  type: ActionTypes.RESET_PASSWORD;
  payload: IAuthResetPassword;
}

export interface IActionAuthLogout {
  type: ActionTypes.LOGOUT;
}

export interface IActionWareHouseLogin {
  type: ActionTypes.WEREHOUSE_LOGIN;
  payload: IWareHouseLogin;
}

export interface IActionResendOTP {
  type: ActionTypes.RESEND_OTP;
  payload: IUserLogin;
}

export interface IActionSetOTP {
  type: ActionTypes.SET_OTP;
  payload: string;
}

export interface IActionLoginFailed {
  type: ActionTypes.LOGIN_FAIELD;
}

export interface IActionLogin {
  type: ActionTypes.LOGIN;
  payload: IUserLogin;
}

export interface IActionLoginSuccess {
  type: ActionTypes.LOGIN_SUCCESS;
}


export function login(data: IUserLogin): IActionLogin {
  return {
    payload: data,
    type: ActionTypes.LOGIN,
  };
}

export function loginSuccess(): IActionLoginSuccess {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
  };
}

export function setOTP(payload: string): IActionSetOTP {
  return {
    payload,
    type: ActionTypes.SET_OTP,
  };
}

export function loginFailed(): IActionLoginFailed {
  return {
    type: ActionTypes.LOGIN_FAIELD,
  };
}

export function resendOTP(data: IUserLogin): IActionResendOTP {
  return {
    payload: data,
    type: ActionTypes.RESEND_OTP,
  };
}

export function wareHouseLogin(data: IWareHouseLogin): IActionWareHouseLogin {
  return {
    payload: data,
    type: ActionTypes.WEREHOUSE_LOGIN,
  };
}

export function logout(): IActionAuthLogout {
  return {
    type: ActionTypes.LOGOUT,
  };
}

export function forgotPassword(data: IAuthForgotPassword): IActionAuthForgotPassword {
  return {
    payload: data,
    type: ActionTypes.FORGOT_PASSWORD,
  };
}

export function resetPassword(data: IAuthResetPassword): IActionAuthResetPassword {
  return {
    payload: data,
    type: ActionTypes.RESET_PASSWORD,
  };
}

export function forgotPasswordSuccess(data: string): IActionAuthForgotPasswordSuccess {
  return {
    payload: data,
    type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
  };
}

export function resetPasswordSuccess(data: boolean): IActionAuthResetPasswordSuccess {
  return {
    payload: data,
    type: ActionTypes.RESET_PASSWORD_SUCCESS,
  };
}


export type Action =
  IActionLogin
  | IActionLoginSuccess
  | IActionSetOTP
  | IActionLoginFailed
  | IActionResendOTP
  | IActionWareHouseLogin
  | IActionAuthLogout
  | IActionAuthForgotPassword
  | IActionAuthResetPassword
  | IActionAuthForgotPasswordSuccess
  | IActionAuthResetPasswordSuccess
