import React from 'react';
import './Location.css'
import { images } from '../../constants/Images';
import { ICoreCities } from '../../models/ICoreState';
import { Alert } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../services';
import ReactHtmlParser from 'react-html-parser';
import { Filter } from '../../utils';

interface IProps {
   onClose: () => void;
   show: boolean
   cities: ICoreCities[]
   selectCity: (param: ICoreCities) => void;
   selectedCity: ICoreCities;
   matchedLocation: boolean
}

interface IState {
   showAlert: boolean
}

class Location extends React.Component<IProps, IState> {
      state = {
         showAlert: true
      }
      
     renderCities = () => {
        const { cities, selectCity, selectedCity } = this.props;
        return cities.map((item) => {
           return (
           <li className={ selectedCity.id === item.id ? 'active' : ''}> 
              <a 
                  href="javascript:void(0);" 
                  className="ripplelink"
                  onClick={() => selectCity(item)}
               >
                  {Filter.translate(item.city, item.city_arabic)}
               </a> 
            </li>
           )
        })
     } 

    render() {
       const { onClose, show, matchedLocation } = this.props;
       const { showAlert } = this.state;
       console.log('show', show);
       
       if (!show) {
          return null;
       }
      return (
         <>
         <div className="custom-modal countrymodal openit"> 
         <div className="container">
            <div className="popup-content">
               {/* <button type="button" className="close btn ripplelink" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                  close<span aria-hidden="true"> × </span>
               </button> */}
              <div className="content-header">
               <img src={images.yellowLogo} />
              </div>
              {/* {
                ( !matchedLocation && showAlert) && (
                    <Alert variant={'warning'} dismissible onClose={() => this.setState({ showAlert: false })}>
                       Your current city did not match with our existing cities, Please select below one 
                    </Alert>
                 )
              } */}
               <div className="content-body">
                  <div className="outertitle">
                     <div className="innertitle">
                        <span className="icon-16"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span> 
                        <h3> 
                           {i18n.t(`location.modal.title`)}
                           <span>
                              {ReactHtmlParser(i18n.t(`location.modal.description`))}
                           </span>
                        </h3>
   
                     </div>
   
                  </div>
                  {/* <div className="form-group locationgroup">
                     <p> Location
                     <a className="nav-link pl-0" href="javascript:void:(0);" data-toggle="modal" data-target="#top_modal"><img src="img/english-flag.png" />Eng <img src="img/arrow-drop.png" className="drop-arrow" alt="" /></a>
                     </p>
                     <input type="text" className="form-control" placeholder="Enter your Location" />
                     
                  </div> */}
                  <div className="trandinglist">
                     <h3>{i18n.t(`location.modal.subtitle`)}</h3>
                     <ul>
                        {this.renderCities()}
                        {/* <li> <a href="javascript:void(0);" className="ripplelink"> Medina</a> </li>
                        <li> <a href="javascript:void(0);" className="ripplelink"><span className="ink animate" style={{ height: '110.984px', width: '110.984px', top: '-46.4845px', left: '-50.8439px' }}></span> Meeca </a> </li>
                        <li className="active"> <a href="javascript:void(0);" className="ripplelink">Riyadh</a> </li>
                        <li> <a href="javascript:void(0);" className="ripplelink">Jeddah</a> </li>
                        <li> <a href="javascript:void(0);" className="ripplelink">Dammam</a> </li>
                        <li> <a href="javascript:void(0);" className="ripplelink">Khamis Mushait</a> </li>
                        <li> <a href="javascript:void(0);" className="ripplelink">Tabuk</a> </li> */}
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>   
      <div className="modal-backdrop show"></div>          
      </>
      )
    }
}

export default withTranslation()(Location)