import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configStore';
import { PersistGate } from 'redux-persist/integration/react';
import { HomePage } from './pages/Home';
import './styles/theme.min.css';
import './styles/App.css';
import './styles/filter.css';
import './styles/slick.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Navigator from './navigation/Navigator';
import { HomeLoader, Notification } from './components';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
// @ts-ignore-start
import AlertTemplate from 'react-alert-template-basic'
import { i18n } from './services';
// @ts-ignore-end
import {I18nextProvider} from "react-i18next";
const {persister, store} = configureStore();

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 10000,
  offset: '25px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 9999,
    width: '100%'
  }
}
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<HomeLoader/>} persistor={persister}>
        <AlertProvider template={AlertTemplate} {...options}>
          <Navigator/>
          <Notification/>
        </AlertProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);