import React from 'react';
import { images } from '../../../constants/Images';
import IRootState, { ICartState, IUserState, IShopState } from '../../../models';
import { connect } from 'react-redux';
import { shop as shopActions } from '../../../actions'
import { Container, ShopCard } from '../Components';
import config from '../../../constants/config';
import { IShopCache } from '../../../models/IShopState';
import { withTranslation } from 'react-i18next';

interface IProps {
   shopGet: () => void;
   cart: ICartState;
   user: IUserState;
   isLoggedin: boolean;
   shop: IShopState
   }

interface IState {
  
}

class ShopPage extends React.Component<IProps> {

   componentDidMount () {
      const { shopGet } = this.props;
      shopGet();
   }

   renderShop = () => {
      const { shop } = this.props;
      return shop.records.ids.map(( id: string, index: number ) => {
         const item: IShopCache = shop.records.cache[id];
         return (
            <ShopCard items={item}  key={index} />
         )
      })
   }


    render() {
      const { user } = this.props;
      
      return (
         <Container>
            <section id="shop" key={'shop'}>
               <div className="main-content">
                  <div className="shop-main">
                     <div className="row">
                        {/* <div className="shop-create belong">
                           <div className="pulse-icon"><img src={images.pulse} /></div>
                           <div className="shop-item">
                              <h3>Create Shop</h3>
                              <span>Belongs to me</span>
                           </div>
                        </div> */}
                        {this.renderShop()}
                     </div>
                  </div>
               </div>
            </section>
         </Container>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  user: state.user,
  shop: state.shop
});

const mapDispatchToProps = {
   shopGet: shopActions.shopGet
}



export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)<any>(ShopPage)) 