import React, { createRef } from 'react';
import { images } from '../../constants/Images';
import { Link } from 'react-router-dom';
import { validation } from '../../services';
import { IUserLogin } from '../../models/IAuthState';
import config from '../../constants/config';
import './LoginModal.css'
import AlertBox from '../AlertBox';
import { PATH } from '../../navigation/NavigationPath';
import i18n from 'i18next';

interface IProps {
   children?: any
   onToggel: () => void
   show: boolean
   loading: boolean
   onLogin: (param: IUserLogin) => void;
   otp: string
   onVerify: () => void;
   sendingOTP: boolean;
   resendOTP: (param: IUserLogin) => void;
}
interface IState {
   toggel: boolean
   formData: { fields: IUserLogin, required: string[] }
   validation: any
   otp: string[]
   isVerify: boolean
}

export default class LoginModal extends React.PureComponent<IProps, IState> {
   firstTextInput: any
   secondTextInput: any
   thirdTextInput: any
   fourthTextInput: any

   state = {
      toggel: false,
      formData: {
         fields: {
            primary_mobile: '',
            device_id: 123456,
            device_type: 'website',
            city: 9,
            access_token: 123456
         },
         required: ['primary_mobile']
      },
      validation: {},
      otp: ["", "", "", ""],
      isVerify: true
   }

   onToggel = () => {
      const { toggel } = this.state;
      this.setState({ toggel: !toggel })
   }

   handleChange = (e: any, field: string) => {
      const { formData } = this.state;
      //console.log('field', field);
      this.setState({ formData: { ...formData, fields: { ...formData.fields, [field]: e.target.value.replace(/\D/g, '') } } })
   }

   onLogin = () => {
      const { formData } = this.state;
      //console.log('formData', formData);
      const validate = validation.isValidate(formData)
      this.setState({ validation: validate })
      const mobile = `${config.defaultPhoneCode}-${Number(formData.fields.primary_mobile)}`
      if (!Object.keys(validate).length) {
         this.props.onLogin({ ...formData.fields, primary_mobile: mobile })
      }
   }

   onResendOTP = () => {
      const { formData } = this.state;
      const { resendOTP } = this.props
      const primary_mobile = `${config.defaultPhoneCode}-${formData.fields.primary_mobile.replace(/[0]/g, '')}`
      resendOTP({ ...formData.fields, primary_mobile })
   }

   onVerify = () => {
      const { otp, onVerify } = this.props;
      //console.log('this.state.otp', this.state.otp.join(""));
      //console.log('otp', otp);
      if (otp === this.state.otp.join("")) {
         this.setState({ isVerify: true })
         onVerify()
      } else {
         this.setState({ isVerify: false })
      }

   }

   updateOTP = (index: number, e: any) => {
      const { otp } = this.state;
      const reg = /^\d+$/;


      const spliceOTP = [...otp]
      const inputArr = [
         this.secondTextInput,
         this.thirdTextInput,
         this.fourthTextInput,
         this.firstTextInput,
      ]
      spliceOTP.splice(index, 1, reg.test(e.target.value) ? e.target.value.substr(e.target.value.length - 1) : "")
      //console.log('spliceOTP', spliceOTP);

      if (index < 3) {
         if (spliceOTP[index])
            inputArr[index].focus();
      } else {
         this.fourthTextInput.blur()
      }
      // @ts-ignore-start
      this.setState({ otp: spliceOTP, isVerify: true })
   }

   onClose = () => {
      const { onToggel } = this.props;
      const { formData } = this.state;
      onToggel()
      this.setState({ formData: { ...formData, fields: { ...formData.fields, primary_mobile: '' } }, otp: ["", "", "", ""], isVerify: true })
   }

   render() {
      const { onToggel, show, loading, otp, sendingOTP, resendOTP } = this.props
      const { formData, isVerify } = this.state;
      // if (!show) {
      //    return null
      // }
      //console.log('validation', this.state.otp);

      return (
         <>
            <div className={`modal right fade tire-details ${show ? 'show' : ''} singup-modal`} id="right_modal_sm" tabIndex={-1} role="dialog" aria-labelledby="right_modal_sm" style={{ display: `${show ? 'block' : 'none'}`, paddingRight: '17px' }} aria-modal="true">
               <div className="modal-dialog" role="document">
                  <div className="modal-content">
                     <div className="modal-header">
                        <button type="button" className="close btn ripplelink" data-dismiss="modal" aria-label="Close" onClick={this.onClose}>
                           {i18n.t('common.button.close')}<span aria-hidden="true"> × </span>
                        </button>
                     </div>
                     <div className="modal-body">
                        <div className="main-heading">
                           {
                              !otp ? (
                                 <h2>{i18n.t('Please_login')} </h2>
                              ) : (
                                    <div className="singup_pagecontent">
                                       <h2>{i18n.t('Verification_Code')} </h2>
                                       <p>{i18n.t('Please_enter_the_code_send_to_your_mobile_number')}</p>
                                    </div>
                                 )}

                        </div>

                        <div className="singup_content">
                           {
                              !otp ? (
                                 <>
                                    <AlertBox message={validation.renderError('primary_mobile', this.state.validation)} show={validation.renderError('primary_mobile', this.state.validation)} type={'danger'} />
                                    <div className="form-control">

                                       <div className="select_numberwrap">
                                          <select>
                                             <option>{config.defaultPhoneCode}</option>
                                          </select>
                                          <input type="tel" name="phone" placeholder="5XXXXXXXX" onChange={(value: any) => this.handleChange(value, 'primary_mobile')} className={'inputMobile'} value={!formData.fields.primary_mobile ? "" : formData.fields.primary_mobile} />
                                       </div>
                                    </div>
                                    {/* <span className='error-input'>{validation.renderError('primary_mobile', this.state.validation)}</span> */}
                                    <div className="singup_btn">
                                       <button className="btn btn-default login_btn" onClick={this.onLogin} disabled={loading}>
                                          {i18n.t('Proceed')}
                                          {
                                             loading && (
                                                <img src={images.loader} style={{ height: 30, width: 30 }} />
                                             )
                                          }
                                       </button>
                                    </div>
                                    <p className="clickhere">{i18n.t('If_you_are_a_vendor')} <Link to={PATH.LOGIN}>{i18n.t('Click_here')}</Link></p>
                                 </>
                              ) : (
                                    <>
                                       <AlertBox message={'Invailid OTP'} show={!isVerify} type={'danger'} />
                                       <div className="otp_wrap">
                                          <input className="partitioned" value={this.state.otp[0]} type="tel" maxLength={1} onChange={(e: any) => this.updateOTP(0, e)} ref={(input) => { this.firstTextInput = input; }} blur-on-submit={false} />
                                          <input className="partitioned" value={this.state.otp[1]} type="tel" maxLength={1} onChange={(e: any) => this.updateOTP(1, e)} ref={(input) => { this.secondTextInput = input; }} blur-on-submit={false} />
                                          <input className="partitioned" value={this.state.otp[2]} type="tel" maxLength={1} onChange={(e: any) => this.updateOTP(2, e)} ref={(input) => { this.thirdTextInput = input; }} blur-on-submit={false} />
                                          <input className="partitioned" value={this.state.otp[3]} type="tel" maxLength={1} onChange={(e: any) => this.updateOTP(3, e)} ref={(input) => { this.fourthTextInput = input; }} blur-on-submit={false} />
                                       </div>

                                       <div className="singup_btn">
                                          <button className="btn btn-default login_btn" onClick={this.onVerify}>{i18n.t('Verify_Number')}</button>
                                       </div>
                                       {
                                          sendingOTP ? (<p className="clickhere">{i18n.t(`login.client.otp_sending`)}</p>) : <p className="clickhere">{i18n.t('I_havent_received_a_code')} ! <a href="javascript:void(0);" className="resend_code" onClick={this.onResendOTP}>{i18n.t('Resend_Code')}</a></p>
                                       }

                                    </>
                                 )
                           }

                        </div>

                     </div>

                  </div>
               </div>
            </div>
            {
               show && (
                  <div className="modal-backdrop show"></div>
               )
            }
         </>
      )
   }
}