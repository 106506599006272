import React from 'react';
import { images } from '../../../constants/Images';
import IRootState, { ICartState, IUserState, IWDState, IShopState } from '../../../models';
import { connect } from 'react-redux';
import { inventory as inventoryActions, shop as shopActions } from '../../../actions'
import { Container, FilterModal } from '../Components';
import config from '../../../constants/config';
import IInventoryState, { IInventoryGet, IInventoryAct, IInventoryAdd, IInventoryEdit, IInventoryGetFilter, IInventoryExport, IInventoryBulkUpload } from '../../../models/IInventoryState';
import { Filter } from '../../../utils';
import { ModalRight } from '../Components';
import { Pagination, Input, AlertModal } from '../../../components';
import { withRouter } from 'react-router-dom';
import { validation, api, i18n } from '../../../services';
import './InventoryPage.css'
import { CSVLink, CSVDownload } from "react-csv";
import  moment from "moment"
import { withTranslation } from 'react-i18next';
import { ISearchGetTireSize, ISearchGetTires } from '../../../models/ISearchState';
import { Alert, Modal, Button } from 'react-bootstrap';
// @ts-ignore-start
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface IProps {
  user: IUserState;
  getInventory: (param: IInventoryGet) => void;
  inventory: IInventoryState
  location: any;
  deleteInventory: (param: IInventoryAct) => void
  wd: IWDState;
  addInventory: (param: IInventoryAdd) => void;
  editInventory: (param: IInventoryEdit) => void;
  bulkUpload: (param: IInventoryBulkUpload) => void;
  getTireSize: (param: ISearchGetTireSize) => void;
  shop: IShopState
  shopGet: () => void;
}

interface IFormFields {
   runflat: string
   year: string
   madein: string
   f_size?: string
   s_size?: string
   t_size?: string
   price: string
   quantity: string
   brand_id?: string
   warehouse_user_id: string;
   code?: string;
   type: 'Normal' | 'Basic',
   inventory_id: '',
   
}

interface IState {
  toggelModal: boolean
  page: number;
  formData: {
      fields: IFormFields,
      required: string[]
   },
   errors: any;
   currentRecord: number;
   toggelFilter:  boolean;
   filterData: IInventoryGetFilter;
   exportData: IInventoryExport[];
   showSuccess: boolean;
   isShowAlertModal: boolean;
   actionParam: IInventoryAct
}


class InventoryPage extends React.Component<IProps> {
   upload: any;
   state: IState = {
      toggelModal: false,
      page: 1,
      formData: {
         fields: {
            runflat: '0',
            year: '',
            madein: '',
            price: '',
            quantity: '',
            type: this.props.user.profile.warehouse_type,
            warehouse_user_id: this.props.user.profile.id,
            ...this.props.user.profile.warehouse_type === 'Basic' ? {
               f_size: '',
               s_size: '',
               t_size: '',
               brand_id: '',
            } : { code: '', },
            inventory_id: ''
         },
         required: [
            'year', 'price', 'quantity',
            ...this.props.user.profile.warehouse_type === 'Basic' ? ['f_size', 's_size', 't_size', 'brand_id'] : ['code']
         ]
      },
      errors: {},
      currentRecord: 0,
      toggelFilter: false,
      filterData: {
         f_size: '',
         s_size: '',
         t_size: '',
         year: '',
         runflat: '',
         quantity: '',
         madein: '',
         price: '',
         brand_id: '',
         ...this.props.user.profile.warehouse_type === 'Normal' && {
            code: '',
            speedindex: '',
            loadindex: '',
            model_no: '',
         }
      },
      exportData: [],
      showSuccess: false,
      isShowAlertModal: false,
      actionParam: { inventory_id: '', type: '' }
   }

   componentDidMount() {
      const { shopGet } = this.props;
      this.getInventory()
      this.getAllInventory()
      this.getTireSize({ type: 'f' })
      shopGet();
   }

   getTireSize = (param: ISearchGetTireSize) => {
      const { getTireSize } = this.props;
      getTireSize(param)
   }

   getAllInventory = async () => {
      const { user } = this.props;
      const { data = [] } = await api.inventory.getAll({
          [`${user.type === 'dealer' ? 'user_id' : 'warehouse_user_id'}`]: user.profile.id, 
          type:  user.type === 'dealer' ? 'Dealer' : user.profile.warehouse_type,
         })
      this.setState({ exportData: data })
   }

   getInventory = (page: number = 1) => {
      const { getInventory, user } = this.props;
      const { filterData } = this.state;
      getInventory({ 
         ...user.type === 'warehouse' ? { 
            warehouse_user_id: user.profile.id,
            type: user.profile.warehouse_type
         } : {
            user_id: user.profile.id,
            type: 'Dealer'
         },
         page,
         ...filterData
         
      })
      this.setState({ page })
   }

   onToggelFilter = () => {
      const { toggelFilter } = this.state;
      this.setState({ toggelFilter: !toggelFilter })
   }

   onSubmit = () => {
      const { formData } = this.state;
      const { addInventory, editInventory } = this.props;
      const validate = validation.isValidate(formData)
      this.setState({ errors: validate })
      console.log('onSubmit', formData);
      
      if (!Object.keys(validate).length ) {
         if (formData.fields.inventory_id) {
            editInventory(formData.fields)
         } else {
            addInventory(formData.fields)
         }
         
      }
   }

   renderOption = (data: any[], type: string) => {
      const { formData } = this.state;
      const fields: Record<any, any> = formData.fields
      return data.map(( row: any, index: number ) => {
         const selected = type === 'brand_id' ? row.id === Number(formData.fields.brand_id) : ['f_size',
         's_size','t_size'].indexOf(type) >= 0 ? row === fields[type] : row.code === formData.fields.code
         return (
            <option value={['f_size','s_size','t_size'].indexOf(type) >= 0  ? row : row.id} selected={selected} key={index}>
               {
                  type=== 'brand_id' ? Filter.translate(row.brand, row.brand_arabic) : ['f_size','s_size','t_size'].indexOf(type) >= 0 ? row : row.code
               }
            </option>
         )
      })
      
   }

   renderInput = () => {
      const { user, wd, inventory } = this.props;
      const { formData, errors } = this.state;
      const inputArr: {field: string, label: string, type: string}[] = [
         
         ...user.profile.warehouse_type === 'Basic' ?
         [
            {field: 'brand_id', label: i18n.t(`wd.inventory.input.label.brand`), type: 'select'},
            {field: 'f_size', label: i18n.t(`wd.inventory.input.label.width`), type: 'select'},
            {field: 's_size', label: i18n.t(`wd.inventory.input.label.aspect`), type: 'select'},
            {field: 't_size', label: i18n.t(`wd.inventory.input.label.diameter`), type: 'select'},
            {field: 'runflat', label: i18n.t(`wd.inventory.input.label.runflat`), type: 'checkbox'},
            {field: 'madein', label: i18n.t(`wd.inventory.input.label.madein`), type: 'text'},
            {field: 'year', label: i18n.t(`wd.inventory.input.label.year`), type: 'number'},
            {field: 'quantity', label: i18n.t(`wd.inventory.input.label.quantity`) , type: 'number'},
            {field: 'price', label: i18n.t(`wd.inventory.input.label.price`) , type: 'number'}, 
         ]
           : 
         [
            {field: 'code', label: i18n.t(`wd.inventory.input.label.code`), type: 'select'},
            {field: 'madein', label: i18n.t(`wd.inventory.input.label.madein`), type: 'text'},
            {field: 'year', label: i18n.t(`wd.inventory.input.label.year`), type: 'number'}, 
            {field: 'price', label: i18n.t(`wd.inventory.input.label.price`) , type: 'number'},
            {field: 'quantity', label: i18n.t(`wd.inventory.input.label.quantity`) , type: 'number'},
            {field: 'runflat', label: i18n.t(`wd.inventory.input.label.runflat`), type: 'checkbox'},
         ],
         
      ]
      const selectInput = ['code', 'brand_id', 'f_size', 's_size', 't_size']
      return inputArr.map((ele: {field: string, label: string, type: string}, index: number) => {
         if (selectInput.indexOf(ele.field) >= 0) {
            const selectTypeData: Record<string, any[]> = {
               brand_id: wd.brand,
               code: wd.tireCode,
               f_size: inventory.tireSize.f,
               s_size: inventory.tireSize.s || [],
               t_size: inventory.tireSize.t || [],
            }
            console.log('selectTypeData[ele.field]', ele.field);
           
            return (
               <div className={`fields ${['brand_id', 'code'].indexOf(ele.field) >= 0  ? 'col-md-12' : 'col-md-3'}`}>
               <label>{ele.label}
               {
                  formData.required.indexOf(ele.field) >= 0 && (<sup>*</sup>)
               }
               
               </label>
               <span className="select-wrapper">
                  <select className="custom-select" onChange={(e: any) => this.handleChange(e, ele.field)}>
                     <option value={''}>{ ele.label}</option>
                     { this.renderOption(selectTypeData[ele.field], ele.field) }
                  </select>
               </span>
               <span className={'error-input'}>{validation.renderError(ele.field, errors)}</span>
            </div>
            )
         }

         if (ele.type === 'checkbox') {
            return (
               <div className="fields col-md-3"> 
               <label className="check-label">{ele.label}
               <input 
                  type={ele.type} 
                  className="form-control mb-2 mr-sm-2" 
                  placeholder={ele.label} 
                  onChange={(e: any) => this.handleChange(e, ele.field)}
                  key={index}
                  // @ts-ignore-start 
                  value={formData.fields[ele.field]}
                  checked={formData.fields.runflat === '1'}
                  min={1}
               />
               <span className={'checkmark'}>
        
                  </span>
            
                  </label>    
           
               <span className={'error-input'}>{validation.renderError(ele.field, errors)}</span>
            </div>
            )
         }

         return (
            <>
               <div className="fields col-md-6"> 
                  <label>{ele.label}
                  {
                     formData.required.indexOf(ele.field) >= 0 && (<sup>*</sup>)
                  }
                  </label>    
                  <input 
                     type={ele.type} 
                     className="form-control mb-2 mr-sm-2" 
                     placeholder={ele.label} 
                     onChange={(e: any) => this.handleChange(e, ele.field)}
                     key={index}
                     // @ts-ignore-start 
                     value={formData.fields[ele.field]}
                     min={1}
                  />
                  <span className={'error-input'}>{validation.renderError(ele.field, errors)}</span>
               </div>
            </>
         )
      })
   }

   handleChange = (e: any, field: string) => {
      const { formData } = this.state;
      const value = field === 'runflat' ? `${Number(!Number(formData.fields.runflat))}` : e.target.value 
      // if (field === 'f_size' && formData.fields.f_size !== e.target.value) {
      //    this.setState({ formData: { ...formData, fields: { ...formData.fields, s_size: '', t_size: '' } } })
      // }
      console.log('value', value);
      
      this.setState({ formData: { ...formData, fields: { ...formData.fields, [field]: value } } }, () => {
         if (['f_size', 's_size'].indexOf(field) >= 0) {
            const type: Record<string, any> = {
               f_size: 's',
               s_size: 't'
            }
            console.log('type[field]', type[field]);
            
            this.getTireSize({ type: type[field], fsize: this.state.formData.fields.f_size, ssize: this.state.formData.fields.s_size })
         }
      })
  
    
    }

   

   renderItem = () => {
      const { inventory, user, wd } = this.props;
      console.log('inventory', wd);
      
      return inventory.records.ids.map((id: string, index: number) => {
         const items = inventory.records.cache[id];
         return (
            <>
               {
                  user.type === 'warehouse' ? 
                  <tr key={index}>
                      {user.profile.warehouse_type === 'Normal' && (<td>{items.typecode}</td>)}
               
               {/* <td>{user.profile.username}</td> */}
               <td>{Filter.translate(items.branddetails?.brand, items.branddetails?.brand_arabic)}</td>  
               {user.profile.warehouse_type === 'Normal' && (<td>{items.model_name}</td>)}
               <td>{items.f_size}</td>
               <td>{items.s_size}</td>
               <td>{items.t_size}</td>
               <td>{items.year}</td>
               {user.profile.warehouse_type === 'Normal' && (<td>{items.speedrating?.speed}</td>)}
               {user.profile.warehouse_type === 'Normal' && (<td>{items.loadrating?.loadindex}</td>)}
               <td>{items.runflat}</td>
               <td>{items.madein}</td>
               <td>{items.quantity}</td>
               <td>{items.price}</td>
               <td> <a href="javascript:void(0);" onClick={() => this.toggelAlertModal({ inventory_id: id, type: user.profile.warehouse_type })}> <img src={images.deleteIcon} /> </a>  
               <a href="javascript:void(0);" onClick={this.actions('edit', { inventory_id: id, type: user.profile.warehouse_type })}><img src={images.pencil} />  </a></td>
                  </tr> : 
                  <tr key={index}>
                     <td>{Filter.translate(items.branddetails?.brand, items.branddetails?.brand_arabic)}</td>   
                     <td>{items.vendor?.shop_name}</td>
                     <td>{Filter.translate(items.city?.city, items.city?.city_arabic)}</td>   
                     <td>{items.f_size}</td>
                     <td>{items.s_size}</td>
                     <td>{items.t_size}</td>
                     <td>{items.year}</td>
                     <td>{items.runflat}</td>
                     <td>{items.quantity}</td>
                     <td>{items.price}</td>
                  </tr>
               }
              
            </>
         )
      })
   }


   onToggelModal = () => {
      const { toggelModal, formData } = this.state;
      this.setState({ 
         toggelModal: !toggelModal, 
         showSuccess: false,
         formData: { 
            ...formData, 
            fields: {
               runflat: '0',
               year: '',
               madein: '',
               price: '',
               quantity: '',
               type: this.props.user.profile.warehouse_type,
               warehouse_user_id: this.props.user.profile.id,
               ...this.props.user.profile.warehouse_type === 'Basic' ? {
                  f_size: '',
                  s_size: '',
                  t_size: '',
                  brand_id: '',
               } : { code: '', },
            }
         } 
      })
   }

   onPaginate = (page: number) => {
      this.getInventory(page)  
   }

   actions = (type: 'edit' | 'delete', param: IInventoryAct) => () => {
      const { deleteInventory } = this.props;
      switch(type) {
         case 'delete' :
            deleteInventory(param)
            this.getAllInventory()
            this.toggelAlertModal()
            return
         case 'edit' :
            this.editInventory(param)
            return
         default :
            return null   
      }
   }

   static getDerivedStateFromProps(props: IProps, state: IState) {
      if (Number(props.inventory.records.totalRecords) > state.currentRecord || Number(props.inventory.records.totalRecords) !== state.currentRecord ) {
         return {
            ...state,
            currentRecord: Number(props.inventory.records.totalRecords),
            // toggelModal: false,
            showSuccess: true,
            ...!state.formData.fields.inventory_id && {
               formData: {
                  ...state.formData,
                  fields: {
                     runflat: '0',
                     year: '',
                     madein: '',
                     price: '',
                     quantity: '',
                     type: props.user.profile.warehouse_type,
                     warehouse_user_id: props.user.profile.id,
                     ...props.user.profile.warehouse_type === 'Basic' ? {
                        f_size: '',
                        s_size: '',
                        t_size: '',
                        brand_id: '',
                     } : { code: '', },
                     inventory_id: ''
                  }
               }
            }
         }
      }
   }

   componentDidUpdate(prevProps: IProps) {
      const { inventory } = this.props;
      const { currentRecord } = this.state;
      if (Number(inventory.records.totalRecords) > Number(prevProps.inventory.records.totalRecords) || Number(inventory.records.totalRecords) !== Number(prevProps.inventory.records.totalRecords)  ) {
        this.getAllInventory()
      }
    }

   editInventory = (param: IInventoryAct) => {
      const { inventory } = this.props
      const { formData } = this.state;
      const inventoryCache = inventory.records.cache[param.inventory_id] 
      const fields = {
         runflat: inventoryCache.runflat,
         year: inventoryCache.year,
         madein: inventoryCache.madein,
         price: inventoryCache.price,
         quantity: inventoryCache.quantity,
         type: this.props.user.profile.warehouse_type,
         warehouse_user_id: this.props.user.profile.id,
         ...this.props.user.profile.warehouse_type === 'Basic' ? {
            f_size: inventoryCache.f_size,
            s_size: inventoryCache.s_size,
            t_size: inventoryCache.t_size,
            brand_id: inventoryCache.branddetails.id,
         } : { code: inventoryCache.typecode, },
         inventory_id: param.inventory_id
      }
      console.log('fields', fields);
      
      this.setState({ formData: { ...formData, fields  }, toggelModal: true, showSuccess: false })
   }

   renderTableHeader = () => {
      const { user } = this.props;
      if (user.type === 'warehouse') {
         return (
            <tr>
            {user.profile.warehouse_type === 'Normal' && (<th>{i18n.t(`wd.inventory.tabel.header.code`)}</th>)}
            {/* <th>{i18n.t(`wd.inventory.tabel.header.warehouse`)} </th> */}
            <th>{i18n.t(`wd.inventory.tabel.header.brand`)} </th>
            {user.profile.warehouse_type === 'Normal' && (<th>{i18n.t(`wd.inventory.tabel.header.model_no`)}</th>)}
            <th>{i18n.t(`wd.inventory.tabel.header.width`)} </th>
            <th>{i18n.t(`wd.inventory.tabel.header.aspect`)} </th>
            <th>{i18n.t(`wd.inventory.tabel.header.diameter`)} </th>
            <th>{i18n.t(`wd.inventory.tabel.header.year`)}  </th>
            {user.profile.warehouse_type === 'Normal' && (<th>{i18n.t(`wd.inventory.tabel.header.speed`)} </th>)}
            {user.profile.warehouse_type === 'Normal' && (<th>{i18n.t(`wd.inventory.tabel.header.load`)} </th>)}
            <th> {i18n.t(`wd.inventory.tabel.header.runflat`)}  </th>
            <th>{i18n.t(`wd.inventory.tabel.header.made_in`)}  </th>
            <th> {i18n.t(`wd.inventory.tabel.header.qty`)} </th>
            <th>{i18n.t(`wd.inventory.tabel.header.price`)} </th>
            <th>{i18n.t(`wd.inventory.tabel.header.action`)} </th>
         </tr>
         )
      }
      return (
         <tr>
         <th>{i18n.t(`wd.inventory.tabel.header.brand`)} </th>
         <th>{i18n.t(`wd.inventory.tabel.header.shop`)} </th>
         <th>{i18n.t(`wd.inventory.tabel.header.city`)} </th>   
         <th>{i18n.t(`wd.inventory.tabel.header.width`)} </th>
            <th>{i18n.t(`wd.inventory.tabel.header.aspect`)} </th>
            <th>{i18n.t(`wd.inventory.tabel.header.diameter`)} </th>
         <th>{i18n.t(`wd.inventory.tabel.header.year`)}  </th>
         <th> {i18n.t(`wd.inventory.tabel.header.runflat`)}  </th>
         <th> {i18n.t(`wd.inventory.tabel.header.qty`)} </th>
            <th>{i18n.t(`wd.inventory.tabel.header.price`)} </th>
      </tr>
      )
   }

   applyFilter = (filterData: IInventoryGetFilter) => {
      console.log('filterData', filterData);
      this.setState({ filterData, toggelFilter: false }, () => {
         this.getInventory(1)
      })
   }

   onResetFilter = () => {
      this.setState({ filterData: {
         f_size: '',
         s_size: '',
         t_size: '',
         year: '',
         runflat: '',
         quantity: '',
         madein: '',
         price: '',
         brand_id: '',
         ...this.props.user.profile.warehouse_type === 'Normal' && {
            code: '',
            speedindex: '',
            loadindex: '',
            model_no: '',
         }
      }, toggelFilter: false }, () => {
         this.getInventory(1)
      })
   }

   onResetForm = () => {
      const { formData } = this.state;
      if (formData.fields.inventory_id) {
         this.editInventory({ type: 'edit', inventory_id: formData.fields.inventory_id });
         console.log('formData.fields.inventory_id', formData.fields.inventory_id);
         
         return true
      }   
      
      this.setState({
         formData: {
            fields: {
               runflat: '0',
               year: '',
               madein: '',
               price: '',
               quantity: '',
               type: this.props.user.profile.warehouse_type,
               warehouse_user_id: this.props.user.profile.id,
               ...this.props.user.profile.warehouse_type === 'Basic' ? {
                  f_size: '',
                  s_size: '',
                  t_size: '',
                  brand_id: '',
               } : { code: '', },
               inventory_id: ''
            },
            required: [
               'runflat', 'year', 'madein', 'price', 'quantity',
               ...this.props.user.profile.warehouse_type === 'Basic' ? ['f_size', 's_size', 't_size', 'brand_id'] : ['code']
            ]
         },
         showSuccess: false,
      })
   }

   onBulkUpload = (event: any) => {
      const { bulkUpload, user} = this.props;
      event.stopPropagation();
      event.preventDefault();
      var file = event.target.files[0];
      console.log(file);
      // this.setState({file}); 
      bulkUpload({ warehouse_user_id: user.profile.id, upload_xlsx: file, type: user.profile.warehouse_type })
   }

   exportCSV = () => {
   //    return (
   //       <ExcelFile element={<button>Download Data</button>}>
   //       <ExcelSheet data={dataSet1} name="Employees">
   //           <ExcelColumn label="Name" value="name"/>
   //           <ExcelColumn label="Wallet Money" value="amount"/>
   //           <ExcelColumn label="Gender" value="sex"/>
   //           <ExcelColumn label="Marital Status"
   //                        value={"Single"}/>
   //       </ExcelSheet>
   //   </ExcelFile>
   //    )
       const { exportData } = this.state;
       const { user } = this.props;
       let headers = [];
       if (user.type === 'warehouse' && user.profile.warehouse_type === 'Basic') {
         headers = [
            { label: "Warehouse", key: "warehouse_name" },
            { label: "Brand", key: "brand_name" },
            { label: "Width", key: "Width" },
            { label: "Aspect", key: "Aspect" },
            { label: "Diameter", key: "Diameter" },
            { label: "Runflat", key: "runflat" },
            { label: "Madein", key: "madein" },
            { label: "Year", key: "year" },
            { label: "Quantity", key: "quantity" },
            { label: "price", key: "price" },
          ]
       } else if (user.type === 'warehouse' && user.profile.warehouse_type === 'Normal') {
         headers = [
            { label: "warehouse", key: "warehouse_name" },
            { label: "Code", key: "typecode" },
            { label: "Made in", key: "madein" },
            { label: "Year", key: "year" },
            { label: "Price", key: "price" },
            { label: "QTY", key: "quantity" },
            { label: "runfalt", key: "runflat" },
          ]
       } else {
         headers = [
            { label: "Brand", key: "brand_name" },
            { label: "Width", key: "Width" },
            { label: "Aspect", key: "Aspect" },
            { label: "Diameter", key: "Diameter" },
            { label: "Runfalt", key: "runflat" },
            { label: "Price", key: "price" },
            { label: "City", key: "vendor_city" },
            { label: "Vendor", key: "vendor_name" },
            { label: "Year", key: "year" },
          ]
       }

      if (!exportData.length) {
         return null
      }
      return (
         <div className="pull-left u-filter-col">
            <ExcelFile element={<button type={'button'} className="btn btn-dark exportid form-control" >{i18n.t(`wd.inventory.button.export`)}  <img className="icon-import" src={images.exportIcon} alt="export" /></button>}>
               <ExcelSheet data={exportData} name='Inventory'>
                  {
                      headers.map(( row, index ) => {
                         return (
                           <ExcelColumn label={row.label} value={row.key}/>
                         )
                        
                      }) 
                  }
               </ExcelSheet>
            </ExcelFile>
            {/* <CSVLink 
               filename={`${user.type === 'dealer' ? 'Dealer' :  user.profile.warehouse_type}_inventory_${moment(new Date()).format('YYYY-MM-DD HH:MM A')}.csv`}
               data={exportData} 
               headers={headers} 
               className="btn btn-dark exportid form-control"
            >
               {i18n.t(`wd.inventory.button.export`)}  <img className="icon-import" src={images.exportIcon} alt="export" />
            </CSVLink> */}
         </div>
      )

   }

   onUpload = () => {
      this.upload.click();
   }

   toggelAlertModal = (param?: IInventoryAct) => {
      const { isShowAlertModal } = this.state;
      this.setState({ 
         isShowAlertModal: !isShowAlertModal,
         ...param?.inventory_id && { actionParam: param }
      })
   }

    render() {
       const { inventory, user, wd, shop } = this.props;
       const { toggelModal, page, toggelFilter, showSuccess, isShowAlertModal, actionParam } = this.state;
console.log('formData.fields', this.state.formData.fields);
console.log('inventory-111', Number(inventory.records.limit)*Number(page));

      return (
        <Container>
           <div key={'inventory'}>
      <div className="main-content report-page" >
         <div className="u-main-wrapper">
            <div className="top-container">
               <form>
                  <div className="top-filter-box row">
                     <div className="col-md-2 col-sm-12 inventry-top">
                        <h1> {i18n.t(`wd.inventory.title`)} </h1>
                        <span>
                        {i18n.t(`wd.pagination.record`, { record: `${page === 1 ? 1 : Number(inventory.records.limit) * (Number(page) - 1)} - ${inventory.records.ids.length < Number(inventory.records.limit) ? inventory.records.ids.length : Number(inventory.records.limit)*Number(page)}`, total: inventory.records.totalRecords })}
                         
                           </span>
                     </div>
                     <div className="col-md-10 col-sm-12  inventry-bottom">
                        <div className="u-filter-right pull-right">
                           <div className="pull-left u-filter-col-btn-box">
                           <div className="pull-left u-filter-col">
                                    <button type="button" className="btn btn-dark exportid form-control" onClick={this.onToggelFilter}>
                                    {i18n.t(`wd.inventory.button.apply_filter`)} 
                                    {/* <img className="icon-import" src={images.exportIcon} alt="export" /> */}
                                    </button> 
                                 </div>
                                 {this.exportCSV() } 
                           {
                              user.type === 'warehouse' && (
                                 <>
                               
                               
                                 <div className="pull-left u-filter-col">
                                    <button type="button" id="create-inventory" className="btn btn-dark exportid form-control" data-toggle="modal" data-target="#admin" onClick={this.onToggelModal}>
                                    {i18n.t(`wd.inventory.button.create_inventory`)}
                                    </button> 
                                 </div>
                                 <div className="pull-left u-filter-col">
                                    <button type="button" className="btn btn-dark exportid bulk form-control" onClick={this.onUpload} disabled={inventory.importLoading}>
                                    {i18n.t(`wd.inventory.button.bulk_upload`)} 
                                    {
                                       inventory.importLoading && (
                                          <img className="icon-import" src={images.loader} alt="export" style={{ height: '50px', width: '50px' }} />
                                       )
                                    }
                                    </button> 
                                    <input id="myInput"
                                       type="file"
                                       ref={(ref) => this.upload = ref}
                                       style={{display: 'none'}}
                                       onChange={this.onBulkUpload}
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    />


                                 </div>
                                 <div className="pull-left u-filter-col">
                                    <a  id="create-inventory" className="btn btn-dark exportid form-control" download={true} href={user.profile.warehouse_type === 'Basic' ? `https://www.estbnh.com/admin/img/excel/basicinventory.xlsx` : `https://www.estbnh.com/admin/img/ejinventorysample.xlsx`} target={'_blank'}>
                                    {i18n.t(`wd.inventory.button.downlad_sample`)}
                                    </a> 
                                 </div>
                              </>
                              )
                           }
</div>

                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
         <div className="u-main-wrapper">
            <div className="admin-container u-table-container">
               <div className="u-inner-wrapper ">
                  <div className="table-responsive ">
                     <table className="table ">
                        <thead className="table-head">
                          {this.renderTableHeader()}
                        </thead>
                        <tbody>
      
                           {this.renderItem()}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
         {
            inventory.records.ids.length > 0 && (
               <Pagination 
                  pages={Number(inventory.records.totalPage)}
                  limit={Number(inventory.records.limit)}
                  total={Number(inventory.records.totalRecords)} 
                  active={page}
                  onClick={this.onPaginate}
               />
            )
         }

      </div>
         <ModalRight 
            show={toggelModal}
            onToggel={this.onToggelModal}
            title={i18n.t(`wd.inventory.modal.create.title`)}
            reset={true}
            onReset={this.onResetForm}
         >
            <>
            {/* {
               showSuccess && (
                  <Alert variant={'success'} style={{ marginTop: '15px' }}>
                     {i18n.t(`wd.inventory.added_success`)}
                  </Alert>
               )
            } */}
            
         <div className="row">
            
            {this.renderInput()}
            <div className="col-md-12">
               <button type="button" className="button2" title="Submit" onClick={this.onSubmit} disabled={inventory.loading}>
               {i18n.t(`common.button.submit`)}
              {
                inventory.loading && (
                  <img src={images.loader} style={{ height: '30px', width: '30px' }}/>
                )
              }

               </button>
            </div>
         </div>
         </>
         </ModalRight>
         <FilterModal
            show={toggelFilter}
            onToggelModal={this.onToggelFilter}
            userType={user.type}
            wareHouseType={user.profile.warehouse_type}
            wd={wd}
            onApply={this.applyFilter}
            onReset={this.onResetFilter}
            tireSize={inventory.tireSize}
            getTireSize={this.getTireSize}
            shop={shop}
         />
         <AlertModal
            show={isShowAlertModal}
            handleClose={this.toggelAlertModal}
            title={i18n.t(`Modal.inventory.delete.title`)}
            description={i18n.t(`Modal.inventory.delete.description`)}
            actionType={'remove'}
            handleAction={this.actions('delete', actionParam)}
         />
     </div>
     
        </Container>
      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  user: state.user,
  inventory: state.inventory,
  wd: state.wd,
  shop: state.shop
});

const mapDispatchToProps = {
   getInventory: inventoryActions.getInventory,
   deleteInventory: inventoryActions.deleteInventory,
   addInventory: inventoryActions.addInventory,
   editInventory: inventoryActions.editInventory,
   bulkUpload: inventoryActions.bulkUpload,
   getTireSize: inventoryActions.getTireSize,
   shopGet: shopActions.shopGet
}



export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(InventoryPage)) )