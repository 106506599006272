import React from 'react';
import { Container } from '../../../components/Base';
import { withRouter } from 'react-router-dom';
import { AlertModal } from '../../../components';
import { PATH } from '../../../navigation/NavigationPath';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../services';


interface IProps {
  history: any
}

interface IState {
	isShowAlertModal: boolean,
  popupMsg:string,
  okMsg:string
}

class TabbyFailure extends React.Component<IProps, IState> {

  state: IState =  {
		isShowAlertModal: true,
    popupMsg:"",
    okMsg:""
	}

  componentDidMount() {
    setTimeout(() => {
      this.setState({popupMsg:i18n.t(`failed_payment_tabby`), okMsg:i18n.t(`OK`)});
    }, 1000);
  }

  toggelAlertModal = () => {
    const { history} = this.props;
		const { isShowAlertModal } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal })
    history.push(PATH.CART)
	}

    render() {
      const { history} = this.props;
      const { isShowAlertModal } = this.state;
      // console.log('rammm===>', i18n.t(`Tamara Payment Cancel`));
       
      return (
        <Container>
          <div style={{height:'300px'}}></div>
        <AlertModal
            show={isShowAlertModal}
            handleClose={this.toggelAlertModal}
            title={(`Tabby Payment Fail`)}
            description={this.state.popupMsg}
            actionType={'remove'}
            handleAction={()=> {}}
            isConfirmation={false}
            closeButtonName={this.state.okMsg}
            handleNavigation={() => history.push(PATH.SEARCH_TIRES_LIST)}
         />


{/* <div role="dialog" aria-modal="true" className="fade modal show" style={{display: "block"}}><div className="modal-dialog"><div className="modal-content"><div className="modal-header"><div className="modal-title h4">Tabby Payment Fail</div><button type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button></div><div className="modal-body">Unfortunately payment through Tabby was not successful, please choose different payment option</div><div className="modal-footer"><button type="button" className="btn btn-secondary">OK</button></div></div></div></div> */}

         </Container>
      )
    }
}

export default withRouter<any, any>(withTranslation()(TabbyFailure)) ;