import React from 'react';
import { Container } from '../../../components/Base';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import IRootState, { ISearchState, ICoreState,IUserState } from '../../../models';
import { search as searcActions, cart as cartActions, core as coreActions } from '../../../actions';
import ICartState, { ITamaraConfirmationParam, ICartAddParam, IPaymentConfirmationParam} from '../../../models/ICartState';
import { connect } from 'react-redux';
import { HomeLoader, Notification } from '../../../components';
import { ModalRight, AddCartSuccessModal, Modal } from '../../../components';
import { PATH } from '../../../navigation/NavigationPath';
import { AlertModal } from '../../../components';
import { i18n } from '../../../services';

interface IProps {
  search: ISearchState;
  getTamaraConfirmation: (param: ITamaraConfirmationParam) => void;
  getPaymentConfirmation: (param: IPaymentConfirmationParam) => void;
  cart: ICartState;
  addToCart: (param: ICartAddParam) => void;
  addToCartSuccess: (param: any) => void;
  history: any;
}

interface IState {
  isModalShow: boolean;
  isShowAlertModal: boolean
  tamaraPaymentMsg:string
}


class TamaraSuccess extends React.Component<IProps, IState> {

  state: IState =  {
    isModalShow : false,
		isShowAlertModal: false,
    tamaraPaymentMsg:""
	}

  componentDidMount() {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let referenceId = url.searchParams.get("referenceId");
    let paymentStatus = url.searchParams.get("paymentStatus");
    let orderId = url.searchParams.get("orderId");
    
    const { getTamaraConfirmation, getPaymentConfirmation } = this.props;

    if(orderId && paymentStatus && referenceId){
    const orederParam = {
      paymentStatus:paymentStatus ? paymentStatus : "",
      orderId:orderId ? orderId : "",
      referenceId:referenceId ? referenceId : "",
    }
    // console.log('tttttt');
    getTamaraConfirmation(orederParam);
  }
      // console.log('rammji=====>', url_string);

      
      // const paymentParam = {
      //   order_number: '00025457',
      //   reference_number: '000254572',
      // }
      // getPaymentConfirmation(paymentParam);
  }

  componentDidUpdate(prevProps: IProps) {
    const { cart, addToCart, getPaymentConfirmation } = this.props;
    //  console.log('rammmmmmmmm====>', cart);
    if(prevProps.cart.tamaraConfirmation !== cart.tamaraConfirmation){
      if(cart.tamaraConfirmation.status_code === 200){
        // let orderDataParam: string | null = localStorage.getItem('addtocartparam'); // Example of a nullable value
        // let paymentSuccessResponse = localStorage.getItem('paymentSuccessResponse');
        // let responseParam = paymentSuccessResponse ? JSON.parse(paymentSuccessResponse) : null;
        // console.log('bbbb=====>', responseParam);
        // console.log('aaa====>', orderDataParam);
        // let param2 = JSON.parse(orderDataParam!);
        // const param = {
        //   ...param2,
        //   platform_type: "Web",
        //   order_number: cart.tamaraConfirmation.order_number,
        //   reference_number: cart.tamaraConfirmation.reference_number,
        // }
        // addToCart(param);

        const paymentParam = {
          order_number: cart.tamaraConfirmation.order_number,
          reference_number: cart.tamaraConfirmation.reference_number,
          status:'Confirmed'
        }

        localStorage.removeItem('addtocartparam');
        localStorage.removeItem('paymentSuccessResponse');
        getPaymentConfirmation(paymentParam);

      } else {
        this.setState({tamaraPaymentMsg:cart.tamaraConfirmation.message});
        this.toggelAlertModal();
        // alert(cart.tamaraConfirmation.message);
        // window.location.href = "/";
      }
    }


    if(prevProps.cart.paymentConfirmation !== cart.paymentConfirmation){ 
      // console.log('aaaaaaaaaa=======================>');
      if(cart.paymentConfirmation.status_code === 200){
        // console.log('bbbbbbbbbbb=======================>');
        this.setState({isModalShow:true});
      } else {
        // console.log('ccccccc=======================>');
        this.setState({tamaraPaymentMsg:cart.paymentConfirmation.message});
        this.toggelAlertModal();
      }
    }
  }

  toggelAlertModal = () => {
		const { isShowAlertModal } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal })
    
	}

  closeAlertModal = () => {
    const { history} = this.props;
		const { isShowAlertModal } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal })
    history.push(PATH.CART);
	}

  onCloseCartSuccess = () => {
    const { addToCartSuccess, history } = this.props;
    addToCartSuccess({})
    history.push(PATH.CART)
  }

    render() {

      const { cart, history } = this.props;
      const { isShowAlertModal, tamaraPaymentMsg, isModalShow } = this.state;
      
      // console.log('ttttttttttttt', isShowAlertModal);

      return (
        <>

          {cart.cartSuccess !== undefined ? (
                    isModalShow ? (
                      <AddCartSuccessModal
                        // @ts-ignore-end
                        items={cart.cartSuccess}
                        onToggel={this.onCloseCartSuccess}
                        show={!!Object.keys(cart.cartSuccess).length}
                      />
                    ) : ''
                  ) : (
                    <HomeLoader />
            )}

        <AlertModal
            show={isShowAlertModal}
            handleClose={this.closeAlertModal}
            title={(`Alert Message`)}
            description={tamaraPaymentMsg}
            actionType={'remove'}
            handleAction={()=> {}}
            isConfirmation={false}
            closeButtonName={i18n.t('OK')}
            handleNavigation={() => history.push(PATH.CART)}
         />

        </>

      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  search: state.search,
  core: state.core,
  cart: state.cart,
  isLoggedin: state.auth.isLoggedin,
  user: state.user.profile
});

const mapDispatchToProps = {
  getTamaraConfirmation: cartActions.getTamaraConfirmation,
  getPaymentConfirmation: cartActions.getPaymentConfirmation,
  addToCart: cartActions.addToCart,
  addToCartSuccess: cartActions.addToCartSuccess,
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(TamaraSuccess)) )