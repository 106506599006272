import React from 'react';
import { images } from '../../../../constants/Images';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "../../../../styles/slick.css";
import Slider from "react-slick";
import config from '../../../../constants/config';
import i18n from '../../../../services/i18n';
import HtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';

interface IProps {
   items: any[]
}
const  settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
};

class Testimonials extends React.Component<IProps> {

   renderItem = () => {
      const {items } = this.props
      return items.map((item, index) => {
         return (
            <div className="slide-btn">
            <div className="testimonails-slider-body">
              <div className="testimonails-content">
               <p>{item.review}</p>
              </div>
              <div className="testimonails-foot">                                 
                 <div className="foot-title">{item.name} <span>{item.address}</span>
                 </div>
                 <div className="foot-img">
                    <img src={`${config.imageBaseUrl}${item.image}`} alt="user" />
                 </div>
              </div>
           </div>
        </div>
         )
      })

   }

    render() {
      return (
         <section className="testimonials-section">
   
   
   <div className="container px-lg-5">

    <div className="row px-lg-5">

<div className="col-lg-12 mobile-app mt-lg-5">
   <div className="row">
      <div className="col-lg-4">
         <img src={images.mobileApp} alt="" className="mob-app" />
      </div>
      <div className="col-lg-6 pl-lg-5 pr-lg-4">
        <div className="mobile-app-content">
         <h3>{HtmlParser(i18n.t('home.download_app.title'))}</h3>
         <h4>{i18n.t('home.download_app.subtitle')}</h4>
         <div className="d-flex flex-wrap">
          <a target="_blank" className="app-btns google-btn mb-2" href="https://play.google.com/store/apps/details?id=com.kafarati.main" role="button">
            <img src={images.googlePlay}  alt="" />
            <span className="app-btn-subtitle">{i18n.t('GET_IT_ON')}</span>
            <span className="app-btn-title">{i18n.t('Google_Play')}</span>
         </a>
         <a target="_blank" className="app-btns apple-btn mr-lg-3 mb-2" href="https://apps.apple.com/in/app/estbnh-%D8%A7%D8%B3%D8%AA%D8%A8%D9%86%D8%A9/id1437483131" role="button">
            <img src={images.apple}  alt="" />
            <span className="app-btn-subtitle">{i18n.t('Download_on_the')}</span>
            <span className="app-btn-title">{i18n.t('App_Store')}</span>
         </a>  
      </div>
   </div>
</div>

</div>
</div>
</div>

</div>
</section>
      )
    }
}

export default withTranslation()<any>(Testimonials)