import { core } from '../actions';
import { ICoreState } from '../models';
import { ICoreNotification } from '../models/ICoreState';

export const initialState: ICoreState = {
  loading: false,
  errors: [],
  notifications: [],
  pages: {
    // @ts-ignore-start
    about: {},
    terms: { title: '', content: ''},
    policy: { title: '', content: ''}
  },
  cities: [],
  selectedCity: { id: 0, city: "Riyadh", city_arabic: "الرياض", latitude :'24.774265', longitude:'46.738586', radius:0 },
  newsletterSubscribing: {loading: false, failed: false},
  forceToLogin: false,
  faq: []
};


function addNotification (state: ICoreState, notification: ICoreNotification) {
  const { notifications } = state;
  const { message } = notification;
  return notifications
    ? notifications.filter(s => s.message !== message).concat(notification)
    : [notification];
}

export default (state: ICoreState = initialState, action: core.Action): ICoreState => {
  switch (action.type) {
    case core.ActionTypes.SET_LOADING: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case core.ActionTypes.ADD_ERROR: {
      const error = action.payload;
      const errors = state.errors
        ? state.errors.concat(error).reverse().slice(0, 5)
        : [error];
      return {
        ...state,
        errors,
      };
    }
    case core.ActionTypes.ADD_NOTIFICATION: {
      const notification = action.payload;
      // if (notification.message) {
        return {
          ...state,
          notifications: addNotification(state, notification),
        };
      // }
    }
    case core.ActionTypes.REMOVE_NOTIFICATION: {
      const message = action.payload;
      // if (state.notifications) {
        return {
          ...state,
          notifications: message !== undefined
            ? state.notifications.filter(s => s.message !== message)
            : state.notifications.slice(1),
        };
      // }
    }
    case core.ActionTypes.CLEAR_ALL_NOTIFICATON : {
      return {
        ...state,
        errors: [],
        notifications:[],
      };
    }
    case core.ActionTypes.SET_PAGE : {
      const { type, title, content } = action.payload;
      console.log('action.payload', action.payload);
      
      return {
        ...state,
        pages: { ...state.pages, [type]: action.payload }
      };
    }
    case core.ActionTypes.SET_CITIES : {
      return {
        ...state,
        cities: action.payload
      };
    }
    case core.ActionTypes.SET_UPDATED_CITY : {
      return {
        ...state,
        selectedCity: action.payload
      };
    }
    case core.ActionTypes.SUBSCRIBE_NEWSLETTER : {
      return {
        ...state,
        newsletterSubscribing: { ...state.newsletterSubscribing, loading: true }
      };
    }
    case core.ActionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS : {
      return {
        ...state,
        newsletterSubscribing: {loading: false, failed: action.payload}
      };
    }
    case core.ActionTypes.FORCE_TO_LOGIN : {
      return {
        ...state,
        forceToLogin: action.payload
      };
    }
    case core.ActionTypes.SET_FAQ : {
      return {
        ...state,
        faq: action.payload
      };
    }
    default:
      return state;
  }
};
