import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import OrderPage from '../pages/WDPanel/OrderPage';
import { InventoryPage, HomePage, ContactUsPage, CartPage, LoginPage } from "../pages";
import { StaticPage, FaqPage } from '../pages/Static';
import { api } from '../services';
import { PATH } from './NavigationPath';
import { BrandPage, TiresPage, SizePage } from '../pages/Search';
import { initI18n } from '../services/i18n';
import { IBrandState } from '../pages/Search/BrandPage/BrandPage';
import IRootState, { ILangState } from '../models';
import { connect } from 'react-redux';
import { Loader } from '../components/Base';
import { HomeLoader, ScrollToTop } from '../components';
import { typeUser } from '../models/IUserState';
import { ManageTirePage, ShopPage } from '../pages/WDPanel';
import { ConnectedRouter } from 'connected-react-router'
import { history } from '../store/configStore';
import { wd as wdActions, core as coreActions } from '../actions'
import { IWdGetElecon } from '../models/IWDState';
import { lang } from 'moment';
import { ForgotPasswordPage, ResetPasswordPage } from '../pages/Auth';
import { LocationPage } from '../pages/Cart';
import { BlogDetailPage, TamaraSuccess, TamaraCancel, TamaraFailure, TabbySuccess, TabbyCancel, TabbyFailure } from '../pages/Home';

interface IProps {
    lang: ILangState
    userType: typeUser
    getElenco: (param: IWdGetElecon) => void;
    getCities: () => void;
}

interface IState {
    isLangLoading: boolean
}

class Navigator extends React.PureComponent<IProps, IState> {
    state: IState = {
        isLangLoading: true
    }

    componentDidMount() {
        //console.log('Navigator-componentDidMount');
        const { lang, getElenco, userType, getCities } = this.props
        api.init()
        this.getlanguage()
        getCities()
        const elencoArr = ['brand', 'speedRating', 'load', 'tireCode', 'model']
        elencoArr.forEach((row: any) => {
            getElenco({ type: row })
        });
        // if (userType !== 'client') {
        //     require('../styles/admin.css')
        //   } else {
        //     require('../styles/App.css')
        //   }
    }

    getlanguage = async () => {
        const { lang } = this.props
        api.init()
        const { website_messages_en, website_messages_ar, payment_settings } = await api.core.getLanguage({ lang: lang.language })
        //console.log('website_messages_en', website_messages_ar);
        // console.log('payment_settings=======>', payment_settings);
        localStorage.setItem('paymetMaxLimitSettings', JSON.stringify(payment_settings));

        this.setState({ isLangLoading: false })
        initI18n(lang.language, { en: { translation: website_messages_en }, ar: { translation: website_messages_ar } })
    }

    static getDerivedStateFromProps(props: IProps, state: IState) {
        if (props.userType === 'client') {
            require('../styles/App.css')
        } else {
            require('../styles/admin.css')
        }
        return state
    }



    clientNavigation = () => {
        return (
            <Switch>
                <Route exact path="/">
                    <HomePage />
                </Route>
                <Route path={PATH.CONTACT_US}>
                    <ContactUsPage />
                </Route>
                <Route path={PATH.SEARCH_BY_BRAND}>
                    <BrandPage />
                </Route>
                <Route path={PATH.SEARCH_BY_BRAND}>
                    <BrandPage />
                </Route>
                <Route path={PATH.SEARCH_TIRES_LIST}>
                    <TiresPage />
                </Route>
                <Route path={PATH.SEARCH_BY_SIZE}>
                    <SizePage />
                </Route>
                <Route path={PATH.CART}>
                    <CartPage />
                </Route>
                <Route path={PATH.LOGIN}>
                    <LoginPage />
                </Route>
                <Route path={PATH.FORGOT_PASSWORD}>
                    <ForgotPasswordPage />
                </Route>
                <Route path={PATH.RESET_PASSWORD}>
                    <ResetPasswordPage />
                </Route>
                <Route path={PATH.MAP}>
                    <LocationPage />
                </Route>
                <Route path={PATH.FAQ}>
                    <FaqPage />
                </Route>
                <Route path={PATH.TAMARA_SUCCESS}>
                    <TamaraSuccess />
                </Route>
                <Route path={PATH.TAMARA_CANCEL}>
                    <TamaraCancel />
                </Route>
                <Route path={PATH.TAMARA_FAILURE}>
                    <TamaraFailure />
                </Route>
                <Route path={PATH.TABBY_SUCCESS}>
                    <TabbySuccess />
                </Route>
                <Route path={PATH.TABBY_CANCEL}>
                    <TabbyCancel />
                </Route>
                <Route path={PATH.TABBY_FAILURE}>
                    <TabbyFailure />
                </Route>
                <Route path={PATH.BLOG_DETAIL}>
                    <BlogDetailPage />
                </Route>
                <Route path={`${PATH.SEARCH_BY_BRAND}/:id`}>
                    <BrandPage />
                </Route>
                <Route path={PATH.STATIC} key=":id">
                    <StaticPage />
                </Route>
            </Switch>
        )
    }

    wdNavigation = () => {
        const { userType } = this.props;
        return (
            <Switch>
                <Route exact path={`${userType === 'warehouse' ? '/' : PATH.MANAGE_ORDER}`}>
                    <OrderPage />
                </Route>
                <Route path={PATH.INVENTORY}>
                    <InventoryPage />
                </Route>
                <Route path={PATH.MANGE_TIRES}>
                    <ManageTirePage />
                </Route>
                <Route exact path={`${userType === 'dealer' ? '/' : PATH.MANGE_SHOP}`}>
                    <ShopPage />
                </Route>
                <Route path={PATH.LOGIN}>
                    <LoginPage />
                </Route>
            </Switch>

        )
    }

    render() {
        const { isLangLoading } = this.state;
        const { userType, lang } = this.props;
        //console.log('isLangLoading', isLangLoading);

        if (isLangLoading) {
            return <HomeLoader />
        }

        return (
            <div className={lang.language === 'ar' ? 'page-rtl' : ''}>
                {/* <ConnectedRouter history={history}> */}
                <Router>
                    <ScrollToTop />
                    {
                        userType === 'client' ? this.clientNavigation() : this.wdNavigation()
                    }
                </Router>
                {/* </ConnectedRouter> */}
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => ({
    lang: state.lang,
    userType: state.user.type,
});

const mapDispatchToProps = {
    getElenco: wdActions.getElenco,
    getCities: coreActions.getCities,
}

export default connect(mapStateToProps, mapDispatchToProps)<any>(Navigator)