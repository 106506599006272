import { put, takeEvery, call, delay, select } from 'redux-saga/effects'
import { wd as wdActions} from '../actions'
import { api } from '../services';
import { IActionElencoGet, IActionWDGetInstallCeter } from '../actions/wdActions';
import { TWDElecon } from '../models/IWDState';
import { getUser } from '../selectors';

export function* getElenconSaga(action: IActionElencoGet) {
  try {
    console.log('action.payload', action.payload);
    const { type } = action.payload;

    const { statusCode, brandsList=[], loadindexsData=[], speedRatingData=[], tyresCodeList=[], modelsList=[] }  = yield call(api.wd.getElecon, action.payload);
    
    const typeAction: {[key in TWDElecon]: any} = {
      brand: { action: wdActions.setBrand, data: brandsList },
      speedRating: { action: wdActions.setSpeedRating, data:  speedRatingData},
      model: { action: wdActions.setModel, data: modelsList },
      load: { action: wdActions.setLoad, data: loadindexsData },
      tireCode: { action: wdActions.setTireCode, data: tyresCodeList },
    }
    console.log(typeAction[type]);
    
    yield put(typeAction[type].action(typeAction[type].data))
  } catch(e) {
    
  }

}

export function* getInstallCenterSaga(action: IActionWDGetInstallCeter) {
  try {
    const user = yield select(getUser)
    const { statusCode, installCenters=[]}  = yield call(api.search.getInstallCenter, {warehouse_user_id: user.profile.id});
    yield put(wdActions.setInstallCenter(installCenters))
  } catch(e) {
    
  }

}



export default [
  takeEvery(wdActions.ActionTypes.WD_ELENCO_GET, getElenconSaga),
  takeEvery(wdActions.ActionTypes.WD_GET_INSTALLCENTER, getInstallCenterSaga),
];