import React from 'react';

import { images } from '../../../constants/Images';
import IRootState, { ISearchState } from '../../../models';
import { connect } from 'react-redux';
import { Header, BrandCard, Years, TireCard, DetailModal, InstallCenterHeader, InstallCenterCard } from '../Components';
import { search as searcActions, search } from '../../../actions'
import { PATH } from '../../../navigation/NavigationPath';
import { withRouter } from "react-router";
import { ISearchGetYear, ISearchGetModel, ISearchModel, ISearchGetTires, ISearchGetRetailerOrderDetail, ISearchTireCache, ISearchSetRetailerOrderDetail, ISearchInstallCenter } from '../../../models/ISearchState';
import { Container, Loader } from '../../../components/Base';
import { ModalRight, Marker } from '../../../components';
import config from '../../../constants/config';
import GoogleMapReact from 'google-map-react';
import { i18n } from '../../../services';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import './LocationPage.css'

interface IProps {
	//   items: ISearchGetRetailerOrderDetail | {};
	tireDetail: ISearchTireCache
	installCenter: ISearchInstallCenter[];
	onReview: (id: string) => void
	onGoBack: () => void;
	//   detail: ISearchSetRetailerOrderDetail
	//   onBack: () => void;
}

interface IState {
	selectedInstallCenterID: string,
	markers: any,
	scaledSize: any,
	changedMarkerIndex: string
}


class LocationPage extends React.Component<IProps, IState> {
	state: IState = {
		selectedInstallCenterID: '',
		markers: [],
		scaledSize: {},
		changedMarkerIndex: '0'
	}
	map: any
	renderItem = () => {
		const { installCenter, onReview } = this.props;
		let { selectedInstallCenterID } = this.state;
		return installCenter.map((item: ISearchInstallCenter, index: number) => {
			if (selectedInstallCenterID == '' && index == 0) {
				selectedInstallCenterID = item.id;
				this.onSelect(selectedInstallCenterID);
			}

			return (
				<InstallCenterCard
					items={item}
					key={index}
					onReview={onReview}
					onSelect={this.onSelect}
					onScroll={this.onScroll}
					selected={selectedInstallCenterID === item.id}
				/>
			)
		})

	}

	onScroll = (e: any) => {		 
		let scrollWidth = e.currentTarget.scrollWidth;
		let scrollLeft = (i18n.language === 'en') ? e.currentTarget.scrollLeft : e.currentTarget.scrollLeft * -1;
		let divWidth = e.target.querySelector('.timeing_details').offsetWidth;
		let divNo = Math.round(scrollLeft / divWidth) + 1;
		let selectedDiv = e.target.querySelector('.timeing_details:nth-child(' + divNo + ')');
		let selectedDivId = selectedDiv.getAttribute('data-id');
		console.log('avs4=' + divNo);
		console.log('avs4=' + selectedDiv);
		console.log('avs4=' + selectedDivId);
		this.onSelect('0');
		this.onSelect(selectedDivId); 
	}

	onSelect = (id: string) => {
		this.setState({ selectedInstallCenterID: id });
		if (Number(id) == 0) {
			if (Number(this.state.changedMarkerIndex) != 0 && this.state.markers[this.state.changedMarkerIndex]) {
				this.state.markers[this.state.changedMarkerIndex].setIcon({
					url: images.blackpin,
					scaledSize: this.state.scaledSize
				});
			}
		} else {

			if (this.state.markers[id])
				this.state.markers[id].setIcon({
					url: images.orangepin,
					scaledSize: this.state.scaledSize
				});
			this.setState({ changedMarkerIndex: id });
		}
	}

	renderMarker = () => {
		console.log("ljjjkjkjkjjkjk")
		const { installCenter } = this.props;
		const { selectedInstallCenterID } = this.state;
		let bounds: any[] = [];
		installCenter.map((item: ISearchInstallCenter, index: number) => {

			this.state.markers[item.id] = (
				<Marker
					lat={Number(item.latitude)}
					lng={Number(item.longitude)}
					key={index}
					image={item.id === selectedInstallCenterID ? images.orangepin : images.blackpin}
					name={item.name}
				/>
			)
		})
		return this.state.markers;

	}

	componentDidMount() {

	}

	handleApiLoaded = ({ map, maps }: { map: any, maps: any }) => {
		const { installCenter } = this.props;
		const { selectedInstallCenterID } = this.state;
		installCenter.map((item: ISearchInstallCenter, index: number) => {
			let marker = new maps.Marker({
				position: { lat: Number(item.latitude), lng: Number(item.longitude) },
				map,
				draggable: false,
				icon: {
					url: (item.id === selectedInstallCenterID ? images.orangepin : images.blackpin),
					scaledSize: new maps.Size(50, 50)
				},
				title: item.name,
				key: index
			})
			this.setState({ scaledSize: new maps.Size(50, 50) });
			this.state.markers[item.id] = (marker);
		})
		const bounds = this.getMapBounds(map, maps);
		map.fitBounds(bounds);
		this.bindResizeListener(map, maps, bounds);
	}


	getMapBounds = (map: any, maps: any) => {
		const { installCenter } = this.props;
		const bounds = new maps.LatLngBounds();
		installCenter.forEach((item: ISearchInstallCenter) => {
			bounds.extend(new maps.LatLng(
				Number(item.latitude),
				Number(item.longitude),
			));
		});
		return bounds;
	}

	// Re-center map when resizing the window
	bindResizeListener = (map: any, maps: any, bounds: any) => {
		maps.event.addDomListenerOnce(map, 'idle', () => {
			maps.event.addDomListener(window, 'resize', () => {
				map.fitBounds(bounds);
			});
		});
	};


	render() {
		const { tireDetail, onGoBack } = this.props;
		console.log('tireDetail', tireDetail);

		return (
			<>
				<InstallCenterHeader items={tireDetail} onGoBack={onGoBack} />
				<div className="location_page_wrap">
					<section className="loction_mapwrap">
						<div className="row">
							<div className="col-lg-12">
								<div className="dilivery_content">{ReactHtmlParser(i18n.t(`install_center.caption`))}</div>
								<div className="installMap" style={{ height: 'calc(100vh - 200px)' }}>
									<GoogleMapReact
										bootstrapURLKeys={{ key: config.googleApiKey }}
										defaultCenter={{ lat: 59.95, lng: 30.33 }}
										defaultZoom={5}
										ref={(ref) => { this.map = ref; }}
										onGoogleApiLoaded={this.handleApiLoaded}
									>
									</GoogleMapReact>
								</div>
							</div>
						</div>
					</section>
					<section className="location_leftmenu">
						<div className="row ">
							<div className="col-lg-12" onScroll={this.onScroll} >
								{this.renderItem()}
							</div>
						</div>
					</section>
				</div>
			</>
		)
	}
}

export default withTranslation()(LocationPage);