import { api } from '..';
import { IUserLogin } from '../../models/IAuthState';

async function getNotification(params: {user_id: string, user_type: string}) {
    console.log('getNotification', params);
    
    try {
        return api.postApi('/notification/list', params)
    } catch (e) {
        Promise.reject(e)
    }
}

export {
    getNotification,
}
