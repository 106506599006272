import React, { createRef } from 'react';
import { images } from '../../constants/Images';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../services';
import './AlertModal.css'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

interface IProps {
   handleClose: () => void;
   show: boolean
   title: string;
   description: string
   actionType: 'remove' | 'add' | 'call'
   handleAction: () => void
   isConfirmation?: boolean
   closeButtonName: string
   handleNavigation?: () => void;
}
interface IState {
   toggel: boolean
   
}

class AlertModal extends React.PureComponent<IProps, IState> {
static defaultProps = {
  closeButtonName: i18n.t('Modal.button.close') 
}
    
  renderActionButton = () => {
    const {  handleClose, show, title, description, actionType, handleAction, isConfirmation = true } = this.props
    const buttonLabel = {
      remove:  i18n.t(`Modal.button.delete`),
      add: 'Add',
      call: i18n.t(`Modal.button.call`)
   };  
    if (isConfirmation) {
      if (actionType === 'call') {
        if (isMobile) {
          return (
            <a  href={`tel:+${description}`}>
              <Button variant="success" onClick={handleAction}>
            {buttonLabel[actionType]}
          </Button>
            </a>
            
          )
        } else {
          return null
        }
        
      } else {
        return (
          <Button variant="warning" onClick={handleAction}>
          {buttonLabel[actionType]}
        </Button>
        )
      }
    }
    return null

    
  }

    render() {
      const {  handleClose, show, title, description, actionType, handleAction, isConfirmation = true, closeButtonName= i18n.t('Modal.button.close'), handleNavigation } = this.props
      const buttonLabel = {
         remove:  i18n.t(`Modal.button.delete`),
         add: 'Add',
         call: i18n.t(`Modal.button.call`)
      };
      return (
        <>
        {
            i18n.language === 'ar' ? (
            <Modal show={show} onHide={handleClose} className="limit_popup">
               <Modal.Header closeButton>
                 <Modal.Title>{title}</Modal.Title>
               </Modal.Header>
               <Modal.Body><p className='modelbodytext'>{description}</p></Modal.Body>
               <Modal.Footer>
                 <Button variant="secondary" onClick={handleNavigation ? handleNavigation : handleClose} >
                   {closeButtonName}
                 </Button>
                 {
                     this.renderActionButton()
                  }
               </Modal.Footer>
             </Modal>
        ) : (
          <Modal show={show} onHide={handleClose}>
             <Modal.Header closeButton>
               <Modal.Title>{title}</Modal.Title>
             </Modal.Header>
             <Modal.Body><p className='modelbodytext'>{description}</p></Modal.Body>
             <Modal.Footer>
               <Button variant="secondary" onClick={handleNavigation ? handleNavigation : handleClose} >
                 {closeButtonName}
               </Button>
               {
                   this.renderActionButton()
                }
             </Modal.Footer>
           </Modal>
        )}
        </>
         
      )
    }
}

export default withTranslation()(AlertModal)