import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import { ISearchBrand, ISearchModel, ISearchInstallCenter } from '../../../../models/ISearchState';
import config from '../../../../constants/config';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { Filter } from '../../../../utils';
import { i18n } from '../../../../services';

interface IProps {
  items: ISearchInstallCenter;
  onReview: (id: string) => void;
  onSelect: (id: string) => void;
  onScroll: (e: any, id: string) => void;
  selected: boolean
}

interface IState {

}



class InstallCenterCard extends React.PureComponent<IProps, IState> {

  render() {
    const { items, onReview, onSelect, onScroll, selected } = this.props;
    return (
      <div data-id={items.id} className={`timeing_details ${selected ? 'active' : ''}`} onClick={() => onSelect(items.id)} onMouseOver={() => onSelect(items.id)} onMouseLeave={() => onSelect('0')}
         >
        <div className="inner_box">
          <div className="img-box">
            <figure className="image"><img src={`${config.imageBaseUrl}${items.image_name}`} alt="" /></figure>
          </div>
          <div className="content-box">
            <div className="upper-box">
              <div className="listing-heading">
                <p>
                  {
                    i18n.language === 'ar' ? (
                      <span>{items.name_ar}</span>
                    ) : (
                      <span>{items.name}</span>
                    )}
                </p>
                <p className="address">{Filter.translate(items.display_location, items.display_location_ar)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="working_timeing">
          <p>{i18n.t('booking.install_center.working_hours')}</p>
          {<span className="full_time">{Filter.renderHours(items.working_hours, items.working_hours_ar)}</span>}
        </div>
        <div className="reviewselect_btn">
          <button type="submit" className="btn btn-primary" id="review_btn" onClick={() => onReview(items.id)}>{i18n.t(`install_center.button.select`)}</button>
        </div>
      </div>
    )
  }
}

export default withTranslation()(InstallCenterCard)
