import React from 'react';

import { images } from '../../../constants/Images';
import IRootState, { ISearchState, IUserState, ILangState } from '../../../models';
import { connect } from 'react-redux';
import { Header, BrandCard, Years, TireCard, DetailModal } from '../Components';
import { search as searcActions, search } from '../../../actions'
import { PATH } from '../../../navigation/NavigationPath';
import { withRouter } from "react-router";
import { ISearchGetYear, ISearchGetModel, ISearchModel, ISearchGetTires, ISearchGetRetailerOrderDetail, ISearchTireCache, ISearchSetRetailerOrderDetail, recordType } from '../../../models/ISearchState';
import { Container, Loader } from '../../../components/Base';
import { ModalRight, AlertModal } from '../../../components';
import config from '../../../constants/config';
import { ICartAddParam } from '../../../models/ICartState';
import { IUserProfile } from '../../../models/IUserState';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../services';
import { Filter } from '../../../utils';
import { Modal, Button } from "react-bootstrap";
import './DetailPage.css';

interface IProps {
//   search: ISearchState;
//   getTires: (param: ISearchGetTires) => void;
  // location: { state: ISearchGetTires }
  lang: ILangState
  items: ISearchGetRetailerOrderDetail | {};
  tireData: ISearchTireCache
  detail: ISearchSetRetailerOrderDetail
  onBack: () => void
  onBooking: (param: ICartAddParam) => void
  location: { state: ISearchGetTires | any}
  user: IUserProfile
  history: any
  isLoading:boolean
  tamaraCheckoutUrl:string
  isTamaraPopOpen:boolean
  closeTamaraPopup:()=>void
}

interface IState {
	isShowAlertModal: boolean
	homeInstall:boolean
	homeInstallAddress:any
	homeInstallLatitude:any
	homeInstallLongitude:any
	isTamaraPopOpenState:boolean
	paymentType:string
	paymentParam:any
	fName:string
	lName:string
	emailAddress:string
	validation:object
	fNameError:string
	lNameError:string
	emailAddressError:string
	alertPopupDisplay:boolean
	alertPopupTitle:string
	alertPopupMessage:string
	isShowAlertModal2: boolean
	popupMsg:string
}

class DetailPage extends React.Component<IProps, IState> {
	state: IState =  {
		isShowAlertModal: false,
		homeInstall: this.props.location.state && this.props.location.state.homeInstall ? this.props.location.state.homeInstall : false,
		homeInstallAddress: localStorage.getItem('user_choose_address'),
		homeInstallLatitude: localStorage.getItem('user_choose_lat'),
		homeInstallLongitude: localStorage.getItem('user_choose_lng'),
		isTamaraPopOpenState:false,
		paymentType:"",
		paymentParam:"",
		fName:"",
		lName:"",
		emailAddress:"",
		fNameError:"",
		lNameError:"",
		emailAddressError:"",
		validation:{},
		alertPopupDisplay:false,
		alertPopupTitle:"",
		alertPopupMessage:"",
		isShowAlertModal2: false,
		popupMsg:"",
	}
	onBooking = () => {
		const { detail, tireData, onBooking, user } = this.props;
		let paymetMaxLimitSettings = localStorage.getItem('paymetMaxLimitSettings');
		let maxLimit = JSON.parse(paymetMaxLimitSettings!);
		let order_limit = (typeof maxLimit.already_active_order_limit != 'undefined') ? maxLimit.already_active_order_limit : 0;
		if(detail.orderCounts >= order_limit) {
			this.toggelAlertModal()
		} else if (!this.state.paymentType){
			this.setState({alertPopupDisplay:true, alertPopupTitle:i18n.t(`Alert`), alertPopupMessage:i18n.t(`select_payment_option`)});
			return false;
		} else {
				
		const record_type: { [key: string]: recordType } = {
			"EJ Basic": "BASIC",
			"EJ Normal": "NORMAL",
			"Retailer": "RETAILER",
		  }

		let promotions_id = 0;
		let promotions_id_rear = 0;
		if (this.hasPromotionId(tireData.promotions)) {
			promotions_id = tireData.promotions.id;
		}
		if (this.hasPromotionId(tireData.promotions_rear)) {
			promotions_id_rear = tireData.promotions_rear.id;
		}
		let tire_brand_model = tireData.brand+' '+tireData.model_name;
		const param = {
			install_center_id: detail.installCenter ? detail.installCenter.id : '',
			...tireData.warehouse_user_id && {
				warehouse_user_id: tireData.warehouse_user_id,
			},
			customer_request_id: detail.customer_request_id,
			customer_request_detail_id: detail.customer_request_detail_id,
			price: detail.price,
			inventory_id: detail.inventory_id,
			user_id: user.id,
			quantity: detail.quantity,
			price_final: detail.price,
			vat_percent: detail.vat_in_percent,
			vat: detail.vat,
			sub_total: detail.sub_total,
			grand_total: detail.total_price,
			discount: detail.discount,
			discount_percent: detail.discount_in_percent,
			type: record_type[tireData.record_type],
			...detail.price_rear && {
				customer_request_detail_id_rear: detail.customer_request_detail_id_rear,
				price_rear: detail.price_rear,
				inventory_id_rear: detail.inventory_id_rear,
				quantity_rear: detail.quantity_rear,
				price_final_rear: detail.price_rear,
			},
			...tireData.vendor_id && {
				vendor_id: tireData.vendor_id
			},
			delivery_address: this.state.homeInstallAddress,
			latitude: this.state.homeInstallLatitude,
			longitude: this.state.homeInstallLongitude,
			installation_amount: detail.installation_amount,
			delivery_amount: detail.delivery_amount != undefined ? detail.delivery_amount : detail.delivery_charge,
			delivery_charge: detail.delivery_charge,
			homeInstall:this.state.homeInstall,
			payment_type:this.state.paymentType,
			promotions_id:promotions_id,
			promotions_id_rear:promotions_id_rear,
			tire_brand_model:tire_brand_model
		}
		this.setState({paymentParam:param});

		// console.log('onBooking', param);
		let paymetMaxLimitSettings = localStorage.getItem('paymetMaxLimitSettings');
		let maxLimit = JSON.parse(paymetMaxLimitSettings!);
		if(this.state.paymentType==='Tamara'){
			// console.log('PaymentHereTamara===>');
			if(Number(detail.total_price) > Number(maxLimit.tamara_max_limit)){
				this.setState({alertPopupDisplay:true, alertPopupTitle:i18n.t(`Alert`), alertPopupMessage:i18n.t(`limit_message_tamara`)});
				return false;
			}
			this.setState({isTamaraPopOpenState:true})
		} else if(this.state.paymentType==='Tabby'){
			// console.log('PaymentHereTabby===>');
			if(Number(detail.total_price) > Number(maxLimit.tabby_max_limit)){
				this.setState({alertPopupDisplay:true, alertPopupTitle:i18n.t(`Alert`), alertPopupMessage:i18n.t(`limit_message_tabby`)});
				return false;
			}
			this.setState({isTamaraPopOpenState:true})
			
		} else {
			// console.log('PaymentHereCash===>');
			// @ts-ignore-start
			onBooking(param);
		}
		
		} 
	
	}

	toggelAlertModal = () => {
		const { isShowAlertModal } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal })
	}

	alertPopupClose = () => {
		this.setState({alertPopupDisplay:false});
	}

	componentDidUpdate(prevProps: IProps) {
		// const { isTamaraPopOpen } = this.props;
		// if (prevProps.isTamaraPopOpen !== isTamaraPopOpen) {
		// 	this.setState({isTamaraPopOpenState:true});
		// }	
	}

	handleClose = () => {
		const {closeTamaraPopup} = this.props;
		this.setState({isTamaraPopOpenState:false});
		this.setState({fName:"",lName:"",emailAddress:"",fNameError:"",lNameError:"",emailAddressError:""});
		closeTamaraPopup();
	}

	onChangeValue = (value:any) => {
		// console.log(value);
		this.setState({paymentType:value});
	  }

	  onSubmit = () => {
		const { fName, lName, emailAddress, paymentParam } = this.state;
		if(!fName && !lName && !emailAddress){
			this.setState({fNameError:i18n.t(`msg_please_enter_firstname`)});
			this.setState({lNameError:i18n.t(`msg_please_enter_lastname`)});
			this.setState({emailAddressError:i18n.t(`msg_please_enter_email`)});
		} else if(!fName){
			this.setState({fNameError:i18n.t(`msg_please_enter_firstname`)});
		} else if(!lName){
			this.setState({lNameError:i18n.t(`msg_please_enter_lastname`)});
		} else if(!emailAddress){
			this.setState({emailAddressError:i18n.t(`msg_please_enter_email`)});
		} else {
			let emailValidate = this.validateEmail(emailAddress);
			if(!emailValidate){
				this.setState({emailAddressError:i18n.t(`msg_please_enter_correct_email`)});
			} else {
				const { onBooking} = this.props;
				this.setState({isTamaraPopOpenState:false});
				const param = {
					...paymentParam,
					first_name: fName,
					last_name: lName,
					email_address: emailAddress,
				  }				
				onBooking(param);
			}
		}
	  }

	  validateEmail = (email:string) => {
		return String(email)
		  .toLowerCase()
		  .match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		  );
	  };

	  handleChange = (value : any, field: string) => {
		const { fName, lName, emailAddress } = this.state;
		// console.log('field', field, value);
		if(field ==='fName'){
			this.setState({fName: value});
			this.setState({fNameError:""});
		} else if(field ==='lName'){
			this.setState({lName: value});
			this.setState({lNameError:""});
		} else if(field ==='emailAddress'){
			this.setState({emailAddress: value});
			this.setState({emailAddressError:""});
		}
	  } 

	  hasDescription(obj: any): obj is { description: string } {
		return obj && typeof obj.description === 'string';
	  }
	
	  hasDescriptionArabic(obj: any): obj is { description_arabic: string } {
		return obj && typeof obj.description_arabic === 'string';
	  }

	  hasName(obj: any): obj is { name: string } {
		return obj && typeof obj.name === 'string';
	  }

	  hasPromotionId(obj: any): obj is { id: number } {
		return obj && typeof obj.id === 'number';
	  }

	  openPromotionPopup = (description : any) => {
		// console.log(description);
		const { isShowAlertModal2, popupMsg } = this.state;
			this.setState({ isShowAlertModal2: !isShowAlertModal2, popupMsg: description });
	
	  }

	  toggelAlertModal2 = () => {
		const { isShowAlertModal2 } = this.state;
		this.setState({ isShowAlertModal2: !isShowAlertModal2 })
	}

	getPromotionsData = (tireData : any) => {
		let promotions_description = "";
		let promotions_description_arabic = "";
		let promotions_name = "";
		if (this.hasDescription(tireData.promotions)) {
			promotions_description = tireData.promotions.description;
		}
		if (this.hasDescriptionArabic(tireData.promotions)) {
			promotions_description_arabic = tireData.promotions.description_arabic;
		}
		if (this.hasName(tireData.promotions)) {
			promotions_name = tireData.promotions.name;
		}

		if(!promotions_description && !promotions_description_arabic && !promotions_name){
			if (this.hasDescription(tireData.promotions_rear)) {
			  promotions_description = tireData.promotions_rear.description;
			}
			if (this.hasDescriptionArabic(tireData.promotions_rear)) {
			  promotions_description_arabic = tireData.promotions_rear.description_arabic;
			}
			if (this.hasName(tireData.promotions_rear)) {
				promotions_name = tireData.promotions_rear.name;
			}
		  }
		return {promotions_description, promotions_description_arabic, promotions_name};
	}


    render() {
    //   console.log('this.props', this.props);
    //   console.log('this.state', this.state);
	  const { tireData, detail, onBack, history, isLoading, tamaraCheckoutUrl, isTamaraPopOpen, lang } = this.props;
	  const { isShowAlertModal,homeInstall, isTamaraPopOpenState } = this.state;
	  // const { state } = location;
	  const record_type: { [key: string]: string } = {
		"EJ Basic": "Confirm Order",
		"EJ Normal": "Confirm Order",
		"Retailer": "Confirm Booking",
	  }

	  let pay_by_tamara,pay_by_tabby,pay_by_cash = "";
	  if(detail && detail.vendorInfo && typeof detail.vendorInfo === 'object' && Object.keys(detail.vendorInfo).length > 0){
		pay_by_tamara = detail.vendorInfo.pay_by_tamara;
		pay_by_tabby = detail.vendorInfo.pay_by_tabby;
		pay_by_cash = detail.vendorInfo.pay_by_cash;
	  } else {
		pay_by_tamara = detail.pay_by_tamara;
		pay_by_tabby = detail.pay_by_tabby;
		pay_by_cash = detail.pay_by_cash;
	  }

	//   console.log('testingpayment=======>',pay_by_tamara,pay_by_tabby,pay_by_cash);

	  const promo_data = this.getPromotionsData(tireData);
	  let promotions_description = promo_data.promotions_description;
	  let promotions_description_arabic = promo_data.promotions_description_arabic;
	  let promotions_name = promo_data.promotions_name;
	  
	  const install_center_universal = tireData.install_center_universal;
	  
      return (
        <>
        <header className="order_detail_page_header">
	<div className="row">
	    <div className="col-lg-12">
	      <div className="listing-block">
	      		
	      	<div className="inner-box ">
				<div className="navbar-left-arrow">
		        	<a href="javascript:void(0);" onClick={onBack}><img src={images.leftArrowBlack} /></a>      
          	  	</div>
          	  	<div className="page_title">
          	  		{
		              	(tireData.record_type === 'EJ Normal' || tireData.record_type === 'EJ Basic' ) && ( 	
						  <h2>{i18n.t(`booking.title.order`)}</h2>
				        )
		            }
		            {
		              	tireData.record_type === 'Retailer' &&  ( 	
				             <h2>{i18n.t(`booking.title`)}</h2>
				        )
		            }
		         
          	  	</div>
					{
						tireData.record_type !== 'Retailer' && (
							<span>{i18n.t(`booking.order_fullfilled_by`)} <span className="warehorseValue">
							 {
				                i18n.language === 'ar' ? (
				                <>{detail.warehourse_display_name_ar}</>
				              ) : (
				                <>{detail.warehourse_display_name}</>
				              )}

							</span></span>
						)
					}
          			
          	</div>        
	      </div>
	    </div>
  	</div>
</header>

{/* <Modal show={isTamaraPopOpen && isTamaraPopOpenState} onHide={()=>this.handleClose()}>
            <Modal.Title>Tamara Payment</Modal.Title>
          <Modal.Body><iframe src={tamaraCheckoutUrl} style={{width:'100%',height:'400px'}}/></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>this.handleClose()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}


		 <Modal show={isTamaraPopOpenState} onHide={()=>this.handleClose()} className={i18n.language === 'ar' ? 'confirmmodelwrap page-rtl' : 'confirmmodelwrap'}>
           
			<form>
			<Modal.Title className='modal-header title-modal'>
				<div className='lefthead'>{this.state.paymentType === 'Tamara' ? i18n.t(`payment_info`) : i18n.t(`payment_info_tabby`) }<span>{i18n.t(`payment_info_below`)}</span></div>
			<div className='right-datapt ml-auto'>
			{/* <button type="button" className="close" data-dismiss="modal">×</button> */}
			</div>
				
				</Modal.Title>
          <Modal.Body>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input 
                  type="text" className="form-control" placeholder={i18n.t(`first_name`)+' *'} 
                  value={this.state.fName} onChange={(e: any) => this.handleChange(e.target.value, 'fName')}
                />
                <span className='error-input'>{this.state.fNameError}</span>
              </div>

			  <div className="form-group col-md-6">
                <input 
                  type="text" className="form-control" placeholder={i18n.t(`last_name`)+' *'} 
                  value={this.state.lName} onChange={(e: any) => this.handleChange(e.target.value, 'lName')}
                />
                <span className='error-input'>{this.state.lNameError}</span>
              </div>

              <div className="form-group col-md-12">
                <input type="email" className="form-control" placeholder={i18n.t(`email`)+' *'} 
                    value={this.state.emailAddress} onChange={(e: any) => this.handleChange(e.target.value, 'emailAddress')}
                />
                <span className='error-input'>{this.state.emailAddressError}</span>
              </div>
            </div>
		  </Modal.Body>
          <Modal.Footer>
		  <Button variant="secondary" onClick={()=>this.handleClose()}>
		  {i18n.t('cart.element.button.cancel')}
            </Button>
		  <button type="button" className="btn btn-primary" onClick={this.onSubmit}>{i18n.t('common.button.submit')}</button>
           
          </Modal.Footer>
		  </form>
        </Modal>

		<AlertModal
				show={this.state.alertPopupDisplay}
				handleClose={this.alertPopupClose}
				title={this.state.alertPopupTitle}
				description={this.state.alertPopupMessage}
				actionType={'remove'}
				handleAction={()=> {}}
				isConfirmation={false}
				closeButtonName={i18n.t(`OK`)}
				// handleNavigation={() => history.push(PATH.CART)}
			 />



<div className="order_summrywrap">
	<section className="tire_infowrap">
		{
			(detail.installCenter && Object.keys(detail.installCenter).length) && (
				<>
				<div className="tireinfo_title">
			<h2>{i18n.t(`booking.install_center.title`)}</h2>
		</div>
		<div className="row ">
		    <div className="col-lg-12">
		      <div className="listing-block order_detailmob">
		      <div className="row order_deliver">
		      	<div className="col-md-6">
		      	{
					// (detail.installCenter && Object.keys(detail.installCenter).length) && (
						<div className="installcenterinfo">
	          				<span>{i18n.t(`booking.install_center.order_delivered_to_this`)}</span>
	          				{/* {Filter.translate(detail.installCenter.name, detail.installCenter.name_ar)} */}
	          			</div>
					// )
				}
		      	</div>
		      	<div className="col-md-6 border-left text-right">
			      	<div className="installcenterinfo b_bottom">
			  				<span>{i18n.t(`booking.install_center.order_delivered_in_hours`)}</span>
							{install_center_universal ? (
								<span className="bold">{i18n.t(`multicity_booking.install_center.hours`, { hours: detail.delivery_hours })}</span>
							) : (
								<span className="bold">{i18n.t(`booking.install_center.hours`, { hours: detail.delivery_hours })}</span>
							)}
			  			</div>
		      	</div>
		      </div>

				
		        <div className="inner-box order_mobile">

		          <div className="image-box">
		            <figure className="image"><img src={`${config.imageBaseUrl}${detail.installCenter.image_name}`} alt="" /></figure>
		          </div>

		          <div className="content-box">
		            <div className="upper-box tireinfo_istingwrap">		    
		              <div className="listing-heading tireinfo_isting-heading">
						<h3><a href="javascript:void(0);"> {Filter.translate(detail.installCenter.name, detail.installCenter.name_ar)}</a></h3>
		                <p className="address">{Filter.translate(detail.installCenter.display_location, detail.installCenter.display_location_ar)}</p>
			              <div className="working_timeing">
							<p>{i18n.t(`booking.install_center.working_hours`)}</p>
							<span className="full_time">{Filter.renderHours(detail.installCenter.working_hours, detail.installCenter.working_hours_ar)}</span>
						  </div>
		              </div> 
		                          
		            </div>

		            
		          </div>
		        </div>
		        <div className="working_timeing timing_mob">
					<p>{i18n.t(`booking.install_center.working_hours`)}</p>
					<span className="full_time">{Filter.renderHours(detail.installCenter.working_hours, detail.installCenter.working_hours_ar)}</span>
				</div>
		      </div>
		    </div>
	  	</div>
				</>
			)  
		}

{
			(detail.vendorInfo && Object.keys(detail.vendorInfo).length) && (
				<>
				<div className="tireinfo_title">
			<h2>{i18n.t(`booking.retailer.title`)}</h2>
		</div>
		<div className="row">
		    <div className="col-lg-12">
		      <div className="listing-block">
			  <div className="row order_deliver">
		      	<div className="col-md-6">
		      	{
					
						<div className="installcenterinfo">
	          				<span>{i18n.t(`booking.retailer.available_onshop`)}</span>
	          			
	          			</div>
				}
		      	</div>
		      	
		      </div>
		        <div className="inner-box booking_detailmob">
		          <div className="image-box">
		            <figure className="image"><img src={`${config.imageBaseUrl}${detail.vendorInfo.image}`} alt="" /></figure>
		          </div>

		          <div className="content-box">
		            <div className="upper-box tireinfo_istingwrap">		    
		              <div className="listing-heading tireinfo_isting-heading">
						<h3><a href="javascript:void(0);"> {Filter.translate(detail.vendorInfo.shop_name, detail.vendorInfo.shop_name_ar)}</a></h3>
		                <p className="address">{Filter.translate(detail.vendorInfo.location, detail.vendorInfo.location_ar)}</p>
			              <div className="working_timeing">
							<p>{i18n.t(`booking.retailer.working_hour`)}</p>
							<span className="full_time">{Filter.renderHours(detail.vendorInfo.working_hours, detail.vendorInfo.working_hours_ar)}</span>
						  </div>
		              </div> 
		                          
		            </div>

		            
		          </div>
		        </div>
		        <div className="working_time timing_mob">
							<p>{i18n.t(`booking.retailer.working_hour`)}</p>
							<span className="full_time">{Filter.renderHours(detail.vendorInfo.working_hours, detail.vendorInfo.working_hours_ar)}</span>
						  </div>
		      </div>
		    </div>
	  	</div>
				</>
			)  
		}
		{
			this.state.homeInstall ?
			<><div className="tireinfo_title">
				<h2>{i18n.t(`installation_mode`)}</h2>
			</div>
			<div className="row">
				<div className="col-lg-12">
					<div className="listing-block">					
						<div className="inner-box mobile_listingtire">
							<div className="row" style={{fontSize:"15px", lineHeight:"25px"}}>
								<div className="col-lg-6">
								<span>{i18n.t(`schedule_your_order_text`)}</span>
								</div>
								<div className="col-lg-6">
								<span className="locationAtThis">{install_center_universal ? i18n.t(`multicity_Installed_at_this_Location`) : i18n.t(`Installed_at_this_Location`)} </span>
								<span>{this.state.homeInstallAddress}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div></>
			: ""
		}
			
		<div className="tireinfo_title">
			<h2>{i18n.t(`booking.tire.title`)}</h2>
		</div>
		<div className="row">
		    <div className="col-lg-12">
		      <div className="listing-block">
		        <div className="inner-box mobile_listingtire">
		          <div className="image-box">
		          	{
		              	tireData.record_type === 'EJ Normal' &&  ( 	
				              <figure className="image"><img src={`${config.imageBaseUrl}${tireData.tire_image}`} alt="" /></figure>
				        )
		            }
		            {
		              	tireData.record_type === 'Retailer' &&  ( 	
				              <figure className="image"><img src={`${config.imageBaseUrl}${tireData.image}`} alt="" /></figure>
				        )
		            }
		            {
		              	tireData.record_type === 'EJ Basic' &&  ( 	
				              <figure className="image"><img src={`${config.imageBaseUrl}${tireData.image}`} alt="" /></figure>
				        )
		            }
		            
		          </div>

		          <div className="content-box">
		            <div className="upper-box">
		            	{
			              	tireData.record_type === 'EJ Normal' &&  ( 	
					              <div className="user-thumb"><img src={`${config.imageBaseUrl}${tireData.image}`} alt="" /></div>
					        )
			            }
		              <div className="listing-heading">
		                <span className="listing-year">{tireData.year}</span>
						<h3><a href="javascript:void:(0);">
						{
                            i18n.language === 'ar' ? (
                            <span>{tireData.brand_arabic}</span>
                        ) : (
                        	<span>{tireData.brand}</span>
                        )}

						<span>{tireData.model_name}</span></a></h3>
		              </div> 
					  {
						  tireData.runflat === 1 && (
							<a href="javascrip:void(0);" className="runflat">{i18n.t(`common.label.runflat`)}</a>
						  )
					  }
		                          
		            </div>

		            {
		            	tireData.madein && (
							<ul className="madein_list"><li><span>{i18n.t(`common.label.madein`)}</span> {tireData.madein}</li></ul>
		            	)
		            }

		            <ul className="listing-info listingInDetailsPage">
		            {
		            	tireData.madein && (
							<li><span>{i18n.t(`common.label.madein`)}</span> {tireData.madein}</li>
		            	)
		            }
		            {
		            	tireData.dealer_warranty && (
							<li><span>{i18n.t(`common.label.warranty`)}</span> 
								{
		                            i18n.language === 'ar' ? (
		                            <span>{tireData.dealer_warranty_arabic}</span>
	                            ) : (
	                            	<span>{tireData.dealer_warranty}</span>
	                            )}

							</li>
		            	)
		            }
		              {
							tireData.price_rear ? (
							<>
							  <li><span>{i18n.t(`tire.label.front_size`)}</span> {`${tireData.f_size}/${tireData.s_size}/${tireData.t_size}`}</li>							  
							  <li><span>{i18n.t(`common.label.front_qty`)}</span> {tireData.quantity}</li>
							  <li><span>{i18n.t(`tire.label.rear_size`)}</span> {`${tireData.f_rear_size}/${tireData.s_rear_size}/${tireData.t_rear_size}`} </li>
							  <li><span>{i18n.t(`common.label.rear_qty`)}</span> {tireData.quantity_rear}</li>
							</>
						) : (
							<>
							  <li><span>{i18n.t(`tire.label.tire_size`)} : </span> {`${tireData.f_size}/${tireData.s_size}/${tireData.t_size}`}</li>
							  <li><span>{i18n.t(`common.label.tire_qty`)}</span> {tireData.quantity}</li>
							</>
						)}

		            </ul>
		          </div>
		        </div>

		        {
		            	tireData.dealer_warranty && (
							<ul className="warranty_ul">
								<li><span className="text-color">{i18n.t(`common.label.warranty`)}</span> 
								{
		                            i18n.language === 'ar' ? (
		                            <span>{tireData.dealer_warranty_arabic}</span>
	                            ) : (
	                            	<span>{tireData.dealer_warranty}</span>
	                            )}

							</li>
							</ul>
		            	)
		            }

		            <ul className=" mobile_listinginfo">
		              {
							tireData.price_rear ? (
							<>
							  <li><span>{i18n.t(`tire.label.front_size`)}</span> {`${tireData.f_size}/${tireData.s_size}/${tireData.t_size}`}</li>							  
							  <li><span>{i18n.t(`common.label.front_qty`)}</span> {tireData.quantity}</li>
							  <li><span>{i18n.t(`tire.label.rear_size`)}</span> {`${tireData.f_rear_size}/${tireData.s_rear_size}/${tireData.t_rear_size}`} </li>
							  <li><span>{i18n.t(`common.label.rear_qty`)}</span> {tireData.quantity_rear}</li>
							</>
						) : (
							<>
							  <li><span>{i18n.t(`tire.label.tire_size`)} : </span> {`${tireData.f_size}/${tireData.s_size}/${tireData.t_size}`}</li>
							  <li><span>{i18n.t(`common.label.tire_qty`)}</span> {tireData.quantity}</li>
							</>
						)}

		            </ul>
		      </div>
		    </div>
	  	</div>
	</section>
	<section className="order_summraypopup">
		<div className="row">
			<div className="col-lg-12">
				<div className="price_mainwrap">
					<div className="order_summray_modalheading">
						<h2>{i18n.t(`booking.price_summary`)}<span>{i18n.t(`booking.tire_qty`, { qty: (Number(tireData.quantity) + Number(tireData.quantity_rear)) })}</span></h2>
					</div>
					<div className="price_summarylisting">
						<ul>
							{
								tireData.price_rear ? (
								<>
									<ul className="front-Rear-D">
										<li>{i18n.t(`common.label.front_price_`)}
											<span>{tireData.price_front} {i18n.t(`sar`)} </span>
										</li>
										<li>{i18n.t(`common.label.front_qty_`)}
											<span>{tireData.quantity}</span>
										</li>
									</ul>
									<ul className="front-Rear-D">
									<li>{i18n.t(`common.label.rear_price_`)}
										<span>{tireData.price_rear} {i18n.t(`sar`)} </span>
									</li>
									<li>{i18n.t(`common.label.rear_qty_`)}
										<span>{tireData.quantity_rear}</span>
									</li>
									</ul>
								</>
							) : (
								<ul className="front-Rear-D">
								<li>{i18n.t(`common.label.tire_price_`)}
									<span>{tireData.price_front} {i18n.t(`sar`)} </span>
								</li>
								<li>{i18n.t(`common.label.tire_qty_`)}
									<span>{tireData.quantity}</span>
								</li>
								</ul>
							)}

							<ul className="front-Rear-D">
								{(this.state.homeInstall && install_center_universal) ? "" : (

									<li>{i18n.t(`common.label.installation_`)}
									{(Number(detail.installation_amount) > 0 && this.state.homeInstall == true) ?
										<span className="">{detail.installation_amount} {i18n.t(`sar`)}</span>
									: (install_center_universal) ?
										(<span className="">{detail.installation_amount} {i18n.t(`sar`)}</span>)
										: (<span className="free">{i18n.t(`common.free`)}</span>)
										
									}
									</li>

								)}

								<li>{i18n.t(`common.label.Delivery`)}
									{detail.delivery_amount != undefined && detail.delivery_amount != '0' ?
											<span className="">{detail.delivery_amount} {i18n.t(`sar`)}</span>
										: (detail.delivery_charge != undefined && (Number(detail.delivery_charge) > 0)) ? <span className="">{Number(detail.delivery_charge)} {i18n.t(`sar`)}</span> :
											<span className="free">{i18n.t(`common.free`)}</span>
										}
								</li>
							</ul>
							
							<ul className="front-Rear-D">
								<li>{i18n.t(`common.label.sub_total_`)} <span>{detail.sub_total} {i18n.t(`sar`)} </span></li>
								{
		                          (Number(detail.discount) > 0 ) ? (
		                            <li>{i18n.t(`common.label.discount`)} {detail.discount_in_percent} % <span>-{detail.discount}  {i18n.t(`sar`)}</span>	
									</li>
		                          ) : ""
		                        }							
								<li>{i18n.t(`common.label.vat`)} {detail.vat_in_percent} % <span>+{detail.vat}  {i18n.t(`sar`)}</span>	</li>
							
							<li><b style={{ fontWeight: 'bold', color: '#000' }}>{i18n.t(`common.label.total_price`)}</b> 
									<span> {detail.total_price} {i18n.t(`sar`)}</span>
							</li>

							{/* <li><a href='javascript:void(0);' onClick={() =>this.openPromotionPopup(Filter.translate(promotions_description, promotions_description_arabic))} > {i18n.t(`includes_gift`)} : {promotions_name} </a></li> */}
							{promotions_name && (
								<li className='include-gift'><div className='promotion_cls'> <p className='included_gift_cls'>{i18n.t(`included_gift`)}</p>&nbsp; {promotions_name} <a href='javascript:void(0);' onClick={() =>this.openPromotionPopup(Filter.translate(promotions_description, promotions_description_arabic))} ><span className='question_mark_cls'><img src={`${config.imageBaseUrl}/question_mark.png`} height={'20px'} width={'20px'} /></span></a></div></li>	
							)}							

							</ul>
							<ul className='paymentoptwrap'>
							<li><span className='titlename'>{i18n.t(`select_payment_option`)}</span></li>
							{pay_by_tamara ==='1' && (
								<li><label> <input type="radio" name="paymenttype" value="Tamara" onChange={()=>this.onChangeValue('Tamara')} /><b>{i18n.t(`four_easy_payment_with`)}</b><span><img src={i18n.language === 'ar' ? images.tamaraImage : images.tamaraImageEn} /></span></label></li>
							)}
							{pay_by_tabby ==='1' && (
							<li><label> <input type="radio" name="paymenttype" value="Tabby" onChange={()=>this.onChangeValue('Tabby')} /><b>{i18n.t(`four_easy_payment_with`)}</b><span><img src={images.tabbyImage} /></span></label></li>
							)}
							{pay_by_cash ==='1' && (
							<li><label> <input type="radio" name="paymenttype" value="Cash" onChange={()=>this.onChangeValue('Cash')} /><b>{i18n.t(`FREE_DELIVERY`)}</b><span></span></label></li>
							)}
							</ul>
						</ul>
					</div>

					{isLoading && (<Loader />) }

					<div className="prce_summarylisting_btn">

						{
			              	tireData.record_type === 'EJ Normal' &&  ( 	
					           <button type="submit" onClick={this.onBooking} name="ejnormal">{i18n.t('booking.retailer.Confirm_Order')}</button>
					        )
			            }
			            {
			              	tireData.record_type === 'Retailer' &&  ( 	
					            <button type="submit" onClick={this.onBooking} name="retailer">{i18n.t('booking.retailer.Confirm_Booking')}</button>
					        )
			            }
			            {
			              	tireData.record_type === 'EJ Basic' &&  ( 	
					            <button type="submit" onClick={this.onBooking} name="ejbasic">{i18n.t('booking.retailer.Confirm_Order')}</button>
					        )
			            }
					</div>

					{/* <div className="free_delivry_link">
						{
							tireData.record_type === 'Retailer' ? i18n.t('booking.retailer.free') : i18n.t('booking.ej.free')
						}
					</div> */}
				</div>
			</div>
		</div>
	</section>
</div>
		<AlertModal
            show={isShowAlertModal}
            handleClose={this.toggelAlertModal}
            title={i18n.t(`Order_Limit_Title`)}
            description={i18n.t(`Order_limit_Description`)}
            actionType={'remove'}
            handleAction={()=> {}}
			isConfirmation={false}
			closeButtonName={i18n.t(`OK`)}
			handleNavigation={() => history.push(PATH.CART)}
        />

		<AlertModal
			show={this.state.isShowAlertModal2}
			handleClose={this.toggelAlertModal2}
			title={i18n.t(`gift_popup_details`)}
			description={this.state.popupMsg}
			actionType={'remove'}
			handleAction={()=> {}}
			isConfirmation={false}
			closeButtonName={i18n.t(`OK`)}
			// handleNavigation={() => history.push(PATH.CART)}
        />

            </>
      )
    }
}

 
 
 export default withRouter<any, any>(withTranslation()(DetailPage)) ;