export function isValidate({ fields, required = [] }: { fields: { [key in string]: any }, required: string[] }) {
    let res = {};
    if (Object.keys(fields).length) {
        for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
                // console.log('fields[key] :', fields[key]);
                console.log('fields[key]', fields[key]);
                
                if (((required.length && required.indexOf(key) >= 0) || required[0] === 'all') && !fields[key]) {
                    res = {
                        ...res,
                        [key]: {
                            error: true,
                            message: getErrorMessage('required', key),
                        },
                    };
                }
            }
            if (fields[key]) {
                // const url = fields[key].search('url')
                switch (key) {
                    // @ts-ignore-start
                case 'email': {
                    if (fields[key].length && !emailFilter(fields[key])) {
                        res = {
                            ...res,
                            email: {
                                error: !emailFilter(fields[key]),
                                message: getErrorMessage('invalid', key),
                            },
                        };
                        break;
                    }
                }
                case 'redirect_url': {
                    if (fields[key].length && !websiteFilter(fields[key])) {
                        res = {
                            ...res,
                            webUrl: {
                                error: !websiteFilter(fields[key]),
                                message: getErrorMessage('invalid', key),
                            },
                        };
                        break;
                    }
                }
                }
            }

            if (key.search('quantity') >= 0) {
                
            }
        }
    }
    return res;
}


function getErrorMessage(type: string, field: string) {
    console.log('type', type);
    
    const message = {
        required: ':field can not be empty',
        invalid: 'Please provide a vailid :field'
    }
    // @ts-ignore-start
    return message[type].replace(':field', field).replace(/_/g, ' ');
}

function emailFilter(email: string) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
}

export function websiteFilter(webUrl: string) {
    const regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    return regex.test(webUrl);
}

export function renderError  (field: string, validations: { [key in string] : any } = {} )  {
      // @ts-ignore-start
    const keys = Object.keys(validations);
    console.log('keys', keys);
    
    if (keys.indexOf(field) >= 0) {
      return validations[field].message;
    }
    return ''
      // @ts-ignore-end
  }

// export function handleSize(file, field) {
//     return new Promise((resolve, reject) => {
//         let obj = {
//             [field]: { message: '', error: false },
//         };

//         const image = new Image();
//         const attr = {
//             ':field': field,
//             ':size': `${DIMENSIONS[field].height}x${DIMENSIONS[field].width}`,
//         };
//         // console.log("file", file);
//         image.onload = function () {
//             console.log('image.height :', image.height);

//             if (image.height !== DIMENSIONS[field].height && image.width !== DIMENSIONS[field].width) {
//                 const message = Helper.translate('errors.dimension').replace(/:field|:size/gi, (x) => {
//                     const str = x.replace(/ +/g, '');
//                     return attr[str];
//                 });
//                 console.log('msg:', message);
//                 obj = {
//                     [field]: { message, error: true },
//                 };
//                 reject(obj);
//             } else {
//                 resolve(obj);
//             }
//         };
//         image.src = file;
//     });
// }
