import { put, takeEvery, call, delay } from 'redux-saga/effects'
import { order as orderActions} from '../actions'
import { api } from '../services';
import { IActionOrderGet, IActionOrderUpdateStatus } from '../actions/orderActions';

export function* getOrderSaga(action: IActionOrderGet) {
  try {
    const { data= {}, statusCode }  = yield call(api.order.getOrder, action.payload);
    if (statusCode === 200) {
      const { totalRecords, totalPages } = data;
      yield put(orderActions.setOrder({ items: data.records, limit: data.perPage, totalPage: totalPages, totalRecords }))
    } else {
      yield put(orderActions.setOrder({ items: [], limit: "", totalPage: "", totalRecords: "" }))
    }
  } catch(e) {
    
  }

}

export function* updateOrderStatusSaga(action: IActionOrderUpdateStatus) {
  try {
    const { statusCode }  = yield call(api.order.updateOrderStatus, action.payload);
  } catch(e) {
    
  }

}



export default [
  takeEvery(orderActions.ActionTypes.ORDER_GET, getOrderSaga),
  takeEvery(orderActions.ActionTypes.ORDER_UPDATE_STATUS, updateOrderStatusSaga),
];