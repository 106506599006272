import { api } from '..';
import { IInventoryGet, IInventoryAct, IInventoryAdd, IInventoryEdit, IInventoryAll, IInventoryBulkUpload } from '../../models/IInventoryState';

async function getInventory(params: IInventoryGet) {
    const apiType: Record<string, string> = {
        Normal: '/normalinv/list',
        Basic:  '/basicinv/list',
        Dealer:  '/dealer/inventorylist',
    }
    try {
        return api.postApi(apiType[params.type], params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function delteInventory(params: IInventoryAct) {
    const apiType: Record<string, string> = {
        Normal: '/normalinv/deleteinv',
        Basic:  '/basicinv/deleteinv'
    }
    try {
        return api.postApi(apiType[params.type], params)
    } catch (e) {
        Promise.reject(e)
    }
}


async function addInventory(params: IInventoryAdd) {
    const apiType: Record<string, string> = {
        Normal: '/normalinv/adddinv',
        Basic:  '/basicinv/adddinv'
    }
    try {
        return api.postApi(apiType[params.type], params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function editInventory(params: IInventoryEdit) {
    const apiType: Record<string, string> = {
        Normal: '/normalinv/updatedinv',
        Basic:  '/basicinv/updatedinv'
    }
    try {
        return api.postApi(apiType[params.type], params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getAll(params: IInventoryAll): Promise<any> {
    const apiType: Record<string, string> = {
        Normal: '/exports/normalinv',
        Basic:  '/exports/basicinv',
        Dealer:  '/exports/dealerinv'
    }
    try {
        return api.postApi(apiType[params.type], params)
    } catch (e) {
        Promise.reject(e)
    }
}

async function bulkUpload(params: IInventoryBulkUpload): Promise<any> {
    const apiType: Record<string, string> = {
        Normal: '/normalinv/importinv',
        Basic:  '/basicinv/importinv'
    }
    try {
        return api.postApi(apiType[params.type], params)
    } catch (e) {
        Promise.reject(e)
    }
}

export {
    getInventory,
    delteInventory,
    addInventory,
    editInventory,
    getAll,
    bulkUpload
}
