import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import '../../../../styles/slick.css';
import { Link } from 'react-router-dom';
import { PATH } from '../../../../navigation/NavigationPath';
import i18n from 'i18next';
import Slider from "react-slick";
import { IHomeBanner } from '../../../../models/IHomeState';
import config from '../../../../constants/config';
import {
  isMobile
} from "react-device-detect";

interface IProps {
  items:IHomeBanner[]
  onSearch: (param: IHomeBanner) => void;
}

interface IState {
  activeIndex: 0
}



export default class Banner extends React.Component<IProps, IState> {
  slider: any = createRef()
  state: IState = {
    activeIndex : 0
  }  

  afterChange = (index: any) => {
    this.setState({ activeIndex: index })
  }
  
  beforeChange = ( prev: any, next: any ) => {
    console.log('prev', prev);
    
  }

  slickToGo = (index: number) => () => {
    // @ts-ignore-start
    this.slider.slickGoTo(index, true)
    
  }

  SearchTire = (param: IHomeBanner) => () => {
    
  }

  renderBanner = () => {
    const { items, onSearch } = this.props;
    if(items)
    return items.map((row: IHomeBanner, index: number) => {
      return (
        <div className="slide-btn" key={index} onClick={() => onSearch(row)}>
        <div className="img-fill">
            <div className="img-box">
              <img src={`${config.imageBaseUrl}${isMobile ? row.img_mobile : row.img}`} alt="slide 1" />
            </div>
      </div>

    </div>
      )
    })
  }

    render() {
      const { activeIndex } = this.state;
      const  settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        dotsClass: 'slider-dots',
        focusOnSelect: true,
        autoplaySpeed:5000,
        appendDots: (dots: any) => {
          return (
            <div className="slider-dots-box">
              <ul className="slider-dots" role="tablist" >
                {dots.map((item: any, index: number) => {
                  return (
                    <li className="" role="presentation" key={index}>
                      
                        {
                          activeIndex === index ? (
                            // <button type="button" role="tab" id={`slick-slide-control0${index}`} aria-controls={`slick-slide0${index}`} aria-label={`${index+1} of 3`} tabIndex={-1} onClick={this.slickToGo(index)}>
                            <svg className="progress-svg" width="40" height="40">
                              <g transform="translate(20,20)">
                                <circle className="circle-go" r="9" cx="0" cy="0"></circle>
                                <text className="circle-tx" x="0" y="1" alignmentBaseline="middle" strokeWidth="2" textAnchor="middle">0</text>
                              </g>
                            </svg>
                            // {/* </button> */}
                          ) : <button type="button" role="tab" id={`slick-slide-control0${index}`} aria-controls={`slick-slide0${index}`} aria-label={`${index+1} of 3`} tabIndex={-1} onClick={this.slickToGo(index)}></button>
                        }
                    </li>
                  );
                })}
              </ul>
            </div>
          )
        },
      };

      return (
         <section className="banner-section bg-size-cover bg-position-center">
          <div className="container-fluid">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 pr-lg-5">
                <div className="banner-box pr-lg-5">
                  {/* <div className="slider-nav"> */}
                      {/* @ts-ignore-start */}
                      <Slider 
                        {...settings} 
                        afterChange={this.afterChange}
                        beforeChange={this.beforeChange}
                        ref={slider => (this.slider  = slider)}
                        className="slider-nav"
                      >
                        {this.renderBanner()}
                      </Slider>
                  {/* </div> */}
                <div className="slider-dots-box"></div>
              </div>

            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              {/* <div className="banner-tire">
                  <img src={images.bannerTire}  alt="" />
              </div> */}
                <div className="seachTbwrp">
                        <div className="main-heading">
                            <h2 className="webtxt">  <span>{i18n.t('Find.the')}</span> {i18n.t('Right.tires.for.you')}</h2>
                            <h2 className="mobViewtxt"> <span>{i18n.t('Find.the')}</span> {i18n.t('Right.tires.for.you')}</h2>
                        </div>
                        <div className="search-tab">
                            <ul>
                                <li><Link to={PATH.SEARCH_BY_BRAND}>
                                        <img src={images.carSelected} alt="img" className="imgNormal" />
                                        <img src={images.carUnselected} alt="img" className="imgActive" />
                                        <span> <span className="searchby">{i18n.t('home.search.search.title')}</span> {i18n.t('home.label.by_vehicle')}</span>
                                        </Link>
                                </li>


                                
                                <li>
                                <Link to={PATH.SEARCH_BY_SIZE}>
                                        <img src={images.tyreSelected} alt="img" className="imgNormal" />
                                        <img src={images.tyreUnselected} alt="img" className="imgActive" />
                                        <span>  <span className="searchby">{i18n.t('home.search.search.title')}</span> {i18n.t('home.label.by_size')}</span>
                                        </Link>
                                </li>

 


                            </ul>
                        </div>
                    </div> 
            </div>
          </div>
          </div>
        </section>
      )
    }
}