import React from 'react';
import { images } from '../../constants/Images';
import { Tooltip, Button, OverlayTrigger } from 'react-bootstrap';
import './Marker.css'
interface IProps {
  lat: number;
  lng: number;
  image: string;
  name: string
}

export default class Marker extends React.PureComponent<IProps> {
static defaultProps = {
  name: ''
}
  renderTooltip = () => {
    return (
      <Tooltip id="button-tooltip" >
        Simple tooltip
      </Tooltip>
    )
  }
  

    render() {
      const { image, name } = this.props;
      return (
         <div style={{ zIndex: 999, }} className='ht'>
            <span className={'tooltip-marker'}>{name}</span>
              <img src={image} style={{ height:'50px', width:'50px'  }} className="ht"/>
            
              {/* <span className="tooltip">this is the tooltip alshdgwh gahfguo 
wfhg fghwoug wugw hgrwuog hwaur guoarwhg rwu</span> */}
       </div>
      )
    }
}