import { api } from '..';

async function getShop(user_id: string) {
    try {
        return api.postApi(`/dealer/shoplist`,  { user_id })
    } catch (e) {
        return Promise.reject(e)
    }
    
}

export {
    getShop,
}
