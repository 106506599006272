import React from 'react';

import { images } from '../../../constants/Images';
import IRootState, { ISearchState, ICoreState } from '../../../models';
import { connect } from 'react-redux';
import { Header, BrandCard, Years, SizeCard } from '../Components';
import { search as searcActions } from '../../../actions'
import { PATH } from '../../../navigation/NavigationPath';
import { withRouter } from "react-router";
import { ISearchGetYear, ISearchGetModel, ISearchBrand, ISearchGetSize, ISearchGetTires, ISearchGetTireSize, typeTireSize, ISearchSetTireSize } from '../../../models/ISearchState';
import { Modal, Input } from '../../../components';
import './SizePage.css'
import { validation } from '../../../services';
import { IHomeBanner } from '../../../models/IHomeState';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

interface IProps {
  getBrand: () => void;
  search: ISearchState;
  history: any;
  getYear: (param: ISearchGetYear) => void;
  match: { params: { id: string } }
  location: { pathname: string, state: { id: string, path: string, bannerData?: IHomeBanner } }
  getModel: (param: ISearchGetModel) => void;
  getSize: (param: ISearchGetSize) => void;
  getTires: (param: ISearchGetTires) => void;
  getTireSize: (param: ISearchGetTireSize) => void;
  core: ICoreState;
  setTireSize: (param: ISearchSetTireSize) => void;
}

interface IState {
  headerMenu: { name: string, value: any, id: number }[];
  activeHeaderMenu: number;
  toggelModal: boolean;
  tireData: ISearchGetTires;
  formData: { fields: { [key in string]: any }; required: string[] };
  validation: any;
  selectedSize: string[];
  showQuantity: boolean;
}

class SizePage extends React.Component<IProps, IState> {

  state: IState = {
    headerMenu: [
      { name: `search.header.width`, value: '', id: 1 },
      { name: `search.header.aspect`, value: '', id: 2 },
      { name: `search.header.diameter`, value: '', id: 3 },
    ],
    activeHeaderMenu: 1,
    toggelModal: false,
    tireData: {
      f_size: 0,
      s_size: 0,
      t_size: 0,
      city_id: this.props.core.selectedCity.id,
      rear_size_tyre: 0,
      f_size_rear: 0,
      s_size_rear: 0,
      t_size_rear: 0,
      quantity: 0,
      quantity_rear: 0,
      promotions:{},
      promotions_rear:{}
    },
    formData: {
      fields: {
        quantity: '',
        quantity_rear: ''
      },
      required: ['quantity']
    },
    validation: {},
    selectedSize: ['front'],
    showQuantity: false
  }

  componentDidMount = () => {
    const { getTireSize } = this.props;
    console.log('componentDidMount');

    getTireSize({ type: 'f' });
    window.onpopstate = () => {
      const { location } = this.props;
      if (this.state.selectedSize.length > 1) {
        this.resetSearch()
      }
    };
  }

  resetSearch = () => {
    const { tireData } = this.state;
    const { getTireSize } = this.props;
    this.setState({
      selectedSize: ['front'],
      showQuantity: false,
      tireData: {
        f_size: 0,
        s_size: 0,
        t_size: 0,
        city_id: this.props.core.selectedCity.id,
        rear_size_tyre: 0,
        f_size_rear: 0,
        s_size_rear: 0,
        t_size_rear: 0,
        quantity: 0,
        quantity_rear: 0,
        promotions:{},
       promotions_rear:{}
      },
      headerMenu: [
        { name: `search.header.width`, value: tireData.f_size, id: 1 },
        { name: `search.header.aspect`, value: tireData.s_size, id: 2 },
        { name: `search.header.diameter`, value: tireData.t_size, id: 3 },
      ],
      activeHeaderMenu: 3
    })
    getTireSize({ type: 's', fsize: this.state.headerMenu[0].value, ssize: this.state.headerMenu[1].value })
    getTireSize({ type: 't', fsize: this.state.headerMenu[0].value, ssize: this.state.headerMenu[1].value })
  }

  renderItem = () => {
    const { search, location } = this.props;
    const { activeHeaderMenu, headerMenu } = this.state;

    const DATA: { [key in number]: typeTireSize } = {
      1: 'f',
      2: 's',
      3: 't',
    }
    const type = DATA[activeHeaderMenu]
    return (
      <div className="col-lg-12">
        <ul className="year-filter">
          {
            search.tireSize[type].map((row: any, index: number) => {
              return <Years
                items={row} key={index}
                onClick={this.onSelect}
                selectedYear={0}
                selectedModel={0}
                headerMenu={headerMenu}
                activeHeaderMenu={activeHeaderMenu}
              />
            })
          }
        </ul>
      </div>
    )
  }

  onProceed = () => {
    const { headerMenu, formData, tireData, selectedSize } = this.state;
    const { getTires, history, core, location } = this.props;
    const param = {
      ...selectedSize.length === 1 ? {
        f_size: headerMenu[0].value,
        s_size: headerMenu[1].value,
        t_size: headerMenu[2].value,
      } : tireData,
      city_id: core.selectedCity.id,
      rear_size_tyre: selectedSize.length === 1 ? 0 : 1,
      f_size_rear: selectedSize.length === 1 ? '' : headerMenu[0].value,
      s_size_rear: selectedSize.length === 1 ? '' : headerMenu[1].value,
      t_size_rear: selectedSize.length === 1 ? '' : headerMenu[2].value,
      quantity: 1,
      quantity_rear: 0,
    }
    const validate = validation.isValidate(formData)
    this.setState({ validation: validate })
    console.log('formData', formData);

    if (!Object.keys(validate).length) {
      // getTires({...param, ...formData.fields, ...selectedSize.length === 1 && { quantity_rear: formData.fields.quantity } });
      history.push(
        PATH.SEARCH_TIRES_LIST,
        {
          ...param,
          ...formData.fields,
          ...location.state && location.state.bannerData &&
          {
            offer_id: location.state.bannerData.offer_id,
            coupon_check: location.state.bannerData.offer_id ? 1 : 0,
          }
        }
      )
    }

  }

  onSelectRear = () => {
    const { headerMenu, tireData, formData, selectedSize } = this.state;
    const { history } = this.props;
    const param = {
      f_size: headerMenu[0].value,
      s_size: headerMenu[1].value,
      t_size: headerMenu[2].value,
      f_size_rear: headerMenu[0].value,
      s_size_rear: headerMenu[1].value,
      t_size_rear: headerMenu[2].value,
    }

    this.setState({
      tireData: { ...tireData, ...param },
      activeHeaderMenu: 1,
      headerMenu: [
        { name: `search.header.width`, value: '', id: 1 },
        { name: `search.header.aspect`, value: '', id: 2 },
        { name: `search.header.diameter`, value: '', id: 3 },
      ],
      formData: {
        ...formData,
        fields: { quantity: '', quantity_rear: '' },
        required: ['quantity', 'quantity_rear']
      },
      showQuantity: false,
      selectedSize: [...selectedSize, 'rear']
    })
    this.onToggel()
    history.push(PATH.SEARCH_BY_SIZE)
  }


  onSelect = ({ id, type, name }: { id: any, type: string, name: string }, isClickMenu: boolean = false) => {
    const { getTireSize } = this.props;
    const { activeHeaderMenu, headerMenu, tireData, selectedSize } = this.state
    const header = headerMenu;
    header.splice(activeHeaderMenu - 1, 1, { ...header[activeHeaderMenu - 1], value: id })
    console.log('header', header);

    this.setState({
      activeHeaderMenu: activeHeaderMenu === 3 ? 3 : activeHeaderMenu + 1,
      headerMenu: header
    }, () => {
      const DATA: { [key in number]: typeTireSize } = {
        1: 'f',
        2: 's',
        3: 't',
      }

      if (activeHeaderMenu <= 2) {
        getTireSize({ type: DATA[activeHeaderMenu + 1], fsize: this.state.headerMenu[0].value, ssize: this.state.headerMenu[1].value })
      } else {
        if (tireData.f_size) {
          // this.getTire() 
          this.setState({ selectedSize: [...selectedSize, 'rear'], showQuantity: true })
        }
        if (!isClickMenu) {
          this.onToggel();
        }
      }
    })
  }

  onToggel = () => {
    const { toggelModal } = this.state;
    this.setState({ toggelModal: !toggelModal })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  handleChange = (e: any, field: string) => {
    var arabics = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    //var arabics2 = ['۰', '١', '٢', '', '', '', '', '', '', '', ''];
    var arabics2 = ['٠', '١', '٢', '٣', '٤', '٥', '٥', '٧', '٨', '٩'];
    var engs = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let value = e.target.value;

    console.log(value);
    let index = arabics.indexOf(value);
    if (index != -1) {
      value = engs[index];
    }
    console.log(index, value);

    let index2 = arabics2.indexOf(value);
    if (index2 != -1) {
      value = engs[index2];
    }
    console.log(index2, value);

    const { formData } = this.state;
    value = value.replace(/^0+/, '')
    if (value.length > 1) {
      value = value.slice(1, 2);
    }

    if (!parseInt(value))
      value = '';

    this.setState({ formData: { ...formData, fields: { ...formData.fields, [field]: value } } })
  }

  renderInput = () => {
    const { formData, selectedSize } = this.state
    const quantityInputArr = selectedSize.length > 1 ? ['quantity', 'quantity_rear'] : ['quantity']
    return quantityInputArr.map((raw: string, index: number) => {
      return (
        <Input
          type={'text'}

          // @ts-ignore-start
          value={formData.fields[`${raw}`]}
          // @ts-ignore-end
          name="quantity"
          onChange={(e: any) => this.handleChange(e, raw)}
          placeholder={i18n.t(raw === 'quantity' ? selectedSize.length > 1 ? 'search.quantity.input.placholder.front' : 'search.quantity.input.placholder' : 'search.quantity.input.placholder.rear')}
          error={validation.renderError(raw, this.state.validation)}
          key={index}
          containerStyle={{ width: selectedSize.length > 1 ? '49%' : '100%' }}
          maxLength={1}
        />
      )
    })
  }

  onProceedSame = () => {
    const { formData } = this.state;
    this.setState({ showQuantity: true, formData: { ...formData, required: ['quantity'] } })
  }

  onClickMenu = (id: number) => {
    console.log('id', id);
    const { headerMenu } = this.state;
    const { setTireSize } = this.props
    // this.onSelect({ id, type: '', name: '' }, true)
    this.setState({ activeHeaderMenu: id })
    if (id !== 3) {
      this.setState({
        headerMenu: [
          { name: `search.header.width`, value: headerMenu[0].value, id: 1 },
          { name: `search.header.aspect`, value: id === 2 ? headerMenu[1].value : '', id: 2 },
          { name: `search.header.diameter`, value: '', id: 3 },
        ],
      })
      setTireSize({ type: 't', items: [] })

    }
  }

  render() {
    const { headerMenu, activeHeaderMenu, toggelModal, tireData, selectedSize, showQuantity } = this.state;
    console.log('headerMenu', headerMenu);

    return (
      <>
        <div className="toolbar-enabled toolbar-marvelapp">
          <Header
            type={'type'}
            menu={headerMenu}
            active={activeHeaderMenu}
            title={i18n.t(`search.header.size.title`)}
            goBack={this.goBack}
            showRear={selectedSize.length > 1}
            tireData={tireData}
            onClickMenu={this.onClickMenu}
          />
          <section className={'brands-estb'}>
            <div className="container">
              <div className="row pb-2 pb-sm-0 pb-md-3 brands">
                {this.renderItem()}
              </div>
            </div>
          </section>
        </div>
        <Modal
          onToggel={this.onToggel}
          show={toggelModal}
        >

          <div className="modal-header title-modal">
            <h4 className="modal-title">
              {
                showQuantity ? i18n.t(`search.size.modal.title.diffrent_size`) : i18n.t(`search.size.modal.title.same_size`)
              }
            </h4>
          </div>
          {
            showQuantity ? (
              <>
                <div className="modal-body quantity sizeQuantity">
                  {this.renderInput()}
                </div>
                <div className="modal-footer proceed">
                  <button type="button" className="footer-btn" onClick={this.onProceed}>{i18n.t(`search.size.modal.button.proceed`)}<img src={images.rightIcon} /></button>
                </div>
              </>
            ) : (
                <>

                  <div className="modal-footer proceed">
                    <button type="button" className="footer-btn" onClick={this.onProceedSame}>{i18n.t('Yes_Proceed')}<img src={images.rightIcon} /></button>
                  </div>

                  <a href={`javascript:void(0);`} className='selectSizeLink' onClick={this.onSelectRear}>{i18n.t('FRONT_REAR_TITLE')}</a>
                </>
              )
          }
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  search: state.search,
  core: state.core
});

const mapDispatchToProps = {
  getBrand: searcActions.getBrand,
  getYear: searcActions.getYear,
  getModel: searcActions.getModel,
  getSize: searcActions.getSize,
  getTires: searcActions.getTires,
  getTireSize: searcActions.getTireSize,
  setTireSize: searcActions.setTireSize,
}



export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(SizePage)))  