import React from 'react';
import { default as Paginate } from "react-js-pagination";

interface IProps {
   pages: number
   active: number;
   onClick: (page: number) => void;
   limit: number
   total: number
}

export default class Pagination extends React.PureComponent<IProps> {

    render() {

      const { onClick, active, pages, limit, total } = this.props;
      return (
         <div className="col-md-12">
            <div className="admin-container">
               <div className="admin-pagination">
                   <Paginate
                     activePage={active}
                     itemsCountPerPage={limit}
                     totalItemsCount={total}
                     pageRangeDisplayed={5}
                     onChange={onClick}
                     itemClass={'page-item'}
                     innerClass={`pagination pagination-sm`}
                     linkClass={`page-link`}
                  />
               </div>
            </div>
         </div>
      )
    }
}