import React from 'react';
import './Loader.css'

interface IProps {

}

export default class Loader extends React.PureComponent<IProps> {
    render() {
      return (
         <div className="row">
         <div className="col-lg-12">
           <div className="loader loader--glisteningWindow"></div>
           <p>Loading ...</p>
         </div>
       </div>
      )
    }
}