import axios from 'axios';
import * as methods from './api';
import config from '../constants/config';
import i18n from './i18n';

const init = () => {
    axios.defaults.baseURL = config.apiUrl;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type,Authorization';
    axios.defaults.headers.common['Cache-Control'] = 'max-age=31536000';
    axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST';
}

const configure = ({ auth }: { auth: { token: string } }) => {
    if (auth) {
        const { token } = auth;
        axios.defaults.headers.common['x-access-token'] = token;
    }
}

class appSetting {
    language: string = 'en'

    getLang = () => {
        return this.language
    }

    updateLang = (lang: string = 'en') => {
        this.language = lang
        console.log('this.language', this.language);

    }
}

const getappSetting = new appSetting;

const postApi = async (url: string, params: any = {}) => { 
    // console.log("AAA=="+url);
    if (url == '/mycarts/orderlist') {
        let formdata = new FormData();
        formdata.append("access_key", "r9ga6iv7ri1nc3ah4ra8u0h0an")
        formdata.append("lang", getappSetting.language)
        if (Object.keys(params).length) {
            for (let key in params) {
                formdata.append(`${key}`, params[key])
            }
        }
        let userDtl = await fetch(`${config.apiUrl}${'/user/userinfo'}`, {
            method: 'POST',
            body: formdata
        }).then(function (response) {
            return response.json();
        });
        let status = userDtl.responseData.data.status;
        let deleteStatus = userDtl.responseData.data.is_delete;
        // alert(status);
        // alert(deleteStatus);
        if (status == 'Deactive' || deleteStatus == 1 || status == "Archive") {
            localStorage.setItem('persist:root', '');
            window.location.href = '/';
        }
    }

    if (params.lang) {
        getappSetting.updateLang(params.lang)
    }

    //console.log('postApi', getappSetting.language);
    return new Promise((resolve, reject) => {
        let formdata = new FormData();
        formdata.append("access_key", "r9ga6iv7ri1nc3ah4ra8u0h0an")
        formdata.append("lang", getappSetting.language)
        if (Object.keys(params).length) {
            for (let key in params) {
                formdata.append(`${key}`, params[key])
            }
        }
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: formdata
        };
        console.log('formdata', formdata);

        fetch(`${config.apiUrl}${url}`, requestOptions).then(function (response) {
            //console.log("Some");
            return response.json();
        }).then(function (result) {
            ///console.log("dddd");
            resolve({ ...result.responseData, statusCode: result.statusCode })
            //console.log(result.responseData);
        }).catch((e) => {
            reject(e)
        });
    })
}


export default {
    init,
    configure,
    postApi,
    ...methods,
}
