import React from 'react';
import { Container } from '../../../components/Base';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import IRootState, { ISearchState, ICoreState,IUserState } from '../../../models';
import { search as searcActions, cart as cartActions, core as coreActions } from '../../../actions';
import ICartState, { ITabbyPaymentParam, ICartAddParam, IPaymentConfirmationParam } from '../../../models/ICartState';
import { connect } from 'react-redux';
import { HomeLoader, Notification } from '../../../components';
import { ModalRight, AddCartSuccessModal, Modal } from '../../../components';
import { PATH } from '../../../navigation/NavigationPath';
import { AlertModal } from '../../../components';
import { i18n } from '../../../services';

interface IProps {
  search: ISearchState;
  getTabbyPayment: (param: ITabbyPaymentParam) => void;
  getPaymentConfirmation: (param: IPaymentConfirmationParam) => void;
  cart: ICartState;
  addToCart: (param: ICartAddParam) => void;
  addToCartSuccess: (param: any) => void;
  history: any;
}

interface IState {
  isModalShow: boolean;
  isShowAlertModal: boolean
  tabbyPaymentMsg:string
}


class TabbySuccess extends React.Component<IProps, IState> {

  state: IState =  {
    isModalShow : false,
		isShowAlertModal: false,
    tabbyPaymentMsg:""
	}

  componentDidMount() {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let referenceId = url.searchParams.get("referenceId");
    let payment_id = url.searchParams.get("payment_id");
    
    const { getTabbyPayment } = this.props;

    if(payment_id && referenceId){
      let orderDataParam: string | null = localStorage.getItem('addtocartparam');
      let grandTotal: number = 0;
      if(orderDataParam){
        let orderData_obj: {} = JSON.parse(orderDataParam!);
        grandTotal = (orderData_obj as { grand_total: number }).grand_total;
      } 
    const orederParam = {
      tabby_payment_id:payment_id ? payment_id : "",
      order_reference:referenceId ? referenceId : "",
      amount:grandTotal,
      // secret_key:"sk_test_67075862-2abb-4902-8e77-fbf4bf273ea9" //Sandbox
      secret_key:"sk_caa914d4-2d9e-4693-8584-ad7965b4f645" //Live
    }
    getTabbyPayment(orederParam);
  }
  }

  componentDidUpdate(prevProps: IProps) {
    const { cart, addToCart, getPaymentConfirmation } = this.props;
    //  console.log('rammmmmmmmm====>', cart);
    if(prevProps.cart.tabbyPayment !== cart.tabbyPayment){

      // console.log('hello====>', cart.tabbyPayment);


      if(cart.tabbyPayment.status_code === 200){
        // let orderDataParam: string | null = localStorage.getItem('addtocartparam'); // Example of a nullable value
        // // console.log('aaa====>', orderDataParam);
        // let param2 = JSON.parse(orderDataParam!);
        // const param = {
        //   ...param2,
        //   platform_type: "Web",
        //   order_number: cart.tabbyPayment.order_number,
        //   reference_number: cart.tabbyPayment.reference_number,
        // }
        // localStorage.removeItem('addtocartparam');
        // addToCart(param);

        const paymentParam = {
          order_number: cart.tabbyPayment.order_number,
          reference_number: cart.tabbyPayment.reference_number,
          status:'Confirmed'
        }

        localStorage.removeItem('addtocartparam');
        localStorage.removeItem('paymentSuccessResponse');
        getPaymentConfirmation(paymentParam);

      } else {
        this.setState({tabbyPaymentMsg:cart.tabbyPayment.message});
        this.toggelAlertModal();
        // alert(cart.tamaraConfirmation.message);
        // window.location.href = "/";
      }
    }

    if(prevProps.cart.paymentConfirmation !== cart.paymentConfirmation){ 
      // console.log('aaaaaaaaaa=======================>');
      if(cart.paymentConfirmation.status_code === 200){
        // console.log('bbbbbbbbbbb=======================>');
        this.setState({isModalShow:true});
      } else {
        // console.log('ccccccc=======================>');
        this.setState({tabbyPaymentMsg:cart.paymentConfirmation.message});
        this.toggelAlertModal();
      }
    }

  }

  toggelAlertModal = () => {
		const { isShowAlertModal } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal })
    
	}

  closeAlertModal = () => {
    const { history} = this.props;
		const { isShowAlertModal } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal })
    history.push(PATH.CART);
	}

  onCloseCartSuccess = () => {
    const { addToCartSuccess, history } = this.props;
    addToCartSuccess({})
    history.push(PATH.CART)
  }

    render() {

      const { cart, history } = this.props;
      const { isShowAlertModal, tabbyPaymentMsg, isModalShow } = this.state;
       
      return (
        <>
        {/* <HomeLoader/> */}

        {cart.cartSuccess !== undefined ? (
                    isModalShow ? (
                      <AddCartSuccessModal
                        // @ts-ignore-end
                        items={cart.cartSuccess}
                        onToggel={this.onCloseCartSuccess}
                        show={!!Object.keys(cart.cartSuccess).length}
                      />
                    ) : ''
                  ) : (
                    <HomeLoader />
            )}


        <AlertModal
            show={isShowAlertModal}
            handleClose={this.closeAlertModal}
            title={(`Alert Message`)}
            description={tabbyPaymentMsg}
            actionType={'remove'}
            handleAction={()=> {}}
            isConfirmation={false}
            closeButtonName={i18n.t(`OK`)}
            handleNavigation={() => history.push(PATH.CART)}
         />

        </>

      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  search: state.search,
  core: state.core,
  cart: state.cart,
  isLoggedin: state.auth.isLoggedin,
  user: state.user.profile
});

const mapDispatchToProps = {
  getTabbyPayment: cartActions.getTabbyPayment,
  getPaymentConfirmation: cartActions.getPaymentConfirmation,
  addToCart: cartActions.addToCart,
  addToCartSuccess: cartActions.addToCartSuccess,
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(TabbySuccess)) )