import { put, takeEvery, call, delay, select } from 'redux-saga/effects'
import {user as userActions} from '../actions'
import { IActionLogin, IActionResendOTP } from '../actions/authActions';
import { api } from '../services';
import { IActionUserGetNotification } from '../actions/userActions';
import { getUser } from '../selectors';

export function* notificationSaga(action: IActionUserGetNotification) {
  try {
    const user = yield select(getUser)
    const { data = [] }  = yield call(api.user.getNotification, { user_id: user.profile.id, user_type: 'Customer' });
    console.log('user', user);
    console.log('datnotificationSagaa', data);
    
    yield put(userActions.setNotification(data));
  } catch(e) {
    
  }

}

export default [
  takeEvery(userActions.ActionTypes.GET_NOTIFICATION, notificationSaga),
];