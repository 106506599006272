import React, { Component } from 'react';
import './Container.css'
import Header from '../Header';
import Footer from '../Footer';
import Location from '../../Location';
import IRootState, { ICoreState, IAuthState, IUserState, ILangState } from '../../../models';
import { connect } from 'react-redux';
import { core as coreActions, auth as authActions, lang as languageActions, home as homeActions } from '../../../actions'
import { ICoreCities, ICoreLangType } from '../../../models/ICoreState';
import { geoCode, api } from '../../../services';
import LoginModal from '../../LoginModal';
import { IUserLogin } from '../../../models/IAuthState';
import ModalRight from '../../ModalRight';
import NotificationCard from '../../NotificationCard';
import { IUserNotification } from '../../../models/IUserState';
import { PATH } from '../../../navigation/NavigationPath';
import { images } from '../../../constants/Images';
import i18n, { switchLang, initI18n } from '../../../services/i18n';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// import '../../../styles/App.css'
interface IProps {
   children: React.ReactElement<any>
   core: ICoreState
   updateCity: (param: ICoreCities) => void;
   auth: IAuthState
   login: (param: IUserLogin) => void;
   addNotification: (message: string, type: string) => void;
   setOTP: (param: string) => void;
   loginFailed: () => void;
   loginSuccess: () => void;
   resendOTP: (param: IUserLogin) => void;
   user: IUserState
   history: any;
   subscribeNewsLetter: (param: { email: string }) => void;
   forceToLogin: (param: boolean) => void;
   setLang: (param: ICoreLangType) => void;
   lang: ILangState
   logout: () => void;
   getBanner: () => void;
   getPage: ({ }) => void;
}

interface IState {
   show: boolean
   matchedLocation: boolean
   toggelLogin: boolean
   toggelNotification: boolean;
   redirectTo: string
}

class Container extends React.PureComponent<IProps, IState> {
   state = {
      show: false,
      matchedLocation: true,
      toggelLogin: false,
      toggelNotification: false,
      redirectTo: ''
   }

   componentDidMount() {
      const { setOTP, loginFailed, core, updateCity } = this.props;
      if (!core.selectedCity.id) {
         this.setState({ matchedLocation: false, show: true })
      } else {
         let selected_city = core.cities.find(item=> item.id === core.selectedCity.id);
         if(selected_city){
         updateCity(selected_city);
      }
      }
      setOTP('')
      loginFailed()
   }

   async componentDidUpdate(prevProps: IProps, prevState: IState, snapshot: any) {
      const { core } = this.props
      const { show } = this.state
      if (!core.selectedCity.id) {
         // this.setState({ matchedLocation: false, show: true })
         // try {
         //    const { city } = await geoCode.getLocation()
         //    const checkCity = core.cities.find((item) => item.city.indexOf(city) >= 0)
         //    const defaultCity = core.cities.find((item) => item.city.indexOf('Riyadh') >= 0)
         //    // this.selectCity(defaultCity ? defaultCity : core.selectedCity)
         //    if (!checkCity) {
         //       this.setState({ matchedLocation: false, show: true })
         //    }
         // } catch(e) {
         //    const defaultCity = core.cities.find((item) => item.city.indexOf('Riyadh') >= 0)
         //    this.selectCity(defaultCity ? defaultCity : core.selectedCity)
         // }
      }
      if (core.forceToLogin) {
         this.onToggelLogin()
         this.props.forceToLogin(false)
      }
   }

   // static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
   //    if (!nextProps.core.selectedCity.id) { 
   //       return {
   //          ...prevState,
   //          matchedLocation: false, 
   //          show: true
   //       }
   //    }
   //  }

   onToggelLocation = () => {
      const { show } = this.state;
      const { children } = this.props;
      //console.log('children.key', children.key);

      if (children.key === 'home')
         this.setState({ show: !show })
   }

   selectCity = (city: ICoreCities) => {
      //console.log('city', city);

      const { updateCity } = this.props;
      updateCity(city)
      this.setState({ show: false })
   }

   onToggelLogin = () => {
      const { toggelLogin } = this.state;
      const { setOTP, loginFailed, logout, history } = this.props;
      const { isLoggedin } = this.props.auth;
      if (!isLoggedin) {
         this.setState({ toggelLogin: !toggelLogin })
      } else {
         logout();
         history.push('/')
      }
      setOTP('')
      loginFailed()

   }

   onLogin = (param: IUserLogin) => {
      //console.log('param', param);

      const { login, core } = this.props;
      login({ ...param, city: core.selectedCity.id })
   }

   resendOTP = (param: IUserLogin) => {
      const { login, core, resendOTP } = this.props;
      resendOTP({ ...param, city: core.selectedCity.id })
   }

   onVerify = () => {
      const { loginSuccess, history } = this.props;
      const { redirectTo } = this.state;
      this.props.addNotification('Loggedin succefully', 'notice')
      this.onToggelLogin()
      loginSuccess();
      setTimeout(() => {
         if (redirectTo === 'notification') {
            this.onToggelNotification()
         } else if (redirectTo === 'cart') {
            history.push(PATH.CART)
         }
      }, 1000)

   }

   onToggelNotification = () => {
      const { toggelNotification } = this.state;
      const { auth } = this.props;
      if (!auth.isLoggedin) {
         this.onToggelLogin()
         this.setState({ redirectTo: 'notification' })
      } else {
         this.setState({ toggelNotification: !toggelNotification })
      }
   }

   renderNotification = () => {
      const { user } = this.props;
      return (
         <div className="modal-body">
            {
               !user.notification.length ? (
                  <div className="notify_imgnodata">
                     <img src={images.notificationNodata} />
                     <div className="notify_nodatacontent">
                        <h3>{i18n.t(`notification.no-record.title`)}</h3>
                        <p>{i18n.t(`notification.no-record.description`)}</p>
                     </div>
                  </div>
               ) : (
                     <div className="notify_listing">
                        <ul>
                           {this.notificationList()}
                        </ul>
                     </div>
                  )
            }

         </div>
      );
   }

   notificationList = () => {
      const { user } = this.props;
      //console.log('user.notification', user.notification);

      return user.notification.map((row: IUserNotification, index: number) => {
         return (
            <NotificationCard items={row} />
         )
      })
   }

   onOpenCart = () => {
      const { toggelNotification } = this.state;
      const { auth, history } = this.props;
      if (!auth.isLoggedin) {
         this.onToggelLogin()
         this.setState({ redirectTo: 'cart' })
      } else {
         // createBrowserHistory().push(PATH.CART)
         history.push(PATH.CART)
      }
   }

   onSubscribe = (email: string) => {
      const { subscribeNewsLetter } = this.props
      subscribeNewsLetter({ email })
   }

   changeLang = (lang: ICoreLangType) => {

      switchLang(lang, this.onUpdateLang)

   }

   onUpdateLang = async () => {
      const { setLang, getBanner } = this.props;
      // @ts-ignore-start
      setLang(i18n.language)
      this.props.getPage({
         slug: 'privacy_policy',
         lang: i18n.language
      })
      this.props.getPage({
         slug: 'terms_condition',
         lang: i18n.language
      })
      // getBanner()
      // const { website_messages }  = await api.core.getLanguage({ lang: i18n.language })
      // @ts-ignore-start
      // initI18n(i18n.language, { [i18n.language]: { translation: website_messages } })
   }

   onLogout = () => {
      const { logout, history } = this.props;
      logout();
      window.location.href = '/'
      // history.push('/')
   }

   render() {
      const { children, core, auth, user, lang } = this.props
      const { show, matchedLocation, toggelLogin, toggelNotification } = this.state;
      //console.log('this.props', this.props);

      return (
         <div className="toolbar-enabled">
            <Header
               onClickLocation={this.onToggelLocation}
               city={core.selectedCity}
               onClickProfile={this.onToggelLogin}
               isLoggedin={auth.isLoggedin}
               onNotificationToggel={this.onToggelNotification}
               notificationCount={user.notification.filter((item: IUserNotification) => item.status !== "0").length}
               openCart={this.onOpenCart}
               changeLang={this.changeLang}
               lang={lang}
               isEdit={children.key === 'home'}
            // onLogout={this.onLogout}
            />
            {children}
            <Location
               onClose={this.onToggelLocation}
               show={show}
               cities={core.cities}
               selectCity={this.selectCity}
               selectedCity={core.selectedCity}
               matchedLocation={matchedLocation}
            />
            <Footer
               onSubscribe={this.onSubscribe}
               newsletterSubscribing={core.newsletterSubscribing}
               openCart={this.onOpenCart}
            />
            <LoginModal
               show={toggelLogin}
               onToggel={this.onToggelLogin}
               loading={auth.loading}
               onLogin={this.onLogin}
               otp={auth.otp}
               onVerify={this.onVerify}
               sendingOTP={auth.sendingOTP}
               resendOTP={this.resendOTP}
            />
            <ModalRight
               onToggel={this.onToggelNotification}
               show={toggelNotification}
               title={i18n.t(`notification.title`)}
               extraClass={!user.notification.length ? 'notificationnodata-modal' : ''}
            >
               {this.renderNotification()}
            </ModalRight>
         </div>
      )
   }
}

const mapStateToProps = (state: IRootState) => ({
   core: state.core,
   auth: state.auth,
   user: state.user,
   lang: state.lang,
});

const mapDispatchToProps = {
   updateCity: coreActions.updateCity,
   login: authActions.login,
   addNotification: coreActions.addNotification,
   setOTP: authActions.setOTP,
   loginFailed: authActions.loginFailed,
   loginSuccess: authActions.loginSuccess,
   resendOTP: authActions.resendOTP,
   subscribeNewsLetter: coreActions.subscribeNewsLetter,
   forceToLogin: coreActions.forceToLogin,
   setLang: languageActions.setLang,
   logout: authActions.logout,
   getBanner: homeActions.getBanner,
   getPage: coreActions.getPage

}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(Container)))