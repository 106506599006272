import { put, takeEvery, call, delay } from 'redux-saga/effects'
import { auth as authActions, user as userActions, core as coreActions } from '../actions'
import { IActionLogin, IActionResendOTP, IActionWareHouseLogin, IActionAuthLogout, IActionAuthForgotPassword, IActionAuthResetPassword } from '../actions/authActions';
import { api } from '../services';
import { push } from 'connected-react-router'

export function* loginSaga(action: IActionLogin) {
  try {
    console.log('action.payload', action.payload);

    const { statusCode, message, userData, otp } = yield call(api.auth.login, action.payload);
    console.log('userData', statusCode, userData, otp, message);
    if (statusCode) {
      yield put(userActions.setProfile(userData));
      yield put(authActions.setOTP(otp));
    } else {
      yield put(coreActions.addNotification(message, 'error'));
      yield put(authActions.loginFailed());
    }

    // yield put(authActions.loginSuccess());
  } catch (e) {
    yield put(authActions.loginFailed());
  }

}

export function* reSendOTPSaga(action: IActionResendOTP) {
  try {
    const { userData, otp } = yield call(api.auth.login, action.payload);
    console.log('userData', userData, otp);

    yield put(userActions.setProfile(userData));
    yield put(authActions.setOTP(otp));
  } catch (e) {
    yield put(authActions.loginFailed());
  }

}

export function* wareHouseLogin(action: IActionWareHouseLogin) {
  try {
    const { data, statusCode, message = '' } = yield call(api.auth.adminLogin, action.payload);
    console.log('userData', data);
    if (statusCode !== 200) {
      yield put(coreActions.addNotification(message, 'error'))
      yield put(authActions.loginFailed());
      yield put(push('/'))
    } else {
      yield put(userActions.setProfile(data));
      yield put(authActions.loginSuccess());
    }

  } catch (e) {
    yield put(authActions.loginFailed());
  }

}

export function* logoutSaga(action: IActionAuthLogout) {
  try {
    yield put(userActions.resetUser());
  } catch (e) {

  }
}

export function* forgotPassSaga(action: IActionAuthForgotPassword) {
  try {

    const { statusCode, otp = '', user_id = '' } = yield call(api.auth.forgotPassword, action.payload);
    if (otp) {
      yield put(authActions.setOTP(otp));
    } else {
      yield put(coreActions.addNotification('Phone number not exist', 'error'))
    }
    yield put(authActions.forgotPasswordSuccess(user_id))
  } catch (e) {

  }
}

export function* resetPassSaga(action: IActionAuthResetPassword) {
  try {
    const { statusCode } = yield call(api.auth.resetPassword, action.payload)
    yield put(coreActions.addNotification('Password reset successfully', 'notice'))

  } catch (e) {

  }
}


export default [
  takeEvery(authActions.ActionTypes.LOGIN, loginSaga),
  takeEvery(authActions.ActionTypes.RESEND_OTP, reSendOTPSaga),
  takeEvery(authActions.ActionTypes.WEREHOUSE_LOGIN, wareHouseLogin),
  takeEvery(authActions.ActionTypes.LOGOUT, logoutSaga),
  takeEvery(authActions.ActionTypes.FORGOT_PASSWORD, forgotPassSaga),
  takeEvery(authActions.ActionTypes.RESET_PASSWORD, resetPassSaga),
];