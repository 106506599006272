import { put, takeEvery, call, delay, select } from 'redux-saga/effects'
import { home as homeActions} from '../actions'
import { api, i18n } from '../services';
import { IActionGetData, IActionHomeGetBanner } from '../actions/homeActions';
import { getCity } from '../selectors'
const type: { [key in string]: string } = {
  about_us: 'about',
  privacy_policy: 'policy',
  terms_condition: 'terms'
}

export function* getData(action: IActionGetData) {
  console.log('getPages', action.payload);
  
  try {
    const data  = yield call(api.home.getData, action.payload) 
    const { brandsList, knowyourtireData, testimonialsListData} = data
    yield put(homeActions.setData({ brandsList, knowyourtireData, testimonialsListData }))
    console.log('data', data);
    
  } catch(e) {
    console.log(e);
    
  }

}

export function* getBanner(action: IActionHomeGetBanner) {
  
  try {
    const city = yield select(getCity)
    console.log('action.payload.getBanner', action);
    
    // @ts-ignore-start
    const { banners }  = yield call(api.home.getBanner, { ...action.payload, city_id: city.id, }) 
    console.log('banners', banners);
    
    yield put(homeActions.setBanner(banners))
  } catch(e) {
    console.log(e);
  }

}



export default [
  takeEvery(homeActions.ActionTypes.GET_DATA, getData),
  takeEvery(homeActions.ActionTypes.GET_BANNER, getBanner),
];